import { useState, useEffect } from "react";
import { signIn, signOut, fetchAuthSession, getCurrentUser  } from '@aws-amplify/auth';
import { MyAuth } from './routes/_login';
import App from './routes';
import { useAuth } from './app/zustore';
import { enqueueSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from "@tanstack/react-router";

const AuthenticatedApp = () => {
  const {isAuthenticated, setIsAuthenticated} = useAuth(s=>({isAuthenticated:s.isAuthenticated, setIsAuthenticated:s.setIsAuthenticated}));
  const [authError, setAuthError] = useState(null);
  const resetAuth = useAuth((s) => s.resetAuth);
  const setAuth  = useAuth((s) => s.setAuth);
  const queryClient = useQueryClient()
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login/_signIn');
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    checkAuth();
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut();
      setIsAuthenticated(false);
      // invalidate the session
      resetAuth();
      enqueueSnackbar('Signed out successfully', { variant: 'success' });
      queryClient.clear()
    
    } catch (error) {
      console.log('Error signing out:', error);
    }
  };

  const checkAuth = async () => {
    try {
      const user = await getCurrentUser();
      enqueueSnackbar(`Welcome ${user.username}`, { variant: 'success' });
      setIsAuthenticated(true);
    } catch (error) {
      setIsAuthenticated(false);
    }
  };
  
  const handleSignIn = async () => {
    const user = await getCurrentUser()
    console.log(user);
    const session = await fetchAuthSession();
    console.log(session)
    enqueueSnackbar(`Signed in successfully, Weclome ${user.username}`, { variant: 'success' });
    setIsAuthenticated(true);
    setAuth(session, user);
    setAuthError(null); // reset error message on successful sign in
  };


  if (!isAuthenticated) {
    return null
  }
  return <App signOut={handleSignOut} /> 

};

export default AuthenticatedApp;