import VesselMap from './VesselMap';


export function LocationBox({ isQuery, width }) {
  // console.log("Location box, ", item);
  return (
    <div 
      style={{
        borderWidth: "2px", borderColor:"black", display: 'flex',
        flexDirection: 'row', marginBottom: '6px', width: '96%'}} 
      className="locationBox">
        <VesselMap isQuery={isQuery}/>
    </div>
  );
};