import React, { useMemo } from 'react';
import { Plot2d } from '../../utils/Plots/EChartPlots'
import { Grid, Card, CardContent } from '@mui/material';
import { useVessel, useFleetData, useLiveData } from "../../app/zustore";

function DynoGrid({ data, gridColumn }) {
  // console.log("Gauge grid, ", {current: current, data: data});
  const fleetData = useFleetData((state) => state.fleetData);
  const liveData = useLiveData((state) => state.liveData);
  const vessel_id  = useVessel((state) => state.vessel_id);

  const gauges = useMemo(() => {
    const latest = data[data.length-1];
    console.groupCollapsed("DynoGrid useMemo")
    console.log(vessel_id)
    console.log(latest)
    console.table(data.slice(1,3))
    console.groupEnd()
    return [
    {
      value:latest['motor_data.0.power'],
      title:"Electrical Power", 
      units:"kW",
      x: "sample_time_str", 
        y:"motor_data.0.power"},
    {
        value:  latest?.['motor_data.0.velocity'] ? latest?.['motor_data.0.velocity'] : 0,
        title:"Motor Rpm", 
        units:"rpm",
        x: "sample_time_str", 
        y:"motor_data.0.velocity"},
    {
        value:  latest['motor_data.0.torque'] ? latest['motor_data.0.torque'] : 0,
        title:"Motor Torque", 
        units:"lb-ft",
        x: "sample_time_str", 
        y:"motor_data.0.torque"},
    {
        value:  latest['motor_data.0.horse_power'] ? latest['motor_data.0.horse_power'] : 0,
        title:"Motor Horsepower", 
        units:"hp",
        x: "sample_time_str", 
        y:"motor_data.0.horse_power"},
    {
      value: latest?.["battery_data.battery_soc"],
       title:"State Of Charge", 
       units:"%",
       x: "sample_time_str", 
       y:"battery_data.battery_soc"},
    {
      value: latest?.["battery_data.battery_voltage"],
       title:"Battery Voltage", 
       units:"V",
       x: "sample_time_str", 
       y:"battery_data.battery_voltage"},
    {
       value: latest?.["battery_data.battery_current"],
       title:"Battery Current", 
       units:"A",
       x: "sample_time_str", 
       y:"battery_data.battery_current"},
    {
        value:  latest?.["battery_data.high_cell_temp"],
        title:"Highest Cell Temp", 
        units:"°C",
        x: "sample_time_str", 
        y:"battery_data.high_cell_temp"},
    {
        value:  latest?.["battery_data.low_cell_voltage"],
        title:"Lowest Cell Voltage", 
        units:"",
        x: "sample_time_str", 
        sigfigs: 3,
        y:"battery_data.low_cell_voltage"},
    {
        value: latest?.['motor_data.0.belt_temp'],
        title:"Belt Temp", 
        units:"°C",
        x: "sample_time_str", 
        y:"motor_data.0.belt_temp"},
    {
        value: latest?.['battery_data.battery_current'],
        title:"Battery Current", 
        units:"amps",
        x: "sample_time_str", 
        y:"battery_data.battery_current"},
    {
        value: latest?.['motor_data.0.motor_temp'],
        title:"Motor Temp", 
        units:"°C",
        x: "sample_time_str", 
        y:"motor_data.0.motor_temp"},
    ]  
  }, [data, liveData])
  
  const stats = useMemo(() => {
    return gauges.map(gauge => {
        const series = data.map(item => item[gauge.y]).filter(value => typeof value === 'number' && value!==null && value!==undefined);
        const sum = series.reduce((a, b) => a + b, 0);
        const min = Math.round((Math.min(...series)*10))/10;
        const max = Math.round((Math.max(...series)*10))/10;
        return {
            average: Math.round((sum / series.length)*10)/10,
            min,
            max
        };
    });
  }, [data, gauges]);

  const cardColors = {
    0: [
        '#EBF5FBb0',
        '#D6EAF8b0',
        '#AED6F1b0',
        '#85C1E9b0',
        '#9cc6e2b0',
    ],
    1: [
        '#97eaafb0',
        '#4ea363b0',
        '#009688',
        '#33a340',
        '#acd67f',
    ]
    }[gridColumn];

  function displayValue(val, sigfigs=1){
    return Math.round(val*(10**sigfigs))/(10**sigfigs)      
  }
  

  return (
    <div className='LiveDataGaugeGrid'> {/* Adjust the spacing here */}
        
      {gauges.map((item, index) => {
        if (item.value === undefined || item.value === null ) {
          return null;
        }
        return (
          <div key={index} className='LiveDataGaugeCard dualDyno'>
            <div style={{ borderRadius: '10px', boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.3)', backgroundColor:cardColors[index % cardColors.length], height:"100%", width:"100%", overflow: 'visible'}}> {/* Increase borderRadius here */}
                  <h2 style={{fontSize:'2.5vh', marginBlockEnd:"0.1em", marginBlockStart:"0.1em"}}>{item.title}</h2>
                  <h1 style={{fontSize:'3vh', marginBlockEnd:"0.1em", marginBlockStart:"0.1em"}}>{displayValue(item.value, item?.sigfigs)} {item.units}</h1>
                  <h2 style={{fontSize:'2vh', marginBlockEnd:"0.1em", marginBlockStart:"0.1em"}}>Min: {stats[index].min} | Max: {stats[index].max}</h2>
                  <h2 style={{fontSize:'2vh', marginBlockEnd:"0.1em", marginBlockStart:"0.1em"}}>Average: {stats[index].average.toFixed(2)} {item.units}</h2>
                  <Plot2d items={data} xAxisKey={item.x} yAxisKey={item.y} chartType='line'/> 
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default DynoGrid;