import { forwardRef, useCallback } from "react";
import { useSnackbar, SnackbarContent, CustomContentProps } from "notistack";
import { Typography, Card, CardActions } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from '@mui/system';
import { CloudSync } from '@mui/icons-material';

const StyledSnackbarContent = styled(SnackbarContent)({
  "@media (min-width:600px)": {
    minWidth: "344px !important"
  }
});

const StyledCard = styled(Card)({
  width: "100%",
  backgroundColor: "#00b9b8"
});

const StyledTypography = styled(Typography)({
  color: "#000"
});

const StyledActionRoot = styled(CardActions)({
  padding: "8px 8px 8px 16px",
  justifyContent: "space-between"
});

const StyledIcons = styled('div')({
  marginLeft: "auto"
});


interface QueryInfoProps extends CustomContentProps {
  showExpand?: boolean;
}

const QueryInfo = forwardRef<HTMLDivElement, QueryInfoProps>(
  ({ id, showExpand, ...props }, ref) => {
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <StyledSnackbarContent ref={ref}>
        <StyledCard>
          <StyledActionRoot>
            <StyledIcons>
                <CloudSync sx={{marginRight:'10px'}} />
            </StyledIcons>
            <StyledTypography variant="body2">
              {props.message}
            </StyledTypography>
            <StyledIcons>
                <CloseIcon fontSize="small" onClick={handleDismiss}/>
            </StyledIcons>
          </StyledActionRoot>
          
        </StyledCard>
      </StyledSnackbarContent>
    );
  }
);

QueryInfo.displayName = "QueryInfo";

export default QueryInfo;
