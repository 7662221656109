import { useState, useEffect, useMemo, useRef } from 'react';

import { usePlots, useVessel, useModal } from '../../app/zustore';

// import Plotly from 'plotly.js-gl2d-dist-min'
const Plotly = window.Plotly;

export const format_label = (label) => {
  try {
    // remove the data path from the label by replacing battery_data_ with ''
    if (label === null || label=== undefined) {
      return ''
    }
    return label.replace(/battery_data_|telemetry_data_|motor_data_\d|obc_data_\d|ecu_data_\d|sensor_data_\d|system_performance_data_\d/g, "").split(".")[label.split(".").length-1].replace(/_/g, " ");
  } catch (err) {
    return ''
  }
}

export default function Plotter({ env, isMobile, dataset, contentAreaHeight, ...props }) {
  const datasource = dataset.source;
  const data  = dataset.data;
  const preset = usePlots(s=>s.preset)
  const plot = usePlots( s=>s.plots?.[datasource]?.[preset] )
  const vessel_id = useVessel(s=>s.vessel_id)
  const plotContainerRef = useRef(null);
  const [plotRendered, setPlotRendered] = useState(false)

  useEffect(() => {
    console.log(contentAreaHeight, preset, datasource)
  }, []);
  
  const plotData = useMemo(() => {
    if (plot===null || !vessel_id || !data || data.length===0) {
      console.log("Conditions not met to create plotData")
      return null
    } 

    function unpack(rows, key) {
      return rows.map(function(row) { 
          if (key === 'sample_time_str'||key==='Pi Time'){
              return new Date(row[key])
          }
          return Math.round(row[key]*1000)/1000
      });
    } 

    let allYs = [];
    if (plot!==undefined){
      if (Object.keys(plot).find(attr=>attr=='y1') && plot.y1.length>0){
          plot.y1.forEach(yKey=>{
              allYs.push(yKey)
          })
      }
      if (Object.keys(plot).find(attr=>attr=='y2') && plot.y2.length>0){
          plot.y2.forEach(yKey=>{
              allYs.push(yKey)
          })
      }
    }

    const  scl = [
      [0, 'rgb(150,0,90)'],
      [0.125, 'rgb(0, 0, 200)'],
      [0.25,'rgb(0, 25, 255)'],
      [0.375,'rgb(0, 152, 255)'],
      [0.5,'rgb(44, 255, 150)'],
      [0.625,'rgb(151, 255, 0)'],
      [0.75,'rgb(255, 234, 0)'],
      [0.875,'rgb(255, 111, 0)'],
      [1,'rgb(255, 0, 0)']
  ];

    const traces = plot?.y1?.map((yAxisKey) => {
      return {
        x: unpack(data, plot.x),
        y: unpack(data, yAxisKey),
        marker: (plot.mode==='markers'||plot.mode==='lines+markers') && allYs.length===1  ? {
          color: unpack(data, plot.c),
          colorscale: scl,
          colorbar:{
            thickness: 10,
            titleside: 'right',
            outlinecolor: 'rgba(68,68,68,0)',
            // ticks: 'outside',
            // ticklen: 3,
            // shoticksuffix: 'last',
            ticksuffix: yAxisKey==='inst_speed'?'mph':'',
            dtick: 10
          },
          size: 12,
          line: {
            width: 1,
            opacity: 0.75
          },
          opacity: 0.75
        } : {
          opacity: 0.55,
          size: 12
        },
        yaxis: "y1",
        name: (datasource==='pi') && format_label(yAxisKey),
        type: "scattergl",
        mode: plot.mode,
        text: data?.map((item) => {
          const colorValue = plot.c ? `${plot.c}: ${item[plot.c]}<br>` : "";
          const yValues = allYs.map((yKey) => `${format_label(yKey)}: ${item[yKey]}<br>`).join("");
          return `${colorValue}${yValues}`;
        })
      };
    });
    plot?.y2.forEach(yAxisKey=>{
      traces.push(
        {
          x: unpack(data, plot.x),
          y: unpack(data, yAxisKey),
          marker: (plot.mode==='markers'||plot.mode==='lines+markers') && allYs.length===1  ? {
            color: unpack(data, plot.c),
            colorscale: [
              [0, "blue"],
              [1, "green"]
            ],
            size: 10,
            line: {
              width: 1,
              opacity: 0.75
            },
            opacity: 0.75
          } : 
          {
            opacity: 0.55,
            size: 12
          },
          yaxis: "y2",
          name: (datasource==='pi') && format_label(yAxisKey),
          type: "scattergl",
          mode: plot.mode,
          text: data?.map((item) => {
            const colorValue = plot.c ? `${plot.c}: ${item[plot.c]}<br>` : "";
            const yValues = allYs.map((yKey) => `${format_label(yKey)}: ${item[yKey]}<br>`).join("");
            return `${colorValue}${yValues}`;
          })
        }
      )
    })
    
    return traces
  }, [preset, plot, data])

  useEffect(() => {
    // console.log('plot data changed, updating plot', plotData)
    // Render the plot into the div with a ref attached
    const layout={
      // title: {text: format_label(preset), x:0.05, y:1.4},
      xaxis: { title: format_label(plot.x),
              tickmode: "auto",
              type: (plot.x==='server_time_str'||plot.x==='sample_time_str') ? 'date' : 'linear',
              nticks: isMobile?2:6,
              tickformat: (plot.x==='server_time_str'||plot.x==='sample_time_str') ? "%m/%d  %H:%M:%S" : '',
              tickangle: 0, },
      yaxis: { 
        title: isMobile ? null : (plot.y1.length===1 ? format_label(plot.y1[0]) : "primary-y"),
        contstraintoward: 'bottom',
        tickmode: 'sync',

      },
      yaxis2: {
        title:  isMobile ? null : (plot.y1.length===1 ? format_label(plot.y2[0]) : "secondary-y"),
        contstraintoward: 'bottom',
        tickmode: 'sync',
        overlaying: "y",
        side: "right",
      },
      editable: true,
      hovermode: "closest",
      hoverinfo: 'y',
      hoverlabel: {bgcolor: 'rgba(200, 200, 200, 0.1)'},
      color:'rgb(255, 255, 255)',
      paper_bgcolor: 'rgb(255, 255, 255)',
      plot_bgcolor: 'rgb(255, 255, 255)',
      autosize: true,
      automargin:true,
      fillpattern: {bgcolor:'rgba(250, 250, 250, 0.2)', fillmode:'overlay'},
      margin: isMobile?{l:30, r:35, t:20, b:30}:{l:55, r:55, t:15, b:40},
      legend: {
        x: 1,
        y: 1.2,
        xanchor: "right",
        yanchor: "top",
        bgcolor: "rgba(250, 250, 250, 0.2)",
        bordercolor: "rgba(0, 0, 0, 0.2)",
        orientation: 'h',
        borderwidth: 1,
      }, 
      // width: 450,
      height: isMobile?420:460,
      // responsive: true
    }

    const config = {
      responsive: true, 
      modeBarButtonsToRemove: [
        'hoverClosestGl2d', 
        'hoverCompareCartesian '
      ]
    }
    // console.log(plotContainerRef.current)
    if (!plotRendered){
      console.log('rendering new preset plot')
      const plot = Plotly.newPlot(plotContainerRef.current, plotData, layout, config);
      // plot.on(event=>console.log('plotly event detected', event));
      setPlotRendered(true);
    } else {
      Plotly.react(plotContainerRef.current, plotData, layout, config);
    }

  }, [plotData]); // Redraw the plot if data or layout props change

  useEffect(()=>{
    return () => {
      if (plotContainerRef.current){
        try {
            console.log('purging preset plot')
            Plotly.purge(plotContainerRef.current);
            setPlotRendered(false)
        } catch (err) {
            console.warn("Caught an error in PlotlyPlots.jsx while purging plot", err)
        }
      } else {
        console.log('no div ref to unmount against - big bad')
      }
    };
  }, [])

  return (
    <div style={{ width: '100%', height: "100%", display: 'flex', flexDirection: 'column', justifyContent:'center', alignItems:'center' }} >

      <div style={{ width: "100%", height: "100%", padding:'4px',  }}>

          <div>
            <div ref={plotContainerRef} />
          </div>
  
      </div>
      
    </div>
  );
}







