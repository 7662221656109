import Select from 'react-select';
import { format_label } from './PlotlyPlots';
import { useEffect } from 'react';

export const PlotAxisSelector = ({axis, options, type, newPlot, setNewPlot})=>{
    
    function handlePlotSelectors(newAxis) {
      const newPlotDef = {  
        x: newAxis.x,
        y1: newAxis.y1,
        y2: newAxis.y2,
        c: newAxis.c,
        mode: newAxis.mode,
        isLocal: true
      }
  
      console.log(newPlotDef)
      setNewPlot(newPlotDef)
    }

    useEffect(() => {
      if (axis==='x'){
        console.log(axis, newPlot[axis], format_label(newPlot[axis]))
      }
    
    }, [axis, newPlot])
    
  
    const handleAxisChange = (axis, selectedOption) => {
      const newValue = (axis=='y1'||axis=='y2') ? selectedOption.map((option) => option.value) : selectedOption.value;
      const newAxis = {...newPlot, [axis]:newValue};
      handlePlotSelectors(newAxis);
    };
  
    function toTitleCase(str) {
      return str.replace(
        /\w\S*/g,
        function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
    }

    const groupStyles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderStyle: "none none solid none", 
      borderWidth: "2.5px",
      borderColor: "#A0ABC0"
    };
    const groupBadgeStyles = {
      backgroundColor: '#EBECF0',
      borderRadius: '2em',
      color: '#172B4D',
      display: 'inline-block',
      fontSize: 12,
      fontWeight: 'normal',
      lineHeight: '1',
      minWidth: 1,
      padding: '0.16666666666667em 0.5em',
      textAlign: 'center',
    };

    const formatGroupLabel = (data) => (
      <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
      </div>
    );
    
    if (!newPlot?.isLocal){
      return null
    }
    if (type=='select'){
      return (
        <div style={{ height: "100%", width:'90%', justifyContent: 'center', alignItems: 'flex-start', display:'flex', flexDirection:'column', marginBottom:'6px' }}>
          <label style={{fontWeight:'400', fontSize:'larger', marginInlineEnd:'20px', marginTop:'8px'}}>{toTitleCase(axis)}{axis==='mode'?'':' Axis'}:</label>
          <Select
            className='plot-axis-selector'
            options={options}
            style={{width:'100%'}}
            formatGroupLabel={formatGroupLabel}
            onChange={(event)=>handleAxisChange(axis, event)}
            value={{ value: newPlot[axis], label: format_label(newPlot[axis]) }}
          />
        </div>
      )
    } else {
      // 'select-multi'
      return (
        <div style={{ height: "100%", justifyContent: 'center', alignItems: 'flex-start', display:'flex', flexDirection:'column', marginBottom:'6px' }}>
          <label style={{fontWeight:'400', fontSize:'larger', marginInlineEnd:'20px', marginTop:'8px'}}>{axis=='y1'?'Primary Y Axis':'Secondary Y Axis'}</label>
          <Select
            className='plot-axis-selector'
            options={options}
            onChange={(event)=>handleAxisChange(axis, event)}
            isMulti={true}
            formatGroupLabel={formatGroupLabel}
            menuPortalStyles={{maxHeight:"10px" }}
            value={newPlot[axis].map((plotKey) => ({value:plotKey, label:format_label(plotKey)}))}
          />
        </div>
      )
    }
  }