import { createFileRoute, redirect, useLocation } from '@tanstack/react-router'
import { Expander } from '../../../fleet/Expander'
import { FleetTripViewer } from '../../../fleet/FleetTripViewer'
import { Skeleton, useMediaQuery } from '@mui/material';
import { useAuth, useLatestTrips, useSelectedNodeStore, useTrips, useUser, useVesselList } from '../../../app/zustore';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { fetchData } from '../../../app/QueryHandler';
import { TripOverview } from '../../../trips/trip-overview';
import { useInterval } from '../../../hooks/interval';
import { processAnalysisData } from '../../../utils/dataProcessing';
import { CompressedCSVToDataFrame } from '../../../utils/utilityMethods';
import GaugeGrid from '../../../vessel/GaugeGrid';
import MapPlotter from '../../../utils/Plots/MapPlots';
import { TripMap } from '../../../trips/TripMap';

export const Route = createFileRoute('/_authenticated/trips/trip')({
  component: TripViewer,
  notFoundComponent: ()=>{
    return (
        <div>
            <h1>The Trip you are trying to view could not be loaded.</h1>
        </div>
    )
  },
  beforeLoad: ({ search, location })=>{
    if (location=='/trips/select'){
      return
    }
    const setTripData = useTrips.getState().setTripData
    setTripData([])
    const { vessel_id, start_time, end_time } = search;
    console.log('/trips/trip before load', location.search.vessel_id, location.search.start_time, location.search.end_time, location)
    
    if (!vessel_id || !search.start_time || !search.end_time){
        throw redirect({
            to: '/trips/select',
          })
    }
  }
})

async function fetchTripData(vessel_id, start_time, end_time){

    const setTripData = useTrips.getState().setTripData;
    let itemsToFetch = 0;
    const token = useAuth.getState().token;
    
    const fetchDataRecursively = async (params, existingData) => {
        
        let {status, body} = await fetchData({ method: 'GET', resource: 'vessel-data/ranged', token: token, params: params });
        console.log(body);
        
        if (status === 200){ // check for valid response
            let df, newData, mergedData=[];            
            
            if (body.data.length>10){
                df = CompressedCSVToDataFrame(body.data);
                newData = df.filter(item => !existingData.some(existingItem => existingItem.sample_time === item.sample_time)); // Filter out any items that already exist in the existing data
                mergedData = [...existingData, ...newData].sort((a, b) => {
                return new Date(a.sample_time) - new Date(b.sample_time);
            }); // Merge the existing data with the new data and sort by sample_time
            // setTripData(mergedData);
        }
        
        if (Object.keys(body.metadata).includes('TotalCount')){
            // setStatText(`${body.metadata.TotalCount} rows to fetch from AWS, total rows: ${mergedData.length}`)
            itemsToFetch = body.metadata.TotalCount;
        } else {
            // setStatText(`${itemsToFetch} rows to fetch from AWS, total rows: ${mergedData.length}`)
        }
        // setProgress(mergedData.length, itemsToFetch);
        // update the stat text here to give feedback to the user
        
        const ClientToken = body.metadata.ClientToken
        if (body.metadata && Object.keys(body.metadata).includes('NextToken') && body.metadata.NextToken!=='None') {
            // Update the qString with the LastEvaluatedKey and recursively fetch again
            await new Promise(resolve => setTimeout(resolve, 30));
            
            const NextToken = body.metadata.NextToken;  
            console.log("sample_time found in response, sending follow-up request:")
            params.client_token = ClientToken
            // Remove any existing next_token parameter from qString
            let prevNextToken = params.next_token
            if (prevNextToken!=NextToken){
                params.next_token = NextToken
                return fetchDataRecursively(params, mergedData);
            } else {
                console.log('NextToken not changing')
                return {error:'NextToken not changing', data:mergedData}
            }
        } else if (df!==undefined){
            console.log("Done recursing")
            console.table(df.slice(0,3))
            console.table(mergedData.slice(0,3))
            return {error:null, data:mergedData}
        } else {
            return {error:'To be determined', data:null}
        }
        
    } else { // invalid response recieved, return an error and don't recurse
        // Finished recursion, Return the data to the parent function to be processed
        return {error:status, data:null}
    }
    }

    const params = {
        vessel_id: vessel_id,
        start_time: start_time,
        end_time: end_time
    }
    console.log("Initial params:", params)

    const existingData = [];
    const {error, data} = await fetchDataRecursively(params, existingData);

    return {error, data}
};
    
function TripViewer(){
    const { vessel_id, start_time, end_time } = Route.useSearch();
    const isMobile = useMediaQuery('(max-width:600px)');
    const [tripData, setTripData] = useState([])
    const vesselList = useVesselList(s=>s.vesselList)
    const vessel = useMemo(()=>vesselList.find(v=>v.vessel_id==vessel_id), [vessel_id, vesselList])

    useEffect(() => {
        console.log('trips/trip', vessel)        
    }, []);

    const tripQuery = useQuery({
        queryKey: ['trip-query'],
        queryFn: async () => {
            console.log('tripQuery entry point')
            const {error, data} = await fetchTripData(vessel_id, start_time, end_time)
            // console.log(error, data)
            if (error != null ) throw new Error(error)
            return data;
        },
        enabled: (vessel_id !== null) && (vessel !== undefined) && (vessel !== null),
        // refetchInterval: activeBoat?1000 * 10:1000*60*5, // 10 seconds when active, 5min otherwise
        retry: 2,
        onSuccess: (data) => {
          // const ;
        //   console.log(data)
            if (data.length > 10) {
                const processedData = processAnalysisData(data, vessel);
                setTripData(processedData);
            }
        },
        onError: (error) => {
          console.log(`No data for ${vessel.vessel_name}, throttling back the query interval`)
          
        }
      });

    if (tripQuery.isLoading || (tripQuery.isFetching && tripData.length==0)){
        return (
            <Skeleton variant='rounded' height={'80%'} width={'80%'}/>
        )
    } else if (tripQuery.isFetched && tripData){
        return (
            <div className='trip-viewer-page'>
                <TripMap data={tripData} />
                <GaugeGrid isMobile={isMobile} data={tripData} vessel_id={vessel_id}/>
                {/* <MapPlotter data={tripData} source='aws' isMobile={isMobile} vessel_id={vessel_id}/> */}
            </div>
        )
    }
}