import { 
    Box, 
    Modal, } from '@mui/material';
import { useGroups } from '../app/zustore';
import { useEffect, useState } from 'react';
// import VesselInfoCollector from '../Panels/AddVessel/VesselInfoCollector';

export const AddVesselModal = ({ showModal, setShowModal }) => {
    const group = useGroups((state)=>(state.selectedGroup))
    const [vessel, setVessel] = useState({isParticleDevice:false});

    useEffect(() => {
        if (group?.node_id){
          const newNode = group.node_id;
          
          setVessel({
            ...vessel, 
            node_id: newNode
          })}
      }, [group]);

    return (
        <Modal 
            open={showModal} 
            onClose={()=>{setShowModal(0)}}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={{ ...styles.box, width: '87.5%', maxWidth: '1000px', }}>
                <Box sx={styles.InnerBox}>
                    {/* <VesselInfoCollector   
                        vessel={vessel} 
                        setVessel={setVessel}
                        setShowModal={setShowModal}
                    /> */}
                </Box>
            </Box>
        </Modal> 
    )
}

const styles = {
    box: {
        position: 'absolute',
        top: '50%',
        maxHeight:'70%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        bgcolor: '#e1e1e1',
        borderRadius: '15px',
        boxShadow: 24,
        overflowY: 'hide',
        display: 'flex',
        flexDirection:'column',
        alignContent:'center',
        justifyContent:'center',
    },
}