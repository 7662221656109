import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useFetchStatus, useVessel } from '../app/zustore';
import "./progressBar.css"; // Import your CSS file for styling

export default function LinearDeterminate() {
    const { vessel_id } = useVessel((state)=>({vessel_id:state.vessel_id}));
    const { total, current, statText } = useFetchStatus((s)=>({
        total:s.total, 
        current:s.current, 
        statText:s.statText
    }));
    const progress = (total!==0) ? (current / total * 100) : 0;

    // console.log(total, current, progress, vessel_id, statText)

  if (total===0){
    return null;
  }

  if (progress<100 && statText!=='Dataset cleared'){
    return (
      <Box sx={{ width: '80%', marginLeft:'auto', marginRight:'auto' }}>
        <LinearProgress variant="determinate" value={progress} sx={{color:'#00b9b8'}}/>
        <a>{Math.round(progress)} %</a>
      </Box>
    );
  }
}