import { useState } from "react";
import dayjs from 'dayjs';
import { downloadCsv, uuidv4} from '../../utils/utilityMethods'
import Box from '@mui/material/Box';
import { Button, Modal } from '@mui/material';
import ErrorBoundary from "../../utils/ErrorBoundary";
import { useAnalysisData, useNumPlots, useSelect, useVessel, useVesselList, useAuth, usePlots, useFetchStatus, useModal} from "../../app/zustore";
import { CompressedCSVToDataFrame } from "../../utils/utilityMethods";
import { fetchData } from "../../app/QueryHandler";
import { processAnalysisData } from "../../utils/dataProcessing";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { ModalTypes, DatasourceTypes } from "../../Types/enums";
import RelativeTimePicker from "./RelativeTimePicker";
import { AbsoluteTimePicker } from "./AbsoluteTimePicker";
import { TripSelector } from "./TripSelector";
import { LogFileLoader } from "./LogFileLoader"
import { LogSelector } from "./LogSelector";

export const DatasetLoader=( { isMobile, showModal, setDataset } )=>{
    let [startTime, setStartTime] = useState(dayjs(Date.now() - 12*60*60*1000));
    let [endTime, setEndTime] = useState(dayjs(Date.now()));  
    const { analysisData, setAnalysisData } = useAnalysisData();
    const {modalType, setShowModal} = useModal()
    // const vessel_id = useVessel(s=>s.vessel_id);
    const vessel_id = useVessel(s=>s.vessel_id);
    const vesselList = useVesselList(s=>s.vesselList)
    const vessel = vessel_id.search(",")<0 ? vesselList.find(vessel => vessel.vessel_id === vessel_id) : {vessel_id:vessel_id, isParticle:false}; // B2B Dyno Artifact
    const {statText, setStatText, total, current, progress, setProgress} = useFetchStatus();
    const isParticle = vessel?.isParticle;
    const [fetchLabel, setFetchLabel] = useState(`Query`)
    const token = useAuth((state)=>state.token);
    const [toggle, setToggle] = useState(DatasourceTypes.Relative)

    async function fetchAnalysisData(){
        setShowModal(0)
        setFetchLabel("Loading...")
        setProgress(0, 0)
        console.groupCollapsed("fetchAnalysisData")
        let itemsToFetch = 0;
        
        const fetchDataRecursively = async (params, existingData) => {

            let {status, body} = await fetchData({ method: 'GET', resource: 'vessel-data/ranged', token: token, params: params });
            console.log(body);

            if (status === 200){ // check for valid response
                let df, newData, mergedData=[];
                
                
                if (body.data.length>10){
                    df = CompressedCSVToDataFrame(body.data);
                    newData = df.filter(item => !existingData.some(existingItem => existingItem.sample_time === item.sample_time)); // Filter out any items that already exist in the existing data
                    mergedData = [...existingData, ...newData].sort((a, b) => {
                        return new Date(a.sample_time) - new Date(b.sample_time);
                    }); // Merge the existing data with the new data and sort by sample_time
                }
                
                if (Object.keys(body.metadata).includes('TotalCount')){
                    setStatText(`${body.metadata.TotalCount} rows to fetch from AWS, total rows: ${mergedData.length}`)
                    itemsToFetch = body.metadata.TotalCount;
                } else {
                    setStatText(`${itemsToFetch} rows to fetch from AWS, total rows: ${mergedData.length}`)
                }
                setProgress(mergedData.length, itemsToFetch);
                // update the stat text here to give feedback to the user
                
                const ClientToken = body.metadata.ClientToken
                if (body.metadata && Object.keys(body.metadata).includes('NextToken') && body.metadata.NextToken!=='None') {
                    // Update the qString with the LastEvaluatedKey and recursively fetch again
                    await new Promise(resolve => setTimeout(resolve, 5000));
                    
                    const NextToken = body.metadata.NextToken; 
                    console.log("sample_time found in response, sending follow-up request:")
                    params.client_token = ClientToken
                    // Remove any existing next_token parameter from qString
                    let prevNextToken = params.next_token
                    if (prevNextToken!=NextToken){
                        params.next_token = NextToken
                        return fetchDataRecursively(params, mergedData);
                    } else {
                        console.log('NextToken not changing')
                        return {error:'NextToken not changing', df:mergedData}
                    }
                } else if (df!==undefined){
                    console.log("Done recursing")
                    console.table(df.slice(0,3))
                    return {error:null, df:mergedData}
                } else {
                    return {error:'To be determined', df:null}
                }

            } else { // invalid response recieved, return an error and don't recurse
                // Finished recursion, Return the data to the parent function to be processed
                return {error:'No Data returned', df:null}
            }
        };

        console.log(vessel_id)
        console.log([startTime, endTime]);
        if (startTime>endTime){ // make sure start time is before the end time
            startTime = startTime + endTime;
            endTime = startTime - endTime;
            startTime = startTime - endTime;
        }
        const params = {
            start_time: startTime.valueOf().toString(),
            end_time: endTime.valueOf().toString(),
            vessel_id: vessel_id
        }
        console.log("Initial params:", params)

        const existingData = analysisData[vessel_id] || [];
        const {error, df} = await fetchDataRecursively(params, existingData);

        if (error===null) {        
            const processedData = processAnalysisData(df, vessel);
            let newStatText = `${processedData.length} total rows fetched from AWS`
            const source = isParticle?'bud':'pi';
            const dataset_id = uuidv4()
            const newDataset = {
                id: vessel_id,
                dataset_id: dataset_id,
                name: vessel.vessel_name,
                source:source, 
                data:processedData,
                num_rows: processedData.length,
                start_time: new Date(startTime).toLocaleString(),
                end_time: new Date(endTime).toLocaleString()
            }
            setAnalysisData(newDataset);
            console.table(newDataset)
            setDataset(newDataset)
            newStatText += ` |${Math.round(processedData[processedData.length - 1]['total_distance_mi']*100)/100} miles traveled`;
            newStatText += vessel.isParticleDevice?"":` | ${processedData[processedData.length - 1]['total_battery_usage']} % of battery used`;
            setStatText(newStatText);
        } else {
            // setStatus("Loaded")
            setStatText(`No data returned from AWS, device was likely offline during this period`)
            setProgress(0, 0)
        }
        setFetchLabel(`Query`);
        console.groupEnd()
    } 

    function handleDownloadCsv() {
        downloadCsv({items:analysisData[vessel_id], vessel:vessel_id})
    }

    const handleResetDataset = ()=>{
        console.groupCollapsed('Reset Dataset button clicked');
        setStatText(vessel_id, "Clearing Dataset");
        setAnalysisData(vessel_id, null); // <--- FIX THIS
        setStatText(vessel_id, "Dataset cleared");
        setFetchLabel(`Query`);
        console.log(vessel_id);
        setProgress(vessel_id, 0, 0)
        console.groupEnd();
    } 
    
    const ButtonStyle = {
        margin: '10px 5px 10px 5px', 
        height:'80%', 
        maxWidth:'48%',
        minWidth:'142px',
        color: '#00b9b8',
        backgroundColor:'#3d444b'
    }

    const selectorType = ()=>{
        switch (toggle) {
            case DatasourceTypes.Absolute:
                return (
                   <AbsoluteTimePicker isMobile={isMobile} setDataset={setDataset} startTime={startTime} endTime={endTime} setStartTime={setStartTime} setEndTime={setEndTime}/>
                )
            case DatasourceTypes.Relative:
                return (
                    <RelativeTimePicker isMobile={isMobile} setDataset={setDataset} setStartTime={setStartTime} setEndTime={setEndTime}/>
                )
            case DatasourceTypes.Trips:
                return (
                    <TripSelector isMobile={isMobile} setDataset={setDataset} setStartTime={setStartTime} setEndTime={setEndTime} />
                )
            case DatasourceTypes.CSV:
                return (
                    <LogFileLoader isMobile={isMobile} setDataset={setDataset} setStartTime={setStartTime} setEndTime={setEndTime} />
                )
            // case DatasourceTypes.S3:
            //     return (
            //         <LogSelector isMobile={isMobile} setDataset={setDataset} setStartTime={setStartTime} setEndTime={setEndTime} />
            //     )
        }
    }

    const QueryTypeSelector=()=>{
        return (
            <Box className='dataset-loader-datetime-box'>
                
                    <Box className={`dataset-loader-datetime-toggle ${toggle===DatasourceTypes.Absolute && 'selected'}`} onClick={()=>setToggle(DatasourceTypes.Absolute)}>
                        <span>Absolute</span>
                    </Box>
                    <Box className={`dataset-loader-datetime-toggle ${toggle===DatasourceTypes.Relative && 'selected'}`} onClick={()=>setToggle(DatasourceTypes.Relative)}>
                        <span>Relative</span>
                    </Box>
                    <Box className={`dataset-loader-datetime-toggle ${toggle===DatasourceTypes.Trips && 'selected'}`} onClick={()=>setToggle(DatasourceTypes.Trips)}>
                        <span>{isMobile?"Trip":'Trip Selector'}</span>
                    </Box>
                    <Box className={`dataset-loader-datetime-toggle ${toggle===DatasourceTypes.CSV && 'selected'}`} onClick={()=>setToggle(DatasourceTypes.CSV)}>
                        <span>{isMobile?"CSV":'CSV Uploader'}</span>
                    </Box>
                    {/* <Box className={`dataset-loader-datetime-toggle ${toggle===DatasourceTypes.S3 && 'selected'}`} onClick={()=>setToggle(DatasourceTypes.S3)}>
                        <span>{isMobile?"Log":'Log Selector'}</span>
                    </Box> */}
                                
            </Box>
        )        
    }
    
    return (
        <Modal 
            open={showModal} 
            onClose={()=>{setShowModal(0)}}
            aria-labelledby="parent-modal-title"
            className="dataset-selector-modal"
            aria-describedby="parent-modal-description"
        >
            <Box className='analyze-page-modal-box'>
                
                {QueryTypeSelector()}

                {selectorType()}

                { toggle!==DatasourceTypes.CSV &&
                <div className='dataloader-time-range-label'>
                    <span style={{color:'white', fontSize:'medium'}}>Query Data between   </span>
                    {isMobile&&<br></br>}
                    <span style={{color:'white', fontSize:'larger', fontWeight:'500', textDecorationLine:'underline'}}>{`${new Date(startTime).toLocaleString()}`}</span>
                    {isMobile&&<br></br>}
                    <span style={{color:'white', fontSize:'medium'}}>   and   </span>
                    {isMobile&&<br></br>}
                    <span style={{color:'white', fontSize:'larger', fontWeight:'500', textDecorationLine:'underline'}}>{`${new Date(endTime).toLocaleString()}`}</span>
                </div>
                }

                { toggle!==DatasourceTypes.CSV &&
                <Box sx={{ display: "flex", flexDirection: "row", flexWrap:'wrap', justifyContent:'space-between' }}>
                    <Button 
                        variant="contained"                             
                        sx={ButtonStyle} 
                            onClick={fetchAnalysisData}     
                        endIcon={<CloudDownloadIcon />}
                    >{fetchLabel}</Button>
                </Box>
                }
            </Box>
        </Modal>
    )
}