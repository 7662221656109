import { useLocation, useNavigate } from "@tanstack/react-router";
import { useNotifications } from "./zustore";


export const Notification = ({notification})=>{
    const navigate = useNavigate();
    const location = useLocation();
    const notifications = useNotifications(s=>s.notifications)
    const removeNotification = useNotifications(s=>s.removeNotification)

    const handleButtonClick = (type) => {
        if (type=='open' && notification.url){
            const search = {}
            let url = notification.url;
            if (url.split('?').length>1){
                Array.from(url.split('?').slice(1)).forEach(element => {
                    const search_pair = element.split('=')
                    search[search_pair[0]] = search_pair[1]
                });
                url = url.split('?')[0]
            }
            console.log({ to: url, search: search})
            navigate({ to: url, search: search})
            removeNotification(notification)
        } else if (type=='dismiss'){
            removeNotification(notification)
        }
    }

    return (
    <div className='notification'>
        <div className='notification-header'>
            <a className='time'>{notification.received_time}</a>
        </div >

        <a className='title'>{notification.title}</a>

        <a className='body'>{notification.body}</a>
        
        <div className='footer'>
        {
        notification.actions &&
        notification.actions.map((action, index)=>{
            return (
            <button key={index} className={action.action} onClick={()=>{handleButtonClick(action.action)}}>{action.title}</button>
            )
        })
        }
        </div>
    </div>
    )
}