import { ChevronLeft, ChevronRight } from "@mui/icons-material"
import { Link } from "@tanstack/react-router";
import { useEffect, useMemo, useState } from "react"


const static_stats = [
    {col:"avg_hp", label:"Average Horsepower", info:"", units:"hp"}, 
    {col:"avg_speed", label:"Average Speed", info:"", units:"mph"}, 
    {col:"electricity_cost", label:"Estimated Electricity Cost", info:"", units:"$"}, 
    {col:"gas_cost", label:"Estimated Gas Cost", info:" by Equivalent ICE Motor", units:"$"}, 
    {col:"fuel_savings", label:"Estimated Fuel Cost saved", info:"relative to an Equivalent ICE Motor", units:"$"}, 
    {col:"max_hp", label:"Max Horsepower", info:"measured at the motor", units:"hp"}, 
    {col:"max_rpm", label:"Max RPM", info:"", units:"rpm"}, 
    {col:"max_speed", label:"Top Speed", info:"", units:"mph"}, 
    {col:"mi_per_kWh", label:"Efficiency", info:"", units:"mi/kWh"}, 
    {col:"hc_nox_co_prevented_lbs", label:"Pounds of Emissions prevented", info:"HC+NOx+CO emitted by an Equivalent ICE Motor", units:"lbs"}, 
];

function convertToEasternTimeManual(utcString) {
    const date = new Date(utcString);
    
    // Get the UTC offset for Eastern Time in minutes (UTC-5 or UTC-4)
    const utcOffset = date.getTimezoneOffset() + 240; // Eastern Time is UTC-4 (DST) or UTC-5
    
    // Adjust the time by the offset in milliseconds
    const easternTime = new Date(date.getTime() - utcOffset * 60 * 1000);
    
    return easternTime.toLocaleString(); // Return the adjusted time
}

const format_date = (time)=>{
    const d = new Date;
    let startTime = new Date(time) - (d.getTimezoneOffset()*60*1000)
    return new Date(startTime).toLocaleString()
}

export const TripOverview = ({trip, initial_index, base_index}) => {
    const [statIndex, setstatIndex] = useState(initial_index % static_stats.length)
    const incrementIndex = ()=>setstatIndex(statIndex + 1 <= static_stats.length-1 ? statIndex+1 : 0)
    const decrementIndex = ()=>setstatIndex((statIndex - 1 >= 0) ? statIndex - 1 : static_stats.length-1)
    const [hovering, setHovering] = useState(false)

    useEffect(() => {
        if (!hovering){
            incrementIndex()
        }
    }, [base_index]);
    // don't automatically scroll while hovering


    const stats = useMemo(()=> static_stats.map(stat => ({...stat, value:trip[stat.col]})),  [trip])

    return (
    <div 
        onMouseEnter={()=>setHovering(true)}
        onMouseLeave={()=>setHovering(false)}
        className='trip-overview'>          
        <div className='header'>
            <h1>{trip.name}</h1>
            <h2>{new Date(trip.start_time).toLocaleString()}</h2>
        </div>
        <div className='stats'>
            <div className='stat'>
                <a>Distance</a>
                <h3>{trip.trip_distance_mi} mi</h3>
            </div>
            
            <div className='stat'>
                <a>Duration</a>
                <h3>{trip.duration_minutes} min</h3>
            </div>
            
            <div className='stat'>
                <a>Battery</a>
                <h3>{Math.round(trip.total_battery_usage_kWh) ?? '--'} kWh</h3>
            </div>
        </div>

        <div className="scroller">
            <button className='left' onClick={decrementIndex}><ChevronLeft /></button>
            <div className="stat-container">
                {stats.map((stat, index) => (
                <div
                    key={index}
                    className={`stat-item ${index === statIndex ? "active" : (index < statIndex && index!=static_stats.length-1) ? "left" : "right"}`}
                >
                    <h4>{stat.label}</h4>
                    <h3>{trip[stat.col]} {stat.units}</h3>
                    <a>{stat.info}</a>
                </div>
                ))}
            </div>
            <div className="location-indicator">
                {
                    static_stats.map((stat,index)=>(
                        <div key={index} className={`indicator-item ${statIndex==index ? 'active' : ''}`}></div>
                    ))
                }
            </div>
            <button className='right' onClick={incrementIndex}><ChevronRight /></button>
        </div>
        <div className='footer'>
            <Link
                to={'/trips/trip'}
                search={{
                    vessel_id: trip.vessel_id,
                    start_time: trip.start_time,
                    end_time: trip.end_time
                }}
                className='fm-btn'
            >
                <span>View Trip</span>
            </Link>
        </div>
    </div>
    )
}