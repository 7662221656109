import * as React from 'react' ;
import { Children, createContext } from 'react';

const ExpanderContext = createContext('Expander');

export const Expander = ({ isMobile, title, startCollapsed, expanderStyle=null, children })=>{
    const [collapsed, setCollapsed] = React.useState(startCollapsed)
    const minHeight = (!collapsed)?'250px':'0px';
    expanderStyle = expanderStyle==null 
        ? {minWidth:'250px', minHeight:minHeight, width:'100%',  display:'flex', flexDirection:'column', borderRadius:'4px', backgroundColor:'#CBD2E07a', alignItems:'center', marginBottom:'2px'} 
        : {...expanderStyle, minHeight:(!collapsed)?'250px':'0px'}

    return (
        <div className={`fleet-expander ${collapsed?'collapsed':'expanded'}`} style={expanderStyle} >            
            <div 
                style={{width:'97%', height:'40px', display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', justifySelf:'center'}}
                onClick={()=>{
                    setCollapsed(!collapsed)
                    if (navigator.vibrate) {
                        navigator.vibrate(100); // Vibrate for 200ms
                    }
                }}
                >
                <a style={{fontSize:'20px', fontWeight:'600', color:expanderStyle.backgroundColor=='#CBD2E07a'?'var(--dark-grey-3)':'var(--light-grey-2)'}}>{`${collapsed?'Show':'Hide'} ${title}`}</a>
                <MenuExpander collapsed={collapsed} setCollapsed={setCollapsed} color={expanderStyle.backgroundColor=='#CBD2E07a'?'var(--dark-grey-3)':'var(--light-grey-2)'}/>
            </div>
            {
                !collapsed && 
                (
                <div style={{display:'flex', flexDirection:isMobile?'column':'row', marginBottom:'6px', width:'98%'}}>
                    <ExpanderContext.Provider value={collapsed}>
                        {Children.map(children, child =>
                            child
                        )}
                    </ExpanderContext.Provider>
                </div>
                )
            }
        </div>
    )
}

const MenuExpander = ({collapsed, setCollapsed, color})=>{

    return (
        <svg xmlns="http://www.w3.org/2000/svg" height='21' width='21' viewBox='0 -2 21 23' fill='none' onClick={()=>setCollapsed(!collapsed)} style={{transform:`rotate(${collapsed?'0':'180'}deg)`}}>
            <path d='M2.35 0.825003L-4.37114e-07 3.175L10 13.175L20 3.175L17.65 0.825002L10 8.45834L2.35 0.825003Z' fill={color} />
        </svg>
    )
}