import { useState, useEffect, useMemo } from "react";
import FleetGrid from "../../fleet/FleetGrid";
import ErrorBoundary from "../../utils/ErrorBoundary";
import FleetMap2 from "../../fleet/FleetMap2";
import { useQueryClient } from "@tanstack/react-query";
import { useFleetData, useVesselList, useGroups, useUser, zuPage, useSelectedNodeStore, useAuth } from "../../app/zustore";
import {
  Box, Button, Typography, useMediaQuery,
  SwipeableDrawer, Tooltip, IconButton,
} from "@mui/material";
import { FleetTreeView } from '../../fleet/FleetTreeView';
import { MembersCard } from '../../fleet/MembersCard';
import { VesselUpdater } from "../../vessel/manage/vesselUpdater";
import { FleetFilterModal } from "../../fleet/FleetFilterModal";
import { ModalTypes } from "../../Types/enums";
// import '../../pageLayout.css';
import { AddVesselModal } from '../../fleet/AddVesselModal';
import { InviteMemberModal } from '../../fleet/InviteMemberModal';
import { NewGroupModal } from '../../fleet/NewGroupModal';
import { AnimationGreet } from "../../loadingBar/animationGreet";
import { SignOutButton } from "../../utils/signOutButton";
import { Menu as MenuIcon } from '@mui/icons-material';
import { LoadingBar } from "../../loadingBar/loadingBar";
import { LeaderBoard } from "../../fleet/leaderboard";
import { FaultViewer } from "../../fleet/FaultViewer";
import { Expander } from "../../fleet/Expander";
import { FleetTripViewer } from "../../fleet/FleetTripViewer";

import { Link, Navigate, createFileRoute, createRoute, redirect, useNavigate } from '@tanstack/react-router'
import { useLocation } from "@tanstack/react-router";
import { StyledTreeView } from "../../fleet/StyledTreeView";

export const Route = createFileRoute('/_authenticated/home')({
  component: FleetOverview,
})

export default function FleetOverview({ signOut }) {
  const vesselList = useVesselList((state) => (state.vesselList)); // array of objects which have attributes vessel_id and table_name
  const fleetData = useFleetData((state) => state.fleetData);
  const {isAuthenticated, setIsAuthenticated} = useAuth(s=>({isAuthenticated:s.isAuthenticated, setIsAuthenticated:s.setIsAuthenticated}));
  const location = useLocation();
  const [modalType, setShowModal] = useState(ModalTypes.None);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [vesselToUpdate, setVesselToUpdate] = useState(null);
  const { groups, selectedGroup } = useGroups((state) => ({ groups: state.groups, selectedGroup: state.selectedGroup }));
  // const [filters, setFilters] = useState({ groups: groups.map((_, index) => index), states: [0, 1, 2, 3], activeTime: 100000 }) // 100,000 min ~= 10 weeks
  const {leftSidebarOpen, toggleLeftSidebar} = zuPage(state=>({leftSidebarOpen:state.leftSidebarOpen, toggleLeftSidebar:state.toggleLeftSidebar}));
  const {selectedNode, setSelectedNode} = useSelectedNodeStore();
  const queryClient = useQueryClient();
  const user = useUser(state => state.user);
  const navigate = useNavigate()

  const showModal = useMemo(() => {
    return modalType !== ModalTypes.None
  }, [modalType])

  const numVessels = vesselList.length
 
  const vesselsOfNode = useMemo(() => {
    if (selectedNode!==null && selectedNode?.vessels && vesselList) {
      // console.log('VesselsOfNode', selectedNode, vesselList)
      let vesselListSubset = Object.values(selectedNode?.vessels)
        .map(
            (vessel_id) => {
                const vessel = vesselList.find((vessel) => vessel?.vessel_id === vessel_id)
                if (vessel) return vessel
            }
        ).filter((vessel) => vessel !== undefined)
        // console.log(vesselListSubset)
        return vesselListSubset.filter((vessel) => vessel !== undefined)
    }
    // console.log(selectedNode, vesselList)
    return -1
  }, [selectedNode, vesselList, location])
  
  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    // Keep the mobile drawer open when swiped to the right
    if (anchor === 'left' && open) {
      toggleLeftSidebar(true);
    } else {
      toggleLeftSidebar(false);
    }
  }; 

  const modal = () => {
    switch (modalType) {
      case ModalTypes.AddVessel:
        return <AddVesselModal showModal={showModal} setShowModal={setShowModal} />
      case ModalTypes.InviteMember:
        return <InviteMemberModal showModal={showModal} setShowModal={setShowModal} />
      case ModalTypes.NewGroup:
        return <NewGroupModal showModal={showModal} setShowModal={setShowModal} selectedNode={selectedNode} />
      case ModalTypes.Update:
        return <VesselUpdater vesselToUpdate={vesselToUpdate} setShowModal={setShowModal} showModal={showModal} />
      
      default:
        return null;
    }
  }

  const handleSidebarButtonClick = ()=>{
    toggleLeftSidebar(!leftSidebarOpen)
  }

  const SideBar = ()=>{
    const SideBarContent = ()=>{
        return (
          <div className='SideBarContent'>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginBottom: '10px',
                width:'100%'
              }}
            >
              <Typography
                variant="h4"
                sx={{ color: 'var(--light-grey-3)', fontSize: '1.2rem', textAlign:'start', marginLeft:'8px' }}
              >
                Now viewing:
              </Typography>
              <Typography
                variant="h2"
                sx={{ color: '#fff', marginLeft: '10px', fontSize: '1.4rem', textAlign:'start', marginRight:'8px' }}
              >
                {selectedNode?.name.replaceAll('_', ' ')||''}
              </Typography>
            
            </div>

  
            {user.nodes &&
              <div style={{width:'95%', justifySelf:'center', }}>
                <FleetTreeView hideIcons={isMobile} toggleLeftSidebar={toggleLeftSidebar} setSelectedNode={setSelectedNode} isSideBar={true}/>
              </div>
            }
              
            {selectedNode &&
              <MembersCard selectedNode={selectedNode} setShowModal={setShowModal} />
            }
            

            <div style={{display:'flex', flexDirection:'column', rowGap:'15px', alignItems:'center', width:'90%'}}>
              <button className="fm-btn" onClick={() => setShowModal(ModalTypes.AddVessel)}>
              <Link to={'/groups/$node_id/new-vessel'} params={{node_id: selectedNode?.id}} mask={{to:`/groups/${selectedNode?.name.replace(' ', '-')}/new-vessel`}}>New Vessel</Link>
              </button>    
    
              {selectedNode?.can_write &&
                <button className="fm-btn" onClick={() => setShowModal(ModalTypes.NewGroup)}>
                  <Link to={'/groups/$node_id/new'} params={{node_id: selectedNode?.id}} mask={{to:`/groups/${selectedNode?.name.replace(' ', '-')}/new`}}>New Group</Link>
                </button>  
              }

              {/* <Link to={'/about'}>About</Link> */}

            </div>

            {/* <SignOutButton signOut={signOut}/> */}
          </div>
        )
    }

    if (isMobile){
      return (
        <SwipeableDrawer
          className='swipe'
          swipeAreaWidth={30}
          anchor="left"
          open={leftSidebarOpen}
          onOpen={toggleDrawer('left', true)}
          onClose={toggleDrawer('left', false)}
        >
          <Box className='SideBar'>
            {SideBarContent()}
          </Box>
        </SwipeableDrawer>
      )
    } else {
      return (
      <Box className={`SideBar open`}>
        {SideBarContent()}
      </Box>
      )
    } 

  }  

  const ContentArea = ()=>{

    const content = () => {
      if (numVessels > 0) {
        if (selectedNode) {
            return (
              <div className="fleet-overview-inner-content">
                <LeaderBoard isMobile={isMobile} />
                

                <Expander isMobile={isMobile} title='Fleet Map' startCollapsed={true}>
                  <FleetMap2
                    style={{ flexShrink: 0 }} vessels={
                      vesselList.reduce((acc, vessel) => {
                        acc[vessel.vessel_id] = vessel.vessel_name;
                        return acc;
                      }, {})}
                  />
                </Expander>

                <Expander isMobile={isMobile} title='Fleet Overview' startCollapsed={false}>
                  <FleetGrid vesselList={vesselsOfNode} />
                </Expander>
                      
                { !isMobile && 
                <Expander isMobile={isMobile} title='Fault viewer' startCollapsed={true}>
                  <FaultViewer isMobile={isMobile} />
                </Expander>                  
                }

              </div>
            )
          } else {
            return (
              <LoadingBar />
            )
          }
        
      } else {
        return <LoadingBar />
      }
    }

    if ( !groups  ){
      return (
        <div className={`ContentBox open`}>
          <Box className="fleet-overview-inner-content">
            <h2 style={{color:'white', marginTop:4, textTransform:'bold'}} >You do not belong to any groups.</h2>
            <Typography sx={{color:'white', }} >Access to boats is provided through groups and you must belong to one in order to see any boats</Typography>
            <Typography sx={{color:'white', }}>If you have already purchased a boat and are the primary owner please contact your sales representative.</Typography>
            <Typography sx={{color:'white', marginBottom:4}}>Otherwise please ask the primary owner of your boat to invite you to their Fleet.</Typography>
            
          </Box>
        </div>
        )
    } else if ( (selectedNode!==null) && (vesselsOfNode==-1) ) {
      console.log(selectedNode, vesselsOfNode)
      return (
        <div className={`ContentBox open`}>
          <Box className="fleet-overview-inner-content">
          <LoadingBar />
          </Box>
        </div>
      )
    } else {
    return (
      <div className={`ContentBox open`}>
        {/* Header */}
        {content()}
      </div>
    )}
  }


  if (Object.keys(location.search).length>0) {
    console.log('Found search params in the Root Component')
    if (Object.keys(location.search).find(k=>k=='devid')){
      if (location.search?.devid=='DEVID_NOT_FOUND'){
        console.log('Your Garmin could not access your boats devid!')
      } else {
        console.log('Found a devid in the home route, redirecting to /_vessels with the search param')
        let vessel = vesselList.find(v=>v.device_id==location.search?.devid) || false
        console.log('Found a devid in the search params! trying to match it to a vessel_id now', location.search?.devid)
        if (vessel) {
          console.log('Matched a vessel to the given devid!', vessel)
          return <Navigate to='/vessels/$vessel_id' params={{vessel_id: vessel.vessel_id}} />
        } else {
          console.log('Found a devid in the search params but could not match it! Eventually redirect to /add_vessel here')
        }
    }
    } else if (Object.keys(location.search).find(k=>k=='redirect') && isAuthenticated){
      console.log('Found a redirect in the Root Component', location.search.redirect)
      return <Navigate to={location.search.redirect}/>
    }
  } else if (!user){
    return <LoadingBar />
  }
  return (
    <ErrorBoundary boundaryName='Fleet grid'>
      <div
        className="container home"
        onClick={() => {
          if (showModal) {
            setShowModal(0);
            setVesselToUpdate(null);
          }
        }}
      >
        {/* Main Content Area */}
        {ContentArea()}

        
        {SideBar()}


        {modal()}
      </div>
      {modal()}
    </ErrorBoundary>
  );
}