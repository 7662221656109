import * as React from 'react';
import { useQuery } from "@tanstack/react-query";
import { useUser, useFleetActivity, useAuth } from '../app/zustore';
import { fetchData } from '../app/QueryHandler';

const Plotly = window.Plotly;

export const FleetActivity = ({isMobile})=>{
    const { fleetActivity, updateFleetActivity } = useFleetActivity()
    const user = useUser(s=>s.user)
    const token = useAuth((state)=>state.token);
    const [numDays, setNumDays] = React.useState(180)
    const plotContainerRef = React.useRef(null);
    const [plotRendered, setPlotRendered] = React.useState(false)

    function unpack(rows, key) {
        return rows.map(function(row) { 
            return row[key]; 
        });
    }

    const tripsFleetQuery = useQuery({
        queryKey: ['trips-fleet'],
        queryFn: async () => {
          // console.log(Object.keys(user.nodes))
          const params = {
            num_days: numDays,
            node_id: Object.keys(user.nodes)[0]
          }
          const {status, body, type} = await fetchData({ method: 'GET', resource: 'trips/fleet', token: token, params: params });
          if (status===204) throw new Error('No data returned')
          if (status===403) throw new Error('403: Unauthorized')
        //   console.log(body)
          const data = body
          // console.log(data)
          if (data==='') throw new Error('No data returned')
          return data;
        },
        enabled: true,
        refetchInterval: 1000 * 60 * 15, // 30 seconds
        retry: 2,
        onSuccess: (data) => {
          if (data) {            
            console.groupCollapsed('trips/fleet query');            
            console.table(data);  
            let sorted_data = data?.map(t=>({...t, date: new Date(t.date.replace("_","-"))})).sort((a, b) => {return a.date - b.date;})
            updateFleetActivity(sorted_data)
            console.groupEnd();
          }
        }
    });

    const plotData = React.useMemo(() => {
        if (fleetActivity.length===0) {
          console.log("Conditions not met to create plotData")
          return null
        } else {
          // console.log('setting or updating the plot data', plot.x, plot.ys, plot.c)
        }

        let numBoatsTrace = {
            type: "bar",
            name: '# Vessels',
            x: unpack(fleetActivity, 'date'),
            y: unpack(fleetActivity, 'number_active_vessels'),
            line: {color: '#7F7F7F'},
            marker: {color: 'white'},
            opacity: '0.625'
        }

        let motorHoursTrace = {
            type: "scattergl",
            mode: "lines",
            name: 'active time (min)',
            yaxis: 'y2',
            x: unpack(fleetActivity, 'date'),
            y: unpack(fleetActivity, 'total_minutes_per_day'),
            line: {color: 'cyan', shape:'spline', smoothing: 5, width:isMobile?1.5:3}
          }

        let distanceTrace = {
            type: "scattergl",
            mode: "lines",
            name: 'distance (mi)',
            yaxis: 'y2',
            x: unpack(fleetActivity, 'date'),
            y: unpack(fleetActivity, 'total_miles_per_day'),
            line: {color: 'white', shape:'spline', smoothing: 5, width:isMobile?1.5:3}
        }

        const data = [numBoatsTrace, motorHoursTrace, distanceTrace];
        return data
      }, [fleetActivity])
    
      React.useEffect(() => {
        // console.log('plot data changed, updating plot', plotData)
        // Render the plot into the div with a ref attached
        const layout={
          // title: {text:`Recent Fleet Activity - last ${numDays} days`, x:0.05, y:1.6, xanchor:'left', font:{size:14}},
          xaxis: { 
            title: 'date',
            tickmode: "auto",
            type: 'date',
            nticks: 4,
            tickformat: "%Y/%m/%d",
            tickangle: 0, 
          },
          yaxis: { title: "Count", color:'gray' },
          yaxis2: {
            title: "Miles - Minutes",
            color: 'cyan',
            overlaying: "y",
            side: "right",
          },
          editable: true,
          hovermode: "x-unified",
          hoverinfo: 'x+y',
          color:'rgba(255, 255, 255, 0.71)',
          paper_bgcolor: 'rgba(0, 0, 0, 0.0)',
          plot_bgcolor: 'rgba(0, 0, 0, 0.32)',
          autosize: true,
          automargin:true,
          margin: isMobile?{l:45, r:45, t:15, b:45}:{l:35, r:55, t:25},
          showlegend: isMobile?false:true,
        //   fillpattern: {bgcolor:'rgba(0, 0, 0, 0.0)', fillmode:'overlay'},
          legend: {
            x: 0,
            y: 1.2,
            xanchor: "left",
            yanchor: "top",
            bgcolor: "rgba(250, 250, 250, 0.2)",
            bordercolor: "rgba(0, 0, 0, 0.2)",
            orientation: 'h',
            borderwidth: 1,
          },
          height: isMobile?215:340,
          responsive: true,
          font: {
            color: 'white',
            family: 'Inter'
          },

        }
    
        const config = {responsive: true, staticPlot: isMobile, displayModeBar: false}
        // console.log(plotContainerRef.current)
        if (!plotRendered){
          console.log('rendering new preset plot')
          Plotly.newPlot(plotContainerRef.current, plotData, layout, config);
          setPlotRendered(true);
        } else {
          Plotly.react(plotContainerRef.current, plotData, layout, config);
        }
    
      }, [plotData]); // Redraw the plot if data or layout props change
    
      React.useEffect(()=>{
        return () => {
          if (plotContainerRef.current){
            try {
                console.log('purging preset plot')
                Plotly.purge(plotContainerRef.current);
                setPlotRendered(false)
            } catch (err) {
                console.warn("Caught an error in PlotlyPlots.jsx while purging plot", err)
            }
          } else {
            console.log('no div ref to unmount against - big bad')
          }
        };
      }, [])

    return (
        <div className='fleetActivity'  >            
            <div className='fleetActivity-plot-area' ref={plotContainerRef} style={{minWidth:'250px', width:'100%', minHeight:isMobile?'210px':'250px', height:'100%'}} />
        </div>
    );
}