import { useState, useEffect, useMemo } from "react";
import { useSelect, useVessel, useVesselList, useAuth, useDyno, dynoIdArray } from "../../../app/zustore";
import ErrorBoundary from "../../../utils/ErrorBoundary";
import { fetchData } from "../../../app/QueryHandler";
import { processAnalysisData, count_rows_per_vessel, utc_time_to_string } from "../../../utils/dataProcessing";
import dayjs from 'dayjs';
import { CompressedCSVToDataFrame } from "../../../utils/utilityMethods";
import { useSnackbar } from 'notistack';
import { useQuery, useQueryClient } from "@tanstack/react-query";
// import '../../../pageLayout.css'
import DynoGrid from "../../../vessel/dyno/DynoGrid";
import { DynoPlots } from "../../../vessel/dyno/DynoPlots";
import { downloadCsv } from "../../../utils/utilityMethods";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { LoadingBar } from "../../../loadingBar/loadingBar";
import { fetchAuthSession } from "@aws-amplify/auth";

export const Route = createFileRoute('/_authenticated/vessels/dyno')({
    // Or in a component
    // loader: dynoLoader,
    component: DynoPage,
    // pendingComponent: ()=><LoadingBar />,
})

async function dynoLoader({ params }){
    const vessel_id = useVessel.getState().vessel_id || params.vessel_id;
    fetchDynoData(vessel_id)
}

async function fetchDynoData(vessel_id){

    // const vessel_id = useVessel.getState().vessel_id || params.vessel_id;
    const isDualDynoMode = useDyno.getState().isDualDynoMode;
    const setDynoData = useDyno.getState().setDynoData;
    const dynoData = useDyno.getState().dynoData;
    const dynoId = isDualDynoMode ? '5da5ef4e-9bc4-4e94-a033-eab5d893b11b,7b7f72d9-bb02-41a2-a6d8-b471c06528b2' : vessel_id;
    let startTime = dayjs(Date.now()).startOf('day').valueOf().toString();
    let endTime = dayjs(Date.now()).valueOf().toString();
    let itemsToFetch = 0;
    const token = useAuth.getState().token;
    let { idToken } = (await fetchAuthSession()).tokens ?? {};
    const JWT = idToken.toString();
    const setFinishedRecursing = useDyno.getState().setFinishedRecursing;
    
    const fetchDataRecursively = async (params, existingData) => {
        
        let {status, body} = await fetchData({ method: 'GET', resource: 'vessel-data/ranged', token: JWT, params: params });
        console.log(body);
        
        if (status === 200){ // check for valid response
            let df, newData, mergedData=[];
            
            
            if (body.data.length>10){
                df = CompressedCSVToDataFrame(body.data);
                newData = df.filter(item => !existingData.some(existingItem => existingItem.sample_time === item.sample_time)); // Filter out any items that already exist in the existing data
                mergedData = [...existingData, ...newData].sort((a, b) => {
                return new Date(a.sample_time) - new Date(b.sample_time);
            }); // Merge the existing data with the new data and sort by sample_time
            setDynoData(mergedData);
        }
        
        if (Object.keys(body.metadata).includes('TotalCount')){
            // setStatText(`${body.metadata.TotalCount} rows to fetch from AWS, total rows: ${mergedData.length}`)
            itemsToFetch = body.metadata.TotalCount;
        } else {
            // setStatText(`${itemsToFetch} rows to fetch from AWS, total rows: ${mergedData.length}`)
        }
        // setProgress(mergedData.length, itemsToFetch);
        // update the stat text here to give feedback to the user
        
        const ClientToken = body.metadata.ClientToken
        if (body.metadata && Object.keys(body.metadata).includes('NextToken') && body.metadata.NextToken!=='None') {
            // Update the qString with the LastEvaluatedKey and recursively fetch again
            await new Promise(resolve => setTimeout(resolve, 30));
            
            const NextToken = body.metadata.NextToken;  
            console.log("sample_time found in response, sending follow-up request:")
            params.client_token = ClientToken
            // Remove any existing next_token parameter from qString
            let prevNextToken = params.next_token
            if (prevNextToken!=NextToken){
                params.next_token = NextToken
                return fetchDataRecursively(params, mergedData);
            } else {
                console.log('NextToken not changing')
                return {error:'NextToken not changing', df:mergedData}
            }
        } else if (df!==undefined){
            console.log("Done recursing")
            console.table(df.slice(0,3))
            return {error:null, df:mergedData}
        } else {
            return {error:'To be determined', df:null}
        }
        
    } else { // invalid response recieved, return an error and don't recurse
        // Finished recursion, Return the data to the parent function to be processed
        return {error:'No Data returned', df:null}
    }
    }

    console.log(vessel_id)
    console.log([startTime, endTime]);
    if (startTime>endTime){ // make sure start time is before the end time
        startTime = startTime + endTime;
        endTime = startTime - endTime;
        startTime = startTime - endTime;
    }

    const params = {
        vessel_id : dynoId,
        start_time:startTime,
        end_time: endTime
    }
    console.log("Initial params:", params)

    const existingData = dynoData || [];
    const {error, df} = await fetchDataRecursively(params, existingData);

    if (error===null) {        
        setDynoData(df) 
        setFinishedRecursing(true)
    }
    console.groupEnd()
};

export function DynoPage(){
    const { vessel_id } = Route.useParams()
    const vessel = useVesselList(s=>s.vesselList).find(vessel => vessel.vessel_id === vessel_id);
    const vessel_name = vessel?.vessel_name;
    const { enqueueSnackbar } = useSnackbar();
    const token = useAuth((state)=>state.token);
    const vesselList = useVesselList(s=>s.vesselList).map(v=>( { ...v, group:Object.values(v.node)[0] } ))
    const [isInitialQuery, setIsInitialQuery] = useState(false)
    const {dynoData, setDynoData, isDualDynoMode, setDynoMode} = useDyno()
    const dynoId = isDualDynoMode ? '5da5ef4e-9bc4-4e94-a033-eab5d893b11b,7b7f72d9-bb02-41a2-a6d8-b471c06528b2' : vessel_id;
    const queryClient = useQueryClient()
    const finishedRecursing = useDyno(s=>s.finishedRecursing)

    const handleToggle = ()=>{
        setDynoMode(!isDualDynoMode)
        // setDynoData(null)
        queryClient.invalidateQueries({ queryKey: ["dual-dyno-query"]})
    }

    function handleDownloadCsv() {
        downloadCsv({items:dynoData, vessel:dynoId})
    }

    const GridType = ()=>{
        if (!dynoData) {
            return null
        } else if (isDualDynoMode){
            return (
            <div className='' style={{height:'100%', width:'100%', display:'flex', flexDirection:'row'}}>
                {
                dynoIdArray.map((vessel_id, index)=>{
                    const split_dyno_data = dynoData.filter(row=>row.vessel_id===vessel_id)
                    const this_vessel = vesselList.find(vessel=>vessel.vessel_id==vessel_id)
                    console.log(vessel_id, split_dyno_data.length)
                    if (split_dyno_data.length>0){
                        return (
                        <ErrorBoundary boundaryName="Live Data Gauge Grid" key={index}>
                            <div style={{height:'100%', width:'100%', display:'flex', flexDirection:'column'}} key={index}>
                                <a style={{fontSize:'24px', fontWeight:'500'}}>{this_vessel.vessel_name}</a>
                                <a style={{fontSize:'20px', fontWeight:'500'}}>{split_dyno_data.length} rows</a>
                                <DynoGrid data={split_dyno_data} vessel_id={vessel_id} gridColumn={index}/>
                            </div>
                        </ErrorBoundary>
                        )
                    }   
                })
                }
            </div>
            )
        } else {
            const data = dynoData.filter(row=>row.vessel_id==vessel_id)
            if (data.length > 0){
                return (
                    <ErrorBoundary boundaryName="Live Data Gauge Grid">
                        <DynoGrid data={data} gridColumn={0} vessel_id={vessel_id}/>
                    </ErrorBoundary>
                )
            }
        }
    }

    useEffect(() => {
        fetchDynoData(vessel_id)
    }, []);

    const dynoDataQuery = useQuery({
        queryKey: ["dual-dyno-query"],
        queryFn: async () => {
          let startTime = isInitialQuery ? dayjs(Date.now()).subtract(1, 'hour').valueOf().toString() : dayjs(Date.now()).startOf('day').valueOf().toString();
          let resource = isInitialQuery ? 'vessel-data/ranged' : 'vessel-data/daily'
          setIsInitialQuery(false)
          if (dynoData && isDualDynoMode) {
            let start_times = dynoIdArray.map((vessel_id, index)=>{
                const split_dyno_data = dynoData.filter(row=>row.vessel_id===vessel_id)
                if (split_dyno_data.length==0){
                    console.log('no data for ', vessel_id);
                    return dayjs(Date.now()).startOf('day').valueOf()
                } else {
                    const sampletime = split_dyno_data[ split_dyno_data.length - 1 ]?.time;
                    const d = new Date();
                    startTime = Date.parse(sampletime) - (d.getTimezoneOffset()*60*1000) + 1;
                    return startTime;}
            })
            console.log('start_times', start_times)        
            startTime = Math.min(...start_times).toString()
          } else if (dynoData){
            const sampletime = dynoData[ dynoData.length - 1 ]?.time;
            const d = new Date();
            startTime = Date.parse(sampletime) - (d.getTimezoneOffset()*60*1000) + 1;
          }
          const params ={
            vessel_id: dynoId,
            start_time: startTime
          }
          const {status, body, type} = await fetchData({ method: 'GET', resource: 'vessel-data/daily', token: token, params: params });
          if (status===204) throw new Error('No data returned')
          // console.log(body)
          const data = body
        //   console.log(data)
          if (data==='' && !dynoData) throw new Error('No data returned')
          return data;
        },
        enabled: vessel_id !== null && finishedRecursing,
        refetchInterval: 2000 * 10, // 10 seconds when active, 5min otherwise
        retry: 2,
        onSuccess: (data) => {
          // const body = body.body;
          // console.log(data)
          if (data.length > 10) {
            // setActiveBoat(true)
            const df = CompressedCSVToDataFrame(data);
            console.groupCollapsed('dual-dyno-data query', dynoId);
            count_rows_per_vessel(df);
            // enqueueSnackbar(`${isDualDynoMode ? vessel_name : 'Both'} updated: ${df.length} rows retrieved`, { variant: 'info' });
            console.table(df);
    
            console.log("setting current data to:", df[df.length - 1]);
            const existingData = dynoData || [];
            
            const newData = df.filter(item => !existingData.some(existingItem => ( (existingItem.sample_time === item.sample_time) && (existingItem.vessel_id === item.vessel_id)) )); // Filter out any items that already exist in the existing data
            const mergedData = [...existingData, ...newData].sort((a, b) => {
              return new Date(a.sample_time) - new Date(b.sample_time);
            }); // Merge the existing data with the new data and sort by sample_time
            const vessel = vesselList.find(vessel => vessel.vessel_id === vessel_id);
            count_rows_per_vessel(mergedData);

            const processedData = mergedData.map(row=> ({...row, sample_time_str: utc_time_to_string(row.time)}));
            console.log(processedData)
            console.groupEnd();
            if ( !dynoData || (processedData.length > dynoData.length) ) {
              setDynoData(processedData);
            }
          }
        },
        onError: (error) => {
          console.log(`No data for ${vessel_name}, throttling back the query interval`)
        //   setActiveBoat(false)
        //   setHasReturnedEmpty(true)
        }
      });

    if (dynoData!==null && dynoData.length>0){
        return (
        <div className="DynoPage" style={{width:'100%', paddingTop:'15px', marginTop:'15px', display:'flex', flexDirection:'column', alignContent:'center'}}>
            
            <div style={{
                width:'350px', height:'40px', 
                alignSelf: 'center',
                display: 'flex', flexDirection:'row', marginBottom: '10px'}}>
                
                <a style={{fontWeight:isDualDynoMode?400:700}}>Single Dyno Mode</a>
                <div style={{
                    width:'120px', height:'40px', 
                    backgroundColor:!isDualDynoMode?'darkgray':'#4a5f6d', 
                    borderRadius:'20px', 
                    boxShadow:'#434343 2px 2px 1px 0px',
                    alignSelf: 'center',
                    display: 'flex', flexDirection:!isDualDynoMode?'row':'row-reverse'
                }}
                onClick={handleToggle}
                >
                    <div style={{
                        width:'36px', height:'36px', 
                        backgroundColor:!isDualDynoMode?'#4a5f6d':'darkgray', 
                        borderRadius:'18px',
                        alignSelf: 'center', justifySelf: 'center', marginLeft:'2px', marginRight:'2px',
                    }}></div>

                    <button style={{position:'absolute', top:'15px', right:'20vw', height:'40px', width:'120px', borderRadius:'4px'}} onClick={handleDownloadCsv}>Download Data</button>
                </div>
                <a style={{fontWeight:isDualDynoMode?700:400}} >Dual Dyno Mode</a>
            </div>

            {/* <ErrorBoundary boundaryName="vessel-header">
            {
                dynoData!==null
                ? <h3 className='LiveDataHeader'>{!isDualDynoMode ? `${vessel_name} -- ${dynoData.filter(row=>row.vessel_id==vessel_id)?.length} rows`: `Dual Dyno Mode! ${dynoData?.length} rows`}</h3>
                : <h3>Loading</h3>
            }
            </ErrorBoundary> */}
            
            { GridType() }
            
            {
            dynoData.length > 0 &&
            <DynoPlots data={dynoData} />
            }
            
        </div>
    )
    } else {
        return (
            <div className="DynoPage" style={{width:'100%', paddingTop:'15px', marginTop:'15px', display:'flex', flexDirection:'column', alignContent:'center'}}>
                <LoadingBar />
            </div>
        )
    }
}