import React, { useEffect, useRef, useState } from "react";
import * as turf from "@turf/turf";
// eslint-disable-next-line import/no-webpack-loader-syntax
import maplibregl from "maplibre-gl"; // ! is important here
// import {maplibreglWorker} from "maplibre-gl/dist/maplibre-gl-csp-worker";
import { useFleetData, useVesselList } from "../app/zustore";
import BoatMarker from "../images/basicBoatMarker.png";
import { Button } from "@mui/material";

// maplibregl.workerClass = maplibreglWorker;
const maptiler_api = process.env.REACT_APP_MAPTILER_API;

const getMapCenter = (fleetData) => {
  console.groupCollapsed("getMapCenter")
  let totalLat = 0,
    totalLng = 0,
    validEntries = 0;
  console.table(fleetData)
  console.groupEnd()
  for (const id in fleetData) {
    
    if (fleetData[id]) {
      const vessel = fleetData[id];
      const latitude = parseFloat(vessel?.telemetry_data?.latitude) || NaN;
      const longitude = parseFloat(vessel?.telemetry_data?.longitude) || NaN;
      
      if (!isNaN(latitude) && !isNaN(longitude)) {
        totalLat += latitude;
        totalLng += longitude;
        validEntries++;
      }
    }
  }

  // If there were no valid entries, return a default center
  if (validEntries === 0) {
    return [0, 0]; // or whatever default center you want
  }

  // Return the average latitude and longitude
  return [totalLng / validEntries, totalLat / validEntries];
};

const FleetMap2 = ({ vessels }) => {
  const mapContainer = useRef(null);
  const mapRef = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [mapCentered, setMapCentered] = useState(false);
  const [bounds, setBounds] = useState(null);
  const [vesselFeatures, setVesselFeatures] = useState([]);
  const vesselList = useVesselList((state) => (state.vesselList));
  const { fleetData } = useFleetData();

  useEffect(() => {
    const map = new maplibregl.Map({
      container: mapContainer.current,
      style: maptiler_api,
      center: getMapCenter(fleetData),
      zoom: 11.15,
    });
  
    map.on("load", () => {
      setMapLoaded(true);
  
      map.addSource("vessels", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      });
  
      map.addLayer({
        id: "vessels",
        type: "symbol",
        source: "vessels",
        layout: {
          "icon-image": "custom-marker",
          "icon-allow-overlap": true,
          "text-field": "{title}",
          "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
          "text-offset": [0, 0.15],
          "text-anchor": "top",
        },
        paint: {
          "icon-color": "#e67e22",
        },
      });
  
      // Add the custom marker image to the map
      map.loadImage(BoatMarker, function (error, image) {
        if (error) throw error;
        map.addImage("custom-marker", image);
      });
  
      map.on("click", "vessels", function (e) {
        console.log("map clicked", e, fleetData);
        showVesselInfo(e.features[0]);
      });
  
      map.on("mouseenter", "vessels", function () {
        map.getCanvas().style.cursor = "pointer";
      });
  
      map.on("mouseleave", "vessels", function () {
        map.getCanvas().style.cursor = "";
      });
  
      updateDataSource(map);
    });
  
    mapRef.current = map;
  
    return () => {
      console.log("unmounting map");
      map.remove();
    };
  }, []); // This ensures the map is created only once
  
  useEffect(() => {
    if (mapLoaded) {
      // const center = getMapCenter(fleetData);
      // mapRef.current.setCenter(center);
      updateDataSource(mapRef.current);
    }
  }, [fleetData, mapLoaded]); // This ensures the map data gets updated when fleetData or mapLoaded changes  

  useEffect(() => {
    console.log(vesselFeatures)
  }, []);

  function showVesselInfo(marker) {
    // console.log("showVesselInfo", marker)
    const vesselId = marker.properties.title;
    console.log(vesselId, marker);
    const desc = marker.properties.description.split(",");
    const latitude = marker.geometry.coordinates[1].toFixed(2);
    const longitude = marker.geometry.coordinates[0].toFixed(2);

    const popupHtml = `
          <h1>${vesselId}</h1>
          <p>Latitude: ${latitude} Longitude: ${longitude}</p>
          <p>Speed: ${desc[0]} mph</p>
          <p>Avg Speed: ${desc[1]} mph</p>
          <p>${desc[2]}</p>
        `;
    new maplibregl.Popup()
      .setLngLat(marker.geometry.coordinates)
      .setHTML(popupHtml)
      .addTo(mapRef.current);
  }

  // Update the data source with new data
  function updateDataSource(map) {
    if (isNaN(map)) {
      map = mapRef.current;
    }
    if (
      map &&
      map.getSource("vessels") &&
      map.getSource("vessels")["_data"]["features"]
    ) {
      const vesselData = {
        type: "FeatureCollection",
        features: [],
      };

      for (const id in fleetData) {
        let vesselInfo = vesselList.find(vessel => vessel.vessel_id === id)
        if (Math.random()>0.95){
          console.log(id, fleetData[id])
        }
        if (
          fleetData[id] 
          && ((fleetData[id]?.telemetry_data?.latitude ?? null)!=null) 
          && ((fleetData[id]?.telemetry_data?.longitude ?? null) !== null)
          && vesselInfo != undefined
        ) {
          const vessel = fleetData[id];
          const latitude = vessel.telemetry_data.latitude;
          const longitude = vessel.telemetry_data.longitude;
          const speed = vessel.telemetry_data.inst_speed;
          const avgSpeed = vessel.telemetry_data.avg_speed;

          const feature = {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [longitude, latitude],
            },
            properties: {
              title: `${vesselInfo.vessel_name}`,
              description: `${speed}, ${avgSpeed},`,
            },
          };
          vesselData.features.push(feature);
        }
      }

      // Calculate the minimum distance for each vessel
      for (let i = 0; i < vesselData.features.length; i++) {
        let minDistance = Infinity;
        for (let j = 0; j < vesselData.features.length; j++) {
          if (i !== j) {
            const from = turf.point(
              vesselData.features[i].geometry.coordinates,
            );
            const to = turf.point(vesselData.features[j].geometry.coordinates);
            const options = { units: "kilometers" };
            const dist = turf.distance(from, to, options);

            if (dist < minDistance) {
              minDistance = dist;
            }
          }
        }
        // Store the minimum distance in the properties of the feature
        vesselData.features[i].properties.minDistance = minDistance;
      }

      // Set the vesselFeatures state with the new data
      console.groupCollapsed("FleetMap vesselData")
      console.table(vesselData)
      console.table(vesselData.features)
      console.groupEnd()
      setVesselFeatures(vesselData.features);

      if (
        map &&
        map.getSource("vessels") &&
        vesselData["features"].length >=
          map.getSource("vessels")["_data"]["features"].length
      ) {
        map.getSource("vessels").setData(vesselData);

        const bounds = new maplibregl.LngLatBounds();
        for (const feature of vesselData.features) {
          bounds.extend(feature.geometry.coordinates);
        }
        setBounds(bounds)

        if (!mapCentered){
          const center = getMapCenter(fleetData);
          map.setCenter(center);
          map.fitBounds(bounds, { padding: 20 });
          setMapCentered(true);
        }
      }
    } else {
      console.log("updateDataSource called but conditionals not met", map);
    }
  }

  // Define handleButtonClick function here:
  const handleButtonClick = (coords, minDistance) => {
    // Calculate the zoom level based on the minimum distance to another vessel
    let zoomLevel = 14; // default zoom level
    if (minDistance < 1) {
      zoomLevel = 20; // closer zoom for vessels that are very close together
    } else if (minDistance < 10) {
      zoomLevel = 15; // intermediate zoom for vessels that are moderately close
    }

    // Set the center and zoom level of the map
    mapRef.current.flyTo({ center: coords, zoom: zoomLevel });
  };

  const buttons = [...vesselFeatures, {'properties': {'title': 'Show All'}}];

  return (
    <div>
      <div
        className="button-container"
        style={{
          marginBottom: "10px",
          marginTop: "10px",
          display: "flex",
          justifyContent: "center",
          maxHeight:'20vh',
          overflow:'scroll',
          flexWrap: "wrap",
          flexGrow:'1'
          
        }}
      >
        {buttons.map((feature, index) => (
          <button
            key={index}
            className={`fm-btn map ${feature.properties.title === 'Show All' && 'show-all'}`}
            
            onClick={() => {
              if (feature.properties.title !== 'Show All'){
              handleButtonClick(
                feature.geometry.coordinates,
                feature.properties.minDistance,
              )} else if (bounds) {
                const center = getMapCenter(fleetData);
                mapRef.current.setCenter(center);
                mapRef.current.fitBounds(bounds, { padding: 20 });
              } else {
                  console.warn("Show all clicked, but bounds not set!")
                }
              }
            }
          >
            <span>{feature.properties.title}</span>
          </button>
        ))}
      </div>
      
      <div
        id="map"
        style={{
          width: "65vw",
          minWidth: '350px',
          height: "60vh",
          borderRadius: "12px",
          justifyContent: "center",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "15px",
          marginTop: "15px",
        }}
        ref={mapContainer}
      />

    </div>
  );
};

export default FleetMap2;
