import { 
    Box, 
    Modal, 
    CardHeader,
    TextField,
    Button
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth } from '../app/zustore';
import { fetchData } from '../app/QueryHandler';
import PublishIcon from '@mui/icons-material/Publish';
// import '../pageLayout.css';
import { enqueueSnackbar } from 'notistack';

export const NewGroupModal = ({ showModal, setShowModal, selectedNode }) => {
    const [groupName, setGroupName] = useState('')
    const token = useAuth(state=>state.token)
      
    const handleInputChange = (event) => {  
        setGroupName(event.target.value);
    };
        
    const handleCreateGroup = async () => {
        if (groupName.length>0){
            console.groupCollapsed('NewGroup-handleSubmit');
            // setStatus('loading')
            let newGroup = {
                node_name:groupName, 
                parent_node_id:selectedNode.id
            };
        
            console.table(newGroup)
            console.log(JSON.stringify(newGroup))
            console.groupEnd();
            
            const {status, response} = await fetchData({method:'POST', resource:'nodes', token:token, body:newGroup})
            if (status==400){
                enqueueSnackbar('Failed to create Group', {variant: 'error'})
            } else if (status==201||status==200){
                enqueueSnackbar('Group Created', {variant: 'success'})
                setShowModal(0)
            }
            console.table(response)
        }
    }

    const header = () => {
        if (!selectedNode) return <CardHeader title="Create a new group"/>
        else return <CardHeader title={`Create new sub group under ${selectedNode.name}`}/>
    }

    return (
        <Modal 
            open={showModal} 
            onClose={()=>{setShowModal(0)}}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <div style={styles.box}>
                {header()}
                <div className='ModalContent' >
                    <TextField                         
                            label={"Name of group"}
                            placeholder={`Enter name of new Group`}
                            variant="outlined"
                            style={{padding: 10,
                                backgroundColor: 'rgb(220,220,220)',
                                paddingRight: '30px',
                                width: '80%'}}
                            defaultValue={''}
                            onChange={(value) => handleInputChange(value)}
                            
                        />
                    <Button 
                        variant="contained" 
                        sx={{
                            margin: '10px auto 10px auto', 
                            height:'80%', 
                            maxWidth:'100px',
                            color: '#00b9b8',
                            backgroundColor:groupName.length>0?'#3d444b':'lightgray'}} 
                            onClick={()=>{handleCreateGroup()}}     
                        endIcon={<PublishIcon />}>
                            Submit
                    </Button>
                </div>
            </div>
        </Modal> 
    )
}

const styles = {
    box: {
        position: 'absolute',
        top: '50%',
        maxHeight:'70%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '88%',
        maxWidth: '1000px',
        backgroundColor: '#e1e1e1',
        borderRadius: '15px',
        boxShadow: 24,
        overflowY: 'hide',
        display: 'flex',
        flexDirection:'column',
        alignContent:'center',
        alignItems:'center',
        justifyContent:'center',
    },
}