import { createFileRoute, Outlet, useNavigate } from '@tanstack/react-router'


export const Route = createFileRoute('/_authenticated/vessels/manage/')({
  component: ManageGroup
})

function ManageGroup() {

  return (
    <div className='group-manage-page'>
     
    <Outlet />     
    
    </div>
  )
}