import React from "react";
import "./loadingBar.css"; // Import your CSS file for styling

export const LoadingBar = ({home=true}) => {
  return (
    <div className={home ? "loading-bar" : "vessel-bar"}>
      <div className="loader"></div>
    </div>
  );
};
