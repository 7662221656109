export const plotPresets = {
  pi: {
    speed: {
      x: 'sample_time_str',
      y1: [
          'telemetry_data.inst_speed'
        ],
      y2: ['motor_data.0.velocity'],
      mode: "markers",
      isLocal: false,
      c: 'telemetry_data.inst_speed'
    },
    faults: {
      x: 'sample_time_str',
      y1: [
          "motor_data.0.system_state",
          "motor_data.0.system_fault",
          "motor_data.0.control_word",
          "obc_data.0.obc_status"
      ],
      y2: [
        "motor_data.0.velocity"
    ],
      mode: "markers",
      isLocal: false,
    },    
    cell_temps: {
      x:  'sample_time_str',
      y1: [
          'battery_data.low_cell_temp',
          'battery_data.high_cell_temp',
          'motor_data.0.motor_temp'          
      ],
      y2: [],
      mode: "lines+markers",
      isLocal: false,
    },
    speed_rpm: {
      x:  'motor_data.0.velocity',
      y1: [ 'telemetry_data.inst_speed' ],
      y2: [],
      mode: "markers",
      isLocal: false,
      c: 'telemetry_data.inst_speed'
    }
  },
  bud: {
    speed: {
      x:  'sample_time_str',
      y1: ['telemetry_data.inst_speed'],
      y2: [],
      mode: "lines+markers",
      isLocal: false,
    },
    distance: {
      x: 'sample_time_str',
      y1: [
          'distance_miles',
          'total_distance_mi'
      ],
      y2: [],
      mode: "lines+markers",
      isLocal: false,
    }
  },  
  csv: {
    speed: {
      x: 'sample_time_str',
      y1: [ 'GPS Speed (mph)' ],
      y2:[],
      c: 'GPS Speed (mph)',
      mode: "lines+markers",
      isLocal: false,
    },
    faults: {
      x: 'sample_time_str',
      y1: [
          "System State",
          "System Fault",
          "Ctrl Word"
      ],
      y2: [
        "Velocity"
      ],
      mode: "markers",
      isLocal: false,
    },    
    temps: {
      x:  'sample_time_str',
      y1: [
          'Lo Cell Temp',
          'Hi Cell Temp',
          'Motor Temp'
        ],
      y2: [],
      mode: "lines+markers",
      isLocal: false,
    },
    speed_v_power: {
      x:  'Power (hp)',
      y1: ['GPS Speed (mph)'],
      y2: [],
      c: 'GPS Speed (mph)',
      mode: "markers",
      isLocal: false,
    }
  }
}