import { useEffect, useState } from 'react';
import { useDataSource, usePlots } from '../../app/zustore';
import { Button, Modal, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'
import PublishIcon from '@mui/icons-material/Publish';
import superjson from 'superjson';
import { PlotCustomizer } from './PlotCustomizer';
import { PresetNamer } from './PlotPresetNamer';
import Select from 'react-select';
import { Download } from '@mui/icons-material';

export const ExportPresetsModal = ({ env, showModal, setShowModal, options, isMobile }) => {
  const [newPresetName, setNewPresetName] = useState(''); 
  const datasource = useDataSource((s)=>s.datasource);
  const plots = usePlots( s=>s.plots)  
  const preset = usePlots(s=>s.preset)
  const plot = plots[datasource]?.[preset] 
  const updateFullDatasource = usePlots( s=>s.updateFullDatasource)  
  const [newPlot, setNewPlot] = useState({...plot, isLocal:true})
  const [updateAccepted, setUpdateAccepted] = useState(0)
  const [selectedPlots, setSelectedPlots] = useState({})

  const localPlotPresets = Object.entries(plots[datasource]).filter(p=>p[1].isLocal);

  const styles = {
      
    button: {
      position: 'absolute',
      top: isMobile?'90%':10,
      right: 10,
      margin: '10px auto 10px auto', 
      height:'30px', 
      width:'90px',
      color: '#00b9b8', 
      backgroundColor: '#3d444b'  
  },
  submit: {
    margin: '10px auto 10px auto', 
    height:'30px', 
    width:'160px',
    color: '#00b9b8', 
    backgroundColor: '#3d444b'  
}
  }

  const handleSelectorChange = (event) => {
    let newSelection = {...selectedPlots};
    event.forEach(p=>(newSelection = {...newSelection, [p.label]:p.value}))
    setSelectedPlots(newSelection);
  };  

  const exportToJson = e => {
    e.preventDefault()
    if (selectedPlots){
        downloadFile({
            data: superjson.stringify({[datasource]: selectedPlots}),
            fileName: 'fleet_dashboard_plot_presets.json',
            fileType: 'text/json',
        })
    }
  }

  useEffect(()=>{
    setNewPlot({...plot, isLocal:true})  
  },[plot])

  if (showModal){
    return (
        <Modal 
            open={showModal} 
            onClose={()=>{setShowModal(0)}}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
          <Box className='analyze-page-modal-box'>
            

            <span className='analyze-page-modal-header'>
                Select whichs presets you want to share, and then click download
            </span>

            <div style={{ width:'300px', justifyContent: 'center', alignItems: 'center', display:'flex', flexDirection:'column', marginBottom:'6px' }}>
                {/* <label style={{fontWeight:'400', fontSize:'larger', marginInlineEnd:'20px', marginTop:'8px'}}>
                    {toTitleCase(axis)}{axis==='mode'?'':' Axis'}:
                </label> */}
                <Select
                    options={localPlotPresets.map(p => ({value:p[1], label:p[0]}))}
                    styles={{width:'300px'}}
                    isMulti={true}
                    onChange={(event)=>handleSelectorChange(event)}
                    value={Object.entries(selectedPlots).map(p=>({ label:p[0], value:p[1]}))}
                />
            </div>
            
            <div className='analyze-page-modal-button-div'>
                <Button variant="outlined" className='analyze-page-modal-button' onClick={()=>{setShowModal(0); setUpdateAccepted(0)}} endIcon={<CloseIcon/>}>Close</Button>
                <Button variant="contained" className='analyze-page-modal-button' sx={{backgroundColor:updateAccepted<2?'#3d444b':'red'}} onClick={exportToJson} endIcon={<Download />}>Download</Button>
            </div>
                
          </Box>
        </Modal> 
    )
  }
}


const downloadFile = ({ data, fileName, fileType }) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType })
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }
  
  