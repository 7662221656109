import {create} from 'zustand';
import { ModalTypes } from '../Types/enums';
import { plotPresets } from '../models/plotlyPresets';

export const zuPage = create(set => ({
    page: 'FleetOverview',
    tab: 1,
    leftSidebarOpen:false,
    toggleLeftSidebar: (open) => set({ leftSidebarOpen: open }),
    rightSidebarOpen:false,
    toggleRightSidebar: (open) => set({ rightSidebarOpen: open }),
    setPage: (page) => {
      set({ page });
      window.scrollTo(0,0);
    },
    setTab: (tab) => set({ tab }),
    setPageTab: (page, tab) => {
      set({ page, tab });
      window.scrollTo(0,0);
    },
  }));

export const useAuth = create((set) => ({
  session: null,
  token: null,
  user: null,
  isAuthenticated: false,
  setIsAuthenticated: (isAuthenticated) => set({isAuthenticated: isAuthenticated}),
  resetAuth: () => set({session: null, token: null, user: null}),
  setAuth: (session, user) => set({session: session.payload, token: session.toString(), user:user})
}))

export const useSelectedNodeStore = create((set) => ({
  selectedNode: null,
  setSelectedNode: (newValue) => set({selectedNode: newValue})
}))

export const useVessel = create((set) => ({
  vessel_id: null,
  vessel_name: null,
  setVessel: (id, name) => set({vessel_id: id, vessel_name: name}),
}));

export const useSelect = create((set) => ({
  selected: '',
  setSelected: (newSelection) => set({selected: newSelection})
}))

export const useLiveData = create((set) => ({
  liveData: {},
  setLiveData: (id, newData) => set((state) => ({
    liveData: { ...state.liveData, [id]: newData },
  }))
}))

export const useCurrent = create((set) => ({
  current: {},
  setCurrent: (id, newData) => set((state) => ({
    current: { ...state.current, [id]: newData },
  })),
}))

export const useFleetData = create((set) => ({
  fleetData: {},
  updateFleetData: (newData) => set({fleetData: newData}),
}))

export const useVesselList = create((set) => ({
  vesselList: [],
  setVesselList: (vesselList) => (set({vesselList:vesselList})),
}))

export const useTrips = create((set) => ({
  trips: {},
  trip: null,
  tripData: [],
  setVesselTrips: (vessel_id, new_trips) => set((state) => (
    {trips: {...state.trips, [vessel_id]:new_trips }}
  )),
  setTrips: (new_trips) => (set( { trips:new_trips } )),
  selectTrip: (new_trip) => (set( {trip:new_trip} )),
  setTripData: (data) => (set( {tripData:data}))
}))

export const useUser = create((set) => ({
  user: null,
  setUser: (newUser) => set({ user: newUser }),
}));

export const useFetchStatus = create((set) => ({
  statText: {},
  total: {},
  current: {},
  progress: {},
  setStatText: (newData) => set(({statText:  newData})),
  setProgress: (current, total) => set(({
    current:  current,
    total:  total,
    progress:  (current / total) * 100,
  }))
}));

export const useDataSource = create((set) => ({
  datasource: 'pi',
  setDataSource: (source) => set({datasource: source})
}));

export const useAnalysisData = create((set) => ({
  analysisData: [], 
  /* 
  [
    {
    id: vessel_id,
    source: 'csv|bud|pi',
    label: 'vessel_id-time'|filename
    data: []
    }
  ]
  */
  setAnalysisData: newData => set((state) => ({
    analysisData: [ ...state.analysisData, newData ],
  })),
  dropDataset: (id) => (set(state=>(
    { analysisData: [...state.analysisData].filter(d=>(d.dataset_id!==id)) }
  ))),
  clearAnalysisData: ()=>set(state=>({analysisData:[]}))
}));

export const useNumPlots = create((set) => ({
  numPlots: 3,
  setNumPlots: (num) => set({numPlots: num})
}));

export const useGroups = create((set) => ({
  groups: [],
  selectedGroup: null,
  updateGroups: (newGroups)=>set({groups:newGroups}),
  setSelectedGroup: (group)=>set({selectedGroup:group})
}))

export const usePlots = create((set) => ({
    plots: plotPresets,
    plotIndex: 0,
    preset: '', 
    updatePlots: (datasource, preset, newPlot )=>set((state)=>(
      {plots: {
        ...state.plots, 
        [datasource]: {
          ...state.plots[datasource], 
          [preset]: newPlot
        }
      }
    })),
    updateFullDatasource: (datasource, newPlots )=>set((state)=>(
      {plots: {
        ...state.plots, 
        [datasource]: {...newPlots}
      }
    })),
    updatePlotIndex: (newIndex)=>set({plotIndex:newIndex}),
    updatePreset: (newPreset)=>set({preset:newPreset})
    })
)

export const use3dPlot = create((set) => ({
  plot3d: {
    pi:{
      x: 'motor_data.0.velocity',
      y: 'motor_data.0.torque',
      z: 'motor_data.0.horse_power',
      c: 'motor_data.0.horse_power',
    },
    bud:{
      x: 'telemetry_data.latitude',
      y: 'telemetry_data.longitude',
      z: 'telemetry_data.inst_speed',
      c: 'telemetry_data.inst_speed',
    },
    csv: {
      x: 'Velocity',
      y: 'Torque',
      z: 'Power (hp)',
      c: 'Power (hp)',
    }
},
  updatePlot3d: (newPlot, datasource )=>set((state)=>(
    {plot3d: {...state.plot3d, [datasource]: newPlot}})),
})
)

export const useModal = create((set) => ({
  modalType: ModalTypes.None,
  setShowModal: (type) => set({modalType: type})
  })
)

export const useLeaderboard = create((set)=>({
  leaderboard: [],
  updateLeaderboard: (newValue)=>set({leaderboard:newValue})
}))

export const useLatestTrips = create((set)=>({
  latestTrips: [],
  updateLatestTrips: (newValue)=>set({latestTrips:newValue})
}))

export const useFleetActivity = create((set)=>({
  fleetActivity: [],
  updateFleetActivity: (newValue)=>set({fleetActivity:newValue})
}))

export const useVesselActivity = create((set)=>({
  vesselActivity: [],
  updateVesselActivity: (newValue)=>set({vesselActivity:newValue})
}))

export const useFaults = create((set)=>({
  faults: [],
  setFaults: (newValue)=>set({faults:newValue})
}))

export const useDyno = create((set)=>({
  dynoData: null,
  finishedRecursing: false,
  isDualDynoMode: false,
  setDynoData: (newValue)=>set({dynoData:newValue}),
  setDynoMode: (newValue)=>set({isDualDynoMode:newValue}),
  setFinishedRecursing: (newValue)=>set({finishedRecursing: newValue})
}))

export const useSort = create((set)=>({
  sortAlgo: 'rankedAlpha',
  setSortAlgo: (newValue)=>set({sortAlgo:newValue})
}))

export const useMotorFilter = create((set)=>({
  motorFilter: 'All',
  setMotorFilter: (newValue)=>set({motorFilter:newValue})
}))

export const dynoIdArray = ['5da5ef4e-9bc4-4e94-a033-eab5d893b11b','7b7f72d9-bb02-41a2-a6d8-b471c06528b2'];

export const useNotifications = create((set)=>({
  endpt: null,
  p256: null,
  auth: null,
  notifications: [],
  setSubscription: (newEndpt, newP256, newAuth)=> set({endpt:newEndpt, p256:newP256, auth:newAuth}),
  addNotification: (newNotification) => set((state)=>({notifications: Array.from(new Set([...state.notifications.slice(-10), newNotification]))})),
  removeNotification: (newNotification) => set((state)=>({notifications: [...state.notifications.filter(n=>n.notification_id!=newNotification.notification_id)]}))
}))

