import { useEffect, useState, useMemo } from "react";
import Plotter from "../../utils/Plots/PlotlyPlots";
import { Box, Card, CardContent, Button } from "@mui/material"
import { usePlots, useDataSource, useModal } from "../../app/zustore"
// import '../../App.css'
import superjson from 'superjson';
import Select from 'react-select';
import TuneIcon from '@mui/icons-material/Tune';
import { PlotCustomizerModal } from "../../utils/Plots/PlotCustomizerModal";
import { ModalTypes } from "../../Types/enums";
import { ExportPresetsModal } from "../../utils/Plots/ExportPresetModal";
import { ImportPresetModal } from "../../utils/Plots/ImportPresetModal";
import { ManageAccounts, Share, UploadFile } from "@mui/icons-material";
import { ManagePresetsModal } from "../../utils/Plots/ManagePresetsModal";
import ErrorBoundary from "../../utils/ErrorBoundary";

// import CustomPlotter from "../utils/CustomPlot";

const env = process.env.REACT_APP_ENV;

function CarouselButton({direction}) {
    const plotIndex = usePlots(s=>s.plotIndex)
    const  updatePlotIndex = usePlots(s=>s.updatePlotIndex)
    const [active, setActive] = useState(false)
    const datasource = useDataSource(s=>s.datasource)
    const presets = Object.keys(usePlots(s=>s.plots)[datasource])
    const updatePreset = usePlots(s=>s.updatePreset)    

    let d = (direction=='back') ? "M20.5 4.5v23M15.5 9.5v13M10.5 13.5v5" : "M11.5 4.5v23M16.5 9.5v13M21.5 13.5v5";

    const handleClick = ()=>{
        if (direction=='next'){
            if ( plotIndex == presets.length-1 ) {
                updatePlotIndex(0)
                updatePreset(presets[0])
            } else {
                updatePlotIndex(plotIndex+1)
                updatePreset(presets[plotIndex+1])
            }
        } else {
            if ( plotIndex == 0 ) {
                updatePlotIndex(presets.length-1)
                updatePreset(presets[presets.length-1])
            } else {
                updatePlotIndex(plotIndex-1)
                updatePreset(presets[plotIndex-1])
            }
        }
    }
    
    return (
        <svg
            width={32}
            height={32}
            fill="none"
            className="CarouselButton"
            xmlns="http://www.w3.org/2000/svg" 
            style={active ? {transform: 'scale(1.0, 1.75)'} : {}}
            onMouseEnter={() => {setActive(true);}}
            onMouseLeave={() => {setActive(false);}}
            onClick={handleClick}
        >
        {/* <path fill={active ? "#666" : 'none'} d="M0 0h32v32H0z" /> */}
        <path
            stroke={active ? '#00eeee' : "#000"}
            strokeWidth={2}
            strokeLinecap="round"
            d={d}
        />
        </svg>
    );
}

export const PlotCarousel = ({ isMobile, contentAreaHeight, dataset }) => {
    const plotIndex = usePlots(s=>s.plotIndex)
    const plots = usePlots(s=>s.plots)
    const preset = usePlots(s=>s.preset)
    const updatePreset = usePlots(s=>s.updatePreset)
    const datasource = dataset.source
    const updateFullDatasource = usePlots(s=>s.updateFullDatasource)
    const {modalType, setShowModal} = useModal()
    const showModal = useMemo(()=>{return modalType!==ModalTypes.None}, [modalType])
    
    const preset_subset = Object.entries(plots[datasource]).filter(p=>!p[1].isLocal).map(p=>p[0]);
    const localPlotPresets = Object.entries(plots[datasource]).filter(p=>p[1].isLocal).map(p=>p[0]);

    useEffect(()=>{
        updatePreset(Object.keys(plots[datasource])[plotIndex])
        console.groupCollapsed('Plot Carousel useEffect');
        console.log(datasource)
        console.log(plots)
        console.table(preset_subset)
        console.table(localPlotPresets || 'No local presets set')
        console.table(preset)
        console.groupEnd();
    },[plots])

    useEffect(()=>{
        const getLocalData = (key, defaultValue)=>{
          const data = localStorage.getItem(key)
          return data===null?JSON.stringify(defaultValue):data
        };
        
        const localPlotPresets = superjson.parse(getLocalData(`plotly-presets-${env}`, {}));
        
        if (localPlotPresets) {
          // check if the data in local storage is newer than the data in the zustore
          const localPlotPresetSubsets = Object.entries(localPlotPresets[datasource]).filter(p=>p[1].isLocal).filter(p=>!Object.keys(p[1]).find(attr=>attr=='ys')).map(p=>p[0]);
          if (localPlotPresetSubsets.map(p=>p[0]).length>0){
            // Update the plots zustore
            const newSubset = {...plots[datasource], ...localPlotPresets[datasource]};
            console.log(newSubset)
            updateFullDatasource(datasource, newSubset);
          } else {
            console.log('conditions not met by local plot preset store', localPlotPresets)
          }
        } 

      }, [])

    const handlePresetChange = (event) => {
        // event.preventDefault()
        updatePreset(event.value)
        console.log('updating plot preset selection', event)
    };

    const Layout = ()=>{
        if (isMobile){
            return (
            <div className='plot-carousel-content-area' >                
                <Card 
                    style={styles.card}> 
                    <CardContent>
                        <Plotter env={env} plotKey={plotIndex} dataset={dataset} preset={preset}  isMobile={isMobile}/>
                    </CardContent>
                </Card>                
            </div>
            )
        } else {
            return (
            <div className='plot-carousel-content-area' >
                <CarouselButton direction={'back'} />
                
                <Card 
                    style={styles.card}> 
                    <CardContent p={'4px'} sx={{pb:'8px'}}>
                        <ErrorBoundary boundaryName='Inner Plots'>
                            <Plotter env={env} plotKey={plotIndex} dataset={dataset} preset={preset}  isMobile={isMobile} contentAreaHeight={contentAreaHeight}/>
                        </ErrorBoundary>
                    </CardContent>
                </Card>
                
                <CarouselButton direction={'next'} />
            </div>
            )
        }
    }

    const Modal = ()=>{
        switch (modalType) {
            case ModalTypes.PlotCustomizer:
                return <PlotCustomizerModal env={env} showModal={showModal} setShowModal={setShowModal} dataset={dataset}/> 
            case ModalTypes.ExportPresets:
                return <ExportPresetsModal showModal={showModal} setShowModal={setShowModal} isMobile={isMobile}/>
            case ModalTypes.ImportPresets:
                return <ImportPresetModal showModal={showModal} setShowModal={setShowModal} isMobile={isMobile}/>
            case ModalTypes.ManagePresets:
                return <ManagePresetsModal  env={env} showModal={showModal} setShowModal={setShowModal} isMobile={isMobile}/>
            default:
                return null;
        }
    }

    const buttonStyle = {
        margin: '2px 0px 5px 0px', 
        height:'80%', 
        maxWidth:'200px',
        color: '#00b9b8',
        backgroundColor:'#3d444b'}

    return (
        <Box xs={12} sm={6} md={6} lg={6} xl={4} sx={styles.box}>
            <div style={{height:'100%', paddingTop:'2px', paddingBottom:'4px', display:'flex', flexDirection:'row', columnGap:'20px'}}>
                <Button 
                    variant="contained"                             
                    sx={buttonStyle} 
                        onClick={()=>setShowModal(ModalTypes.PlotCustomizer)}     
                    endIcon={<TuneIcon />}>{!isMobile && "Customize"}</Button>
                <Button 
                    variant="contained"                             
                    sx={buttonStyle} 
                        onClick={()=>setShowModal(ModalTypes.ManagePresets)}     
                    endIcon={<ManageAccounts />}>{!isMobile && "Manage Presets"}</Button>
            </div>
            
            {preset!=='' && Layout()}

            <div className='plot-carousel-navbar'>
                {/* Global Presets */}
                <div style={{ height: "100%", marginLeft:'auto', flexGrow:'1', justifyContent: 'space-around', alignItems: 'center', marginRight:'auto', display:'flex', flexDirection:'row', marginBottom:'6px' }}>
                    { preset_subset.map((p, index)=>{
                        return (
                        <a key={index} className={`carousel-label ${p==preset && 'active'}`} onClick={()=>updatePreset(p)}>{p.replaceAll('_', '-')}</a>
                        )    
                    })}
                </div>

                {/* Local Presets */}
                <div className={localPlotPresets.find(p=>p==preset)?'personal-presets active':'personal-presets'} style={{ height: "100%", width:'250px', marginLeft:'auto', justifyContent: 'center', alignItems: 'center', marginRight:'auto', display:'flex', flexDirection:'column', marginBottom:'6px' }}>
                    <span style={{marginInlineEnd:'20px', marginTop:'8px', fontSize:'larger', fontWeight:'600', userSelect:'none', color:localPlotPresets.find(p=>p==preset)?'yellow':'black'}}>Personal Presets:</span>
                    <Select
                        options={localPlotPresets.map((localPresetName) => ({value:localPresetName, label:localPresetName}))}
                        onChange={handlePresetChange}
                        isMulti={false}
                        menuPortalStyles={{maxHeight:"10px" }}
                        // menuPortalTarget={document.body}
                        // menuPosition={'fixed'}
                        value={localPlotPresets.find(p=>p==preset)?{value:preset, label:preset}:{}}
                    />
                </div>
            </div>
            {Modal()}
        </Box>
    )
}

const styles = {
    box: {
        maxWidth:'100%', 
        width: '100%',
        maxHeight: '84vh',
        marginBottom:'4px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    card: { 
        // borderRadius: '10px', 
        CardShadow: 'rgb(7 169 255 / 61%) 0px 0px 5px 2px', 
        boxShadow: 'inset #0a3d55 -2px 2px 6px 0px',
        backgroundColor: 'white', 
        width: "98%",
        height: '100%'   
    },
    header: {
        color: '#000',
        fontSize: '18pt'
      }
}