import { useAuth } from "../app/zustore";
import { Button, Box, IconButton } from "@mui/material";
import { enqueueSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import { Logout } from "@mui/icons-material";
import { signOut } from "@aws-amplify/auth";
import { useNavigate } from "@tanstack/react-router";

export const SignOutButton = ({ color, expanded=false }) => {
    const { setIsAuthenticated, resetAuth } = useAuth(state=>({resetAuth:state.resetAuth, setIsAuthenticated:state.setIsAuthenticated}));
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const handleSignOut = async () => {
      if (window.confirm('Sign Out?')){
        try {
          await signOut();
          setIsAuthenticated(false);
          resetAuth();
          enqueueSnackbar('Signed out successfully', { variant: 'success' });
          queryClient.clear()
          navigate({to:'/signIn'})
        } catch (error) {
          console.log('Error signing out:', error);
        }
      }
    };

    const handleLogoutButtonClick = () => {
      handleSignOut();
    };

    const handleHover = () => {

    }

    return (
      <Box className='sign-out-button-box'>
        <Button 
            variant="outlined" 
            className={`sign-out-button ${expanded?'expanded':''}`}            
            aria-label="go to home"
            onMouseOver={handleHover}
            onClick={handleLogoutButtonClick}  sx={{ width: '100%', color: color||'white', size: 'large', border:'solid', borderWidth:'1px'}}
            endIcon={<Logout sx={{color:'#5f6369'}}/>}
        ><a>Sign Out</a></Button>
    </Box>
    )
}