import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
    Badge,
    Box,
    Typography,
    Tooltip
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import BoatIcon from '@mui/icons-material/DirectionsBoat'; // Assuming you're using this icon for boats
import PersonIcon from '@mui/icons-material/Person';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { useUser, useGroups } from '../app/zustore';
import { useEffect, useMemo } from 'react';
// import { DraggableStyledTreeItem } from './draggable-tree-item';

import { useDrag, useDrop } from 'react-dnd';
// import StyledTreeItem from './StyledTreeItem'; // Ensure this path is correct

const ItemType = 'TREE_ITEM';

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.mode === 'light' ? theme.palette.grey[800] : theme.palette.grey[200],
    [`& .${treeItemClasses.content}`]: {
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(0.5, 1),
        margin: theme.spacing(0.2, 0),
        [`& .${treeItemClasses.label}`]: {
            fontSize: '0.9rem',
            fontWeight: 500,
            whiteSpace: 'nowrap', // Ensure the text does not wrap
            overflow: 'hidden', // Hide overflowed text
            textOverflow: 'ellipsis', // Add ellipsis for overflowed text
        },
    },
    [`& .${treeItemClasses.iconContainer}`]: {
        borderRadius: '50%',
        backgroundColor: '#1A202C',
        color: theme.palette.mode === 'dark' && theme.palette.primary.contrastText,
        padding: theme.spacing(0.2, 0.2),
    },
    [`& .${treeItemClasses.groupTransition}`]: {
        marginLeft: 5,
        paddingLeft: 8,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}));

const DraggableStyledTreeItem = ({ node, moveNode, level = 0 }) => {
    useEffect(() => {
        console.log(node)
    }, []);

    
    const [, drag] = useDrag(() => ({
        type: ItemType,
        item: { id: node?.id },
    }));
    
    const [, drop] = useDrop(() => ({
        accept: ItemType,
      drop: (draggedItem) => moveNode(draggedItem?.id, node?.id),
    }));
    
    if (node==null) return null

    const colorMap = ['#3357FF', '#ff8733', '#673ab7'];
    const color = node.label?.search(/Flux/) !== -1 ? '#00B9B8' : colorMap[level % colorMap.length];
  
    return (
      <StyledTreeItem
        ref={(instance) => drag(drop(instance))}
        nodeId={node.id}
        label={
          <Box className="fleet-tree-item" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '24px' }}>
              <Tooltip title="Groups Level" placement="top" sx={{ position: 'relative', right: '20px', top: '50%', transform: 'translateY(-50%)' }}>
                <div
                  style={{
                    width: '20px',
                    height: '20px',
                    minWidth: '20px',
                    minHeight: '20px',
                    flexShrink: 0,
                    backgroundColor: color,
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <span style={{ color: '#fff', fontSize: '12px' }}>{level + 1}</span>
                </div>
              </Tooltip>
              <Typography sx={{ ml: 1, height: '24px' }}>{node.label}</Typography>
            </Box>
          </Box>
        }
        sx={{
          paddingBottom: '1px',
          textAlign: 'left',
          '&:not(:last-child)': {
            marginBottom: '10px',
          },
        }}
      >
        {Array.isArray(node.children) &&
          node.children.map((childNode) => (
            <DraggableStyledTreeItem key={childNode.id} node={childNode} moveNode={moveNode} level={level + 1} />
          ))}
      </StyledTreeItem>
    );
};

// export default DraggableStyledTreeItem;
const RenderTree = ({ node, moveNode, level = 0 }) => {
    if (!node) return null;
  
    return <DraggableStyledTreeItem node={node} moveNode={moveNode} level={level} />;
  };
  
export const StyledTreeView = ({ hideIcons, isSideBar, setSelectedNode }) => {
    const user = useUser((state) => state.user);
    const { groups, setSelectedGroup } = useGroups((state) => ({
      groups: state.groups,
      setSelectedGroup: state.setSelectedGroup,
    }));
  
    const mapChildren = (nodeId) => {
      const node = groups.find((group) => group.node_id === nodeId);
      if (!node) return null;
  
      const children = node.child_node_ids ? node.child_node_ids.map((childId) => mapChildren(childId)) : [];
  
      const vesselIds = new Set(node.vessel_ids || []);
      children.forEach((child) => {
        if (child) {
          child.vessels.forEach((vesselId) => vesselIds.add(vesselId));
        }
      });
  
      return {
        id: node.node_id,
        name: node.node_name,
        label: node.node_name,
        vessels: Array.from(vesselIds),
        members: node.members ? node.members : [],
        can_write: node.can_write,
        children: children,
      };
    };
  
    const data = useMemo(() => {
      if (!groups) return null;
      const roots = Object.keys(user.nodes).map((n) => groups.find((g) => g.node_id == n)) || [null];
      if (!roots) return null;
      const mappedData = roots.map((root) => mapChildren(root.node_id));
      setSelectedGroup(groups.find((group) => group.node_id === mappedData[0].id));
      setSelectedNode(mappedData[0]);
      return mappedData;
    }, [groups, user]);
  
    if (!data) {
      return <div>User {user.user_name} does not belong to any groups!</div>;
    }
  
    const moveNode = (draggedId, targetId) => {
      // Implement your logic to update the tree data
      // Remove the dragged item from its original position
      // and add it to the target position
    };
  
    return (
        <div className={`Fleet-Treeview-div ${isSideBar ? 'side-bar' : 'manage'}`}>
        <DndProvider backend={HTML5Backend}>
          <RichTreeView
            aria-label="file system navigator"
            defaultExpandedItems={groups.map((g) => g.node_id)}
            items={data}
            slots={{ item: DraggableStyledTreeItem }}
            onItemSelectionToggle={(event, nodeId) => {
              const nodeData = groups.find((group) => group.node_id === nodeId);
              const nodeDataSubTree = mapChildren(nodeId);
              setSelectedNode(nodeDataSubTree);
              setSelectedGroup(nodeData);
            }}
            sx={{
              paddingTop: '2px',
              paddingRight: '2px',
              paddingBottom: '2px',
              maxHeight: '80vh',
            }}
          />
        </DndProvider>
        </div>
      );
    };