import { useEffect, useState } from 'react';
import { fetchData } from '../../../app/QueryHandler';
import PublishIcon from '@mui/icons-material/Publish';
import { enqueueSnackbar } from 'notistack';
import { FleetTreeView } from '../../../fleet/FleetTreeView';
import { createFileRoute } from '@tanstack/react-router';
import { useAuth, useGroups, useSelectedNodeStore, useVesselList } from '../../../app/zustore';
import { VesselUpdater } from '../../../vessel/manage/vesselUpdater';
import { VesselTreeUpdater } from '../../../vessel/manage/vesselTreeUpdater';
import { InputField } from '../../../Panels/AddVessel/vesselScreenUtils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MenuItem, useMediaQuery } from '@mui/material';
import Select from 'react-select';
import { flatVesselFields } from '../../../Types/vessel';

export const Route = createFileRoute('/_authenticated/groups/new-vessel')({
    component: NewVessel
  })

export function NewVessel ({ initial_node }) {
    const isMobile = useMediaQuery('(max-width:600px)');
    const [error, setError] = useState(null)
    const defaultAttrs = [
      { 
        label: "Vessel Name",                    
        field: "vessel_name", 
        id: "vessel_name", 
        optional: false,
        readOnly: false,
        path: '.',
        children: null,
        rank: 1
    },
    { 
        label: "Device ID",                      
        field: "device_id", 
        id: "device_id", 
        optional: false,
        readOnly: true,
        path: '.',
        children: null,
        rank: 2
    }]
    const [selectedFields, setSelectedFields] = useState(defaultAttrs)
    const {selectedNode, setSelectedNode} = useSelectedNodeStore();
    const queryClient = useQueryClient();
      const [fieldData, setFieldData] = useState({})

    const [fieldValidity, setFieldValidity] = useState({
        "vessel_name": false,
        "device_id": false,
    }
    );

    const add_vessel = useMutation({ // ReactQuery Mutation, handles the request
      mutationFn: async () => {
        // vessel is the vessel object to be sent to the API
        let newVessel = {'node_id': selectedNode.id};
        if (fieldData['.']) {
            newVessel = { ...newVessel, ...fieldData['.'] };
        }
        if (fieldData['outboards']) {
            newVessel.outboards = [fieldData['outboards']];
        }
        if (fieldData['outboards[0].prop']) {
            if (!newVessel.outboards) newVessel.outboards = [{}];
            newVessel.outboards[0].prop = fieldData['outboards[0].prop'];
        }
        console.log(newVessel)
        const token = useAuth.getState().token
        let {status, response} = await fetchData({ method: 'POST', resource: 'vessel-info', token: token, body: newVessel })
        console.log("response from POST vessel-info", response, status)
        if (status == 400) {
          throw new Error('Vessel name already in use!')
        }
        return response
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['groups'] })
        queryClient.invalidateQueries({ queryKey: ['vessel-info'] })
        enqueueSnackbar("New Vessel Added! 🚤🚤", { variant: 'QueryInfo' })
      },
      onError: (error) => {
        console.info(error)
        setError(error)
        enqueueSnackbar("Failed to add new Vessel!", { variant: 'error' })
      }
    })

    useEffect(() => {
        console.log(selectedFields, flatVesselFields, flatVesselFields.map(f=>f.id))
    }, [selectedFields]);

    const validateInput = (value, field) => {
            // console.log("validateInput", value, field, value.length)
            return value.length > 0;
    }
        
    const handleInputChange = (event, field) => {
      console.groupCollapsed("handleInputChange");
      console.log(event.target.value)
      console.log(field)
      const isValid = validateInput(event.target.value, field); // Replace `validateInput` with your own validation function
      console.log('isValid =', isValid)
  
      setFieldValidity((prevValidity) => ({
        ...prevValidity,
        [field.field]: isValid,
      }));
  
      setFieldData({ ...fieldData, [field.path]: { ...fieldData[field.path], [field.field]: event.target.value } });
  
      console.table({ ...fieldData, [field.path]: { ...fieldData[field.path], [field.field]: event.target.value } })
  
      console.groupEnd();
    };

    const button_disabled = ()=>{
        if (
            !selectedNode 
            // || selectedNode?.children
            || !selectedNode?.can_write
        ) return true;
        else {
            return false;
        }
    }

    const handleSelect = (event)=>{
      console.log("handleSelect", event)
      setSelectedFields([...defaultAttrs, ...event.filter(v=>!defaultAttrs.find(defaultAttr=>(defaultAttr.id==v.id)))])
    }

    const styles = {
      multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
      },
      multiValueLabel: (base, state) => {
        return state.data.isFixed
          ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
          : base;
      },
      multiValueRemove: (base, state) => {
        return state.data.optional ? base: { ...base, display: 'none' } ;
      },
    };
    
    const orderOptions = (values) => {
      return values
        .filter((v) => v.isFixed)
        .concat(values.filter((v) => !v.isFixed));
    };

    return (
        <div className='manage-page-1-col' >
                <h3 
                // style={{color:'var(--red-alt)'}}
                >Add new vessel to {selectedNode && selectedNode?.name} Group</h3>
                <div className='outer new-vessel' >
                    
                    {/* <VesselUpdater vesselToUpdate={vesselToUpdate}/> */}
                    <div className='group-selector'>
                      <h3>Select a group to add the new vessel to</h3>
                      <FleetTreeView setSelectedNode={setSelectedNode} initial_selection={initial_node}/>
                    </div>

                    <div className='field-selector'>
                      <h3>Select the attributes of the new vessel to fill in</h3>
                      <Select 
                        onChange={handleSelect}
                        isMulti={true}
                        styles={styles}
                        menuPortalStyles={{maxHeight:"10px" }}
                        options={flatVesselFields.flatMap(f=>({...f, value:f.id}))}
                        isClearable={selectedFields.some((v)=>v.optional)}
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                        value={selectedFields.flatMap(f=>({...f, value:f.id}))}
                      />
                      
                      {/* <VesselTreeUpdater vesselToUpdate={vesselToUpdate} selectedFields={selectedFields} setSelectedFields={setSelectedFields}/> */}
                      </div>
                    
                    <div className='input-fields'>
                      <h3>Fill in the selected fields for the new vessel</h3>
                        {selectedFields.filter(f=>f.children==null).sort((fieldA, fieldB)=>fieldA.rank-fieldB.rank).map((field, index) => (
                        <InputField
                            key={field.rank}
                            label={field.label}
                            index={index}
                            optional={false}
                            // style={styles.required}
                            onChange={(value) => handleInputChange(value, field)}
                        />))}

                      {error && <a>{error}</a>}
                      
                      <button 
                          className='confirm'
                          disabled={button_disabled()}
                          onClick={()=>{add_vessel.mutate()}} >
                              {!isMobile && <span>Submit</span>}
                              <PublishIcon />
                      </button>
                    </div>
                    

                    
                </div>
            </div>
    )
}