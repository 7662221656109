import * as React from 'react';
import { format_numerical_text } from './miniUI';

export const MiniCharging = ({index, soc, cp, cc, cv})=>{

    let soc_height = Math.round(163 * (1-(soc/100)))

    return (
    <svg className={`vessel-overview-charging`} width="95%" height="95%" viewBox="0 0 326 173" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g className='vessel-card-charging-battery'>
            <mask id='mini-charging-battery-mask'>
                <rect x='10' y="10" width="48" height="163" rx="4" fill="white"/>
            </mask>

            <rect x='10' y="10" width="48" height="163" rx="4" fill="#12F17A"/>
            <rect x='10' y='10' width="48" height={soc_height} rx="0" fill="#A0ABC0" mask='url(#mini-charging-battery-mask)'/>
            <text id="70%" fill="#E2E7F0" fontFamily="Inter"  letterSpacing="0.0em" textAnchor='start' x="65" y="170">
                <tspan fontSize="40">{soc}</tspan>
                <tspan fontSize="30">%</tspan>
            </text>
        </g>
        <g id="charging-text-group" style={{transform: "translate(-20px, 0px)"}}>
            <g id="Group 4">
                <text id="charge power" fill="#A0ABC0" fontFamily="Inter" fontSize="12" letterSpacing="0.05em">
                    <tspan x="235" y="13">charge power</tspan>
                </text>
                <text id="6.2 kW" fill="white" fontFamily="Inter" fontSize="20" letterSpacing="0.05em">
                    <tspan  textAnchor='start' x="235" y="39">{format_numerical_text(Math.round(cp*10)/10)}</tspan>
                </text>
                <text id="6.2 kW" fill="white" fontFamily="Inter" fontSize="14" letterSpacing="0.05em">
                    <tspan  textAnchor='start' x="282" y="39">kW</tspan>
                </text>
                <line id="Line 3" x1="237" y1="51.8158" x2="326" y2="51.8158" stroke="#4A5468"/>
            </g>
            <g id="Group 5">
                <text id="charge current" fill="#A0ABC0" fontFamily="Inter" fontSize="12" letterSpacing="0.05em">
                    <tspan x="235" y="76">charge current</tspan>
                </text>
                <text id="31/32 A" fill="white" fontFamily="Inter" fontSize="20" letterSpacing="0.05em">
                    <tspan  textAnchor='start' x="235" y="101.179">{format_numerical_text(Math.round(cc))}</tspan>
                </text>
                <text id="31/32 A" fill="white" fontFamily="Inter" fontSize="14" letterSpacing="0.05em">
                    <tspan textAnchor='start' x="282" y="101.179">A</tspan>
                </text>
                <line id="Line 3_2" x1="237" y1="116.121" x2="326" y2="116.121" stroke="#4A5468"/>
            </g>
            <g id="Group 6">
                <text id="charge voltage" fill="#A0ABC0" fontFamily="Inter" fontSize="12" letterSpacing="0.05em">
                    <tspan x="235" y="140">charge voltage</tspan>
                </text>
                <text id="210 V" fill="white" fontFamily="Inter" fontSize="20" letterSpacing="0.05em">
                    <tspan  textAnchor='start' x="235" y="165">{format_numerical_text(Math.round(cv))}</tspan>
                </text>
                <text id="210 V" fill="white" fontFamily="Inter" fontSize="14" letterSpacing="0.05em">
                    <tspan textAnchor='start' x="282" y="165">V</tspan>
                </text>
            </g>
        </g>
    </svg>
)
}