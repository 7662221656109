import { useState, useEffect, useMemo } from 'react';
import { useDataSource, usePlots, useAnalysisData, useVessel } from '../../app/zustore';
import { PlotAxisSelector } from './PlotAxisSelector';
import { format_label } from './PlotlyPlots';

const excludedKeys = [
  'devid', 
  'vessel_id', 
  // 'on_water',
  'tmoffset', 
  'iot_fw', 
  'version', 
  'telemetry_data.gnsslock',
  // 'time', 
  // 'sample_time', 
  'telemetry_data.altitude',
  'telemetry_data.latitude',
  'telemetry_data.longitude'];

export const PlotCustomizer = ({newPlot, setNewPlot, dataset}) => {
    const preset = usePlots(s=>s.preset)
    const datasource = dataset.source;
    const data  = dataset.data;
    const plot = usePlots( s=>s.plots?.[datasource]?.[preset] )  
    const vessel_id = useVessel(s=>s.vessel_id)

    const options = useMemo(()=>{
      if (!vessel_id || !data || data.length===0) return null
      console.log(
        Object.keys(data[1]), 
        Object.keys(data[1])
        .filter((key) => {
            const isExcluded = excludedKeys.includes(key);
            return !isExcluded;
        })
        .map((key) => ({
            value: key,
            label: key
              .replace(/battery_data_|telemetry_data_|motor_data_\d|obc_data_\d|ecu_data_\d|sensor_data_\d|system_performance_data_\d/g, "")
              .split(".")[key.split(".").length-1]
              .replace(/_/g, " "),
            group: key.split('.')[0]
        }
      )))

      const flattened_options = Object.keys(data[1])
        .filter((key) => {
            const isExcluded = excludedKeys.includes(key);
            return !isExcluded;
        })
        .map((key) => ({
            value: key,
            label: key
              .replace(/battery_data_|telemetry_data_|motor_data_\d|obc_data_\d|ecu_data_\d|sensor_data_\d|system_performance_data_\d/g, "")
              .split(".")[key.split(".").length-1]
              .replace(/_/g, " "),
            group: key.split('.')[0]
        }));

        const predefinedGroups = [
          "battery_data", "telemetry_data", "motor_data", "obc_data", "ecu_data", "sensor_data", "system_performance_data"
        ];
        
        const result = [
          {label: "battery_data", options:[]}, 
          {label: "telemetry_data", options:[]}, 
          {label: "motor_data", options:[]}, 
          {label: "obc_data", options:[]}, 
          {label: "ecu_data", options:[]}, 
          {label: "sensor_data", options:[]}, 
          {label: "system_performance_data", options:[]},
          {label: "top level fields", options:[]}
        ];

        // Create a lookup map for easy access
          const groupMap = result.reduce((acc, group) => {
            acc[group.label] = group.options;
            return acc;
        }, {});
    
        flattened_options.forEach(item => {
          let group = item.group;
  
          // Assign to 'calculated fields' if not in predefined groups
          if (!groupMap[group]) {
              group = 'top level fields';
          }
  
          if (groupMap?.[group]){
            groupMap[group].push({
                value: item.value,
                label: item.label
            });
          }
          else {
            console.log(group, 'could not be added to plot customizer')
          }
        });

      return result     
    }, [ data, vessel_id, excludedKeys])   

    // useEffect(()=>{
      // console.log(newPlot)
    // }, [newPlot])
    
    const markerOptions = [
          {value: 'lines', label:'lines'},
          {value: 'lines+markers', label:'lines and markers'},
          {value: 'markers', label:'markers'}
        ]
  
    const containerStyle = {
          width: '70%',
          maxWidth: '500px',
          height: '75%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          // alignItems: 'center',
          columnGap: '15px'
    }

    const allYs = [...newPlot?.y1, ...newPlot?.y2];

    if (newPlot?.isLocal){
      return (
        <div style={{...containerStyle}}>
          
          <PlotAxisSelector newPlot={newPlot} setNewPlot={setNewPlot} axis='x' options={options} type='select' />
          <PlotAxisSelector newPlot={newPlot} setNewPlot={setNewPlot} axis='y1' options={options} type='select-multi' />
          <PlotAxisSelector newPlot={newPlot} setNewPlot={setNewPlot} axis='y2' options={options} type='select-multi' />
          <PlotAxisSelector newPlot={newPlot} setNewPlot={setNewPlot} axis='mode' options={markerOptions} type='select' />
    
          {(newPlot.mode==='markers'||newPlot.mode==='lines+markers') && allYs.length===1 && 
            // display a drop menu to select color of markers if there is a single y var and the marker type is markers
            <PlotAxisSelector axis='c' options={options} newPlot={newPlot} setNewPlot={setNewPlot} type='select' />
          }
        </div>
      )
    }
  }