import { createFileRoute, Outlet, useNavigate } from '@tanstack/react-router'
import { useGroups, useSelectedNodeStore, useUser, zuPage } from '../../../app/zustore'
import { useEffect, useState } from 'react'
import { useMediaQuery } from '@mui/material'
import { FleetTreeView } from '../../../fleet/FleetTreeView'
import { StyledTreeView } from '../../../fleet/StyledTreeView'
import { ManageTabs } from '../../../Types/enums'

export const Route = createFileRoute('/_authenticated/groups/')({
  component: ManageGroup
})

function ManageGroup() {
  const { node_id } = Route.useParams()
  const user = useUser(state => state.user);
  const groups = useGroups((state) => ({ groups: state.groups})).groups
  const isMobile = useMediaQuery('(max-width:600px)');
  const group = groups?.find(n=>n.node_id==node_id);
  const {sidebarOpen, toggleSidebar} = zuPage(state=>({sidebarOpen:state.sidebarOpen, toggleSidebar:state.toggleSidebar}));
  const {selectedNode, setSelectedNode} = useSelectedNodeStore();
  const [actionTab, setActionTab] = useState(ManageTabs.MoveNode)
  const navigate = useNavigate()


  // const content = () => {
  //   switch (actionTab) {
  //     case ManageTabs.MoveNode:
  //       return <MoveNode initial_node={node_id} />
  //     case ManageTabs.NewNode:
  //       return <NewGroup selectedNode={node_id} />
  //     case ManageTabs.DeleteNode:
  //       return <DeleteGroup initial_node={node_id} />
  //     case ManageTabs.RenameNode:
  //       return <RenameGroup initial_node={node_id} />
  //     case ManageTabs.DeleteVessel:
  //       return null
  //     case ManageTabs.NewNode:
  //       return null
  //     case ManageTabs.NewVessel:
  //       return null
      
  //     default:
  //       return null;
  //   }
  // }

  return (
    <div className='group-manager-page'>
     
    <Outlet />     
    
    </div>
  )
}