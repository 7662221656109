export const faults_defs = {
    99:	"MC Fault (obsolete)",
    98:	"BMS Fault (obsolete)",
    97: "Charger Fault",
    96:	"OBC Fault (obsolete)",
    90:	"Cascadia Motor Controller Fault",
    91:	"Sevcon Motor Controller Fault",
    80:	"Emus BMS Fault",
    81:	"Flux BMS Fault",
    70:	"Netpower OBC Fault",
    71:	"GreenWatt OBC Fault",
    60:	"Sensata EVCC Fault",
    50:	"Netpower DCDC Fault",
    // Warnings	
    102: "Low SOC Warning",
    103: "Belt Overtemp Warning",
    104: "Nonzero Throttle on Startup Alert",
    105: "Charger Unplugged With Ignition On Alert",
    106: "Idle Timeout Alert",
    107: "ECOS Absent On Startup Alert",
    108: "ECOS Pulled During Operation Alert",
    111: "AN1 Fault (obsolete)",
    112: "AN2 Fault (obsolete)",
    113: "AN3 Fault (obsolete)",
    114: "AN4 Fault (obsolete)",
    115: "AN5 Fault (obsolete)",
    116: "AN6 Fault (obsolete)",
    117: "AN7 Fault (obsolete)",
    118: "AN8 Fault (obsolete)",
    119: "AN9 Fault (obsolete)",
    120: "AN10 Fault (obsolete)",
    121: "AN11 Fault (obsolete)",
    122: "AN12 Fault (obsolete)",
    123: "MC CAN Timeout",
    124: "BMS CAN Timeout",
    125: "OBC CAN Timeout",
    126: "RPI CAN Timeout (obsolete)",
    127: "HotSpot Overtemp Warning",
    128: "N2K CAN Timeout",
    130: "Both Belt Oil Temp Sensors Failed",
    131: "Single Belt Oil Temp Sensor Failed",
    132: "Liquid Detection Sensor Failed",
    133: "Trim Tilt Angle Sensor Failed",
    134: "Coolant Level Sensor Failed",
    135: "Low Coolant Fault",
    136: "Leakage Detected Fault",
        
    // Cutbacks	
    202: "Low SOC Cutback",
    203: "Belt Overtemp Cutback",
    227: "HotSpot Overtemp Cutback",
        
    // Critical 	
    301: "Throttle Disconnect Disable (obsolete)",
    302: "Low SOC Disable",
    303: "Belt Overtemp Disable",
    327: "HotSpot Overtemp Disable",
    329: "HVIL Open Disable",
    330: "Contactor Feedback Failure",
}