import * as React from 'react' ;
import { useQuery } from "@tanstack/react-query";
import { useUser, useLeaderboard, useFleetData, useVesselList, useAuth, useFaults } from '../app/zustore';
import { fetchData } from '../app/QueryHandler';
import { faults_defs } from '../Types/fault_defs';
import { FaultTreeViewer } from './FaultTreeViewer';
import { FaultLinePlot } from './FaultLinePlot';



export const FaultViewer = ({ isMobile,  })=>{
    const {faults, setFaults} = useFaults()
    const vesselList = useVesselList(s=>s.vesselList)
    const user = useUser(s=>s.user)
    const token = useAuth((state)=>state.token);
    const collapsed = React.useContext('Expander')

    function unpack(rows, key) {
        return rows.map(function(row) { 
            return row[key]; 
        });
    }

    async function fetchTestData({token, qString}){
        let response, body = null;
        
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': token,
        };
        const url = `https://0iwhnu0gpi.execute-api.us-east-1.amazonaws.com/test/fault${qString ? `?${qString}` : ''}`;
        console.log(url)
        console.groupEnd(); 
        try {
            const queryOptions = {
                method: "GET",
                headers: headers,
            }
            if (body) queryOptions.body = JSON.stringify(body)
            response = await fetch(url, queryOptions);

            // console.log(response)
            // If fetch was successful but the response status is not OK
            if (!response.ok) {
                // Handle 403 specifically
                if (response.status === 403) {
                    throw new Error('Forbidden: Access is denied.');
                } else {
                    // Handle other non-OK statuses
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            } else if (response.status == 204){
                return {status:204, body:null}
            }
            // console.log('response ok', response.status)

            const payload = await response.json();
            // console.log(payload.body)
            if (payload.body===null || payload.body.length===0){
                throw new Error('No Data Received!')
            }
            return {status:response.status, body:payload};
        } catch (error) {
            console.log(error)
            return response.status, ''
        }
    } 

    const faultsQuery = useQuery({
        queryKey: ['faults'],
        queryFn: async () => {
          const vessels = vesselList ? vesselList.flatMap((vessel) => vessel.vessel_id) : [];
          const params = {
            vessel_id: vessels
          }
          const {status, body, type} = await fetchData({ method: 'GET', resource: 'vessel-data/faults', token: token, params: params });
          //fetchTestData({ token: token, qString: qString });
          if (status===204) throw new Error('No data returned')
          if (status===403) throw new Error('403: Unauthorized')
          console.log(body)
          const data = body
          // console.log(data)
          if (data==='') throw new Error('No data returned')
          return data;
        },
        enabled: !collapsed,
        refetchInterval: 1000 * 60 * 15, // 30 seconds
        retry: 2,
        onSuccess: (data) => {
          if (data) {            
            console.groupCollapsed('faults query');   
            console.log(data)
            // convert the fault to an int and calculate the severity
            data = data.filter(r=>r.fault.length>1).map(row=>{
                const fault = parseInt(row.fault)
                return ({
                ...row, 
                fault: fault, 
                desc: faults_defs[fault] ? `${faults_defs[fault]}` : 'Description not found',
                severity: parseInt(row.fault / 100) * 100,
                vessel_name: vesselList.find(vessel=> vessel.vessel_id===row.vessel_id)?.vessel_name
            })})       
            console.log(data)
            setFaults(data)
            // updateLeaderboard(data)
            console.groupEnd();
          }
        }
    });

    return (
        <div style={{display:'flex', flexDirection:isMobile?'column':'row', marginBottom:'6px', width:'96%'}}>
            <FaultLinePlot faults={faults} isMobile={isMobile} />
            <FaultTreeViewer faults={faults} isMobile={isMobile} />
        </div>
    )
}