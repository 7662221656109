import { useEffect, useState } from 'react';
import { useDataSource, usePlots } from '../../app/zustore';
import { Button, Modal, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'
import PublishIcon from '@mui/icons-material/Publish';
import superjson from 'superjson';
import { PlotCustomizer } from './PlotCustomizer';
import { PresetNamer } from './PlotPresetNamer';
import { UploadFile } from '@mui/icons-material';

export const ImportPresetModal = ({ env, showModal, setShowModal, options, isMobile }) => {
  const [newPresetName, setNewPresetName] = useState(''); 
  const datasource = useDataSource((s)=>s.datasource);
  const plots = usePlots( s=>s.plots)  
  const preset = usePlots(s=>s.preset)
  const plot = plots[datasource]?.[preset] 
  const updateFullDatasource = usePlots( s=>s.updateFullDatasource)  
  const [newPlot, setNewPlot] = useState({...plot, isLocal:true})
  const [uploadedData, setUploadedData] = useState({});

  const styles = {
    button: {
      position: 'absolute',
      top: isMobile?'90%':10,
      right: 10,
      margin: '10px auto 10px auto', 
      height:'30px', 
      width:'90px',
      color: '#00b9b8', 
      backgroundColor: '#3d444b'  
    },
    submit: {
      margin: '10px auto 10px auto', 
      height:'30px', 
      width:'90px',
      color: '#00b9b8', 
      backgroundColor: '#3d444b'  
    }
  }

  const handleInputChange = (event) => {
    setNewPresetName(event.target.value);
  }; 
  

  const handleChange = e => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = e => {
      const data = superjson.parse(e.target.result);
      setUploadedData(data)
      const [uploadedDataSource, updloadedPresets] = Object.entries(data)[0] 
      console.log(uploadedDataSource, updloadedPresets)
      if (uploadedDataSource==='csv'||uploadedDataSource==='pi'||uploadedDataSource==='bud'){
        const newSubset = {...plots[uploadedDataSource], ...updloadedPresets};
        const newPlots = {...plots, [uploadedDataSource]:newSubset};
        console.log(newPlots)
        updateFullDatasource(uploadedDataSource, newSubset);
        localStorage.setItem(`plotly-presets-${env}`, superjson.stringify(newPlots));
      }
    };
  };


  useEffect(()=>{
    setNewPlot({...plot, isLocal:true})  
  },[plot])

  if (showModal){
    return (
        <Modal 
            open={showModal} 
            onClose={()=>{setShowModal(0)}}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
          <Box className='analyze-page-modal-box'>
            

            <span className='analyze-page-modal-header'>Upload a presets JSON file</span>
          
            <div style={{
              width:'60%', maxWidth:'500px', height:'25%', maxHeight:'300px', borderRadius:'12px',
              display:'flex', placeContent:'center', placeItems:'center', backgroundColor:'#dcf2f58c', 
              flexDirection: 'column'
            }}
              >
              {/* <input type="file" onChange={handleChange} /> */}
              
                <label onChange={handleChange} htmlFor="formId" style={{padding:'50px'}}>
                    <input name="Upload file" type="file" id="formId" hidden />
                    <UploadFile sx={{height:'40px', width:'40px'}} />
                </label>
              </div>
            
              <div className='analyze-page-modal-button-div'>
                <Button variant="outlined" className='analyze-page-modal-button' onClick={()=>{setShowModal(0)}} endIcon={<CloseIcon/>}>Close</Button>
              </div>
                

          </Box>
        </Modal> 
    )
  }
}