import * as React from 'react';
import { count_rows_per_vessel } from '../../utils/dataProcessing';
import { useVesselList } from '../../app/zustore';

const Plotly = window.Plotly;

export const DynoPlots = ({ data })=>{
    const plotContainerRef = React.useRef(null);
    const [plotRendered, setPlotRendered] = React.useState(false)
    const vesselList = useVesselList(s=>s.vesselList)

    const COLORS = ['#EBF5FBb0', '#97eaafb0']

    function unpack(rows, key) {
        return rows.map(function(row) { 

            return row[key]; 
        });
    }

    const vessel_ids = React.useMemo(()=>{
        // use helper function which counts the rows for each vessel id present in the array to extract the unique IDs
        console.log(data)
        return Object.keys(count_rows_per_vessel(data))
    }, [data])

    const plotData = React.useMemo(() => {
        if (data.length===0 || vessel_ids==undefined) {
          console.log("Conditions not met to create plotData")
          return null
        } else {
            return vessel_ids.map((vessel_id, index) => {
            const vessel_name = vesselList.find(vessel=>vessel.vessel_id==vessel_id)?.vessel_name
            return ({
                type: "scatter",
                mode: "lines+markers",
                name: `Power (kW) ${vessel_name}`,
                yaxis: 'y',
                x: unpack(data.filter(row=>row.vessel_id==vessel_id), 'time'),
                y: unpack(data.filter(row=>row.vessel_id==vessel_id), 'battery_data.electrical_power'),
                line: {color: COLORS[index], shape: 'hv'},
            })})
        }

      }, [data, vessel_ids])
    
      React.useEffect(() => {
        // console.log('plot data changed, updating plot', plotData)
        // Render the plot into the div with a ref attached
        const layout={
          title: `Back to Back Dyno`,
          xaxis: { 
            title: 'time',
            tickmode: "auto",
            type: 'date',
            nticks: 8,
            tickformat: "%m/%d  %H:%M:%S",
            tickangle: 0, 
          },
          yaxis: { title: "kW" },
          editable: true,
          hovermode: "x-unified",
          hoverinfo: 'x+y',
          color:'black',
          hoverlabel: { bgcolor: "#00E" },
          paper_bgcolor: 'rgba(0, 0, 0, 0.0)',
          plot_bgcolor: 'rgba(0, 0, 0, 0.0)',
          autosize: true,
          automargin:true,
          fillpattern: {bgcolor:'rgba(0, 0, 0, 1.0)', fillmode:'overlay'},
          legend: {
            x: 1,
            y: 1.2,
            xanchor: "right",
            yanchor: "top",
            bgcolor: "rgba(250, 250, 250, 0.2)",
            bordercolor: "rgba(0, 0, 0, 0.2)",
            orientation: 'h',
            borderwidth: 1,
          },
          height: 360,
          responsive: true,
          font: {
            color: 'white',
            family: 'Inter'
          },
          
        }
    
        const config = {responsive: true,}
        // console.log(plotContainerRef.current)
        if (!plotRendered && data.length>0){
          console.log('rendering new preset plot')
          Plotly.newPlot(plotContainerRef.current, plotData, layout, config);
          setPlotRendered(true);
        } else if (plotRendered && data.length>0) {
          Plotly.react(plotContainerRef.current, plotData, layout, config);
        }
    
      }, [plotData]); // Redraw the plot if data or layout props change
    
      React.useEffect(()=>{
        return () => {
          if (plotContainerRef.current){
            try {
                console.log('purging preset plot')
                Plotly.purge(plotContainerRef.current);
                setPlotRendered(false)
            } catch (err) {
                console.warn("Caught an error in PlotlyPlots.jsx while purging plot", err)
            }
          } else {
            console.log('no div ref to unmount against - big bad')
          }
        };
      }, [])
    
    if (data.length===0){
        return (
        <div>
            <span>Loading Recent vessel activity...</span>
        </div>
    )} else {
        return (
            <div className='dyno-plot' style={{minWidth:'250px', minHeight:'150px', width:'100%', height:'100%'}}>            
                <div className='dyno-plot-area' ref={plotContainerRef} style={{minWidth:'250px', minHeight:'150px', width:'100%', height:'100%'}}/>
            </div>
        );
    }
}