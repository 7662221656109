import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";
import { signUp, signIn, confirmSignUp, resetPassword } from '@aws-amplify/auth';
import { Button, TextField, Typography } from "@mui/material";


export const Route = createFileRoute('/_login/signUp')({
    // loader: async ({ params: { vessel_id } }) => fetchPost(postId),
    // errorComponent: PostErrorComponent as any,
    notFoundComponent: () => {
      return (
        <p>Error loading Login Page</p>
      )
    },
    component: SignUp,
  })

export function SignUp ({ onSignIn, setFormType }) {
    const [formState, setFormState] = useState({
      username: '',
      email: '',
      password: '',
      verificationCode: '',
      showVerificationCode: false, // add a state to show/hide the verification code field
    });
  
    const [openModal, setOpenModal] = useState(false);  
    const [errorMessage, setErrorMessage] = useState(null);  
    const [usernameSpaceError, setUsernameSpaceError] = useState(null);  
    const [error, setError] = useState(null);
  
    const handleChange = (e) => {
      setFormState({ ...formState, [e.target.name]: e.target.value });
      if (e.target.name === 'username') {
        setErrorMessage(null); // Clear error message when the user starts typing in the username field
      }
    };    
  
    const handleSignUp = async (e) => {
      e.preventDefault();
      console.log(formState)
      if (formState.username.includes(' ')) {
        setUsernameSpaceError('Spaces are not allowed in username');
      } else {
        setUsernameSpaceError(null);
        try {
          await signUp({
            username: formState.username,
            password: formState.password,
            options: {
              userAttributes:{
                email: formState.email,
              },
              autoSignIn: true
            },
          });
          console.log('Sign up successful!');
          setFormState({ ...formState, showVerificationCode: true });
        } catch (error) {
          console.log('Error signing up:', error);
          setError(error); 
        }
      }
    };    
  
    const handleCloseModal = () => {
      setOpenModal(false);
      setErrorMessage(null);
    };    
  
    const handleVerification = async (e) => {
      e.preventDefault();
      try {
          await confirmSignUp({username:formState.username, confirmationCode:formState.verificationCode});
          console.log('Verification successful!');
          await signIn({username:formState.username, password:formState.password});
          onSignIn();
          setErrorMessage(null); // clear any existing error messages
      } catch (error) {
          console.log('Error verifying email:', error);
          setError(error); // set error state
          setErrorMessage(error.message);
          try {
            await signIn({username:formState.username, password:formState.password});
            onSignIn();
          } catch (error) {
            console.log(error)
          }
      }
    };  
  
    // const CustomBackdrop = () => (
    //   <Backdrop sx={{ backgroundColor: 'rgba(50, 0, 0, 0.5)' }} open={openModal} onClick={handleCloseModal} />
    // );
  
    return (
  
      <form onSubmit={formState.showVerificationCode ? handleVerification : handleSignUp}>
  
        <div className='Auth-content-box-inner'>
          
            <Typography variant="h5" color={'white'}>Sign Up</Typography>
         
          
            <TextField
              label="Username"
              name="username"
              autoComplete='current-username'
              value={formState.username}
              onChange={handleChange}
              fullWidth
              required
            />
         
          {usernameSpaceError && (
            
              <Typography variant="body2" color="error">
                {usernameSpaceError}
              </Typography>
           
          )}
          
            <TextField
              label="Email"
              name="email"
              value={formState.email}
              onChange={handleChange}
              type="email"
              fullWidth
              required
            />
         
          
            <TextField
              label="Password"
              name="password"
              autoComplete='current-password'
              value={formState.password}
              onChange={handleChange}
              type="password"
              fullWidth
              required
            />
         
          {formState.showVerificationCode && (
            
              <TextField
                label="Verification code"
                name="verificationCode"
                value={formState.verificationCode}
                onChange={handleChange}
                fullWidth
                required
              />
           
          )}
          
            <Button variant="contained" color="primary" type="submit" fullWidth>
              {formState.showVerificationCode ? 'Verify Email' : 'Sign Up'}
            </Button>
         
          {error && (
            
              < Typography variant="body2" color="error">
                {error.message}
              </ Typography>
           
          )}
        </div>
      </form>
    );      
};