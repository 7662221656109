import { 
  Box, 
  Modal, 
  CardHeader,
  TextField,
  Button,
  useMediaQuery
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth, useSelectedNodeStore } from '../../../app/zustore';
import { fetchData } from '../../../app/QueryHandler';
import PublishIcon from '@mui/icons-material/Publish';
import { enqueueSnackbar } from 'notistack';
import { FleetTreeView } from '../../../fleet/FleetTreeView';
import { createFileRoute } from '@tanstack/react-router'
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DeleteForever, UpgradeOutlined } from '@mui/icons-material';

export const Route = createFileRoute('/_authenticated/groups/members')({
  component: Members
})

export function Members ({  }) {
  const [groupName, setGroupName] = useState('')
  const isMobile = useMediaQuery('(max-width:600px)');
  const token = useAuth(state=>state.token)
  const {selectedNode, setSelectedNode} = useSelectedNodeStore();
  const [memberToPromote, setMemberToPromote] = useState(null);
  const queryClient = useQueryClient();
  const [emailStatus, setEmailStatus] = useState('empty')
  const { user } = useAuth.getState(); // Get user information from useAuth
  const [email, setEmail] = useState(null)

  useEffect(() => {
    console.log(selectedNode, user)
  }, [selectedNode]);
    
  const handleInputChange = (event) => {  
    const email = event.target.value
    setEmail(email)
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailPattern.test(email)) {
        setEmailStatus('ready'); // Valid email
    } else if (email.length > 0) {
        setEmailStatus('entering'); // Invalid email with content
    } else {
        setEmailStatus('empty'); // Empty input
    }
  };

  const promoteMemberMutation = useMutation({
    mutationFn: async (update) => {
      const token = useAuth.getState().token;
      const {status, response} = await fetchData({
        method: 'PUT',
        resource: 'invites',
        token: token,
        body: update,
      });

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['promotion'] });
      enqueueSnackbar('Member Promoted! 💃🕺🕺🏿💃🏿', { variant: 'QueryInfo' });
    },
    onError: () => {
      enqueueSnackbar('Failed to promote member!', { variant: 'error' });
    },
  });

  const handlePromoteMember = async (member) => {
    setMemberToPromote(member.user_name);
    let update = {
      node_id: selectedNode.node_id,
      node_owners: [...selectedNode.node_owners, memberToPromote],
    };
    promoteMemberMutation.mutate(update);
  };


  const isNodeOwner = user && selectedNode?.can_write || false;
      
  const handleInviteMember = async () => {
      if (emailStatus=='ready'){
          console.groupCollapsed('NewInvite-handleSubmit');
          // setStatus('loading')
          let body = {
              node_id: selectedNode.id, 
              email: email
          };
      
          console.log(JSON.stringify(body))
          console.groupEnd();
          
          const {status, response} = await fetchData({method:'POST', resource:'invites', token:token, body:body})
          if (status==400){
              enqueueSnackbar('Failed to invite new memeber', {variant: 'error'})
          } else if (status==201||status==200){
              enqueueSnackbar('User Invited!', {variant: 'success'})              
          }
          console.table(response)
      }
  }
  
  const handleDeleteMember = async (member_id) => {
      if (groupName.length>0){
          console.groupCollapsed('delete-member-handleSubmit');
          // setStatus('loading')
          let newGroup = {
              node_id: selectedNode.id, 
              member_id: member_id
          };
      
          console.table(newGroup)
          console.log(JSON.stringify(newGroup))
          console.groupEnd();
          
          if (window.confirm("Are you sure you want to permanently remove this user?")){
            const {status, response} = await fetchData({method:'PUT', resource:'nodes/member', token:token, body:newGroup})
            if (status==400){
                enqueueSnackbar('Failed to invite new memeber', {variant: 'error'})
            } else if (status==201||status==200){
                enqueueSnackbar('Group Created', {variant: 'success'})
                window.location.reload()
                
            }
            console.table(response)
          }
      }
  }

  const header = () => {
      if (!selectedNode) return <h1>Manage group members</h1>
      else return <h1>{`Manage members of the ${selectedNode.name} group`}</h1>
  }

  // if (!selectedNode) return null
  return (
      <div className='manage-page-2-col' >
        {header()}
        {/* <div className='ModalContent' > */}
        <div className='content'>
          <div className='left'>
              <FleetTreeView setSelectedNode={setSelectedNode} initial_selection={selectedNode}/>   

              <div className='members-container'>
              {/* <div className={`header ${selectedNode?.can_write?'owner':''}`} key={0}>
                <a>username</a>
                <a>member type</a>
                <a>status</a>
                {selectedNode?.can_write &&
                  <a>action</a>
                }
              </div> */}

              {
                selectedNode?.can_write &&
                <div className='footer' key={'invite'}>
                <a >Invite a new user</a>
                <TextField                         
                  label={"email"}
                  className={`text-field ${emailStatus}`}
                  placeholder={`Enter email of the user to invite`}
                  variant="outlined"                  
                  defaultValue={''}
                  style={{borderColor:emailStatus}}
                  onChange={(value) => handleInputChange(value)}                  
                  />

                <button className='confirm' onClick={handleInviteMember} >
                  {!isMobile && <span>Submit</span>}
                  <PublishIcon />
                </button>
              </div>}
              </div>       
          </div>  
        {selectedNode && 
          <div className='right'>

              <div className={`member-items ${selectedNode?.can_write?'owner':''}`}>
              {(selectedNode?.members != null && selectedNode?.can_write)
              ? (
                Object.entries(selectedNode?.members).map(([member_id, member], index) => {
                  return (
                    <div className={`member-item ${selectedNode?.can_write?'owner':''}`} key={index+1}>
                      <h4 className='username'>
                        <span className='left'>{member.user_name}</span>
                        <span className={`right ${member.status}`}>{member.status}</span>
                      </h4>
                      <div className='description'>
                        <a>{member.access_type}</a>
                        
                      </div>
                      {(selectedNode?.can_write) &&
                      <a className='action'>
                        <button className='fm-btn filled' 
                          onClick={() => {
                            handlePromoteMember(member_id);
                          }}
                          >
                          <span>Promote</span>
                          <UpgradeOutlined />
                        </button>
                        <button className='fm-btn outline error'
                          onClick={() => {
                            handleDeleteMember(member_id);
                          }}
                          >
                          <span>Remove</span>
                          <DeleteForever />
                        </button>
                      </a>
                      }     
                    </div>
                  );
                })
              ) 
              : (
                <div className={`member-item ${selectedNode?.can_write?'owner':''}`} key={1}>
                  <a>----</a>
                  <a style={{color:'var(--red-alt)', fontWeight:600}}><span>No Users yet</span></a>
                  <a>----</a>                
              </div>
              )
            }
            </div>
            
          </div>
        }
        </div>
      </div>
  )
}