import React, { useEffect, useRef, useState, useMemo } from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import maplibregl from 'maplibre-gl';      // ! is important here
// import maplibreglWorker from 'maplibre-gl/dist/maplibre-gl-csp-worker';
import { useVessel, useCurrent, useLiveData, useFleetData, useAnalysisData, useDataSource } from "../app/zustore";
import boatIcon from "../images/MapMarker.svg";

// maplibregl.workerClass = maplibreglWorker;

const maptiler_api = process.env.REACT_APP_MAPTILER_API;

const VesselMap = ({ isQuery, width }) => {
  const mapContainer = useRef(null);
  const mapRef = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [zoom, setZoom] = useState(12);
  const [marker, setMarker] = useState(null);
  const { vessel_id, vessel_name } = useVessel((s) => ({vessel_id:s.vessel_id, vessel_name:s.vessel_name}));
  const liveData = useLiveData((s) => s.liveData);
  const analysisData = useAnalysisData((s) => s.analysisData)
  const datasource = useDataSource(s=>s.datasource)

  const Lat = datasource=='csv'?'Latitude':'telemetry_data.latitude';
  const Lon = datasource=='csv'?'Longitude':'telemetry_data.longitude';
  const Speed = datasource=='csv'?'GPS Speed (mph)':'telemetry_data.inst_speed';

  const { data, latest } = useMemo(() => {
    let data = null;

    if (isQuery) {
      data = analysisData[vessel_id].filter((item) => item?.[Lat] !== null);
    } else {
      data = liveData[vessel_id].filter((item) => item?.[Lat] !== null);
    }

    const latest = data[data.length - 1];
    console.groupCollapsed('vessel map useMemo');
    console.table(data.slice(1,4))
    console.log(latest)
    console.groupEnd();
    return { data, latest };
  }, [analysisData, liveData, isQuery, vessel_id]);

  useEffect(() => {
    console.groupCollapsed("Vessel Map useEffect 1")
    console.table(data.slice(0, 10))
    const latitude = data[data.length-1][Lat];
    const longitude = data[data.length-1][Lon];
    console.log(latitude, longitude)
    console.groupEnd();
    if (latitude && longitude){
      const map = new maplibregl.Map({
        container: mapContainer.current,
        style: maptiler_api,
        
        center: [longitude, latitude],
        zoom: 6,
      });

      if (map.loaded()) {
          setMapLoaded(true);
      } else {
          map.on("load", () => {    
              map.resize();
              const markerElement = document.createElement('div');
              markerElement.innerHTML = `<img src="${boatIcon}" style="width: 150px; height: 150px;" />`;

              const marker = new maplibregl.Marker({
                element: markerElement
              })
                .setLngLat([longitude, latitude])
                .setRotation(0)
                .addTo(map);

              setMarker(marker);    

              map.addSource("location-history", {
                  type: "geojson",
                  data: {
                  type: "FeatureCollection",
                  features: [],
                  },
              });

              map.addLayer({
                  id: "location-history-line",
                  type: "line",
                  source: "location-history",
                  layout: {
                  "line-join": "round",
                  "line-cap": "round",
                  },
                  paint: {
                  "line-color": "#3b9ad3",
                  "line-width": 4,
                  },
              });

              map.on('wheel', (e) => {
                e.preventDefault();
              });

              setMapLoaded(true);
          });
      }

      mapRef.current = map;
      return () => {
        map.remove();}}
  }, []);

  useEffect(() => {
    if (mapRef.current && mapLoaded) {
      // ToDo add ability to set the lat long based on the data source
      // Currently does not work with CSV data
      // console.log('Vessel map useEffect, must be triggering on the changes to zoom')
      const latitude = latest[Lat]
      const longitude = latest[Lon];
      // console.log("Vessel Map Use Effect 2 ", latitude, longitude, format_date(Date.now()));
      
      const prevLat = marker.getLngLat().lat
      const prevLon = marker.getLngLat().lng
      // console.log(marker, prevLat, prevLon)
      marker.setLngLat([longitude, latitude]);

      if (latitude!=prevLat || longitude!=prevLon) {
        mapRef.current.easeTo({
          center: [longitude, latitude],
          zoom: zoom,
        });
      } else {
        mapRef.current.easeTo({
          // center: [longitude, latitude],
          zoom: zoom,
        });
      }

      // setPrevCoord([latitude, longitude]);
    }
  }, [latest, vessel_id, mapLoaded, zoom, marker]);

  useEffect(() => {
    if (mapRef.current && mapLoaded && mapRef.current.getSource("location-history")) {
      
      const coordinates = data.map((item) => [
        item[Lon],
        item[Lat],
      ]);
    
      mapRef.current.getSource("location-history").setData({
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: coordinates,
            },
          },
        ],
      });
      // setPrevCoord({latitude:coordinates.latitude, longitude:coordinates.longitude})
    }
  }, [liveData, mapLoaded]);

  const handleZoomIn = () => {
    if (zoom < 22) {
      const newZoom = zoom + 1;
      setZoom(newZoom);
    }
  };
  
  const handleZoomOut = () => {
    if (zoom > 0) {  // assuming the minimum zoom level is 0
      const newZoom = zoom - 1;
      setZoom(newZoom);
    }
  };  

  return (
    <div style={{minWidth:'75vw'}}>
      <div
        ref={mapContainer}
        style={{ 
            width: '90%', 
            height: '500px', 
            borderRadius:"5px", 
            justifyContent:"center", 
            marginLeft:"auto", 
            marginRight:"auto",
            marginBottom:"5px",
            // boxShadow: 'rgb(7 169 255 / 61%) 0px 0px 5px 5px'
        }}
      >
      <div style={{ position: 'absolute', top: '10px', left: '10px', borderRadius:'10px', zIndex:500, width:"10%", height:'10%', display: 'flex', alignItems: 'center', minWidth:'80px' }} className='zoomButtons'>
        <button onClick={handleZoomIn} style={{width:"40%", height: "90%", borderRadius:'5px', fontSize:'3vh', textAlign:'center', opacity:'50%'}}>+</button>
        <button onClick={handleZoomOut} style={{width:"40%", height: "90%", borderRadius:'5px', fontSize:'3vh', textAlign:'center', opacity:'50%'}}>−</button>
      </div>


      <div 
          style={{ 
            position: 'absolute', 
            top: '10px', 
            right: '10px', 
            backgroundColor:'var(--dark-grey-2, #1ba1fa)', 
            borderRadius:'10px', 
            width:"20%", 
            // height:'10%',
            maxWidth:"200px",
            minWidth:'150px',
            zIndex:500}} 
          className='zoomButtons'>
        <h1 style={{paddingInlineStart:'5px', paddingInlineEnd:'5px', fontSize:'2vh', color:'white'}}>Speed: {latest[Speed]} mph</h1>
      </div>
      
      </div>
    </div>
  );
  };
  
  export default VesselMap;