import { useEffect, useState, } from "react";
// import '../app/mapbox-gl/dist/mapbox-gl.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useQueryClient } from '@tanstack/react-query';
// import '../App.css';
import { useResizeDetector } from 'react-resize-detector'
import { getCurrentUser } from '@aws-amplify/auth';
import { Navigate, Outlet, createFileRoute, useLocation, useNavigate } from '@tanstack/react-router';
import { useSnackbar } from 'notistack';
import { ModalTypes } from '../Types/enums';
import { 
  useVesselList, 
  useAuth, 
  useFleetData,
  useNotifications, 
} from "../app/zustore";
import FleetOverview from './_authenticated';
import { subscribeUser } from "../app/push-notifications";

export const checkIsAuthenticated = async () => {
  const setIsAuthenticated = useAuth.getState().setIsAuthenticated;
  try {
    const user = await getCurrentUser();
    console.log('isAuthenticated', true, user)
    setIsAuthenticated(true)
    return true;
  } catch (error) {
    console.log('isAuthenticated caught an error so user is not authenticated', error)
    setIsAuthenticated(false)
    return false;
  }
}

export const Route = createFileRoute('/')({
  component: App,
  beforeLoad: async ({ context, location }) => {
    console.log('beforeLoad, checking for authentication in the root route', location)
    const setIsAuthenticated = useAuth.getState().setIsAuthenticated;
    if ( (location.pathname !== '/signIn') && (location.pathname !== '/signUp') && (location.pathname !== '/reset') ){
      let authenticated = await checkIsAuthenticated();
      setIsAuthenticated(authenticated);
      if (!authenticated){
        console.log('not authenticated!', location.pathname, location)
        console.log('redirecting to login')
        // throw redirect({
        //   to: '/signIn',
        //   search: {
        //     redirect: location.href,
        //   },
        // })
      } else {
        console.log('authenticated!')
        // throw redirect({
        //   to: '/_authenticated',
        //   search: {
        //     redirect: location.href,
        //   },
        // })
      }
    }
  }
})

export function determineStatus(msg) {
  if (!msg) {
      return { text: "Loading", color: "var(--light-grey-3)", priority: 0 };
  }

  if (!msg || typeof msg !== "object") {
      return { text: "Unknown", color: "var(--light-grey-3)", priority: 0 };
  }

  const timeDiff = Date.now() - msg.sample_time;

  if (timeDiff > 1800000) {
      return { text: 'Offline', color: "var(--light-grey-3)", priority: 0 };
  }

  if (msg.on_water && msg.telemetry_data?.inst_speed > 2) {
      return { text: "On Water", color: "var(--light-grey-1)", priority: 4 };
  }

  if ((msg?.ecu_data?.[0]?.system_state===5 || msg.motor_data?.[0]?.system_state === 5) ) { //&& (msg.obc_data?.[0]?.obc_status ?? null) === null
      return { text: "Active", color: "#00B9B8", priority: 7 };
  }

  if ((msg?.ecu_data?.[0]?.system_state!==5&&msg.motor_data?.[0]?.system_state !== 5) && msg.telemetry_data?.inst_speed > 2) {
    return { text: "In Tow", color: "var(--light-grey-1)", priority: 4 };
  }

  if ((msg.motor_data?.[0]?.system_state ?? null) === null && (msg.obc_data?.[0]?.charge_power ?? null) !== null) {
      return { text: "Charging", color: msg.obc_data[0].charge_power >= 0.1 ? "#28E27B" : "#D29827", priority:  msg.obc_data[0].charge_power >= 0.1 ? 6 : 5 };
  }

  if ((msg.motor_data?.[0]?.system_state ?? null) === 16) {
      return { text: 'Fault', color: 'var(--red-alt)', priority: 3 };
  }

  if ((msg.motor_data?.[0]?.system_state ?? null) === null && (msg.obc_data?.[0]?.obc_status ?? null) === null) {
      return { text: "Off", color: "var(--dark-grey-1)", priority: 2 };
  }

  return { text: "Unknown", color: "var(--light-grey-3)", priority: 1 };
};

function App() {
  const [anchorEl, setAnchorEl] = useState(null);
  const { height, ref } = useResizeDetector();
  const {isAuthenticated, setIsAuthenticated} = useAuth(s=>({isAuthenticated:s.isAuthenticated, setIsAuthenticated:s.setIsAuthenticated}));
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {vesselList, setVesselList } = useVesselList();

  useEffect(() => {
    if (!checkAuth()){
      navigate({to:'/login/_signIn'});
    };
  }, [navigate]);

  const checkAuth = async () => {
    try {
      const user = await getCurrentUser();
      enqueueSnackbar(`Welcome ${user.username}`, { variant: 'success' });
      setIsAuthenticated(true);
      return true;
    } catch (error) {
      setIsAuthenticated(false);
      return false;
    }
  };  
 
  const open = Boolean(anchorEl);

  if (height==0){
    return null
  }

  console.log('routes/index rendered')

  if (!isAuthenticated){
    return <Navigate to='/signIn' search= {{ redirect: location.href, }}/>
  } else if (Object.keys(location.search).length>0) {
    /**
     * Redirects to handle opening the app with search params
     */
    console.log('Found search params in the Root Component')
    if (Object.keys(location.search).find(k=>k=='devid')){
      if (location.search?.devid=='DEVID_NOT_FOUND'){
        console.log('Your Garmin could not access your boats devid!')
      } else {
        console.log('Found a devid in the index route, redirecting to /home with the search param')
        return <Navigate to='/home' search={{devid: location.search?.devid}} />
      }
    } else if (Object.keys(location.search).find(k=>k=='redirect') && isAuthenticated){
      console.log('Found a redirect in the Root Component', location.search.redirect)
      return <Navigate to={location.search.redirect}/>
    }
  }
  return (
    <Navigate to='/home' />
  )
}

export default App;