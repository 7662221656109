import { useVessel, useVesselList } from "../../app/zustore.js";
import { Button, Box, Typography } from '@mui/material';
import CSVReader from "../../utils/UploadFile.jsx"; 
import CloseIcon from '@mui/icons-material/Close'
import { uploadData } from 'aws-amplify/storage';

export const LogFileLoader = ({ setDataset }) => {
    const vessel_id = useVessel(s=>s.vessel_id);
    const vessel = useVesselList(s=>s.vesselList).find(vessel => vessel.vessel_id === vessel_id);

    // const handleUploadToS3 = async () => {
    //     try {
    //         const result = await uploadData({
    //           key: filename,
    //           data: file,
    //           options: {
    //             accessLevel: 'guest', // defaults to `guest` but can be 'private' | 'protected' | 'guest'
    //             onProgress // Optional progress callback.
    //           }
    //         }).result;
    //         console.log('Succeeded: ', result);
    //       } catch (error) {
    //         console.log('Error : ', error);
    //       }
    // }

    return (
            <Box sx={{ ...styles.box, width: '85%', maxWidth:'1000px'  }}>
                
                <Typography style={styles.modalTitle}>Upload A CSV</Typography>
                
                <CSVReader vessel_id={vessel_id} vessel={vessel} setDataset={setDataset} />
            </Box>
    )
}


const styles = {
    box: {
        maxHeight:'70%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // width: '50%',
        // bgcolor: '#e1e1e1',
        // border: '3px solid #6c7982',
        borderRadius: '15px',
        overflowY: 'hide',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: 1,
    },
    InnerBox: {
        display: 'flex',
        flexDirection: 'column',
        height:'100%',
        width: "100%",
        bgcolor: '#e1e1e1',
        overflowY: 'scroll',
        borderTopStyle: 'double',
        borderBottomStyle: 'double',
        WebkitOverflowScrolling: 'touch',
        mb: 3,
    },
    buttonContainer: {
        display:'flex', 
        flexDirection:'row', 
        justifyContent:'space-around',
        alignItems: 'flex-end' ,
        gridArea: 'footer',
        paddingTop: '10px'
    },
    modalTitle: {
        marginLeft:'5px', 
        marginBottom: '15px',
        fontSize: 'calc(1.5vw + 2vh)', /* Adjusts font size based on viewport width and height */
        overflow: 'hidden', /* Ensures content doesn't spill outside the container */
        whiteSpace: 'nowrap', /* Prevents text from wrapping to the next line */
        textOverflow: 'ellipsis', /* Adds an ellipsis when the text overflows */
    },
    selectorDiv: {
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        margin: '0 auto', // Center horizontally
    },
    editorContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center', // Center horizontally
        width: '100%',
        margin: '0 auto', // Center horizontally
        marginTop: '20px',
        boderRadius: '4px',
        // height: '100%',
        marginBottom:'20px',
        WebkitOverflowScrolling: 'touch',
    },
    editorOuterDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Align items vertically
        justifyContent: 'space-between', // Space between h3 and TextField
        width: '100%',
        marginTop: '10px',
        backgroundColor: 'white'
    },
    nestedContainer: {display:'flex', flexDirection:'column', alignItems:'center', width:'90%',
        marginTop: '15px', marginBottom: '15px'
    },
    editorInnerDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center', // Align items vertically
        justifyContent: 'space-between', // Space between h3 and TextField        
        width: '100%',
    },
    input: {
        padding: 10,
        backgroundColor: 'white',
        paddingRight: '30px',
        width: '100%'
    },
    selector: { 
        minWidth: "200px", 
        marginBottom:'10px',
        width: "90%", 
        maxWidth: "800px", 
        backgroundColor: 'aliceblue' },
    divider: {
        backgroundColor: 'black',
        height: '4px',
        width: '100%',
    },
    button: {
        margin: '10px auto 10px auto', 
        height:'80%', 
        maxWidth:'100px',
        color: '#00b9b8',
        
    }
}