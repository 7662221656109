export const propellerFields = [
    {
        field: 'prop_blades',
        id: 'outboards[0].prop.prop_blades',
        label: 'Propeller Blades',
        optional: true,
        readOnly: false,
        path: 'outboards[0].prop',
        children: null,
        rank: 29
    },
    {
        field: 'prop_diam',
        id: 'outboards[0].prop.prop_diam',
        label: 'Propeller Diameter',
        optional: true,
        readOnly: false,
        path: 'outboards[0].prop',
        children: null,
        rank: 30
    },
    {
        field: 'prop_pitch',
        id: 'outboards[0].prop.prop_pitch',
        label: 'Propeller Pitch',
        optional: true,
        readOnly: false,
        path: 'outboards[0].prop',
        children: null,
        rank: 31
    },
    {
        field: 'prop_serial',
        id: 'outboards[0].prop.prop_serial',
        label: 'Propeller Serial Number',
        optional: true,
        readOnly: false,
        path: 'outboards[0].prop',
        children: null,
        rank: 32
    }
];

export const outboardFields = [
    {
        label: 'Horsepower',
        field: 'horsepower',
        id: 'horsepower',
        optional: false,
        readOnly: false,
        path: 'outboards',
        children: null,
        rank: 20
    },
    {
        label: 'Make',
        field: 'make',
        id: 'outboard_make',
        optional: false,
        readOnly: false,
        path: 'outboards',
        children: null,
        rank: 21
    },
    {
        label: 'Outboard Color',
        field: 'color',
        id: 'outboard_color',
        optional: false,
        readOnly: false,
        path: 'outboards',
        children: null,
        rank: 22
    },
    {
        label: 'Max RPM Range',
        field: 'max_rpm_range',
        id: 'max_rpm_range',
        optional: false,
        readOnly: false,
        path: 'outboards',
        children: null,
        rank: 23
    },
    {
        label: 'Model',
        field: 'model',
        id: 'outboard_model',
        optional: false,
        readOnly: false,
        path: 'outboards',
        children: null,
        rank: 24
    },
    {
        label: 'Propeller',
        field: 'prop',
        id: 'prop',
        optional: true,
        readOnly: false,
        path: 'outboards',
        children: propellerFields,
        rank: 25
    },
    {
        label: 'Serial Number',
        field: 'serial_number',
        id: 'serial_number',
        optional: true,
        readOnly: false,
        path: 'outboards',
        children: null,
        rank: 26
    },
    {
        label: 'Type',
        field: 'type',
        id: 'type',
        optional: true,
        readOnly: false,
        path: 'outboards',
        children: null,
        rank: 27
    },
    {
        label: 'Gear Ratio',
        field: 'gear_ratio',
        id: 'gear_ratio',
        optional: true,
        readOnly: false,
        path: 'outboards',
        children: null,
        rank: 28
    }
];

export const fullVessel = [
    { 
        label: "Vessel Name",                    
        field: "vessel_name", 
        id: "vessel_name", 
        optional: false,
        readOnly: false,
        path: '.',
        children: null,
        rank: 1
    },
    { 
        label: "Device ID",                      
        field: "device_id", 
        id: "device_id", 
        optional: false,
        readOnly: true,
        path: '.',
        children: null,
        rank: 2
    },
    { 
        label: "Hull Make",                           
        field: "make", 
        id: "hull_make", 
        optional: true,
        readOnly: false,
        path: '.',
        children: null,
        rank: 3
    },
    { 
        label: "Hull Model",                          
        field: "model", 
        id: "hull_model", 
        optional: true,
        readOnly: false,
        path: '.',
        children: null,
        rank: 4
    },
    { 
        label: "Hull Color",                          
        field: "color", 
        id: "hull_color", 
        optional: true,
        readOnly: false,
        path: '.',
        children: null,
        rank: 5
    },
    { 
        label: "Hull ID Number",                 
        field: "hin", 
        id: "hin", 
        optional: true,
        readOnly: false,
        path: '.',
        children: null,
        rank: 6
    },
    { 
        label: "Length (ft)",                    
        field: "length", 
        id: "length", 
        optional: true,
        readOnly: false,
        path: '.',
        children: null,
        rank: 7
    },
    { 
        label: "Beam (ft)",                      
        field: "beam", 
        id: "beam", 
        optional: true,
        readOnly: false,
        path: '.',
        children: null,
        rank: 8
    },
    { 
        label: "Draft (ft)",                     
        field: "draft", 
        id: "draft", 
        optional: true,
        readOnly: false,
        path: '.',
        children: null,
        rank: 9
    },
    { 
        label: "Max capacity (lbs)",             
        field: "max_capacity", 
        id: "max_capacity", 
        optional: true,
        readOnly: false,
        path: '.',
        children: null,
        rank: 10
    },
    { 
        label: "Max persons",                    
        field: "max_persons", 
        id: "max_persons", 
        optional: true,
        readOnly: false,
        path: '.',
        children: null,
        rank: 11
    },
    { 
        label: "Battery Configuration ex: 4s3p", 
        field: "battery", 
        id: "battery", 
        optional: true,
        readOnly: false,
        path: '.',
        children: null,
        rank: 12
    },
    { 
        label: "Hull Type",                      
        field: "hull_type", 
        id: "hull_type", 
        optional: true,
        readOnly: false,
        path: '.',
        children: null,
        rank: 13
    },
    { 
        label: "Is Particle Device",             
        field: "isParticleDevice", 
        id: "isParticleDevice", 
        optional: true,
        readOnly: true,
        path: '.',
        children: null,
        rank: 14
    },
    { 
        label: "Updated At",                     
        field: "updated_at", 
        id: "updated_at", 
        optional: true,
        readOnly: true,
        path: '.',
        children: null,
        rank: 15
    },
    { 
        label: "Updated By",                     
        field: "updated_by", 
        id: "updated_by", 
        optional: true,
        readOnly: true,
        path: '.',
        children: null,
        rank: 16
    },
    { 
        label: "Created By",                     
        field: "created_by", 
        id: "created_by", 
        optional: true,
        readOnly: true,
        path: '.',
        children: null,
        rank: 17
    },
    { 
        label: "Created At",                     
        field: "created_at", 
        id: "created_at", 
        optional: true,
        readOnly: true,
        path: '.',
        children: null,
        rank: 18
    },
    {
        label: "Outboard",
        field: "outboards",
        id: "outboards",
        optional: true,
        readOnly: false,
        path: '.',
        children: outboardFields,
        rank: 19
    }
];

export const flatVesselFields = [
    ...fullVessel.filter((field)=>field.children==null), 
    ...outboardFields.filter((field)=>field.children==null), 
    ...propellerFields
]



export const Vessel = flatVesselFields.filter((field) => !field.readOnly);

