export async function subscribeUser() {
    
    const registration = await navigator.serviceWorker.ready;
    
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array('BOYLC2kQZpiNZswkl_F1UDysRjGe9WoFpOrlHLoBLH3p9NIm7e3k6dcAfg8N6zy9Vgy6t4xqErsRGwljBDg0UlE') // Replace with your VAPID public key
    });
    const subscriptionJson = subscription.toJSON()
    console.log('Push subscription:', JSON.stringify(subscription), subscriptionJson.endpoint, subscriptionJson.keys?.p256dh, subscriptionJson.keys?.auth, subscription.toJSON());
    
    return subscriptionJson;
}
  
  function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
  
  // // Call this function when your app is loaded
  // subscribeUser().then(subscription => {
  //   const setSubscription = useNotifications.getState().setSubscription
  //   console.log('Subscription object:', subscription);
  //   setSubscription(subscription.endpoint, subscription.keys.p256dh, subscription.keys.auth)
  // });