import { createFileRoute, useLocation, useNavigate } from "@tanstack/react-router"
import { useEffect, useState } from "react";
import { signUp, signIn, confirmSignUp, resetPassword, getCurrentUser, fetchAuthSession, updatePassword, confirmSignIn } from '@aws-amplify/auth';
import { Link } from "@tanstack/react-router";
import { LoadingButton } from '@mui/lab';
import PublishIcon from '@mui/icons-material/Publish';
import { TextField, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useAuth } from "../../app/zustore";
import { checkIsAuthenticated } from "..";

export const Route = createFileRoute('/_login/signIn')({
    // loader: async ({ params: { vessel_id } }) => fetchPost(postId),
    // errorComponent: PostErrorComponent as any,
    notFoundComponent: () => {
      return (
        <p>Error loading Login Page</p>
      )
    },
    component: SignIn,
  })

export function SignIn ({ setFormType }) {
    const {isAuthenticated, setIsAuthenticated} = useAuth(s=>({isAuthenticated:s.isAuthenticated, setIsAuthenticated:s.setIsAuthenticated}));
    const resetAuth = useAuth((s) => s.resetAuth);
    const setAuth  = useAuth((s) => s.setAuth);
    const [ updateTemp, setUpdateTemp ] = useState(false)
    const location = useLocation()
    const navigate = useNavigate();
    const [state, setState] = useState({
      username: '',
      password: '',
      newPassword: ''
    });
  
    const [authError, setAuthError] = useState(null); // New state for handling auth error
  
    // Add a new loading state
    const [loading, setLoading] = useState(false);

    async function handleUpdatePassword(oldPassword, newPassword) {
      try {
        await updatePassword({ oldPassword, newPassword });
      } catch (err) {
        console.log(err);
      }
    }

    const onSignIn = async () => {
        const user = await getCurrentUser()
        console.log(user);
        const session = await fetchAuthSession();
        console.log(session)
        enqueueSnackbar(`Signed in successfully, Weclome ${user.username}`, { variant: 'success' });
        setIsAuthenticated(true);
        setAuth(session, user);
        setAuthError(null); // reset error message on successful sign in
        
    };
  
    const handleChange = (e) => {
      setState({ ...state, [e.target.name]: e.target.value });
    };
  
    const handleForgotPassword=()=>{
      setFormType("ForgotPassword");
    }

    async function handleResetPassword(username) {
      try {
        const output = await resetPassword({ username });
        handleResetPasswordNextSteps(output);
      } catch (error) {
        console.log(error);
      }
    }

    function handleResetPasswordNextSteps(output) {
      const { nextStep } = output;
      switch (nextStep.resetPasswordStep) {
        case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
          const codeDeliveryDetails = nextStep.codeDeliveryDetails;
          console.log(
            `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`
          );
          // Collect the confirmation code from the user and pass to confirmResetPassword.
          break;
        case 'DONE':
          console.log('Successfully reset password.');
          break;
        default:
          console.log(nextStep)
      }
    }
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true); // Start loading
      try {
        const signInOutput = await signIn({username:state.username, password:state.password});
        console.log('Sign in called successfully!', signInOutput);
        if (signInOutput.isSignedIn){
          await onSignIn();
        } else {
          console.log(signInOutput.nextStep.signInStep)
          setUpdateTemp(true)
          // navigate({ to:"update", params:{username:state.username, password:state.password}, redirect:location.search.redirect}) 
        }
      } catch (error) {
        console.log(error, typeof(error), error?.message)
        if (error.message == 'There is already a signed in user.') {
          console.log('User already authenticated:', error, typeof(error));
          setAuthError('You are already signed in.');
          setIsAuthenticated(true)
          
          // Optionally, you might want to navigate to the home page here
            if (location?.search?.redirect){
              console.log('found redirct in URL, redirecting after logging in')
              navigate({ to:location.search.redirect})  
            } else {
              navigate({ to: '/' });
            }
        } else {
          console.log('Error signing in:', error);
          setAuthError('Incorrect username or password');
        }
      } finally {
        setLoading(false); // Stop loading
      }
    };

    const handleUpdateTempPass = async ()=>{
      console.log({challengeResponse: state.password,
        option: {
          userAttribute: {username:state.username}
        }})
      await confirmSignIn({
        challengeResponse: state.newPassword,
        option: {
          userAttribute: {username:state.username}
        }
      })
    }

    if (updateTemp){
      return (
        <form onSubmit={handleUpdateTempPass}>
            <div className='Auth-content-box-inner'>
                
              <Typography variant="h5" color='white'>Please update your temporary Password</Typography>

    
              <TextField
                label="New Password"
                name="newPassword"
                value={state.newPassword}
                autoComplete="current-password"
                onChange={handleChange}
                type="password"
                fullWidth
                required
              />
              
    
              {/* Error message */}
              {authError && (                
                <Typography variant="body2" color="error">
                    {authError}
                </Typography>               
              )}
        
              <LoadingButton loading={loading} loadingPosition="start" startIcon={<PublishIcon />} variant="contained" color="primary" type="submit" fullWidth>
                Sign In
              </LoadingButton>
              
            </div>
        </form>
      )
    } else {
      return (
        <form onSubmit={handleSubmit}>
            <div className='Auth-content-box-inner'>
                
              <Typography variant="h5" color='white'>Sign In</Typography>
                
              <TextField
                label="Username"
                name="username"
                value={state.username}
                onChange={handleChange}
                autoComplete="username"
                fullWidth
                required
                />
    
              <TextField
                label="Password"
                name="password"
                value={state.password}
                autoComplete="current-password"
                onChange={handleChange}
                type="password"
                fullWidth
                required
              />
              
    
              {/* Error message */}
              {authError && (                
                <Typography variant="body2" color="error">
                    {authError}
                </Typography>               
              )}
        
              <LoadingButton loading={loading} loadingPosition="start" startIcon={<PublishIcon />} variant="contained" color="primary" type="submit" fullWidth>
                Sign In
              </LoadingButton>
            
              <Link to="/reset" className="link">Forgot your password?</Link>
              
            </div>
        </form>
    );}  
  };