import { useState, useEffect, useMemo } from "react";
import FleetGrid from "../../fleet/FleetGrid";
import ErrorBoundary from "../../utils/ErrorBoundary";
import { useQueryClient } from "@tanstack/react-query";
import { useFleetData, useVesselList, useGroups, useUser, zuPage, useSelectedNodeStore, useAuth } from "../../app/zustore";
import {
  Box, Button, Typography, useMediaQuery,
} from "@mui/material";
import { VesselUpdater } from "../../vessel/manage/vesselUpdater";
import { FleetFilterModal } from "../../fleet/FleetFilterModal";
import { ModalTypes } from "../../Types/enums";
// import '../../pageLayout.css';
import { AddVesselModal } from '../../fleet/AddVesselModal';
import { InviteMemberModal } from '../../fleet/InviteMemberModal';
import { NewGroupModal } from '../../fleet/NewGroupModal';
import { AnimationGreet } from "../../loadingBar/animationGreet";
import { SignOutButton } from "../../utils/signOutButton";
import { LoadingBar } from "../../loadingBar/loadingBar";
import { Link, Navigate, createFileRoute, createRoute, redirect, useNavigate } from '@tanstack/react-router'
import { useLocation } from "@tanstack/react-router";

export const Route = createFileRoute('/_authenticated/profile')({
  component: Profile,
})

export default function Profile({ signOut }) {
  const vesselList = useVesselList((state) => (state.vesselList)); // array of objects which have attributes vessel_id and table_name
  const [modalType, setShowModal] = useState(ModalTypes.None);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [vesselToUpdate, setVesselToUpdate] = useState(null);
  const { groups, selectedGroup } = useGroups((state) => ({ groups: state.groups, selectedGroup: state.selectedGroup }));
  // const [filters, setFilters] = useState({ groups: groups.map((_, index) => index), states: [0, 1, 2, 3], activeTime: 100000 }) // 100,000 min ~= 10 weeks
  const {sidebarOpen, toggleSidebar} = zuPage(state=>({sidebarOpen:state.sidebarOpen, toggleSidebar:state.toggleSidebar}));
  const {selectedNode, setSelectedNode} = useSelectedNodeStore();
  const queryClient = useQueryClient();
  const user = useUser(state => state.user);
  const navigate = useNavigate()

  const showModal = useMemo(() => {
    return modalType !== ModalTypes.None
  }, [modalType])

  const numVessels = vesselList.length

  
  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    // Keep the mobile drawer open when swiped to the right
    if (anchor === 'left' && open) {
      toggleSidebar(true);
    } else {
      toggleSidebar(false);
    }
  }; 

  const modal = () => {
    switch (modalType) {
      case ModalTypes.AddVessel:
        return <AddVesselModal showModal={showModal} setShowModal={setShowModal} />
      case ModalTypes.InviteMember:
        return <InviteMemberModal showModal={showModal} setShowModal={setShowModal} />
      case ModalTypes.NewGroup:
        return <NewGroupModal showModal={showModal} setShowModal={setShowModal} selectedNode={selectedNode} />
      case ModalTypes.Update:
        return <VesselUpdater vesselToUpdate={vesselToUpdate} setShowModal={setShowModal} showModal={showModal} />
      
      default:
        return null;
    }
  }

  const handleSidebarButtonClick = ()=>{
    toggleSidebar(!sidebarOpen)
  }

  const ContentArea = ()=>{

    const content = () => {
        if (user) {
            return (
              <div className="fleet-overview-inner-content">
                
              </div>
            )
          } else {
            return (
              <LoadingBar />
            )
          }
    }

    if ( !groups  ){
      return (
        <div className={`ContentBox open`}>
          <Box className="fleet-overview-inner-content">
            <h2 style={{color:'white', marginTop:4, textTransform:'bold'}} >You do not belong to any groups.</h2>
            <Typography sx={{color:'white', }} >Access to boats is provided through groups and you must belong to one in order to see any boats</Typography>
            <Typography sx={{color:'white', }}>If you have already purchased a boat and are the primary owner please contact your sales representative.</Typography>
            <Typography sx={{color:'white', marginBottom:4}}>Otherwise please ask the primary owner of your boat to invite you to their Fleet.</Typography>
            
          </Box>
        </div>
        )
    } else {
    return (
      <div className={`ContentBox open`}>
        {/* Header */}
        {content()}
      </div>
    )}
  }


  
  return (
    <ErrorBoundary boundaryName='Profile Page'>
      <div
        className="container"
        onClick={() => {
          if (showModal) {
            setShowModal(0);
            setVesselToUpdate(null);
          }
        }}
      >
        {/* Main Content Area */}
        {ContentArea()}
        <SignOutButton color='black' sx={{color:'#5f6369'}}/>

      </div>
    </ErrorBoundary>
  );
}