import { React } from "react";
import { TextField } from '@mui/material';
import { BorderStyle } from "@mui/icons-material";


const styles = {
    container: {
      flex: 1,
      padding: 16,
      backgroundColor: "#F8F8F8",
    },
    hidden: {
      display: "none",
    },
    inputContainer: {
      marginBottom: 12,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyItems:'center',
      columnGap:'20px',
      justifyContent:'space-between',
      border: '1px solid #ccc',
      borderStyle: 'none none solid none'

    },
    label: {
      fontSize: 18,
      fontWeight: "bold",
      marginBottom: 8,
      color: "var(--light-grey-2)",
    },
    labelOptional: {
      fontSize: 14,
      fontWeight: "bold",
      marginBottom: 8,
      color: "#333",
    },
    button: { 
      width:"25%", 
      minWidth:'100px',
      height:'50px', 
      borderWidth:'1px', 
      margin:"20px", 
      fontSize:'1rem',
      borderRadius: '5px'
    },
    input: {
      backgroundColor: '#fff',
      borderRadius: '5px',
      boxShadow: "inset 0px -2px 2px 0px var(--dark-grey-2)",
      color: "var(--dark-grey-2)",
    
  }
}

export const InputField = ({ index, field, label, value, onChange, vesselToUpdate, optional=false, numeric=false }) => {
  let defaultValue = "";
  if (vesselToUpdate) {
      if (field.path === '.') {
          defaultValue = vesselToUpdate[field.field] || "";
      } else if (field.path === 'outboards' && vesselToUpdate.outboards && vesselToUpdate.outboards[0]) {
          defaultValue = vesselToUpdate.outboards[0][field.field] || "";
      } else if (field.path === 'prop' && vesselToUpdate.outboards && vesselToUpdate.outboards[0] && vesselToUpdate.outboards[0].prop) {
          defaultValue = vesselToUpdate.outboards[0].prop[field.field] || "";
      }
  }

  return (

    <div style={styles.inputContainer}>
      <h1 style={optional?styles.labelOptional:styles.label}>{label}</h1>
      <TextField
        sx={styles.input}
        type={numeric?'number':''}
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        placeholder={`Enter ${label}`}
        inputProps={numeric?{
          pattern: "[0-9]*", // Only allow numeric characters
          inputMode: "numeric", // Show numeric keyboard on mobile devices
        }:{}}
      />
    </div>
  );}