import * as React from 'react';
import { useQuery } from "@tanstack/react-query";
import { useUser, useVesselActivity, useAuth } from '../app/zustore';
import { fetchData } from '../app/QueryHandler';

const Plotly = window.Plotly;

export const VesselActivity = ({ isMobile, vessel_id })=>{
    const { vesselActivity, updateVesselActivity } = useVesselActivity()
    const user = useUser(s=>s.user)
    const token = useAuth((state)=>state.token);
    const [numDays, setNumDays] = React.useState(90)
    const plotContainerRef = React.useRef(null);
    const [plotRendered, setPlotRendered] = React.useState(false)

    function unpack(rows, key) {
        return rows.map(function(row) { 

            return row[key]; 
        });
    }

    const tripsVesselQuery = useQuery({
        queryKey: ['trips-vessel'],
        queryFn: async () => {
          console.log(Object.keys(user.nodes))
          const qString = `num_days=${numDays}&vessel_id=${vessel_id}`;
          const {status, body, type} = await fetchData({ method: 'GET', resource: 'trips/vessel', token: token, qString: qString });
          if (status===204) throw new Error('No data returned')
          if (status===403) throw new Error('403: Unauthorized')
        //   console.log(body)
          const data = body
          // console.log(data)
          if (data==='') throw new Error('No data returned')
          return data;
        },
        enabled: true,
        refetchInterval: 1000 * 60 * 15, // 30 seconds
        retry: 2,
        onSuccess: (data) => {
          if (data) {            
            console.groupCollapsed('trips/vessel query');            
            console.log(Object.keys(data))
            console.table(data[vessel_id]); 
            if (Object.keys(data).find(v=>v==vessel_id)) {
              let sorted_data = data?.[vessel_id].map(t=>({...t, date: new Date(t.date.replace("_","-"))})).sort((a, b) => {return a.date - b.date;})
              updateVesselActivity(sorted_data)}
            console.groupEnd();
          }
        }
    });

    const plotData = React.useMemo(() => {
        if (vesselActivity.length===0) {
          console.log("Conditions not met to create plotData")
          return null
        } else {
            let numBoatsTrace = {
                type: "bar",
                name: 'Number of trips',
                x: unpack(vesselActivity, 'date'),
                y: unpack(vesselActivity, 'number_trips'),
                line: {color: '#7F7F7F'},
                marker: {color: 'lightgray'},
                opacity: '0.625'
            }

            let motorHoursTrace = {
                type: "scatter",
                mode: "lines",
                name: 'active time - minutes',
                yaxis: 'y2',
                x: unpack(vesselActivity, 'date'),
                y: unpack(vesselActivity, 'total_minutes_per_day'),
                line: {color: '#00b9b8', shape: 'hv'}
                }

            let distanceTrace = {
                type: "scatter",
                mode: "lines",
                name: 'distance - miles',
                yaxis: 'y2',
                x: unpack(vesselActivity, 'date'),
                y: unpack(vesselActivity, 'total_miles_per_day'),
                line: {color: 'orange', shape: 'hv'}
            }

            let efficiencyTrace = {
                type: "scatter",
                mode: "lines",
                name: 'efficiency - mi/kWh',
                yaxis: 'y',
                x: unpack(vesselActivity, 'date'),
                y: unpack(vesselActivity, 'avg_mi_per_kwh_per_day'),
                line: {color: '#00ff00', shape: 'hv'},
                text: unpack(vesselActivity, 'days_since')
            }
        
            const data = [numBoatsTrace, motorHoursTrace, distanceTrace, efficiencyTrace];
            return data
        }

      }, [vesselActivity])
    
      React.useEffect(() => {
        // console.log('plot data changed, updating plot', plotData)
        // Render the plot into the div with a ref attached
        const layout={
          // title: `Recent Vessel Activity - last ${numDays} days`,
          xaxis: { 
            title: 'date',
            tickmode: "auto",
            type: 'date',
            nticks: 4,
            tickformat: "%Y - %m - %d",
            tickangle: 0, 
          },
          yaxis: { title: "Count - mi/kWh", color:'#00ff00' },
          yaxis2: {
            title: "Miles - Minutes",
            color: '#00b9b8',
            overlaying: "y",
            side: "right",
          },
          editable: true,
          hovermode: "x-unified",
          hoverinfo: 'x+y',
          color:'rgba(255, 255, 255, 0.71)',
          paper_bgcolor: 'rgba(0, 0, 0, 0.0)',
          plot_bgcolor: 'rgba(0, 0, 0, 0.32)',
          autosize: true,
          automargin:true,
          margin: isMobile?{l:45, r:45, t:15, b:45}:{l:35, r:55, t:45},
          showlegend: isMobile?false:true,
        //   fillpattern: {bgcolor:'rgba(0, 0, 0, 0.0)', fillmode:'overlay'},
          legend: {
            x: 1,
            y: 1.2,
            xanchor: "right",
            yanchor: "top",
            bgcolor: "rgba(250, 250, 250, 0.2)",
            bordercolor: "rgba(0, 0, 0, 0.2)",
            orientation: 'h',
            borderwidth: 1,
          },
          height: isMobile?215:340,
          responsive: true,
          font: {
            color: 'white',
            family: 'Inter'
          },
          
        }
    
        const config = {responsive: true, staticPlot: isMobile, displayModeBar: false}
        // console.log(plotContainerRef.current)
        if (!plotRendered && vesselActivity.length>0){
          console.log('rendering new preset plot')
          Plotly.newPlot(plotContainerRef.current, plotData, layout, config);
          setPlotRendered(true);
        } else if (plotRendered && vesselActivity.length>0) {
          Plotly.react(plotContainerRef.current, plotData, layout, config);
        }
    
      }, [plotData]); // Redraw the plot if data or layout props change
    
      React.useEffect(()=>{
        return () => {
          if (plotContainerRef.current){
            try {
                console.log('purging preset plot')
                Plotly.purge(plotContainerRef.current);
                setPlotRendered(false)
            } catch (err) {
                console.warn("Caught an error in PlotlyPlots.jsx while purging plot", err)
            }
          } else {
            console.log('no div ref to unmount against - big bad')
          }
        };
      }, [])
    
    if (vesselActivity.length===0){
        return (
        null
    )} else {
        return (
            <div className='vessel-activity' style={{minWidth:'35vw', minHeight:'150px', width:'100%', height:'100%'}}>            
                <div className='vessel-activity-plot-area' ref={plotContainerRef} style={{minWidth:'250px', minHeight:'150px', width:'100%', height:'100%'}}/>
            </div>
        );
    }
}