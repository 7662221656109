import React from 'react';
import { useQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useAuth } from "./zustore";
import { fetchAuthSession, getCurrentUser } from '@aws-amplify/auth';

const api = process.env.REACT_APP_ENV;

const buildQueryString = (params) => {
    if (params === null || params===undefined){
        return null
    } else if ( typeof params !== 'object' || Array.isArray(params)) {
        console.error('Invalid params argument:', params);
        return null
    }
    return Object.keys(params)
        .filter(key => {
            const value = params[key];
            return value !== null && value !== undefined && value !== '';
        })
        .map(key => {
            const value = params[key];
            if (Array.isArray(value)) {
                return value.map(val => `${key}=${encodeURIComponent(val)}`).join('&');
            }
            return `${key}=${encodeURIComponent(value)}`;
        })
        .join('&');
};

export const fetchData = async ({method, resource, token, body, params}) => {
    const setAuth = useAuth.getState().setAuth;
    // console.groupCollapsed(`fetchData ${method} ${resource} -- ${new Date()}`);
    // console.log(api)
    // console.log(method)
    // console.log(resource)
    // console.log(token)
    // console.log(params)
    // console.table(body)
    let response = null;
    if (method===null || !method) return {'statusCode': 500, message:'no method provided'}
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token,
    };
    const qString = buildQueryString(params);
    // const url = `https://zfj7mdm06f.execute-api.us-east-1.amazonaws.com/${resource}${qString ? `?${qString}` : ''}`;
    const url = `https://api.${api}.fluxmarine.com/v1/${resource}${qString ?    `?${qString}` : ''}`;
    // const url = `https://api.dev.fluxmarine.com/v1/${resource}${qString ?    `?${qString}` : ''}`;
    // console.log(url)
    // console.groupEnd(); 
    try {
        const queryOptions = {
            method: method,
            headers: headers,
        }
        if (body) queryOptions.body = JSON.stringify(body)
        response = await fetch(url, queryOptions);

        // console.info(response)
        // If fetch was successful but the response status is not OK
        if (!response.ok) {
            // Handle 403 specifically
            if (response.status === 403) {
                throw new Error('Forbidden: Access is denied.');
            } else if (response.status === 401){
                let { idToken } = (await fetchAuthSession({ forceRefresh: true })).tokens ?? {};
                const JWT = idToken.toString();
                const user = await getCurrentUser();
                setAuth(JWT, user.userId);
                console.log('refreshed JWT')
            } else {
                // Handle other non-OK statuses
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
        } else if (response.status == 204){
            return {status:204, body:null, type:response.headers.get('content-type')}
        }
        // console.log('response ok', response.status)

        const payload = await response.json();
        // console.log(payload)
        if (payload===null || payload==undefined || payload?.length===0){
            throw new Error('No Data Received!')
        }
        return {status:response.status, body:payload, type:response.headers.get('content-type')};
    } catch (error) {
        // if (response?.status === 401){
            let { idToken } = (await fetchAuthSession({ forceRefresh: true })).tokens ?? {};
            const JWT = idToken.toString();
            const user = await getCurrentUser();
            setAuth(JWT, user.userId);
            console.log('refreshed JWT')
        // }
        // console.log(error)
        return {status:response?.status, body:'', type:response?.headers.get('content-type')}
    }
};