// import { schema } from "../models/ddb_schema"
import Papa from 'papaparse';
import pako from 'pako';
import FileSaver from 'file-saver';

export function format_date(unixTimestamp,){
    const d = new Date;
    const date = new Date(new Date(unixTimestamp).getTime() - (d.getTimezoneOffset()*(60*1000))); // Convert seconds to milliseconds

    return date.toLocaleString();
}

export const format_date_string = (time)=>{
  const d = new Date;
  let startTime = Date.parse(time) - (d.getTimezoneOffset()*60*1000)
  return new Date(startTime).toLocaleString()
}

export let getEpochMillis = function(dateStr) {
  let r = /^\s*(\d{4})-(\d\d)-(\d\d)\s+(\d\d):(\d\d):(\d\d)\s+UTC\s*$/
    , m = (""+dateStr).match(r);
  return (m) ? Date.UTC(m[1], m[2]-1, m[3], m[4], m[5], m[6]) : undefined;
};

export function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export function flattenObjectWithSchema(ob, schema, prefix = '') {
    return Object.keys(schema).reduce((acc, key) => {
        const pre = prefix.length ? prefix + '.' : '';
        const fieldSchema = schema[key];
    
        if (Array.isArray(schema[key])) {
            const arrLength = schema[key].length;
            const arr = ob[key] || Array(arrLength).fill(null);
            for (let i = 0; i < arrLength; i++) {
              Object.assign(acc, flattenObjectWithSchema(arr[i] || {}, schema[key][0], `${pre}${key}_${i}`));
            }
        } else if (typeof fieldSchema === 'object' && fieldSchema !== null) {
          // Handle nested object schema
          if (ob[key]) {
            // Object.assign(acc, flattenObjectWithSchema(ob[key], fieldSchema, pre + key));
            Object.assign(acc, flattenObjectWithSchema(ob[key] || {}, schema[key], pre + key));
          }
        } else {
            // Handle primitive schema
            if (key === 'sample_time'){
                acc[pre + key] = ob[key];
                acc["server_time_str"] = format_date(ob[key]);
            } else if (key === 'inst_speed'){
              acc[pre + key] = Math.round(ob[key] * 22.369363)/10;
             }else {
                acc[pre + key] = ob[key] !== undefined ? ob[key] : null;
            }
        }
    
        return acc;
      }, {});
  }

export function downloadCsv({items, vessel}) {
    const csv = Papa.unparse(items);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    console.log(items[0], blob);
    FileSaver.saveAs(blob, `${vessel}.csv`);
  }

export function getDistance(lat1, lon1, lat2, lon2) {
    const R = 6371e3; // metres
    const φ1 = lat1 * Math.PI/180; // φ, λ in radians
    const φ2 = lat2 * Math.PI/180;
    const Δφ = (lat2-lat1) * Math.PI/180;
    const Δλ = (lon2-lon1) * Math.PI/180;
  
    const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ/2) * Math.sin(Δλ/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  
    const d = R * c; // in metres
    return d<500 && d>1 ? d : 0;
  }

export function leftInnerJoin(VesselList, vesselInfo) {
    // Create a dictionary of vessel info objects keyed by vessel_id
    const vesselInfoDict = {};
    vesselInfo.forEach((vessel) => {
      vesselInfoDict[vessel.vessel_id] = vessel;
    });
    
    // Loop through each item in VesselList and join with corresponding vessel info
    const results = VesselList.map((vessel) => ({
      ...vessel,
      ...vesselInfoDict[vessel.vessel_id]
    }));
    
    return results;
}

export function CompressedCSVToDataFrame(base64EncodedData) {
  // Decode the base64 encoded data
  const compressedData = atob(base64EncodedData);

  // Convert the binary string to a Uint8Array
  const binaryData = new Uint8Array(compressedData.length);
  for (let i = 0; i < compressedData.length; i++) {
    binaryData[i] = compressedData.charCodeAt(i);
  }

  // Decompress the data
  const decompressedData = pako.inflate(binaryData, { to: 'string' });

  // Split the decompressed data into lines and trim each line
  const trimmedData = decompressedData.trim();

  // Load the trimmed CSV string into a Pandas DataFrame
  const parsedData = Papa.parse(trimmedData, {
    header: true,
    dynamicTyping: true,
  });

  const df = parsedData.data; // Extract the data from Papaparse

  return df;
}

export function decompressJSON(base64EncodedData){
  // Decode the base64 encoded data
  const compressedData = atob(base64EncodedData);

  // Convert the binary string to a Uint8Array
  const binaryData = new Uint8Array(compressedData.length);
  for (let i = 0; i < compressedData.length; i++) {
    binaryData[i] = compressedData.charCodeAt(i);
  }

  // Decompress the data
  const decompressedData = pako.inflate(binaryData, { to: 'string' });

  return JSON.parse(decompressedData)
};

window.decompress = decompressJSON;