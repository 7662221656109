import { useState, useEffect, useMemo } from "react";
import Box from '@mui/material/Box';
import { useResizeDetector } from 'react-resize-detector'
import { useMediaQuery } from '@mui/material';
import ErrorBoundary from "../../../utils/ErrorBoundary";
import { useAnalysisData, useDataSource, useNumPlots, useSelect, useVessel, useVesselList, useAuth, usePlots, useFetchStatus, useModal } from "../../../app/zustore";
import { UploadFileModal } from "../../../app/modals/UploadFileModal";
import { ModalTypes } from "../../../Types/enums";
import LinearDeterminate from "../../../loadingBar/progressBar";
import Scatter3D from "../../../utils/Plots/ThreeDScatter";
import { FaultCodeCounter } from "../../../vessel/faultCodes";
import { PlotCarousel } from "../../../vessel/analyze/plotCarousel";
import MapPlotter from "../../../utils/Plots/MapPlots";
import { DatasetSelector } from "../../../vessel/analyze/DatasetSelector";
import { DatasetLoader } from "../../../vessel/analyze/DatasetLoader";
import { createFileRoute } from "@tanstack/react-router";
import { Expander } from "../../../fleet/Expander";
import GaugeGrid from "../../../vessel/GaugeGrid";

export const Route = createFileRoute('/_authenticated/vessels/analyze')({
    // Or in a component
    component: AnalysisPage,
  })

export default function AnalysisPage({ contentAreaHeight }){
    const isMobile = useMediaQuery('(max-width:600px)');
    const {modalType, setShowModal} = useModal()
    // const vessel_id = useVessel(s=>s.vessel_id);
    const { vessel_id } = Route.useParams()
    const vessel = useVesselList(s=>s.vesselList).find(vessel => vessel.vessel_id === vessel_id);
    const { width, ref } = useResizeDetector();
    const { selected } = useSelect(state=>state.selected);
    const {statText, setStatText, total, current, progress, setProgress} = useFetchStatus();
    const [dataset, setDataset] = useState(null);
    const {datasource, setDataSource} = useDataSource();
    const showModal = useMemo(()=>{return modalType!==ModalTypes.None}, [modalType])

    const contentboxShadow = 'black 4px 4px';

    useEffect(() => {
        console.log(dataset)
    }, [dataset]);

    const status = ()=>{
        if (statText[vessel_id]){
            return (
            <>
                <h3>{statText[vessel_id]}</h3> 
                
                {/* <Divider orientation="horizontal" style={{marginBottom: "15px", marginTop:'20px'}}/>  */}
            </>
            )
        } else return null
    }

    const content = () => {

        if (dataset && dataset.data.length>0){
            return (
                <ErrorBoundary boundaryName='Analysis Content'>
                <div className="analyze-content">

                    
                    {(dataset && dataset.source!=='csv') &&
                    <Expander isMobile={isMobile} title='Faults' startCollapsed={true} style={{marginTop:'25px'}}>
                        <FaultCodeCounter data={dataset.data} />
                    </Expander>}                    
   
                    <ErrorBoundary boundaryName='Outer Plots'>
                    <Expander isMobile={isMobile} title='Custom Plot' startCollapsed={false}>
                        <PlotCarousel dataset={dataset} isMobile={isMobile} contentAreaHeight={contentAreaHeight}/>
                    </Expander>
                    </ErrorBoundary>

                    {dataset.source=='pi' &&
                    <ErrorBoundary boundaryName="Analyze Data Gauge Grid">
                        <Expander isMobile={isMobile} title='Gauge Grid' startCollapsed={false}>
                            <GaugeGrid isMobile={isMobile} data={dataset.data} vessel_id={vessel_id}/>
                        </Expander>
                    </ErrorBoundary>
                    }
                        
                    <ErrorBoundary boundaryName='Map Plotter'>
                    <Expander isMobile={isMobile} title='Map' startCollapsed={true}>
                        <MapPlotter data={dataset.data} isMobile={isMobile} source={dataset.source} vessel_id={vessel_id}/>
                    </Expander>
                    </ErrorBoundary>

                    <ErrorBoundary boundaryName='outer 3d'>
                    <Expander isMobile={isMobile} title='3D Plot' startCollapsed={true}>
                        <Scatter3D dataset={dataset} contentAreaHeight={contentAreaHeight}/>
                    </Expander>
                    </ErrorBoundary>
                        
                </div>
                </ErrorBoundary>
            )
        } else return null
    }

    const Modal =()=>{
        switch (modalType) {
            case ModalTypes.DatasetLoader:
                return <DatasetLoader showModal={showModal} isMobile={isMobile} setDataset={setDataset}/>
            default:
                return null;
        }
    }    

    return (
        <Box sx={{ 
                flexGrow: 1, marginBottom:'30vh', 
                width:'100%', 
                display: 'flex', flexDirection: 'column', 
                alignItems: 'center',
                height: '100%',
                // background: "linear-gradient(45deg, rgb(20, 44, 70), rgb(96, 165, 167))"
            }}>

            <div style={{width:"100%", height:'5px'}} ref={ref} className="width-ref-div"></div>
            
            <DatasetSelector isMobile={isMobile} setDataset={setDataset} dataset={dataset}/>
            <div style={{height:'25px'}}></div>
            
            {/* {status()} */}
            
            {content()}
            
            {Modal()}
        </Box>
        
    )
}  