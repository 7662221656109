import { useLocation } from "@tanstack/react-router";
import { createFileRoute, Outlet, useNavigate } from '@tanstack/react-router'
import { useGroups, useSelectedNodeStore, useUser, zuPage } from '../../app/zustore';
import { useMediaQuery } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { ManageTabs } from '../../Types/enums';

export const Route = createFileRoute('/_authenticated/groups')({
  component: ManageLayout
})

export default function ManageLayout() {
  const { node_id } = Route.useParams()
  const user = useUser(state => state.user);
  const groups = useGroups((state) => ({ groups: state.groups})).groups
  const isMobile = useMediaQuery('(max-width:600px)');
  const group = groups?.find(n=>n.node_id==node_id);
  const {sidebarOpen, toggleSidebar} = zuPage(state=>({sidebarOpen:state.sidebarOpen, toggleSidebar:state.toggleSidebar}));
  const {selectedNode, setSelectedNode} = useSelectedNodeStore();
  const location = useLocation();
  const navigate = useNavigate()
  
  const actionTab = useMemo(()=>{
    return location.pathname.split('/')[2];
  },[location])

  useEffect(() => {
    console.log(location, location.pathname.split('/')[3])
  }, [location]);

  return (
    <div className='manage-page'>
      <h1>Manage Groups</h1>

    <div style={{display:'flex', flexDirection:'column', marginBottom:'1px', alignItems:'center'}}>
      <div className='btn-container'>
        
        <button 
          style={{backgroundColor:actionTab==='new'&&'#00b9b8', borderRadius:'5px', }} 
          onClick={() =>
              navigate({ to:`/groups/new`} )}
            >{"New"+(!isMobile?' Group':'')}</button>

        <button 
          style={{backgroundColor:actionTab==='move'&&'#00b9b8', borderRadius:'5px', }} 
          onClick={() =>
             navigate({ to:`/groups/move`} )}
          >{"Move"+(!isMobile?' Group':'')}</button>

        <button 
          style={{backgroundColor:actionTab==='delete'&&'#00b9b8', borderRadius:'5px', }} 
          onClick={() =>
           navigate({ to:`/groups/delete`} )}
        >{"Delete"+(!isMobile?' Group':'')}</button>

        <button 
          style={{backgroundColor:actionTab==='rename'&&'#00b9b8', borderRadius:'5px', }} 
          onClick={() =>
           navigate({ to:`/groups/rename`} )}
        >{"Rename"+(!isMobile?' Group':'')}</button>
        
        <button 
          style={{backgroundColor:actionTab==='members'&&'#00b9b8', borderRadius:'5px', }} 
          onClick={() =>
           navigate({ to:`/groups/members`} )}
        >{"Manage"+(!isMobile?' Group Members':'')}</button>

        <button 
          style={{backgroundColor:actionTab==='new-vessel'&&'#00b9b8', borderRadius:'5px', }} 
          onClick={() =>
           navigate({ to:`/groups/new-vessel`} )}
        >{"Add New Vessel"+(!isMobile?' to Group':'')}</button>

      </div>
    </div>

    {/* {content()}  */}
    <Outlet />     
    
    </div>
  )
}