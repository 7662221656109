import React, { useState, Suspense, useEffect, useMemo } from 'react';
import { Box, Typography, useMediaQuery, SwipeableDrawer, } from "@mui/material";
import { useVessel, zuPage, useVesselList, useUser, useAuth, useTrips, dynoIdArray, useSelect, useLeaderboard } from 'app/zustore';
// import '../../pageLayout.css';
import { VesselSelector } from "vessel/vesselSelector";
import { fetchData } from 'app/QueryHandler';
import ErrorBoundary from 'utils/ErrorBoundary';
import { useQuery } from "@tanstack/react-query";
import { Link, Outlet, createFileRoute, createRoute, redirect, useLocation, useNavigate } from '@tanstack/react-router'
import {  decompressJSON } from 'utils/utilityMethods';
import { LeaderboardInfoWidget, numberWithCommas } from 'fleet/leaderboard';
import { AccessTimeOutlined, AttachMoneyOutlined, Co2Outlined, ElectricBoltOutlined, NumbersOutlined, RouteOutlined } from '@mui/icons-material';

export const Route = createFileRoute('/_authenticated/vessels')({
  // Or in a component
  component: VesselsComponent,
  beforeLoad: ({ search, location })=>{
    if (location.pathname=='/vessels/select'){
      return
    }
    const vessel_id = search.vessel_id
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    const setVessel = useVessel.getState().setVessel
    console.log('_vessels before load', search.vessel_id, location, uuidRegex.test(vessel_id))
    if (!uuidRegex.test(vessel_id)) {
      const subPath = location.href.split('/').slice(2).join('/');
      const vessel = vesselList.find(v=>v.vessel_name==vessel_id);
      console.log('redirecting to proper route with vessel_id instead of vessel_name in the search', location.state)
      throw redirect({
        to: `/${subPath}`,
        search: {vessel_id: vessel.vessel_id},
        mask: `/${vessel.vessel_name.replace(' ', '-')}/${subPath}`
    })};
    // Parser function to determine if the param is a UUID or a name
    const vesselList = useVesselList.getState().vesselList;
    const vessel_name = vesselList.find(v=>v.vessel_id==vessel_id)?.vessel_name || 'NOT_FOUND';
    console.log('live data before load:', vessel_name)
  }
})

function VesselsComponent({ contentAreaHeight }) {
  const isMobile = useMediaQuery('(max-width:600px)');
  const {leftSidebarOpen, toggleLeftSidebar} = zuPage(state=>({leftSidebarOpen:state.leftSidebarOpen, toggleLeftSidebar:state.toggleLeftSidebar}));
  const { vessel_id } = Route.useSearch()
  const {tab, setPage, setTab} = zuPage(state=>({setPage:state.setPage, tab:state.tab, setTab:state.setTab}));
  const vesselList = useVesselList(s=>s.vesselList)
  const setVessel = useVessel(s=>s.setVessel)
  const setSelected = useSelect(s=>s.setSelected)
  const vessel_name = vesselList.find(v=>v.vessel_id==vessel_id)?.vessel_name || '';
  const token = useAuth((state)=>state.token);
  const setVesselTrips = useTrips(s=>s.setVesselTrips);
  const leaderboard = useLeaderboard(s=>s.leaderboard)
  const vessel_lifetime_stats = leaderboard.find(row=>row.vessel_id==vessel_id) ?? null
  const liveRoute = dynoIdArray.find(vessel=>vessel.vessel_id==vessel_id) ? '/dyno' : '/live';
  const user = useUser(s=>s.user)
  const location = useLocation()

  useEffect(() => {
    if (location.pathname=='/vessels/select'){
      return
    }
    console.log('_vessels setting the vessel id', vessel_id)
    setVessel(vessel_id, vessel_name);
    const selectionIndex = vesselList.findIndex(v=>v.vessel_id===vessel_id);
    if ( selectionIndex >=0 ){
      setSelected(selectionIndex)
    }
  }, [vessel_id, location]);

  const handleTabClick = (tabNumber) => {
    setTab(tabNumber);
  };

  useEffect(()=>{
    if (!isMobile){
      toggleLeftSidebar(true)
    }
  },[isMobile])

  const vesselTripsQuery = useQuery({
    queryKey: ['vessel-trips'],
    queryFn: async () => {
      const params = {
        vessel_id: vessel_id,
        limit: 75
      }
      const JWT = useAuth.getState().token
      const {status, body, type} = await fetchData({ method: 'GET', resource: 'trips/sample', token: JWT, params: params });
      if (status===204) {
        
        throw new Error('No data returned')
      }
      if (status===403) throw new Error('403: Unauthorized')
      // console.log(body)
      const data = body
      console.log(type)
      if (type=='application/json' && Object.keys(data).length===0) throw new Error('No data returned')
      if (type=='application/zip'){
        console.log('detected a zipped response to trips/sample')
        return decompressJSON(data)
      }
      return data;
    },
    enabled: (vessel_id!==null) && (tab!==3) && (token!==null) && (vesselList.length>0),
    refetchInterval: 1000 * 60 * 15, // 15min
    retry: 2,
    onSuccess: (data) => {
      if (data) {            
        console.groupCollapsed('trips/sample query');            
        console.table(data);  
        
        data = data?.[vessel_id]?.map(t=>(
          {
            ...t, 
            start_time_str: new Date(t['start_time']).toLocaleString(), 
            end_time_str: new Date(t['end_time']).toLocaleString()
          }
        ))
        console.table(data)
        setVesselTrips(vessel_id, data)
        console.groupEnd();
      }
    }
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    // Keep the mobile drawer open when swiped to the right
    if (anchor === 'left' && open) {
      toggleLeftSidebar(true);
    } else {
      toggleLeftSidebar(false);
    }
  };

  const handleSidebarButtonClick = ()=>{
    toggleLeftSidebar(!leftSidebarOpen)
  }

  const handleReturnClick = ()=>{
    setPage('FleetOverview');
  }

  const info_widgets = useMemo(()=>{
    console.log(leaderboard, vessel_id, vessel_lifetime_stats)
    return [
    {
        label: "Odometer", 
        units: "mi", 
        tooltip: 'The total distance traveled by Vessels in your fleet during trips',
        icon: <RouteOutlined className='icon'/>, 
        value: numberWithCommas(Math.round(vessel_lifetime_stats?.odometer))
    },
    {
        label: "Motor Hours", 
        units: "hrs", 
        tooltip: 'The motor hours accumulated by Vessels in your fleet during trips',
        icon: <AccessTimeOutlined className='icon'/>, 
        value: numberWithCommas(Math.round(vessel_lifetime_stats?.motor_hours))
    },
    {
        label: "Trips", 
        units: "", 
        tooltip: 'The total number of trips completed by Vessels in your fleet',
        icon:  <NumbersOutlined className='icon'/>, 
        value: numberWithCommas(Math.round(vessel_lifetime_stats?.num_trips))
    },
    {
        label: "CO2eq abatement", 
        units: "lbs", 
        tooltip: 'The total equivalent CO2 weight greenhouse gas emmissions which would have been emitted by equivalents vessels to those in your fleet during trips',
        icon: <Co2Outlined className='icon'/>, 
        value: numberWithCommas(Math.round(vessel_lifetime_stats?.total_co2eq_prevented_lbs))
    },
    {
        label: "Energy Usage", 
        units: "kWh", 
        tooltip: 'The total electrical energy used by Vessels in your fleet during trips',
        icon: <ElectricBoltOutlined className='icon'/>, 
        value: numberWithCommas(Math.round(vessel_lifetime_stats?.cumulative_battery_kWh))
    },
    {
        label: "Fuel savings", 
        units: "$", 
        tooltip: 'The difference between the estimated cost of the electrical energy used by your fleet, and the estimated number of gallons which would have been required by equivalent vessels to complete identical trips',
        icon: <AttachMoneyOutlined className='icon'/>, 
        value: numberWithCommas(Math.round(vessel_lifetime_stats?.total_fuel_savings))
    }
]}, [vessel_lifetime_stats])

  const SideBar = () => {
    const SideBarContent = ()=>{
      return (
        <Box className='SideBarContent'>
          
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              marginBottom: '10px',
              textAlign: 'start'
            }}>
          <Typography
              variant="h4"
              sx={{ color: '#888', fontSize: 'larger', textAlign:'start', marginLeft:'10px'  }}
            >
              Now viewing:
            </Typography>
            <Typography
              variant="h2"
              sx={{ color: isMobile ? '#000' : '#fff', marginLeft: '10px', fontSize: 'large',maxWidth:'140px' }}
            >
              {vessel_name}
            </Typography>
          </div> 

          <div className='sidebar-info-boxes'>
          {
              info_widgets.map((widget, index) => (
                  <LeaderboardInfoWidget widget={widget} key={index}/>
              ))
          }
          </div>
  
          <VesselSelector />
  
          <div className="tab-buttons" style={styles.tabButtons}>
            <a style={{color:'white', fontSize:'30px', textAlign:'center'}}>Tab</a>
            {/* <button className={`fm-btn ${tab==1?'active':'inactive'}`}
              // style={tab === 1 ? { ...styles.tabButtonStyle, ...styles.activeTabButtonStyle } : styles.tabButtonStyle}
              onClick={() => dynoIdArray.find(vessel=>vessel.vessel_id==vessel_id) ? handleTabClick(4) : handleTabClick(1)}
              >
                <span>Live Data</span>
            </button> */}

            <button className='fm-btn' disabled={vessel_id==null}>
                <Link 
                  to={`/vessels/${liveRoute}`} 
                  search={{vessel_id: vessel_id,}}                  
                  >Live</Link>
            </button>
            
            <button className='fm-btn' disabled={vessel_id==null}>
                <Link 
                  to="/vessels/analyze" 
                  search={{vessel_id: vessel_id,}}
                  
                  >Analyze</Link>
            </button>

            <button className='fm-btn' disabled={vessel_id==null}>
                <Link 
                  to="/vessels/manage" 
                  search={{vessel_id: vessel_id,}}
                  >Manage</Link>
            </button>
          </div>

          <div style={{width:'90%'}} onClick={handleReturnClick}>
            <button className='fm-btn'>
              <Link to='/'>Fleet Page</Link>
            </button>
          </div>

          
        </Box>
      )
    }

    if (isMobile) {
      return (
        <SwipeableDrawer
            className='swipe'
            anchor="left"
            swipeAreaWidth={45}
            open={leftSidebarOpen}
            onOpen={toggleDrawer('left', true)}
            onClose={toggleDrawer('left', false)}
            
          >
          <Box className='SideBar'>
            {SideBarContent()}
          </Box>
        </SwipeableDrawer>
      )
    } 
    else if (leftSidebarOpen) {
      return (
      <Box className={`SideBar open`}>
        {SideBarContent()}
      </Box>
      )
    } else {
      return (
        <Box className={`SideBar open`} onClick={handleSidebarButtonClick}>
          <button
            color="black"
            aria-label="open drawer"
            style={{alignSelf:'center', backgroundColor:'#404040', margin:0, padding:0, border:0}}
            onClick={handleSidebarButtonClick}
          >
            <svg 
              // class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-19d88bx-MuiSvgIcon-root" 
              focusable="false" 
              style={{width:'100%', aspectRatio:'1', color:'black', transform:'rotate(90deg)', backgroundColor:'#404040'}}
              aria-hidden="true" 
              viewBox="0 0 24 24" 
              data-testid="MenuIcon">
              <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
            </svg>
          </button>     
        </Box>
      )
    } 
  }  
  
  return (
    <div className="container vessels">
      {SideBar()}      
      
      <div className={`ContentBox ${isMobile && 'mobile'} open`}>
        <ErrorBoundary boundaryName='Vessel Page'>
          <Outlet />
        </ErrorBoundary>
      </div>
    </div>
  );
}

const styles = {
  container: {
    width: '100%',
    height: '100vh',
    display: "flex",
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 0,
  },
  tabButtons: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
    width:'90%',
    justifySelf: 'center',
    borderRadius: '5px',
  },
  tabButtonStyle: {
    padding: '10px 20px',
    border: 'none',
    cursor: 'pointer',
    width: '100%',
    backgroundColor: '#929292',
    outline: 'none',
    fontSize: '16px',
    transition: 'background-color 0.3s',
  },
  activeTabButtonStyle: {
    backgroundColor: '#007bff',
    color: '#fff',
    width: '100%',
  },  
  tabContentStyle: {
    position:'absolute',
    right:0,
    top:0,
    width: '80vw',
    display:'flex',
  },
  tabContentStyleMobile: {
    width: '100vw'
  },
  returnBtn: {
    width:'80%', 
    height:'50px', 
    backgroundColor:'#929292', 
    borderRadius:'5px', 
    justififySelf:'center', 
    alignSelf:'center', 
    display:'flex',
    justifyContent: 'center',
    marginBottom:'20px',
    boxShadow: '0px 0px 5px #000'
  },
  sideBarStyle: { 
    display: 'flex', 
    flexDirection: 'column', 
    width: '20%', 
    minWidth:'300px', 
    height:'100vh', 
    maxHeight: '300vh', 
    paddingBottom:'30px', 
    paddingTop:'10px', 
    backgroundColor: '#404040', 
  },
  sideBarStyleMobile: {
    width: '90vw',
  }
} 