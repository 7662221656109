import { 
    CardContent, 
    Box, 
    TextField, 
    Button,
    Modal, } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { enqueueSnackbar } from "notistack";
import { useGroups, useAuth } from '../app/zustore';
import { useState } from 'react';
import PublishIcon from '@mui/icons-material/Publish';
import { fetchData } from '../app/QueryHandler';

export const InviteMemberModal = ({ showModal, setShowModal }) => {
    const [newUser, setNewUser] = useState('')
    const group = useGroups((state)=>(state.selectedGroup))
    const queryClient = useQueryClient()

    const inviteMutation = useMutation({
        mutationFn: async (invite) => {
            const token = useAuth.getState().token
            let {status, response} = await fetchData({method:'POST', resource:'invites', token:token, body:invite})
            console.log("response from POST vessel-info", response)
            return response
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['groups']})
            enqueueSnackbar("New Member Invited! 💃🕺🕺🏿💃🏿", {variant:'QueryInfo'})
          },
          onError: () => {
            enqueueSnackbar("Failed to invite new Member!", {variant:'error'})
          }
    })
    
    const handleInputChange = (event) => {  
        setNewUser(event.target.value);
    };

    const handleInviteMember = async () => {
        if (newUser.length>0){
            console.groupCollapsed('handleSubmit');
            // setStatus('loading')
            let invite = {'node_id':group.node_id, 'email':newUser};
            
            console.table(invite)
            console.log(JSON.stringify(invite))
            console.groupEnd();

            inviteMutation.mutate(invite)
            setShowModal(0)
        }
    }

    return (
        <Modal 
            open={showModal} 
            onClose={()=>{setShowModal(0)}}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            sx={{overflowY:'scroll'}}
        >
            <Box sx={{ ...styles.box, width: '87.5%', maxWidth:'1000px'  }}>
                <Box sx={styles.InnerBox}>
                   
                <CardContent sx={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
                    <TextField 
                        
                        label={"Enter the email of the user to invite!"}
                        placeholder={`Enter email of user to invite`}
                        variant="outlined"
                        style={{padding: 10,
                            backgroundColor: 'rgb(220,220,220)',
                            paddingRight: '30px',
                            width: '100%'}}
                        defaultValue={''}
                        onChange={(value) => handleInputChange(value)}
                        
                    />
                    <Button 
                    variant="contained" 
                    
                    sx={{
                        margin: '10px auto 10px auto', 
                        height:'80%', 
                        maxWidth:'45%',
                        color: '#00b9b8',
                        backgroundColor:newUser.length>0?'#3d444b':'lightgray'}} 
                        onClick={()=>{handleInviteMember()}}     
                    endIcon={<PublishIcon />}>Submit</Button>
                </CardContent>
                </Box>
            </Box>
        </Modal> 
    )
}

const styles = {
    box: {
        position: 'absolute',
        top: '50%',
        maxHeight:'70%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        bgcolor: '#e1e1e1',
        borderRadius: '15px',
        boxShadow: 24,
        overflowY: 'hide',
        display: 'flex',
        flexDirection:'column',
        alignContent:'center',
        justifyContent:'center',
    },
}