import { useEffect, useState } from 'react';
import { useDataSource, usePlots } from '../../app/zustore';
import { Button, Modal, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'
import PublishIcon from '@mui/icons-material/Publish';
import superjson from 'superjson';
import { PlotCustomizer } from './PlotCustomizer';
import { PresetNamer } from './PlotPresetNamer';

export const PlotCustomizerModal = ({ env, showModal, setShowModal, dataset }) => {
  const datasource = useDataSource((s)=>s.datasource);
  const plots = usePlots( s=>s.plots)  
  const preset = usePlots(s=>s.preset)
  const plot = plots[datasource]?.[preset] 
  const [newPresetName, setNewPresetName] = useState(plot.isLocal?preset:`${preset}_1`); 
  const updateFullDatasource = usePlots( s=>s.updateFullDatasource)  
  const [newPlot, setNewPlot] = useState({...plot, isLocal:true})
  const  updatePlotIndex = usePlots(s=>s.updatePlotIndex)
  const updatePreset = usePlots(s=>s.updatePreset)    
  const [updateAccepted, setUpdateAccepted] = useState(0)

  const handleInputChange = (event) => {
    setNewPresetName(event.target.value);
  };  

  const handleSubmit = ()=>{
    // update the zustore and save to local storage
    if (newPresetName.length>0){
      const newSubset = {...plots[datasource], [newPresetName]:{...newPlot, isLocal:true}};
      const newPlots = {...plots, [datasource]:newSubset};
      console.log(newPlots)
      updateFullDatasource(datasource, newSubset);
      localStorage.setItem(`plotly-presets-${env}`, superjson.stringify(newPlots));
      
      updatePlotIndex(Object.keys(newSubset).length-1)
      updatePreset(newPresetName)
      setUpdateAccepted(1)
      setShowModal(0);
    } else {
      setUpdateAccepted(2)
    }
  }

  useEffect(()=>{
    setNewPlot({...plot, isLocal:true})
    console.groupCollapsed('Plot Customizer Modal')
    console.log(plot)
    console.log(preset)
    console.log(plot.isLocal?"Personal Preset":"Default Preset")
    console.groupEnd()
    if (!plot.isLocal){
      setNewPresetName(`${newPresetName}_1`)
    }
  },[plot, preset])

  if (showModal){
    return (
        <Modal 
            open={showModal} 
            onClose={()=>{setShowModal(0)}}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
          <Box className='analyze-page-modal-box'>
            

            <span className='analyze-page-modal-header' >Create a new personal Preset</span>
            <PresetNamer handleInputChange={handleInputChange} initialName={newPresetName}/>
            <PlotCustomizer newPlot={newPlot} setNewPlot={setNewPlot} dataset={dataset}/>
            <div className='analyze-page-modal-button-div'>
              <Button variant="contained" className='analyze-page-modal-button' onClick={()=>{setShowModal(0); setUpdateAccepted(0)}} endIcon={<CloseIcon/>}>Close</Button>
              <Button variant="contained" className='analyze-page-modal-button' sx={{backgroundColor:updateAccepted<2?'#3d444b':'red'}} onClick={()=>{handleSubmit()}} endIcon={<PublishIcon />}>Submit</Button>
            </div>
              
          </Box>
        </Modal> 
    )
  }
}
