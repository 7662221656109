import React, { useState, useEffect, useMemo, useRef } from 'react';

import { useDataSource, usePlots, useAnalysisData, useVessel, useModal } from '../../app/zustore';
import { ModalTypes } from '../../Types/enums';

// import Plotly from 'plotly.js-gl2d-dist-min'
const Plotly = window.Plotly;

export const format_label = (label) => {
  try {
    // remove the data path from the label by replacing batterydata. with ''
    if (label === null || label=== undefined) {
      return ''
    }
    return label
    .replace(/batterydata.|telemetry_data.|motordata.\d|obcdata.\d|_str\d/g, "")
    .replace(/_/g, " ");
  } catch (err) {
    return ''
  }
}

export default function MapPlotter({ isMobile, data, source='aws', vessel_id}) {
  const datasource = source;
  const plotContainerRef = useRef(null);
  const [plotRendered, setPlotRendered] = useState(false);
  const [zoom, setZoom] = useState(12);

  const plot = ( datasource=='csv' )
    ? {lat:'Latitude', lon:'Longitude', color:'GPS Speed (mph)'}
    : {lat:'telemetry_data.latitude', lon:'telemetry_data.longitude', color:'telemetry_data.inst_speed'}

    const handleScroll = (e) => {
        console.log('Scroll event:', e);
        // Here, you can set your state variable or perform any other actions
        
      };

  const plotData = useMemo(() => {
    if (plot===null || !vessel_id || !data || data.length===0) {
      console.log("Conditions not met to create plotData")
      return null
    } 

    function unpack(rows, key) {
        return rows.map(function(row) { return row[key]; });}

    const  scl = [
        [0, 'rgb(150,0,90)'],
        [0.125, 'rgb(0, 0, 200)'],
        [0.25,'rgb(0, 25, 255)'],
        [0.375,'rgb(0, 152, 255)'],
        [0.5,'rgb(44, 255, 150)'],
        [0.625,'rgb(151, 255, 0)'],
        [0.75,'rgb(255, 234, 0)'],
        [0.875,'rgb(255, 111, 0)'],
        [1,'rgb(255, 0, 0)']
    ];

    const traces = [{
        lon: unpack(data.filter(d=>d[plot.lon]!==0), plot.lon),
        lat: unpack(data.filter(d=>d[plot.lat]!==0), plot.lat),
        marker: {
          color: unpack(data.filter(d=>d[plot.color]!==null), plot.color),
          colorscale: scl,
          reversescale: false,
          opacity: 0.75,
          size: 10,
          colorbar:{
            thickness: 10,
            titleside: 'right',
            outlinecolor: 'rgba(68,68,68,0)',
            // ticks: 'outside',
            // ticklen: 3,
            // shoticksuffix: 'last',
            ticksuffix: 'mph',
            dtick: 5
          }
        },
        name: 'Vessel Location Data',
        type: "scattermapbox",
        mode: 'lines+markers',
        text: data?.map((item) => {
          return `Time: ${item['sample_time_str']}<br>Speed: ${item[plot.color]}`;
        })
    }];
    
    return traces
  }, [plot])

  useEffect(() => {
    // console.log('plot data changed, updating plot', plotData)
    // Render the plot into the div with a ref attached
    const longitude = data.filter(d=>d[plot.lon]!==0)[data.filter(d=>d[plot.lon]!==0).length-1][plot.lon];
    const latitude = data.filter(d=>d[plot.lat]!==0)[data.filter(d=>d[plot.lat]!==0).length-1][plot.lat];
    const layout={
        mapbox: {
            center: {
              lat: latitude,
              lon: longitude
            },
            domain: {
              x: [0, 1],
              y: [0, 1]
            },
            style: 'open-street-map',
            zoom: zoom
          },
        editable: true,
        hovermode: "closest",
        hoverinfo: 'y',
        hoverlabel: {bgcolor: 'rgba(200, 200, 200, 0.1)'},
        color:'white',
        paper_bgcolor: 'rgba(200, 200, 200, 0)',
        plot_bgcolor: 'rgba(200, 200, 200, 0)',
        autosize: true,
        automargin:true,
        fillpattern: {bgcolor:'white', fillmode:'overlay'},
        margin: {l:5, r:55, t:5,b:5},
        borderRadius:'15px',
        showlegend: false, 
        // width: 450,
        height: isMobile?400:520,
      // responsive: true
    }

    const config = {
      responsive: true, 
      displayModeBar: false,
      modeBarButtonsToRemove: [
        'hoverClosestGl2d', 
        'hoverCompareCartesian '
      ]
    }
    // console.log(plotContainerRef.current)
    if (!plotRendered){
      console.log('rendering new preset plot')
      const plot = Plotly.newPlot(plotContainerRef.current, plotData, layout, config);
      plotContainerRef.current.addEventListener('scroll', handleScroll)
      // plot.on(event=>console.log('plotly event detected', event));
      setPlotRendered(true);
    } else {
      Plotly.react(plotContainerRef.current, plotData, layout, config);
    }

  }, [plotData, zoom]); // Redraw the plot if data or layout props change

  const handleZoomIn = () => {
    if (zoom < 22) {
      const newZoom = zoom + 1;
      setZoom(newZoom);
    }
  };
  
  const handleZoomOut = () => {
    if (zoom > 0) {  // assuming the minimum zoom level is 0
      const newZoom = zoom - 1;
      setZoom(newZoom);
    }
  }; 

  useEffect(()=>{
    return () => {
      if (plotContainerRef.current){
        try {
            console.log('purging preset plot')
            Plotly.purge(plotContainerRef.current);
            setPlotRendered(false)
        } catch (err) {
            console.warn("Caught an error in PlotlyPlots.jsx while purging plot", err)
        }
      } else {
        console.log('no div ref to unmount against - big bad')
      }
    };
  }, [])

  return (
    <div style={{ width: '100%', height: "100%", display: 'flex', flexDirection: 'column', justifyContent:'center', alignItems:'center' }} >

      <div style={{ width: "100%", height: "100%", padding:'4px',  }}>
          <div>
            <div ref={plotContainerRef} id="analysis-map-plot-div" style={{position:'relative'}}>
                <div style={{ position: 'absolute', top: '10px', left: '10px', borderRadius:'10px', zIndex:500, width:"10%", height:'10%', display: 'flex', alignItems: 'center', minWidth:'80px' }} className='zoomButtons'>
                    <button onClick={handleZoomIn} style={{width:"40%", height: "90%", borderRadius:'5px', fontSize:'3vh', textAlign:'center', backdropFilter: 'blur(6px)', opacity:'50%'}}>+</button>
                    <button onClick={handleZoomOut} style={{width:"40%", height: "90%", borderRadius:'5px', fontSize:'3vh', textAlign:'center', backdropFilter: 'blur(6px)', opacity:'50%'}}>−</button>
                </div>
            </div>
          </div>
  
      </div>
      
    </div>
  );
}