import React, { useState, useEffect, useMemo, useRef } from 'react';
import Select from 'react-select';
import { useDataSource, use3dPlot, useAnalysisData, useVessel } from '../../app/zustore';
import { IconButton } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { format_label } from './PlotlyPlots';

const Plotly = window.Plotly;

const excludedKeys = [
  'devid', 
  'vessel_id', 
  'tmoffset', 
  'iot_fw', 
  'version', 
  'telemetry_data.gnsslock',];

  // export const format_label = (label) => {
  //   try {
  //     // remove the data path from the label by replacing battery_data_ with ''
  //     if (label === null || label=== undefined) {
  //       return ''
  //     }
  //     return label.replace(/battery_data_|telemetry_data_|motor_data_\d|obc_data_\d|ecu_data_\d|sensor_data_\d|system_performance_data_\d/g, "").split(".")[label.split(".").length-1].replace(/_/g, " ");
  //   } catch (err) {
  //     return ''
  //   }
  // }

export default function Scatter3D({ plotKey, preset='surface', dataset, contentAreaHeight, ...props }) {
  const datasource = useDataSource((state)=>state.datasource);
  const plot3d = use3dPlot(s=>s.plot3d)[datasource]
  const data  = dataset.data;
  const vessel_id = useVessel(state=>state.vessel_id)
  const plotType = 'surface'  
  const plotContainerRef = useRef(null);

  const options = useMemo(()=>{
    if (!vessel_id || !data || data.length===0) return null
    console.log(data.slice(0,20))
    return  Object.keys(data[1])
      .filter((key) => {
          const isExcluded = excludedKeys.includes(key);
          return !isExcluded;
      })
      .map((key) => ({
          value: key,
          label: format_label(key)
      }));   
  }, [data, vessel_id])
  
  const plotData = useMemo(() => {
    if (plot3d===null || !vessel_id || data.length===0) {
      console.log("Conditions not met to create plotData")
      return null
    }
    let items = () => {
      try {

        let x=plot3d.x;
        let y=plot3d.y;
        let z=plot3d.z;
        let c=plot3d.c;

        return data.map(item => {
            const dataPoint = { 
              [x]: item[x],
              [y]: item[y],
              [z]: item[z],
              [c]: item[c]
            };
                
            return dataPoint;
        });
      }
      catch (err) {
          console.warn("Caught an error in generateData! datasource", datasource, "key", plotKey, 'sample x', data[0], err)
          return null
      }
    };

    items = items().filter(
      i=>(i[plot3d.x])
    );
    
    const  scl = [
      [0, 'rgb(150,0,90)'],
      [0.125, 'rgb(0, 0, 200)'],
      [0.25,'rgb(0, 25, 255)'],
      [0.375,'rgb(0, 152, 255)'],
      [0.5,'rgb(44, 255, 150)'],
      [0.625,'rgb(151, 255, 0)'],
      [0.75,'rgb(255, 234, 0)'],
      [0.875,'rgb(255, 111, 0)'],
      [1,'rgb(255, 0, 0)']
  ];

    const trace = {
      x: items?.map((item) => Math.round(item[plot3d.x]*1000)/1000),
      y: items?.map((item) => Math.round(item[plot3d.y]*1000)/1000),
      z: items?.map((item) => Math.round(item[plot3d.z]*1000)/1000),
      name: 'scatter 3d',
      type:  'scatter3d',
      mode: 'markers',
      yAxis: 'y1',
      marker:{
        color: items?.map((item) => item[plot3d.c]),
        colorscale: scl,
        size: 12,
        line: {
          color: 'rgba(21, 21, 21, 1)',
          width: 4
        },
        opacity: 1
      },
      text: items?.map((item) => {
        const xLbl = format_label(plot3d.x);
        const yLbl = format_label(plot3d.y);
        const zLbl = format_label(plot3d.z);
        return `${xLbl}:${item[plot3d.x]}<br>${yLbl}:${item[plot3d.y]}<br>${zLbl}:${item[plot3d.z]}`;
      })
    };

    console.groupCollapsed('3d scatter plot trace');
    console.table(trace)
    console.groupEnd();
    
    return [trace]
  }, [preset, plot3d, plotType])

  useEffect(() => {
    // Render the plot into the div with a ref attached
    const layout={
      xaxis: { title: format_label(plot3d.x)},
      yaxis: { title: format_label(plot3d.y)},
      zaxis: { title: format_label(plot3d.z)},
      editable: true,
      hovermode: "x-unified",
      hoverinfo: 'x+y',
      color:'rgb(229, 245, 245, 0)',
      paper_bgcolor: 'rgb(229, 245, 245, 0)',
      plot_bgcolor: '#fff',
      fillpattern: {bgcolor:'rgb(229, 245, 245, 0)', fillmode:'overlay'},
      margin: {l:4, r:4, t:4, b:4},
      legend: {
        x: 0.225,
        y: 1.4,
        xanchor: "right",
        yanchor: "top",
        bgcolor: "rgba(255, 255, 0, 0.03)",
        bordercolor: "rgba(0, 0, 0, 0.02)",
        borderwidth: 1,
      }, 
      // width: 1200,
      height: contentAreaHeight*.85,
      responsive: true  
    }

    const config = {
      responsive: true, 
      modeBarButtonsToRemove: [
        'hoverClosestGl2d', 
        'hoverCompareCartesian '
      ]
    }

    Plotly.newPlot(plotContainerRef.current, plotData, layout, config);
    

    // Optional: Return a cleanup function to purge the plot when the component unmounts
    return () => {
      try {
        Plotly.purge(plotContainerRef.current);
      } catch (err) {
        // console.warn("Caught an error in PlotlyPlots.jsx while purging plot", err)
      }
    };
  }, [plotData]); // Redraw the plot if data or layout props change

  return (
    <div style={{ width: '98%', height: "98%" }} >
      
      <div className='surfacePlot-buttons' style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
        <PlotSelector axis='x' options={options}/>
        <PlotSelector axis='y' options={options}/>
        <PlotSelector axis='z' options={options}/>
        <PlotSelector axis='c' options={options}/>
      </div>

      <div style={{ width: "90%", height: "90%", marginLeft:'auto', marginRight:'auto', paddingBottom:'20px', borderRadius:'3px'}}>
        { plot3d && data.length>0
        ? <div ref={plotContainerRef} />
        : <div style={{height: "300px"}}></div>
        }
      </div>
      
    </div>
  )
}

const PlotSelector = ({axis, options})=>{
  const datasource = useDataSource((state)=>state.datasource);
  const plot3d = use3dPlot(s=>s.plot3d)[datasource]
  const updatePlot3d = use3dPlot(s=>s.updatePlot3d)  
  
  function handlePlotSelectors(newAxis) {
    const newPlotDef = {
      datasource: datasource,
      x: newAxis.x,
      y: newAxis.y, 
      z: newAxis.z,
      c: newAxis.c
    }
    updatePlot3d(newPlotDef, datasource)
  }

  const handleAxisChange = (axis, selectedOption) => {
    const newAxis = {...plot3d, [axis]:selectedOption.value};
    handlePlotSelectors(newAxis);
  };

  return (
    <div style={{ height: "100%", marginLeft:'auto', justifyContent: 'center', alignItems: 'center', marginRight:'auto', display:'flex', flexDirection:'column', marginBottom:'2px' }}>
      <label style={{marginInlineEnd:'20px', marginTop:'2px'}}>{axis.toUpperCase()} Axis:</label>
      <Select
        options={options}
        onChange={(event)=>handleAxisChange(axis, event)}
        value={{ value: plot3d[axis], label: datasource=='csv'?plot3d[axis]:format_label(plot3d[axis]) }}
      />
    </div>
  )
}