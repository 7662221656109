import { useVessel } from 'app/zustore';
import { Outlet, createFileRoute, createRoute, useNavigate } from '@tanstack/react-router'
import { TripSelector } from 'trips/TripSelector';

export const Route = createFileRoute('/_authenticated/trips/select')({
  // Or in a component
  component: SelectTripsPage,
})

function SelectTripsPage({ contentAreaHeight }) {
  const setVessel = useVessel(s=>s.setVessel)

  setVessel(null, null)
  console.log('no vessel selected route')
  return (
    <div className='selector-trips'>
      <TripSelector />
    </div>
  );
}

const styles = {
  container: {
    width: '100%',
    height: '100vh',
    display: "flex",
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 0,
  },
  tabButtons: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
    width:'90%',
    justifySelf: 'center',
    borderRadius: '5px',
  },
  tabButtonStyle: {
    padding: '10px 20px',
    border: 'none',
    cursor: 'pointer',
    width: '100%',
    backgroundColor: '#929292',
    outline: 'none',
    fontSize: '16px',
    transition: 'background-color 0.3s',
  },
  activeTabButtonStyle: {
    backgroundColor: '#007bff',
    color: '#fff',
    width: '100%',
  },  
  tabContentStyle: {
    position:'absolute',
    right:0,
    top:0,
    width: '80vw',
    display:'flex',
  },
  tabContentStyleMobile: {
    width: '100vw'
  },
  returnBtn: {
    width:'80%', 
    height:'50px', 
    backgroundColor:'#929292', 
    borderRadius:'5px', 
    justififySelf:'center', 
    alignSelf:'center', 
    display:'flex',
    justifyContent: 'center',
    marginBottom:'20px',
    boxShadow: '0px 0px 5px #000'
  },
  sideBarStyle: { 
    display: 'flex', 
    flexDirection: 'column', 
    width: '20%', 
    minWidth:'300px', 
    height:'100vh', 
    maxHeight: '300vh', 
    paddingBottom:'30px', 
    paddingTop:'10px', 
    backgroundColor: '#404040', 
  },
  sideBarStyleMobile: {
    width: '90vw',
  }
} 