import { useLocation, useNavigate } from "@tanstack/react-router";
import { useDataSource, useNumPlots, useSelect, useVessel, useVesselList } from "../app/zustore";
import { Grid, Card, CardContent, MenuItem, Select, Button } from '@mui/material';
import { useEffect } from "react";

export const VesselSelector = ()=>{
    const { selected, setSelected } = useSelect();
    const vessel_id = useVessel((state) => state.vessel_id);
    const vesselList = useVesselList((state) => state.vesselList);
    const setVessel = useVessel(state=>state.setVessel);
    const setNumPlots = useNumPlots(state=>state.setNumPlots);
    const setDataSource = useDataSource(state=>state.setDataSource);
    const navigate = useNavigate()
    const location = useLocation();

    useEffect(() => {
        console.log(location)
        setSelected(vesselList.findIndex(v=>v.vessel_id===vessel_id))
    }, []);
    
    const handleSelect = (event) => {
        console.groupCollapsed("handleSelect")
        console.log(location)
        console.log(event.target.value)
        console.log(vesselList)
        const vessel = vesselList[event.target.value];
        console.log(vessel)
        setSelected(event.target.value);
        setVessel(vessel.vessel_id, vessel.vessel_name);
        if (vessel.isParticleDevice) {
            console.log('This is a particle device')
            setNumPlots(2)
            setDataSource('bud')
        }
        const pathParts = location.pathname.split('/');
        const subPath = pathParts.length>3 ? pathParts[pathParts.length-1] : ''
        console.log(pathParts)
        console.log(subPath)
        console.groupEnd()
        navigate({
            to: location.pathname, 
            search:{vessel_id: vessel.vessel_id},
        })
        
    }
    if (!vesselList || vesselList.length==0 || Object.keys(vesselList).length==0){
        return null
    }

    return (
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selected}
            label="Select Vessel"
            onChange={handleSelect}
            style={{maxWidth:"200px", backgroundColor:'aliceblue', minWidth:'100px', width:'80%'}}>
            {vesselList
                .sort((a, b) => {
                    const nameA = a.vessel_name != null ? a.vessel_name : a.vessel_id;
                    const nameB = b.vessel_name != null ? b.vessel_name : b.vessel_id;
                    return nameA.localeCompare(nameB);
                })
                .map((boat, index) => (
                    <MenuItem value={index} key={index}>{boat.vessel_name!=null?boat.vessel_name:boat.vessel_id}</MenuItem>
                ))
            }                
        </Select>
    )
}