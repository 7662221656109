import { color } from 'echarts';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { format_label } from './PlotlyPlots';
// import Plotly from 'plotly.js-gl2d-dist-min'
const Plotly = window.Plotly;


export default function FaultCountBar({ faultCounts, faultCodeDefs}) {
  const plotContainerRef = useRef(null);
  const [plotRendered, setPlotRendered] = useState(false);
  const [zoom, setZoom] = useState(12);

  const plotData = useMemo(() => {
    if ( !faultCounts || faultCounts.length===0) {
      console.log("Conditions not met to create plotData")
      return null
    } 

    let faultCodes = Object.keys(faultCounts);
    let counts = Object.values(faultCounts);
    
    // Plotting with Plotly.js
    // console.log(faultCounts)
    const traces = Object.entries(faultCounts).map((fault, index)=>({
      x: [fault[1]],
      y: [`${fault[0]}-${faultCodeDefs[fault[0]]?.name}`],
      name: faultCodeDefs[fault[0]]?.name,
      type: 'bar',
      orientation: 'h',
      hoverinfo: faultCodeDefs[fault[0]]?.desc,
      // width: .1,
      text: faultCodeDefs[fault[0]]?.name,
    }))
    // console.log(traces)

    var trace = {
      x: counts,
      y: faultCodes,
      color: faultCodes.map(f=>`${faultCodeDefs[f]?.name}`),
      type: 'bar',
      orientation: 'h',
      hoverinfo: 'none',
      textposition: 'none',
      width: 20,
      // text: faultCodes.map(f=>`${faultCodeDefs[f]?.name}`),      
    };
    
    return traces
  }, [faultCounts])

  useEffect(() => {
    // console.log('plot data changed, updating plot', plotData)
    // Render the plot into the div with a ref attached
    const layout={
        title: 'Fault Code Counts',
        xaxis: {
            title: 'Count',
            color:'white',
            // Ensure fault codes are treated as discrete categories
          },
          yaxis: {
            color:"white",
            // title: 'Fault Code',
            // type: 'category'
        },
        hovermode: false,
        color:'white',
        paper_bgcolor: 'rgba(200, 200, 200, 0.05)',
        plot_bgcolor: 'rgba(200, 200, 200, 0.05)',
        autosize: true,
        bargap:15,
        automargin:true,
        
        fillpattern: {bgcolor:'white', fillmode:'overlay'},
        margin: {l:250, r:40, t:45,b:40},
        showlegend: false,
        font: {
          color: 'white',
          family: 'Inter'
        }, 
        // width: 450,
        height: 250,
      responsive: true
    }

    const config = {
      responsive: true, 
      displayModeBar: false,
      modeBarButtonsToRemove: [
        'hoverClosestGl2d', 
        'hoverCompareCartesian '
      ]
    }
    // console.log(plotContainerRef.current)
    if (!plotRendered){
      console.log('rendering new preset plot')
      Plotly.newPlot(plotContainerRef.current, plotData, layout, config);
      setPlotRendered(true);
    } else {
      Plotly.react(plotContainerRef.current, plotData, layout, config);
    }

  }, [plotData, zoom]); // Redraw the plot if data or layout props change

  useEffect(()=>{
    return () => {
      if (plotContainerRef.current){
        try {
            console.log('purging preset plot')
            Plotly.purge(plotContainerRef.current);
            setPlotRendered(false)
        } catch (err) {
            console.warn("Caught an error in PlotlyPlots.jsx while purging plot", err)
        }
      } else {
        console.log('no div ref to unmount against - big bad')
      }
    };
  }, [])

  return (
    <div style={{ width: '100%', height: "100%", display: 'flex', flexDirection: 'column', justifyContent:'center', alignItems:'center' }} >

      <div style={{ width: "100%", height: "100%", padding:'4px',  }}>
          <div>
            <div ref={plotContainerRef} id={`fault-code-bar-plot-div`} style={{position:'relative', borderRadius:'20px'}}></div>
          </div>
  
      </div>
      
    </div>
  );
}