import React from 'react';
import ReactECharts from 'echarts-for-react';
import { graphic } from 'echarts'
import { useMediaQuery } from "@mui/material";

export function Plot2d({ items, xAxisKey, yAxisKey, chartType='line' }) {
  const isMobile = useMediaQuery('(max-width:600px)');

  if (!xAxisKey || !yAxisKey) {
    return null;
  } else {
  return (
    <ReactECharts
      className=''
      option={{
        textStyle: {color: '#A0ABC0'},
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: items.map((item) => item[xAxisKey]),
          axisLine: {lineStyle: {color:'#4A5468aa'}},
          axisLabel: {
            rotate: 45,  // rotate labels 45 degrees
            formatter: function (value) {
              // assuming the format is "month/day/year hour:minute:second"
              const [, time] = value.split(' ');  // get the time portion
              return `${time}`;  // return only the time
            },
          },
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {color:'#4A5468aa'}
          }
        },
        grid: {
          left: '20%',  // adjust this value to create more space for the y-axis labels
          borderColor: '#A0ABC0'
        },
        tooltip: {
          trigger: 'axis',
          position: function (point, params, dom, rect, size) {
            // position the tooltip in the center of the chart horizontally and near the top vertically
            return [size.viewSize[0] / 2 - size.contentSize[0] / 2, size.viewSize[1] / 7 - size.contentSize[1] / 2];
          },
          textStyle: {
            fontSize: 10
          }
        },                                                         
        toolbox: {
          feature: {
            dataView: { show: false, readOnly: false },
            restore: { show: false },
            saveAsImage: { show: false }
          }
        },
        series: [
          {
            data: items.map((item) => Math.round(item[yAxisKey]*100)/100),
            type: 'line',
            symbol: 'none',
            markLine: {
              silent: true,
            },
            lineStyle: {
              color: '#00FFFE'
            },
            areaStyle: {
              color: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#00FFFE'
                },
                {
                  offset: 1,
                  color: '#CBD2E0aa'
                }]),
              opacity: 0.5}
          },
        ],
      }}          
      style={{ height: isMobile ? '200px' : '220px' }}
    />
    );
  }
}
