// src/routes/login/index.jsx
import { useEffect, useState } from 'react';
import ForgotPassword from '../app/ForgotPassword';
import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PublishIcon from '@mui/icons-material/Publish';
import { signUp, signIn, confirmSignUp, resetPassword } from '@aws-amplify/auth';
import { Link, Navigate, Outlet, createFileRoute, useLocation, useNavigate } from '@tanstack/react-router';
import { useAuth } from '../app/zustore';
import { checkIsAuthenticated } from '.';

export const Route = createFileRoute('/_login')({
  component: MyAuth
});

const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
const fallback = '/home' as const

function MyAuth({ onSignIn }){
    const isAuthenticated = useAuth(s=>s.isAuthenticated);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
      async function checkAuth() {
        const authStatus = await checkIsAuthenticated();
        console.log('isAuthenticated?', authStatus)
        // if (location?.search?.redirect){
        //   console.log('found redirct in URL, redirecting after logging in')
        //   navigate({ to:location.search.redirect})  
        // } else {
        //   navigate({ to: '/' });
        // }
      }
      checkAuth();
      
    }, []);

    useEffect(() => {
      console.log(location)
      if (isAuthenticated) {
        const redirectPath = location?.search?.redirect || '/';
      
        // // Regular expression to match a UUID4 pattern
        // const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

        // const pathParts = redirectPath.split('/');
        // const vesselId = pathParts[1];
        // const subPath = pathParts.length > 2 ? pathParts.slice(2).join('/') : '';
        if (Object.keys(location.search).length>0) {
          console.log('Found search params in the _login Component')
          if (redirectPath!=='/'){
            console.log('Found a redirect when logging in:', location, redirectPath)
            navigate({ to: redirectPath });
          } else if (Object.keys(location.search).find(k=>k=='devid')){
            if (location.search?.devid=='DEVID_NOT_FOUND'){
              console.log('Your Garmin could not access your boats devid!')
            } else {
              console.log('Found a devid in the index route, redirecting to /home with the search param')
              navigate({to:'/home', search:{devid: location.search?.devid}})
            }
          }
        } else {
          console.log('Did not find a redirect when logging in:', location, redirectPath)
          navigate({ to: '/' });
        }
      }
    }, [isAuthenticated, location]);

    const isSignIn = location.pathname === '/signIn';
    const isSignUp = location.pathname === '/signUp';

    const handleSignIn = () => {
      onSignIn();
    };
  
    // const ScreenState = ()=>{
    //   switch (formType) {
    //     case "SignIn": return (
    //       <SignIn onSignIn={handleSignIn} setFormType={setFormType} />
    //     )
    //     case "SignUp": return (
    //       <SignUp onSignIn={handleSignIn} setFormType={setFormType} />
    //     )
    //     case "ForgotPassword": return (
    //       <ForgotPassword setFormType={setFormType}/>
  
    //     )
    //     default: return (<SignIn onSignIn={handleSignIn} setFormType={setFormType} />
  
    //     )
    //   }
    // }
  
    return (
        <div style={{alignItems:"center", height:"100vh", display:'flex', flexDirection:'column', justifyContent:'flex-start'}}>
            <Box className='Auth-state-selector-box' >
                <Link className={`Auth-state-selector ${isSignIn?'selected':""}`} to="/signIn">Sign In</Link>
                <Link className={`Auth-state-selector ${isSignUp?'selected':""}`} to="/signUp">Sign Up</Link>
            </Box>
  
            <Box className='Auth-content-box'>
              <Outlet />
            </Box>
  
        </div>
    )
}