import { useState, useEffect, useMemo } from "react";
import { fetchData } from "../../app/QueryHandler";
import { useAuth, useGroups, useUser } from "../../app/zustore";
import { Vessel } from "../../Types/vessel";
import { TreeView, TreeItem } from '@mui/lab';
import { Checkbox, TextField, Button, Box, Typography, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PublishIcon from '@mui/icons-material/Publish';
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";

import { useTreeViewApiRef } from '@mui/x-tree-view/hooks';

export const VesselTreeUpdater = ({ variant, selectedFields, setSelectedFields }) => {
    const user = useUser(state => state.user);
    const groups = useGroups((state) => state.groups)
    const [expandedItems, setExpandedItems] = useState(Vessel)
    const [selectedTreeNodes, setSelectedTreeNodes] = useState([...selectedFields]);
    const apiRef = useTreeViewApiRef();

    // useEffect(() => {
    //     if (initial_selection) {
    //         setSelectedTreeNode(initial_selection);
    //     }
    // }, [initial_selection]);
 
    const mapChildren = (nodeId) => {
        console.log('recursing with mapChildren', nodeId)
        const node = groups.find(group => group.node_id === nodeId);
        if (!node) {
            console.log("Could not find node:", nodeId)
            return null;
        };

        // Recursively build the children nodes
        const children = node.child_node_ids ? Object.values(node.child_node_ids).map(childNode => mapChildren(childNode)) : null;

        // Aggregate vessel IDs from the current node and its children
        const vesselIds = new Set(node.vessel_ids || []);
        if (children) {children.forEach(child => {if (child) {child.vessels.forEach(vesselId => vesselIds.add(vesselId));}});}

        return {
            id: node.node_id,
            name: node.node_name,
            label: node.node_name,
            vessels: Array.from(vesselIds),
            members: node.members ? node.members : [],
            can_write: node.can_write,
            children: children,
            parentId: node.parent_node_id,
            parentName: groups.find(g=>g.node_id==node.parent_node_id)?.node_name || null,
            can_write: node.can_write
        };
    };        

    const data = useMemo(() => {
        console.groupCollapsed('FleetManagement useMemo - data');
        if (!groups) {
            console.log("No groups found");
            return null;
        }
        const roots = Object.keys(user?.nodes).map(n=>groups.find(g=>g.node_id==n)) || [null];
        console.log("found root", user?.nodes, roots)
        if (!roots) {
            console.log("Could not find root node");
            return null;
        }
        const mapped_data = roots.map(root=>mapChildren(root.node_id));
        const nodeData = groups.find(group => group.node_id === mapped_data.id);
        // setSelectedGroup(nodeData);
        // setSelectedNode(mapped_data[0]);
        console.log(mapped_data)
        console.groupEnd();
        return mapped_data;
    }, [Vessel]);

    const handleExpandedItemsChange = (event, newExpandedItem, isExpanded) => {
        console.log('Expanded items changed:', newExpandedItem);
        if (isExpanded) {
            setExpandedItems([...expandedItems, newExpandedItem]);
        } else {
            if (!Object.keys(user.nodes).find(g=>g==newExpandedItem)){
            setExpandedItems(expandedItems.filter(item => item !== newExpandedItem));}
        }
        // setExpandedItems([...expandedItems, newExpandedItem] || Object.keys(user.nodes));
    };

    const handleSelectedItemsChange = (event, itemId) => {
        if (itemId != null) {
          setSelectedFields([...selectedFields, apiRef.current.getItem(itemId)]);
        }
      };

    const getItemsWithChildren = (itemIds, apiRef) => {
        const getItemsRecursively = (item) => {
            console.log(item)
            let items = [item];
            if (item.children) {
                for (const childId of item?.children) {
                    const childItem = apiRef.current.getItem(childId.id);
                    items = items.concat(getItemsRecursively(childItem));
                }
            }
            return items;
        };
    
        return itemIds
            .filter(i => i !== undefined)
            .map(i => apiRef.current.getItem(i))
            .flatMap(getItemsRecursively)
            .sort((fieldA, fieldB) => fieldA.rank - fieldB.rank);
    };

    return (
        <div className={`VesselTreeview-div`} style={{ 
            
            }}>
            <RichTreeView
                aria-label="file system navigator"
                className={`Fleet-Treeview ${variant}`}
                expandedItems={expandedItems}
                apiRef={apiRef}
                // onExpandedItemsChange={handleExpandedItemsChange}
                
                selectedItems={selectedTreeNodes}
                onItemExpansionToggle={handleExpandedItemsChange}
                multiSelect={true}
                checkboxSelection={true}
                sx={{
                    paddingTop: '2px',
                    paddingRight: '2px',
                    paddingBottom: '2px',
                    maxHeight: '80vh',
                    
                }}
                onSelectedItemsChange={(event, itemIds) => {
                    
                    console.groupCollapsed('selecting a new node in the Tree Viewer', itemIds)
                    console.log(event);
                    // const nodeDataSubTree = mapChildren(itemIds)
                    // console.log('new Node selected in Fleet Tree View', nodeDataSubTree)
                    const items = getItemsWithChildren(itemIds, apiRef);
                    console.log(items)
                    setSelectedTreeNodes(itemIds)
                    setSelectedFields(items)
                    console.groupEnd();
                    // setSelectedNode(nodeDataSubTree);
                    // setSelectedGroup(nodeData);
                    // setSelectedTreeNode([itemIds])
                }}
                items={Vessel} 
                />
            {/* >
                <RenderTree data={data} hideIcons={hideIcons} setSelectedNode={setSelectedNode} isSideBar={isSideBar}/>
            </RichTreeView> */}
        </div>
    );
}

const styles = {
    modal: { 
        position: 'fixed', top: '15%', left: '7%', width: '86%', 
        height: '70%', borderRadius: "2px", justifyContent: "center", 
        backgroundColor: "#3d5ea5", marginLeft: "auto", 
        marginRight: "auto", padding: "0px", boxShadow: 'rgb(0 0 0) 15px 15px 1px 5px',
    },
    box: {
        top: '50%',
        maxHeight: '70%',
        left: '50%',
        alignSelf: 'center',
        width: '100%',
        bgcolor: '#e1e1e1',
        border: '3px solid #6c7982',
        borderRadius: '15px',
        overflowY: 'hide',
        display: 'grid',
        gridTemplateRows: 'auto 1fr auto',
        gridTemplateColumns: '1fr',
        gridTemplateAreas: `
          "header"
          "content" 
          "footer"
        `,
        pt: 1,
    },
    InnerBox: {
        position: 'relative',
        gridArea: 'content',
        height: '100%',
        width: "100%",
        bgcolor: '#e1e1e1',
        overflowY: 'scroll',
        borderTopStyle: 'double',
        borderBottomStyle: 'double',
        WebkitOverflowScrolling: 'touch',
        mb: 3,
    },
    buttonContainer: {
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'space-around',
        alignItems: 'flex-end',
        gridArea: 'footer',
        paddingTop: '10px',
    },
    modalTitle: {
        marginLeft: '5px', display: 'block',
        marginBottom: '15px',
        gridArea: 'header',
        width: '100%',
        height: '100%',
        textAlign: 'left',
        fontSize: 'calc(1.5vw + 2vh)',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        position: 'relative',
    },
    button: {
        margin: '10px auto 10px auto', 
        height: '80%', 
        maxWidth: '45%',
        color: '#00b9b8',
    }
};
