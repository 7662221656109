import { useEffect, useState } from 'react';
import { fetchData } from '../../../app/QueryHandler';
import PublishIcon from '@mui/icons-material/Publish';
import { enqueueSnackbar } from 'notistack';
import { FleetTreeView } from '../../../fleet/FleetTreeView';
import { createFileRoute } from '@tanstack/react-router';
import { useAuth, useGroups, useSelectedNodeStore } from '../../../app/zustore';

export const Route = createFileRoute('/_authenticated/groups/delete')({
    component: DeleteGroup
  })

export function DeleteGroup ({ initial_node }) {
    const {groups, updateGroups} = useGroups((state) => ({ groups: state.groups, updateGroups:state.updateGroups}))
    const token = useAuth(state=>state.token)
    const {selectedNode, setSelectedNode} = useSelectedNodeStore();

    useEffect(() => {
        console.log(selectedNode)
    }, [selectedNode]);
        
    const handleDeleteGroup = async () => {
        console.groupCollapsed('Delete Group handleSubmit');
        // setStatus('loading')
        let params = {
            node_id:selectedNode?.id
        };
    
        console.groupEnd();
        if (window.confirm(`Are you sure you want to delete ${selectedNode?.name}?`)){
            const {status, response} = await fetchData({method:'DELETE', resource:'nodes', token:token, params:params})
            if (status==400){
                enqueueSnackbar('Failed to create Group', {variant: 'error'})
            } else if (status==201||status==200){
                enqueueSnackbar('Group deleted', {variant: 'success'})
                updateGroups(response)
                
            }
            console.table(response)
        }
    }

    const button_disabled = ()=>{
        if (
            !selectedNode
            || selectedNode?.children
            || !selectedNode?.can_write
        ) return true;
        else {
            return false;
        }
      }

    const header = () => {
        if (!selectedNode) return <h1>Delete a group</h1>
        else return <h1>{`Delete ${selectedNode?.name}?`}</h1>
    }

    return (
        <div className='manage-page-1-col' >
            {header()}
            {selectedNode?.children && <h3 style={{color:'var(--red-alt)'}}>Group must have no subgroups</h3>}
                
                <FleetTreeView setSelectedNode={setSelectedNode} initial_selection={initial_node}/>
                
                <button 
                    className='confirm'
                    disabled={button_disabled()}
                    onClick={()=>{handleDeleteGroup()}}     
                    endIcon={<PublishIcon />}>
                        Delete
                </button>
            </div>
    )
}