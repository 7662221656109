export enum UserSignInResult {
    Success,
    NotFound,
    NotConfirmed,
}

export enum ScreenState {
    None,
    Group,
    NewGroup,
    ExistingGroup,
    VesselInfo,
    AddAnother,
  }

export enum ModalTypes {
    None,
    Update,
    Filter,
    AddVessel,
    UploadFile,
    InviteMember,
    NewGroup,
    PlotCustomizer,
    ExportPresets,
    ImportPresets,
    ManagePresets,
    DatasetLoader
}

export enum DatasourceTypes {
  None,
  Absolute,
  Relative,
  Trips,
  CSV,
  S3
}

export enum ManageTabs {
  None,
  New,
  Move,
  Delete,
  Rename,
  Edit,
}