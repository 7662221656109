import { createFileRoute, useLocation } from '@tanstack/react-router'
import { Expander } from '../../../fleet/Expander'
import { FleetTripViewer } from '../../../fleet/FleetTripViewer'
import { useMediaQuery } from '@mui/material';
import { useAuth, useLatestTrips, useSelectedNodeStore, useUser, useVesselList } from '../../../app/zustore';
import React, { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { fetchData } from '../../../app/QueryHandler';
import { TripOverview } from '../../../trips/trip-overview';
import { useInterval } from '../../../hooks/interval';

export const Route = createFileRoute('/_authenticated/trips/')({
  component: TripsScroller
})

function TripsScroller(){
  const {latestTrips, updateLatestTrips} = useLatestTrips()
  const user = useUser(s=>s.user)
  const token = useAuth((state)=>state.token);
  const isMobile = useMediaQuery('(max-width:600px)');
  const vesselList = useVesselList((state) => (state.vesselList));
  const {selectedNode, setSelectedNode} = useSelectedNodeStore();
  const location = useLocation();
  const [baseIndex, setBaseIndex] = useState(0)

  const incrementBaseIndex = ()=>{
    setBaseIndex(baseIndex+1)
  }

  useInterval(incrementBaseIndex, 5000)  

  const vesselsOfNode = useMemo(() => {
    if (selectedNode!==null && selectedNode?.vessels && vesselList) {
      // console.log('VesselsOfNode', selectedNode, vesselList)
      let vesselListSubset = Object.values(selectedNode?.vessels)
        .map(
            (vessel_id) => {
                const vessel = vesselList.find((vessel) => vessel?.vessel_id === vessel_id)
                if (vessel) return vessel
            }
        ).filter((vessel) => vessel !== undefined)
        // console.log(vesselListSubset)
        return vesselListSubset.filter((vessel) => vessel !== undefined)
    }
    // console.log(selectedNode, vesselList)
    return -1
  }, [selectedNode, vesselList, location])

  

    return (
    <div className='trip-scroller'>
      {latestTrips && latestTrips.sort((a,b)=>b.start_time-a.start_time).map((trip, index)=>(
        <TripOverview trip={trip} key={index} initial_index={index} base_index={baseIndex}/>
      ))}
    </div>
    )
}