import React from 'react';
import ReactDOM from 'react-dom/client';
import './app/styles.css'
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import AuthenticatedApp from './AuthenticatedApp';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { SnackbarProvider, MaterialDesignContent } from 'notistack';
import { styled } from '@mui/material';
import Invite  from './app/InviteSnackBar'
import QueryInfo from './app/QueryInfo';
import { RouterProvider, createRouter } from '@tanstack/react-router'
import { subscribeUser } from './app/push-notifications'

import config from './aws-exports';
import testconfig from './aws-exports-test';  
import devconfig from './aws-exports-dev';  

// Import the generated route tree
import { routeTree } from './routeTree.gen'
import { LoadingBar } from './loadingBar/loadingBar';

// Create a new router instance
const router = createRouter({ 
  routeTree,
  context: {
    auth: undefined,
  },
  defaultPendingComponent: ()=><LoadingBar className={"default-pending-component"} />
})

const env = process.env.REACT_APP_ENV;
console.log(env)
if (env==='test'){
  Amplify.configure(testconfig);
  console.log('test config')
} else if (env==='dev'){
  Amplify.configure(devconfig);
  console.log('dev config')
} else {
  Amplify.configure(config);
}

const queryClient = new QueryClient()

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js')
    .then(registration => {
      console.log('Service Worker Registered', registration);
    })
    .catch(error => {
      console.log('Service Worker Registration Failed', error);
    });
}



const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: '#2D7738',
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#970C0C',
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: '#00B9B8',
    color: 'black'
  },
}));

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>    
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider 
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        maxSnack={4}
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
          info: StyledMaterialDesignContent,
          Invite: Invite,
          QueryInfo: QueryInfo
        }}
        iconVariant={{info:'🚤'}}
        >

        {/* <AuthenticatedApp />  */}
        <RouterProvider router={router} />

        <ReactQueryDevtools initialIsOpen={false} />
      </SnackbarProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
