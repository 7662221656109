import React, { useEffect } from 'react';
import VesselOverview from './VesselCard/VesselOverview';
import { Divider, Grid } from '@mui/material';
import { LoadingBar } from '../loadingBar/loadingBar';
import { useMotorFilter, useSort } from '../app/zustore';

export default function FleetGrid({ vesselList }) {
  const {sortAlgo, setSortAlgo} = useSort();
  const {motorFilter, setMotorFilter} = useMotorFilter();

  useEffect(() => {
    // console.log('FleetGrid uef', vesselList)
  }, []);

  function SortVesselCards(a,b){
    switch (sortAlgo) {
      case 'alpha': {
        if (a.vessel_name < b.vessel_name) {
          return -1;
        }
        if (a.vessel_name > b.vessel_name) {
          return 1;
        }
        return 0;
      }
      case 'rankedAlpha': {
        if (b.status.priority === a.status.priority){
          if (a.vessel_name < b.vessel_name) {
            return -1;
          }
          if (a.vessel_name > b.vessel_name) {
            return 1;
          }
          return 0;
          // return Date.parse(b.latest_message) - Date.parse(a.latest_message)
        }
        return b.status.priority - a.status.priority
      }
      case 'rankedTime': {
        if (b.status.priority === a.status.priority){
          return Date.parse(b.latest_message) - Date.parse(a.latest_message)
          // return Date.parse(b.latest_message) - Date.parse(a.latest_message)
        }
        return b.status.priority - a.status.priority
      }
      case 'time': {
        return Date.parse(b.latest_message) - Date.parse(a.latest_message)
      }
    }
  }

  function filterVesselCards(vessel){
    if (motorFilter=='All'){
      return true
    } else if (motorFilter=='ICE' && vessel.isParticleDevice){
      return true
    } else if (motorFilter=='V3.5' && String(vessel?.outboards?.[0]?.model).toUpperCase()?.slice(0,8)!='FM100-V4' && !vessel.isParticleDevice){
      return true
    } else if (motorFilter=='V4' && String(vessel?.outboards?.[0]?.model).toUpperCase()?.slice(0,8)=='FM100-V4'){
      return true
    }
  }

  if (vesselList.length>0){
    return (
      <div className='fleet-grid-outer'>
        <div className='fleet-grid-info-box' >
          <div className='fleet-grid-sort-box'>
            <a className='header'>Sorting Method</a>
            <div className='filter-btn-div'>
              <button onClick={()=>{
                setSortAlgo('alpha')
                if (navigator.vibrate) {
                        navigator.vibrate(50);
                    }
                }}className={`fm-btn sort ${sortAlgo=='alpha'?'selected':''}`}><span>A-Z</span></button>
              <button onClick={()=>{
                setSortAlgo('rankedAlpha')
                if (navigator.vibrate) {
                        navigator.vibrate(50);
                    }
                }}className={`fm-btn sort ${sortAlgo=='rankedAlpha'?'selected':''}`}><span>Status, A-Z</span></button>
              <button onClick={()=>{
                setSortAlgo('time')
                if (navigator.vibrate) {
                        navigator.vibrate(50);
                    }
                }}className={`fm-btn sort ${sortAlgo=='time'?'selected':''}`}><span>Time</span></button>
              <button onClick={()=>{
                setSortAlgo('rankedTime')
                if (navigator.vibrate) {
                        navigator.vibrate(50);
                    }
                }}className={`fm-btn sort ${sortAlgo=='rankedTime'?'selected':''}`}><span>Status, time</span></button>
            </div>
          </div>

          <div className='fleet-grid-sort-box'>
            <a className='header'>Filter Motors</a>
            <div className='filter-btn-div'>
              <button onClick={()=>{
                setMotorFilter('All')
                if (navigator.vibrate) {
                        navigator.vibrate(50);
                    }
                }}className={`fm-btn sort ${motorFilter=='All'?'selected':''}`}><span>All</span></button>
              <button onClick={()=>{
                setMotorFilter('ICE')
                if (navigator.vibrate) {
                        navigator.vibrate(50);
                    }
                }}className={`fm-btn sort ${motorFilter=='ICE'?'selected':''}`}><span>ICE</span></button>
              <button onClick={()=>{
                setMotorFilter('V3.5')
                if (navigator.vibrate) {
                        navigator.vibrate(50);
                    }
                }}className={`fm-btn sort ${motorFilter=='V3.5'?'selected':''}`}><span>V3.5</span></button>
              <button onClick={()=>{
                setMotorFilter('V4')
                if (navigator.vibrate) {
                        navigator.vibrate(50);
                    }
                }}className={`fm-btn sort ${motorFilter=='V4'?'selected':''}`}><span>V4</span></button>
            </div>
          </div>

        </div>
        
        <Grid container spacing={0} justifyContent="center" className='fleet-grid-inner'>
          {Object
            .values(vesselList)
            .filter(v=>v.latest_message!=null && filterVesselCards(v))
            .sort((a, b) => SortVesselCards(a,b))
            .map((vessel, index) => (

              <VesselOverview 
                key={index} 
                vessel={vessel} 
                index={index}
                isBud={vessel.isParticleDevice}
                style={styles.VesselOverview}
              />

          ))}
        </Grid> 
        
      </ div>
    )
  } else {
    console.log('Vessel List Empty!')
    return (
      null
    )
  }
}

const styles = {
  full: { height: '100%', width: '100%', display:'flex', flexDirection:'column', alignItems:'center' },
  VesselOverview: { height: '100%', width: '100%', minWidth:'250px', minHeight:'250px' }
}