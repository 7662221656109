import Box from '@mui/material/Box';
import { useResizeDetector } from 'react-resize-detector'
import { Grid, Card, CardContent, Button, useMediaQuery, Modal } from '@mui/material';
import { MobileDateTimePicker, DesktopDateTimePicker  } from "@mui/x-date-pickers";

export const AbsoluteTimePicker = ({ isMobile, startTime, endTime, setStartTime, setEndTime})=>{
    const boxStyle = {
        backgroundColor:'#ffffff33',
        display: 'flex', placeContent:'center', placeItems:'center', borderRadius:'8px',
        padding:'10px'
    };

    const labelStyle = {
        color: 'white', marginRight:'5px', marginLeft:'5px', fontSize:'larger'
    }

    return ( 
        <Box sx={{
            display: "flex", 
            flexDirection: isMobile?"column":'row',
            rowGap: isMobile?'6px':'2px',
            columnGap: isMobile?'2px':'8px',
            justifyContent: 'space-evenly',
            marginTop:'25px',
            width:'100%'
        }}>
            <div style={boxStyle}>
                <span style={labelStyle} >From</span>
                {
                    isMobile 
                    ?
                    <MobileDateTimePicker 
                        label="Data start time" value={startTime} 
                        style={{ width: "60%", fontSize:'4vh' }} onChange={(newValue) => setStartTime(newValue)} />
                    :
                    <DesktopDateTimePicker 
                        orientation='landscape' defaultValue={startTime} label="Data start time" 
                        views={['year', 'month', 'day', 'hours', 'minutes',]}
                        value={startTime} onChange={(newValue) => setStartTime(newValue)}/>
                }
                </div>
            <div style={boxStyle}>
                <span style={labelStyle} >Until</span>
                {
                    isMobile 
                    ?
                    <MobileDateTimePicker 
                        label="Data end time" value={endTime} 
                        style={{ width: "60%", fontSize:'4vh' }} onChange={(newValue) => setEndTime(newValue)} />
                    :
                    <DesktopDateTimePicker 
                        orientation='landscape' defaultValue={endTime} label="Data end time" 
                        views={['year', 'month', 'day', 'hours', 'minutes',]}
                        value={endTime} onChange={(newValue) => setEndTime(newValue)}/>
                }
            </div>
        </Box>
    )
}