import { useState, forwardRef, useCallback } from "react";
import clsx from "clsx";
import { useSnackbar, SnackbarContent, CustomContentProps, enqueueSnackbar } from "notistack";
import {Collapse, IconButton, Typography, Card, CardActions, Paper, Button} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/system';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from './zustore';
import { fetchData } from './QueryHandler';


interface InviteProps extends CustomContentProps {
  showExpand?: boolean;
  invites?: any
}


type invite = {
  node_name?: string,
  node_id: string,
  status?: string,
  invite_time?: string
} 

type entry = [
  id: string,
  invite: invite
]

const Invite = forwardRef<HTMLDivElement, InviteProps>(
  ({ id, showExpand, invites, ...props }, ref) => {
    const { closeSnackbar } = useSnackbar();
    const [expanded, setExpanded] = useState(false);
    const token = useAuth(state=>state.token)
    const queryClient = useQueryClient()

    const respondToInvite = useMutation({
      mutationFn: async (invite: invite) => {
        let {status, body}: any = await fetchData({method:'PUT', resource:'invites', token, body:invite, params:null});
        console.log("response from PUT invites", body)
        return body
      },
      onSuccess: () => {
        queryClient.invalidateQueries({queryKey: ['groups']})
        queryClient.invalidateQueries({queryKey: ['vessel-info']})
        enqueueSnackbar("New Vessel Added! 🚤🚤", {variant:'QueryInfo' as any})
      },
      onError: () => {
        enqueueSnackbar("Failed to respond to invite", {variant:'error'})
      }
    })
    
    // React.useEffect(()=>{console.log(invites)})
    
    
    const handleExpandClick = useCallback(() => {
      setExpanded((oldExpanded) => !oldExpanded);
    }, []);
    
    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    const handleInviteResponse = ((node_id:string, response:string)=>{
      const invite = {node_id: node_id, status:response}
      respondToInvite.mutate(invite)
    })
    
    return (
      <StyledSnackbarContent ref={ref}>
        <StyledCard>
          <StyledActionRoot>
            <StyledTypography variant="body2">
              {props.message}
            </StyledTypography>
            <StyledIcons>
              {showExpand ?
              <StyledExpand
              aria-label="Show more"
              size="small"
              expanded={expanded}
              onClick={handleExpandClick}
              >
                <ExpandMoreIcon />
              </StyledExpand>
              : null
            }
              <IconButton size="small" onClick={handleDismiss}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </StyledIcons>
          </StyledActionRoot>
          { showExpand && Object.values(invites).length>0 ?
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              {Object.entries(invites).map((entry: entry)=>{
                const node_id: string = entry[0];
                const invite: invite = entry[1];
                
                return (
                  <StyledPaper sx={{...{
                    display: 'flex',
                    flexDirection:'row',
                    alignContent:'center', 
                    justifyContent:'space-between', 
                    alignItems: 'center'
                  }}}>
                    <Typography
                      gutterBottom
                      variant="caption"
                      style={{ color: "#000", display: "block" }}
                      >
                      {invite.node_name}
                    </Typography>
                    <StyledButton size="small" color="primary" onClick={(event)=>handleInviteResponse(node_id, 'accepted')}>
                      <StyledCheckIcon />
                      Accept
                    </StyledButton>
                    <StyledButton size="small" sx={{color:'red'}} onClick={(event)=>handleInviteResponse(node_id, 'rejected')}>
                      <StyledCancelIcon />
                      Reject
                    </StyledButton>
                  </StyledPaper>
              )})}
              
            </Collapse>
          : null  
        }
        </StyledCard>
      </StyledSnackbarContent>
    );
  }
  );
  
  Invite.displayName = "Invite";
  
  const StyledSnackbarContent = styled(SnackbarContent)({
    "@media (min-width:600px)": {
      minWidth: "344px !important"
    }
  });
  
  const StyledCard = styled(Card)({
    width: "100%",
    backgroundColor: "#00b9b8"
  });
  
  const StyledTypography = styled(Typography)({
    color: "#000"
  });
  
  const StyledActionRoot = styled(CardActions)({
    padding: "8px 8px 8px 16px",
    justifyContent: "space-between"
  });
  
  const StyledIcons = styled('div')({
    marginLeft: "auto"
  });
  
  const StyledExpand = styled(IconButton)(({ expanded }: { expanded: boolean }) => ({
    padding: "8px 8px",
    transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
    color: "#000",
    transition: "all .2s"
  }));
  
  const StyledPaper = styled(Paper)({
    backgroundColor: "#fff",
    padding: 16
  });
  
  const StyledCheckIcon = styled(CheckCircleIcon)({
    fontSize: 20,
    paddingRight: 4
  });
  
  const StyledCancelIcon = styled(CancelIcon)({
    fontSize: 20,
    paddingRight: 4
  });
  
  const StyledButton = styled(Button)({
    padding: 0,
    textTransform: "none"
  });

  export default Invite;
  