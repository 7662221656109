import { useState, useCallback, useMemo } from "react";
import dayjs from 'dayjs';
import { Grid, Card, CardContent, Button, useMediaQuery, Modal } from '@mui/material';
import ErrorBoundary from "../../utils/ErrorBoundary";
import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "../../app/ag-grid-theme.css" // Theme
import { useAnalysisData, useDataSource, useNumPlots, useSelect, useVessel, useVesselList, useAuth, usePlots, useFetchStatus, useModal, useTrips} from "../../app/zustore";

export const TripSelector =( { isMobile, setStartTime, setEndTime } )=>{
    const vessel_id = useVessel(s=>s.vessel_id);
    const trips = useTrips(s=>s.trips)?.[vessel_id]
    const vessel = useVesselList(s=>s.vesselList).find(vessel => vessel.vessel_id === vessel_id);
    const [selectedTrip, setSelectedTrip] = useState(null)

    const [colDefs, setColDefs] = useState([
        {
            field: 'start_time_str', 
            headerName: 'Trip Start Time', 
            tooltipField: 'time', 
            minWidth: isMobile?180:220,
            checkboxSelection: true,
            headerTooltip:'Trip time Column',
            filter: 'agSetColumnFilter'
        }, 
        {
            field: 'end_time_str', 
            headerName: 'Trip End', 
            tooltipField: 'time', 
            minWidth: isMobile?180:220,
            headerTooltip:'Trip time Column',
            filter: 'agSetColumnFilter'
        }, 
        {
            field: 'duration_minutes', 
            headerName: isMobile?"T":'Duration (minutes)', 
            minWidth: 60,
            tooltipField: 'duration', 
            headerTooltip:'Duration (minutes)',
            filter: 'agNumberColumnFilter'
        }, 
        {
            field: 'trip_distance_mi', 
            headerName: isMobile?"D":'Distance (miles)', 
            minWidth: 60,
            headerTooltip:'Distance (miles)',
            filter: 'agNumberColumnFilter'
        }, 
        {
            field: 'kwh_usage_trip', 
            headerName: isMobile?"B":'Battery (kWh)', 
            minWidth: 60,
            tooltipField: 'kwh_usage_trip', 
            filter: 'agNumberColumnFilter'
        }, 
        {
            field: 'n_samples', 
            headerName: isMobile?"#":'# Messages', 
            minWidth: 60,
            tooltipField: '# Messages', 
            headerTooltip:'Number of rows in Trip',
            filter: 'agNumberColumnFilter'
        },
        {
            field: 'start_time', 
            headerName: 'start time', 
            tooltipField: 'start time', 
            minWidth: 0,
            hide: true,
            filter: 'agNumberColumnFilter'
        },
        {
            field: 'end_time', 
            headerName: 'end time', 
            tooltipField: 'end time', 
            minWidth: 0,
            hide: true,
            filter: 'agNumberColumnFilter'
        },
    ]);

    const defaultColDef = useMemo(() => {
        return {
          flex: 1,
          minWidth: 50,
          filter: 'agTextColumnFilter',
          menuTabs: ['filterMenuTab'],
        };
    }, []);

    const onRowSelected = useCallback((event) => {
        if (event.node.isSelected()){
            setStartTime(event.node.data.start_time)
            setEndTime(event.node.data.end_time)
        }
      }, []);

    if (trips && trips.length>0) {
        return(
        <div className={"ag-theme-alpine-dark trips-table"} >
                <ErrorBoundary boundaryName='Dataset Selector Table'>
                    <AgGridReact 
                    pagination={true}
                    rowHeight={30}
                    enableCellChangeFlash={true}
                    paginationPageSize={6}
                    paginationPageSizeSelector={[5,6,10]}
                    rowData={trips} 
                    // domLayout={'autoHeight'}
                    defaultColDef={defaultColDef}
                    onRowSelected={onRowSelected}
                    columnDefs={colDefs} />
                </ErrorBoundary>
        </div>
        )
    } else {
        return (
        <span>Loading available trips...</span>
        )
    }
}