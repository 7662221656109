import { useEffect, useState } from 'react';
import { fetchData } from 'app/QueryHandler';
import PublishIcon from '@mui/icons-material/Publish';
import { enqueueSnackbar } from 'notistack';
import { FleetTreeView } from 'fleet/FleetTreeView';
import { createFileRoute } from '@tanstack/react-router';
import { useAuth, useGroups, useVesselList } from 'app/zustore.js';

export const Route = createFileRoute('/_authenticated/vessels/manage/delete')({
    component: DeleteVessel
  })

export function DeleteVessel ({  }) {
    const vesselList = useVesselList(state=>state.vesselList);
    const { vessel_id } = Route.useParams()
    const vessel = vesselList.find(v=>v.vessel_id==vessel_id) || vesselList.find(v=>v.vessel_name==vessel_id)
    const vessel_name = vessel?.vessel_name || '';
    const {groups, updateGroups} = useGroups((state) => ({ groups: state.groups, updateGroups:state.updateGroups}))
    const groupOfVessel = groups?.find(g=>g.node_id==Object.keys(vessel?.node)?.[0])
    const token = useAuth(state=>state.token)

    useEffect(() => {
        console.log({groups, vessel, groupOfVessel})
    }, [vessel]);
        
    const handleDeleteGroup = async () => {
        console.groupCollapsed('NewGroup-handleSubmit');
        // setStatus('loading')
        let params = {
            vessel_id_id:vessel_id
        };
    
        console.groupEnd();
        if (window.confirm(`Are you sure you want to delete ${groupOfVessel?.node_name}?\n\nThis is irreversible`)){
            const {status, response} = await fetchData({method:'DELETE', resource:'vessel-info', token:token, params:params})
            if (status==400){
                enqueueSnackbar('Failed to create Group', {variant: 'error'})
            } else if (status==201||status==200){
                enqueueSnackbar('Group deleted', {variant: 'success'})
                // updateGroups(response)
                
            }
            console.table(response)
        }
    }

    const button_disabled = ()=>{
        if (
            !groupOfVessel
            || !groupOfVessel?.can_write
        ) return true;
        else {
            return false;
        }
      }

    const header = () => {
        if (!groupOfVessel) return <h1>Delete a vessel</h1>
        else return <h1>{`Delete ${vessel_name} from ${groupOfVessel?.node_name}?`}</h1>
    }

    return (
        <div className='manage-page-1-col' >
                {header()}
                {groupOfVessel?.children && <h3 style={{color:'var(--red-alt)'}}>Group must have no subgroups</h3>}
                <div className='ModalContent' >
                    
                    
                    <button 
                        className='confirm'
                        disabled={button_disabled()}
                        onClick={()=>{handleDeleteGroup()}}     
                        >
                            <span>Delete</span>
                            <PublishIcon />
                    </button>
                </div>
            </div>
    )
}