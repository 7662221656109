import { 
    TextField,
    Button
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth } from 'app/zustore';
import { fetchData } from 'app/QueryHandler';
import PublishIcon from '@mui/icons-material/Publish';
import { enqueueSnackbar } from 'notistack';
import { FleetTreeView } from 'fleet/FleetTreeView';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/vessels/manage/new')({
    component: NewGroup
  })

export function NewGroup ({ initial_node }) {
    const [groupName, setGroupName] = useState('')
    const token = useAuth(state=>state.token)
    const [selectedNode, setSelectedNode] = useState(initial_node);
      
    const handleInputChange = (event) => {  
        setGroupName(event.target.value);
    };
        
    const handleCreateGroup = async () => {
        if (groupName.length>0){
            console.groupCollapsed('NewGroup-handleSubmit');
            // setStatus('loading')
            let newGroup = {
                node_name:groupName, 
                parent_node_id:selectedNode.id
            };
        
            console.table(newGroup)
            console.log(JSON.stringify(newGroup))
            console.groupEnd();
            
            const {status, response} = await fetchData({method:'POST', resource:'nodes', token:token, body:newGroup})
            if (status==400){
                enqueueSnackbar('Failed to create Group', {variant: 'error'})
            } else if (status==201||status==200){
                enqueueSnackbar('Group Created', {variant: 'success'})
                window.location.reload()
                
            }
            console.table(response)
        }
    }

    const header = () => {
        if (!selectedNode) return <h1>Create a new group</h1>
        else return <h1>{`Create a new sub group under ${selectedNode.name}`}</h1>
    }

    return (
        <div className='manage-page-1-col' >
                {header()}
                <div className='ModalContent' >
                    
                    <FleetTreeView setSelectedNode={setSelectedNode} initial_selection={initial_node}/>
                    
                    <TextField                         
                            label={"Name of group"}
                            className='text-field'
                            placeholder={`Enter name of new Group`}
                            variant="outlined"
                            
                            defaultValue={''}
                            onChange={(value) => handleInputChange(value)}
                            
                        />
                    <Button 
                        variant="contained" 
                        sx={{
                            margin: '10px auto 10px auto', 
                            height:'80%', 
                            maxWidth:'100px',
                            color: '#00b9b8',
                            backgroundColor:groupName.length>0?'#3d444b':'lightgray'}} 
                            onClick={()=>{handleCreateGroup()}}     
                        endIcon={<PublishIcon />}>
                            Submit
                    </Button>
                </div>
            </div>
    )
}