import { 
    Box, 
    Modal, 
    CardHeader,
    TextField,
    Button
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth, useSelectedNodeStore } from '../../../app/zustore';
import { fetchData } from '../../../app/QueryHandler';
import PublishIcon from '@mui/icons-material/Publish';
import { enqueueSnackbar } from 'notistack';
import { FleetTreeView } from '../../../fleet/FleetTreeView';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/groups/new')({
    component: NewGroup
  })

export function NewGroup ({ initial_node }) {
    const [newGroup, setNewGroup] = useState({
        node_name:'',
        parent_node_id:initial_node?.id,
        email:'',
        first_name:'',
        last_name:''
    })
    const token = useAuth(state=>state.token)
    const {selectedNode, setSelectedNode} = useSelectedNodeStore();
      
    const handleInputChange = (target, event) => {  
        setNewGroup({...newGroup, [event.target.name]: event.target.value});
    };

    useEffect(() => {
        console.log({msg:'selected a new node', node:selectedNode})
        if (selectedNode && selectedNode?.id){
            console.log({...newGroup, parent_node_id: selectedNode.id})
            setNewGroup({...newGroup, parent_node_id: selectedNode.id});
        }
    }, [selectedNode]);
        
    const handleCreateGroup = async () => {
        if (newGroup.node_name.length>0){
            console.groupCollapsed('NewGroup-handleSubmit');
            // setStatus('loading')
            console.log(newGroup)
            let newNode = {
                node_name:newGroup.node_name, 
                parent_node_id:selectedNode?.id,
                contact: (newGroup.email!=='') ? {email: newGroup.email, first_name:newGroup?.first_name, last_name:newGroup?.last_name} : null
            };
        
            console.table(newNode)
            console.log(JSON.stringify(newNode))
            console.groupEnd();
            
            const {status, response} = await fetchData({method:'POST', resource:'nodes', token:token, body:newNode})
            if (status==400){
                enqueueSnackbar('Failed to create Group', {variant: 'error'})
            } else if (status==201||status==200){
                enqueueSnackbar('Group Created', {variant: 'success'})
                window.location.reload()
                
            }
            console.table(response)
        }
    }

    const header = () => {
        if (!selectedNode) return <h1>Create a new group</h1>
        else return <h1>{`Create a new sub group under ${selectedNode.name}`}</h1>
    }

    return (
        <div className='manage-page-1-col' >
                {header()}
                {/* <div className='ModalContent' > */}
                    
                    <FleetTreeView setSelectedNode={setSelectedNode} initial_selection={initial_node}/>
                    
                    <TextField                         
                            label={"Name of group"}
                            name="node_name"
                            value={newGroup.node_name}
                            className='text-field'
                            placeholder={`Enter name of new Group`}
                            variant="outlined"                            
                            defaultValue={''}
                            onChange={(value) => handleInputChange('group', value)}
                            
                        />

                    {/* <div className='contact'>
                    <h2><u style={{color:'white'}}>Contact of new Group</u></h2>
                    <TextField                         
                            label={"Email of Group Owner"}
                            name="email"
                            value={newGroup.email}
                            className='text-field'
                            placeholder={`Enter name of new Group`}
                            variant="outlined"                            
                            defaultValue={''}
                            autoComplete='email'
                            onChange={(value) => handleInputChange('email', value)}
                    />

                    <div className='name'>
                        <TextField                         
                                label={"First Name"}
                                name="first_name"
                                value={newGroup.first_name}
                                className='text-field'
                                placeholder={`Enter name of new Group`}
                                variant="outlined"                            
                                defaultValue={''}
                                onChange={(value) => handleInputChange('first_name', value)}
                                
                                />
                        <TextField                         
                                label={"Last Name"}
                                name="last_name"
                                value={newGroup.last_name}
                                className='text-field'
                                placeholder={`Enter name of new Group`}
                                variant="outlined"                            
                                defaultValue={''}
                                onChange={(value) => handleInputChange('last_name', value)}
                        />
                    </div>
                    <p style={{color:'lightgray'}}>This email is used to automatically add a new user to this group as the owner when they sign up.</p>
                    </div> */}

                    <button 
                        className='confirm'
                        // disabled={button_disabled()}
                        onClick={()=>{handleCreateGroup()}}     
                        >
                            <span>Submit</span>
                            <PublishIcon />
                    </button>
                {/* </div> */}
            </div>
    )
}