import React, { useEffect, useState } from 'react';
import {
  IconButton,
  CardContent,
  CardHeader,
  Collapse,
  Card,
  Typography,
  CardActions,
  Button,
  Tooltip
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchData } from '../app/QueryHandler';
import { ExpandMore } from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import { ModalTypes } from '../Types/enums';
import { useAuth } from '../app/zustore';

export const MembersCard = ({ selectedNode, setShowModal }) => {
  const [expanded, setExpanded] = useState(false);
  const [hover, setHover] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [memberToPromote, setMemberToPromote] = useState(null);
  const queryClient = useQueryClient();

  const { user } = useAuth.getState(); // Get user information from useAuth

  useEffect(() => {
    console.log(selectedNode)
  }, []);

  const promoteMemberMutation = useMutation({
    mutationFn: async (update) => {
      const token = useAuth.getState().token;
      const {status, response} = await fetchData({
        method: 'PUT',
        resource: 'nodes',
        token: token,
        body: update,
      });

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['promotion'] });
      enqueueSnackbar('Member Promoted! 💃🕺🕺🏿💃🏿', { variant: 'QueryInfo' });
    },
    onError: () => {
      enqueueSnackbar('Failed to promote member!', { variant: 'error' });
    },
  });

  const handlePromoteMember = async (member) => {
    setMemberToPromote(member.user_name);
    setConfirmationDialogOpen(true);
  };

  const handleConfirmPromote = () => {
    setConfirmationDialogOpen(false);

    let update = {
      node_id: selectedNode.node_id,
      node_owners: [...selectedNode.node_owners, memberToPromote],
    };
    promoteMemberMutation.mutate(update);
    setShowModal(0);
  };

  const handleCloseDialog = () => {
    setConfirmationDialogOpen(false);
  };

  const isNodeOwner = user && selectedNode?.can_write || false;

  return (
    <Card
      sx={{
        width:'90%',
        marginBottom: '20px',
        position: 'relative',
        backgroundColor: 'var(--dark-grey-1)',
        
      }}
    >
      <CardHeader title={`${selectedNode.members ? Object.keys(selectedNode.members).length : 0} Members`} />
      <IconButton
        onPointerOver={(event) => setHover(true)}
        onPointerOut={(event) => setHover(false)}
        sx={{
          position: 'absolute',
          top: '5px',
          right: '5px',
          padding: '2px',
          '& :hover': {
            color: '#00b9b8',
          },
        }}
        onClick={() => setShowModal(ModalTypes.InviteMember)}
      >
        <Typography sx={{ display: hover ? 'block' : 'none' }}>Invite New Member</Typography>
        <PersonAddAlt1Icon
          sx={{
            '& :hover': {
              color: '#00b9b8',
            },
          }}
        />
      </IconButton>
      <CardActions
        disableSpacing
        sx={{ justifyContent: 'center', backgroundColor: 'rgb(230, 230, 230)'}}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <ExpandMore
          sx={{
            transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div
          style={{
            maxHeight: '200px',
            overflowY: 'scroll',
          }}
        >
          <CardContent>
            {selectedNode.members ? (
              Object.entries(selectedNode?.members).map(([member_id, member], index) => {
                return (
                  <div
                    key={index}
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifySelf: 'center',
                      alignSelf: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        // marginBottom: '10px',
                      }}
                    >
                      <a style={{ fontSize: 'small', color: 'black' }}>{member.user_name}</a>
                      {(isNodeOwner && !selectedNode.can_write) && ( // Render the PROMOTE button only if the user is a node owner
                        <Tooltip title="Give user write-access" placement="top" sx={{ position: 'relative', right: '20px', top: '50%', transform: 'translateY(-20%)' }}>
                          <div
                            style={{
                              backgroundColor: '#00b9b8',
                              color: 'white',
                              borderRadius: '5px',
                              padding: '5px',
                              fontSize: '1vw',
                            }}
                            onMouseEnter={() => {
                              document.body.style.cursor = 'pointer';
                            }}
                            onMouseLeave={() => {
                                // Restore the default cursor when leaving the tile
                                document.body.style.cursor = 'auto';
                            }}
                            onClick={() => {
                              handlePromoteMember(member_id);
                            }}
                          >
                            PROMOTE
                          </div>
                        </Tooltip>
                      )}
                    </div>
                    <div style={{ marginTop: '5px' }}></div>
                  </div>
                );
              })
            ) : null}
          </CardContent>
        </div>
      </Collapse>

      {/* Confirmation Dialog */}
      {confirmationDialogOpen && (
        <Card>
          <CardContent>
            Are you sure you want to promote '{memberToPromote}'?
          </CardContent>
          <CardActions sx={{ justifyContent: 'center' }}>
            <Button
              onClick={handleCloseDialog}
              sx={{
                backgroundColor: '#00b9b8',
                color: 'white',
                padding: '5px',
                fontSize: '1vw',
                borderRadius: '5px',
                marginRight: '10px', // Add margin to separate the buttons
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmPromote}
              sx={{
                backgroundColor: '#00b9b8',
                color: 'white',
                padding: '5px',
                fontSize: '1vw',
                borderRadius: '5px',
              }}
            >
              Confirm
            </Button>
          </CardActions>
        </Card>
      )}
    </Card>
  );
};
