import { useState, useMemo, useCallback, useEffect } from "react";
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import ErrorBoundary from "../../utils/ErrorBoundary";
import { downloadCsv, uuidv4} from '../../utils/utilityMethods'
import { useAnalysisData, useVessel, useVesselList, useFetchStatus, useModal } from "../../app/zustore";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import LinearDeterminate from "../../loadingBar/progressBar";
import { ModalTypes } from "../../Types/enums";
import { AgGridReact, gridApi } from 'ag-grid-react'; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "../../app/ag-grid-theme.css" // Theme

export const DatasetSelector = ({ isMobile, setDataset, dataset })=>{
    const analysisData = useAnalysisData(s=>s.analysisData);
    const dropDataset = useAnalysisData(s=>s.dropDataset);
    const vessel_id = useVessel(s=>s.vessel_id);
    const vessel = useVesselList(s=>s.vesselList).find(vessel => vessel.vessel_id === vessel_id);
    const isParticle = vessel?vessel.isParticle:false;
    const { total, current, statText } = useFetchStatus((s)=>({
        total:s.total, 
        current:s.current, 
        statText:s.statText
    }));
    const {modalType, setShowModal} = useModal()
    const [gridApi, setGridApi] = useState(null)
    
    const [colDefs, setColDefs] = useState([
        {
            field: 'name', 
            headerName: 'Name', 
            tooltipField: 'name', 
            headerTooltip:'Vessel name Column',
            checkboxSelection: true,
            filter: 'agSetColumnFilter'
        },
        {
            field: 'start_time', 
            headerName: 'Start Time', 
            filter: 'agSetColumnFilter'
        },
        {
            field: 'end_time', 
            headerName: 'End Time', 
            filter: 'agSetColumnFilter'
        },  
        {
            field: 'num_rows', 
            headerName: '# Rows', 
            filter: 'agSetColumnFilter'
        },
        {
            field: 'source', 
            headerName: 'data source', 
            filter: 'agSetColumnFilter'
        },
    ]);

    const defaultColDef = useMemo(() => {
        return {
          flex: 1,
          minWidth: 120,
          filter: 'agTextColumnFilter',
          menuTabs: ['filterMenuTab'],
        };
    }, []);

    const ButtonStyle = {
        margin: '10px 5px 10px 5px', 
        height:'80%', 
        maxWidth:'48%',
        minWidth:'142px',
        color: '#00b9b8',
        backgroundColor:'#3d444b'
    }

    function selectRowByDatasetId() {
        if (gridApi!==null && !gridApi.isDestroyed()){
            try {
                gridApi.forEachNode(function(rowNode) {
                if (rowNode.data.dataset_id === dataset.dataset_id) {
                    rowNode.setSelected(true);
                } else {
                    rowNode.setSelected(false);
                }
                })
            } catch (error){
                console.log(error)
            }
        }
    }

    useEffect(() => {
        selectRowByDatasetId()
    }, [dataset, analysisData]);

    const onGridReady =(params)=>{
        setGridApi(params.api);        
      }
      

    const onRowSelected = useCallback((event) => {
        if (event.node.isSelected()){
            console.log(event.node)
            setDataset(event.node.data)
        }
      }, []);

    const handleDeleteDataset = ()=>{
        dropDataset(dataset.dataset_id)
    }

    function handleDownloadCsv() {
        downloadCsv({items:dataset, vessel:vessel_id})
    }

    return (
        <Box 
        sx={{ 
            display: "flex", flexDirection: "column", 
            rowGap: "20px", maxWidth:'1000px', width:isMobile?'90%':'80%', 
            marginLeft:'auto', marginRight:'auto',
            backgroundColor: '#c4c4c469', padding: '14px',
            boxShadow: 'black 4px 4px',
            justifyContent: 'space-between',
            borderRadius: '10px', alignItems: 'center',
            marginTop: '15px'
        }}>
            <span style={{fontSize:'2.5vh'}}>Select a Locally available Dataset</span>
            
            {/* <h2>{width} px</h2> */}

            { analysisData.length>0 ?
                <div 
                style={{ borderRadius:"20px", width:'100%'}}
                className={
                    "ag-theme-alpine-dark local-datasets-table" 
                }>
                    <ErrorBoundary boundaryName='Dataset Selector Table'>
                        <AgGridReact 
                        pagination={true}
                        rowHeight={40}
                        enableCellChangeFlash={true}
                        paginationPageSize={5}
                        rowData={analysisData} 
                        defaultColDef={defaultColDef}
                        onGridReady={onGridReady}
                        rowSelection='single'
                        domLayout={'autoHeight'}
                        onRowSelected={onRowSelected}
                        columnDefs={colDefs} />
                    </ErrorBoundary>
                </div>
                :
                <span>No data loaded</span>
            }
            <LinearDeterminate current={current} total={total}/>
            <span style={{fontSize:'2.5vh'}}>Load new data</span>
            <Box sx={{ display: "flex", flexDirection: "row", flexWrap:'wrap', justifyContent:'space-between' }}>
                <Button 
                    variant="contained"                             
                    sx={isMobile?{...ButtonStyle, width:'90%'}:ButtonStyle} 
                    style={isMobile?{...ButtonStyle, width:'100%', maxWidth:'100%'}:ButtonStyle} 
                    onClick={()=>setShowModal(ModalTypes.DatasetLoader)}     
                    endIcon={<CloudDownloadIcon />}
                >Load new dataset</Button>

                <Button 
                    variant="contained"                             
                    sx={ButtonStyle} 
                    disabled={analysisData.length===0}
                    onClick={handleDownloadCsv}     
                    endIcon={<DownloadIcon />}
                >Download{!isMobile && ' CSV'}</Button>
                
                <Button 
                    variant="contained"                             
                    sx={ButtonStyle} 
                    disabled={analysisData.length===0 || dataset==null}
                    onClick={handleDeleteDataset}     
                    endIcon={<HighlightOffIcon />}
                >Remove{!isMobile && ' Dataset'}</Button>

            </Box>
        </Box>
    )
}