import React, { useState } from 'react';
import { useCSVReader, lightenDarkenColor, formatFileSize } from 'react-papaparse';
import { useAnalysisData, useDataSource, useModal, useFetchStatus } from '../app/zustore';
import { ModalTypes } from '../Types/enums';
import { uuidv4 } from './utilityMethods';

const GREY = '#CCC';
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(DEFAULT_REMOVE_HOVER_COLOR, 40);
const GREY_DIM = '#686868';

const styles = {
  zone: {
    alignItems: 'center',
    border: `4px dashed ${GREY}`,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    height: '50%',
    justifyContent: 'center',
    padding: 10,
  },
  file: {
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: 20,
    display: 'flex',
    height: 120,
    width: 120,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex',
  },
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em',
  },
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: GREY_DIM,
  },
  default: {
    borderColor: GREY,
  },
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  },
};

export default function CSVReader({ vessel, vessel_id, setDataset }) {
  const { CSVReader } = useCSVReader();
  const setDataSource = useDataSource((state) => state.setDataSource);
  const { analysisData, setAnalysisData } = useAnalysisData();
  const setShowModal = useModal((state) => state.setShowModal);
  const { statText, setStatText } = useFetchStatus();
  const [removeHoverColor, setRemoveHoverColor] = useState(DEFAULT_REMOVE_HOVER_COLOR);

  function parseCsvData(csvData) {
    console.groupCollapsed('Parsing CSV Data!');
    let headers, startRow, timeMethod;
    // Find the start time
    const startTimeRow = csvData.find(row => row[0]?.startsWith('Start time:'));
    if (!startTimeRow) {
      throw new Error("Start time row not found.");
    }
    const startTimeStr = startTimeRow[0].split('e:')[1]?.trim();
    if (!startTimeStr) {
      throw new Error("Start time value not found.");
    }
    const startTime = new Date(startTimeStr);
    
    // Find the sample interval and determine the time method
    const sampleIntervalRow = csvData.find(row => row[0].startsWith('Sample interval (ms)'));
    let sampleInterval;
    if (sampleIntervalRow) {
      sampleInterval = sampleIntervalRow[0].split(':')[1].trim();
      timeMethod = 'constant';
    } else {
      timeMethod = 'variable';
    }
    console.log(timeMethod, 'time column in the Log File')
    
    // Find the row with headers
    const headersRow = csvData.find(row => row[0] === 'Sample');
    if (headersRow) {
      headers = headersRow.slice(0); // Assuming the first column is always 'Sample'
    } else {
      throw new Error("Headers row not found.");
    }
    
    // Find the row where the data starts
    startRow = csvData.findIndex(row => row[0] === 'Sample') + 1;
    const rows = csvData.slice(startRow);
    const parsedRows = [];
    let prevTime = startTime;
    let total_distance = 0;
    let prevTimeStr = prevTime.toISOString();
    
    console.log(startRow, startTime, timeMethod, headers)
    
    rows.filter(row => row[1]!==0 && row[0]!=='' && row.length>1).forEach(row => {
      const obj = { sample_time_str: prevTimeStr };
      headers.forEach((header, j) => {
        const value = Number(row[j]);
        obj[header] = isNaN(value) ? row[j] : value;
      });
      obj['Throttle Percent Abs'] = Math.abs(obj['Throttle Percent'])
      obj['Max Range'] = obj['Troll Range'] / (obj['Battery SOC'] / 100)
      if (obj['Distance'] !== null) {
        total_distance += obj['Distance'];
        obj['total_distance_m'] = total_distance;
        obj['total_distance_km'] = total_distance / 1000;
        obj['total_distance_mi'] = total_distance / 1609.344;
      }
      if (Math.abs(obj['Power (kW)'])>1 && obj['GPS Speed (mph)']!==0 && obj['Throttle Percent Abs']>6){
        obj['mi_per_kWh'] = obj['GPS Speed (mph)'] / obj['Power (kW)'];
        obj['MPG'] = obj['mi_per_kWh'] * 33.7;
      } else {
        obj['mi_per_kWh'] =0;
        obj['MPG'] =0;
      }
      obj['GPH'] = obj['GPS Speed (mph)'] / obj['MPG']
      parsedRows.push(obj);
      if (timeMethod === 'constant') {
        prevTime = new Date(prevTime.getTime() + Number(sampleInterval));
      } else {
        // console.log(obj, prevTime, headers[1]===" interval (s)")
        if (headers[1]===" interval (s)") {
          prevTime = new Date(prevTime.getTime() + Number(obj[' interval (s)']) * 1000); // Seconds
        } else {
          prevTime = new Date(prevTime.getTime() + Number(obj[1]) * 1000*1000); // Miliseconds
        } 
      }
      try {
        prevTimeStr = prevTime.toISOString();
      } catch (err) {
        console.log(row, err, prevTime)
      }
    });
    
    console.groupEnd();
    return parsedRows;
  }
  
  const handleAcceptedUpload = (results) => {
    console.log('---------------------------');
    console.log(results);
    console.log('---------------------------');
    const parsedData = parseCsvData(results.data);
    console.log(parsedData.slice(0, 4));
    setDataSource("csv");
    const dataset_id = uuidv4();
    const newDataset = {
    id: vessel_id,
    name: vessel.vessel_name,
    dataset_id: dataset_id,
    source: 'csv',
    data: parsedData,
    num_rows: parsedData.length,
    start_time: parsedData[0]['sample_time_str'],
    end_time: parsedData[parsedData.length - 1]['sample_time_str']
    };
    setAnalysisData(newDataset);
    setDataset(newDataset);
    setStatText(`Parsed ${parsedData.length} rows`);
    setShowModal(ModalTypes.None);
    };
    
    return (
    <CSVReader
      onUploadAccepted={(results) => {handleAcceptedUpload(results);}}
      onDragOver={(event) => {event.preventDefault();}}
      onDragLeave={(event) => {event.preventDefault();}}>
        {({ 
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
        Remove,
        }) => (
      <div
        {...getRootProps()}
        style={styles.zone}>
        {acceptedFile ? (
        <div style={styles.file}>
        <div style={styles.info}>
        <span style={styles.size}>
        {formatFileSize(acceptedFile.size)}
        </span>
        <span style={styles.name}>{acceptedFile.name}</span>
        </div>
        <div style={styles.progressBar}>
        <ProgressBar />
        </div>
        <div
        {...getRemoveFileProps()}
        style={styles.remove}
        onMouseOver={() => setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT)}
        onMouseOut={() => setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR)}
        >
        <Remove color={removeHoverColor} />
        </div>
        </div>
        ) : (
        <div style={{width:"30vw"}}>
        <h3>Drop CSV file here or click to upload</h3>
        </div>
        )}
      </div>
      )}
    </CSVReader>
  );
}
