import React, { useState, Suspense, useEffect } from 'react';
import { Box, Typography, useMediaQuery, SwipeableDrawer, } from "@mui/material";
import { useVessel, zuPage, useVesselList, useUser, useAuth, useTrips, dynoIdArray, useSelect, useLatestTrips } from 'app/zustore';

import ErrorBoundary from '../../utils/ErrorBoundary';
import { Link, Outlet, createFileRoute, useLocation, useNavigate } from '@tanstack/react-router'
import { useQuery } from '@tanstack/react-query';
import { fetchData } from 'app/QueryHandler';
import dayjs from 'dayjs';

export const Route = createFileRoute('/_authenticated/trips')({
  // Or in a component
  component: TripsComponent,
})

function TripsComponent({ contentAreaHeight }) {
  const isMobile = useMediaQuery('(max-width:600px)');
  const {leftSidebarOpen, toggleLeftSidebar} = zuPage(state=>({leftSidebarOpen:state.leftSidebarOpen, toggleLeftSidebar:state.toggleLeftSidebar}));
  const { trip_id } = Route.useParams()
  const selectTrip = useTrips(s=>s.selectTrip)
  const {tab, setPage, setTab} = zuPage(state=>({setPage:state.setPage, tab:state.tab, setTab:state.setTab}));
  const vesselList = useVesselList(s=>s.vesselList)
  const setSelected = useSelect(s=>s.setSelected)
  const {latestTrips, updateLatestTrips} = useLatestTrips()
  const trip = useTrips(s=>s.trip)
  const token = useAuth((state)=>state.token);
  const setVesselTrips = useTrips(s=>s.setVesselTrips);
  const user = useUser(s=>s.user)
  const location = useLocation()

  useEffect(() => {
    if (location.pathname=='/trips/select'){
      return
    }
    console.log('trips setting the trip id', trip_id)
    selectTrip(trip_id);
    
  }, [trip_id, location]);

  const handleTabClick = (tabNumber) => {
    setTab(tabNumber);
  };

  useEffect(()=>{
    if (!isMobile){
      toggleLeftSidebar(true)
    }
  },[isMobile])

  function getLatest(data){
    let startTime = dayjs(Date.now()).subtract(7, 'day').valueOf().toString();
    if ( Object.keys(data).length>0 ){
      startTime = Object.values(data).map(v=>Date.parse(v.time)).sort((a,b) => a-b)
      startTime = startTime[startTime.length-1]
      const d = new Date;
      startTime = startTime - parseInt(d.getTimezoneOffset()*60*1000) + 1
    }
    return startTime
  }

  const latestTripsQuery = useQuery({
    queryKey: ['FleetTrips'],
    queryFn: async () => {
        const vessels = vesselList ? vesselList.flatMap((vessel) => (vessel.vessel_id)) : [];
        // console.log(vessels)
        let startTime = getLatest(latestTrips)
        const params = {
            // vessel_id: vessels,
            startTime: startTime
        };
        const {status, body, type} = await fetchData({ method: 'GET', resource: 'trips', token: token, params: params });
      if (status===204) throw new Error('No data returned')
      if (status===403) throw new Error('403: Unauthorized')
      
      const data = body
      // console.log(data)
      if (data==='') throw new Error('No data returned')
      return data;
    },
    enabled: true,
    refetchInterval: 1000 * 60 * 15, // 30 seconds
    retry: 2,
    onSuccess: (data) => {
      if (data) {            
        console.groupCollapsed('fleet trip viewer query');            
        console.log(data);  
        console.log(vesselList) 
        // data = Object.values(data).reduce((acc, next) => acc.concat(next), [])
        // const updatedLeaderboard = [...leaderboard, ...data]
        // const newData = data.filter(item => !leaderboard.some(existingItem => existingItem.vessel_id === item.vessel_id)); // Filter out any items that already exist in the existing data
        // const mergedData = [...leaderboard, ...newData]
        
        data = data.map(trip=>{
            let vessel = vesselList.find(vessel=> vessel.vessel_id===trip.vessel_id)
            if (!vessel) {
                console.log(trip.vessel_id)
                vessel = {vessel_name:trip.vessel_id, battery:12}
            }
            if (typeof(vessel.battery)==String){
                console.log(vessel)
            }
            return ({
                ...trip, 
                name:vessel?.vessel_name, 
                battery:vessel.battery,
                
            })
            }
        )
        console.table(data.slice(1,10), data.length)
        updateLatestTrips([...data, ...latestTrips])
        console.groupEnd();
      }
    }
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    // Keep the mobile drawer open when swiped to the right
    if (anchor === 'left' && open) {
      toggleLeftSidebar(true);
    } else {
      toggleLeftSidebar(false);
    }
  };

  const handleSidebarButtonClick = ()=>{
    toggleLeftSidebar(!leftSidebarOpen)
  }

  const handleReturnClick = ()=>{
    setPage('FleetOverview');
  }

  const SideBar = () => {
    const SideBarContent = ()=>{
      return (
        <Box className='SideBarContent'>
          
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              marginBottom: '10px',
              textAlign: 'start'
            }}>
          <Typography
              variant="h4"
              sx={{ color: '#888', fontSize: 'larger', textAlign:'start', marginLeft:'10px', paddingTop:'1em'  }}
            >
              Trips:
            </Typography>
  
          </div> 
  
          
  
          <div className="tab-buttons" style={styles.tabButtons}>
            <a style={{color:'white', fontSize:'30px', textAlign:'center'}}>Tab</a>
         

            <button className='fm-btn'>
                <Link to={`/trips/`} params={{trip_id: trip,}}>Feed</Link>
            </button>
            
            {/* <button className='fm-btn'>
                <Link to="/$trip_id/analyze" params={{trip_id: trip,}}>Analyze</Link>
            </button>

            <button className='fm-btn'>
                <Link to="/$trip_id/manage" params={{trip_id: trip,}}>Table</Link>
            </button> */}
          </div>

          
        </Box>
      )
    }

    if (isMobile) {
      return (
        <SwipeableDrawer
            className='swipe'
            anchor="left"
            swipeAreaWidth={45}
            open={leftSidebarOpen}
            onOpen={toggleDrawer('left', true)}
            onClose={toggleDrawer('left', false)}
            
          >
          <Box className='SideBar'>
            {SideBarContent()}
          </Box>
        </SwipeableDrawer>
      )
    } 
    else if (leftSidebarOpen) {
      return (
      <Box className={`SideBar open`}>
        {SideBarContent()}
      </Box>
      )
    } else {
      return (
        <Box className={`SideBar open`} onClick={handleSidebarButtonClick}>
          <button
            color="black"
            aria-label="open drawer"
            style={{alignSelf:'center', backgroundColor:'#404040', margin:0, padding:0, border:0}}
            onClick={handleSidebarButtonClick}
          >
            <svg 
              // class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-19d88bx-MuiSvgIcon-root" 
              focusable="false" 
              style={{width:'100%', aspectRatio:'1', color:'black', transform:'rotate(90deg)', backgroundColor:'#404040'}}
              aria-hidden="true" 
              viewBox="0 0 24 24" 
              data-testid="MenuIcon">
              <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
            </svg>
          </button>     
        </Box>
      )
    } 
  }  
  
  return (
    <div className="container trips">
      {SideBar()}      
      
      <div className={`ContentBox ${isMobile && 'mobile'} open`}>
        <ErrorBoundary boundaryName='Vessel Page'>
          <Outlet />
        </ErrorBoundary>
      </div>
    </div>
  );
}

const styles = {
  container: {
    width: '100%',
    height: '100vh',
    display: "flex",
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 0,
  },
  tabButtons: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
    width:'90%',
    justifySelf: 'center',
    borderRadius: '5px',
  }
} 