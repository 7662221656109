import { useMatch } from '@tanstack/react-router';
import FaultCountBar from '../utils/Plots/FaultCountBar';
import { useMemo, useState } from 'react';
import { utc_time_to_string } from '../utils/dataProcessing';

export const FaultCodeCounter = ({data})=>{
    const accumulatedFaults = useMemo(()=>{
        let faults = data
            ?.filter(r => (r.ecu_faults !== undefined && r.ecu_faults !== null))
            ?.reduce((acc, item) => {
            const faultTime = new Date(item.time); // assuming `timestamp` is the time the fault occurred
            if (!acc[item.ecu_faults]) {
                acc[item.ecu_faults] = { count: 0, times: [] };
            }
            acc[item.ecu_faults].count += 1;
            acc[item.ecu_faults].times.push(faultTime);
            return acc;
            }, {}
            );
    console.log(faults);
    return (Object.keys(faults).length>0) ? faults : {'-1': {count:1, time: []}};
    }, [data]);
    
    if (Object.entries(accumulatedFaults).length>0) {
        return (
            <div className='faultBox' style={{marginLeft:'auto', marginRight:'auto', marginBottom:'15px'}}>
                {/* <a style={{fontWeight:'bold'}}>Faults seen:</a > */}
                <div className='faults'>
                    <div className='alerts-container'>
                        {Object.entries(accumulatedFaults).map(([fault_code, faultData], index)=>(
                            <Alert fault_code={fault_code} faultData={faultData} key={index}/>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}

const Alert = ({fault_code, faultData})=>{
    const [expanded, setExpanded] = useState(false)
    let fault = useMemo(()=>{
        return faultCodes[fault_code]
    }, [fault_code])

    if (!fault || fault==undefined){
        return null
    }

    let dString = (fault_code==-1) 
                ? 'M14.6667 9.33333H17.3333V12H14.6667V9.33333ZM14.6667 14.6667H17.3333V22.6667H14.6667V14.6667ZM16 2.66666C8.63999 2.66666 2.66666 8.64 2.66666 16C2.66666 23.36 8.63999 29.3333 16 29.3333C23.36 29.3333 29.3333 23.36 29.3333 16C29.3333 8.64 23.36 2.66666 16 2.66666ZM16 26.6667C10.12 26.6667 5.33332 21.88 5.33332 16C5.33332 10.12 10.12 5.33333 16 5.33333C21.88 5.33333 26.6667 10.12 26.6667 16C26.6667 21.88 21.88 26.6667 16 26.6667Z'
                : "M0 23.75H27.5L13.75 0L0 23.75ZM15 21H12.5V18.5H15V21ZM15 16H12.5V8H15V16Z";

    const severityClass=()=>{
        if (fault_code==-1){
            return 'non-fault'
        } else if (fault.icon==0){
            return 'severity0'
        } else if (fault.icon==100){
            return 'severity1'
        } else if (fault.icon==200){
            return 'severity2'
        } else if (fault.icon==300){
            return 'severity3'
        } else {
            return 'non-fault'
        }
    }

    return (
        <div 
        className={`fault-manager-item-container fault-expander ${expanded?'expanded':''} long-text`} 
        id={`fault-item-${fault.code}`} 
        data-fault-code={`${fault.code}`} 
        data-severity="300" data-dismiss={fault.dismiss}
        onClick={()=>{
            setExpanded(!expanded)
            if (navigator.vibrate) {
                navigator.vibrate(100); // Vibrate for 200ms
            }
        }}
        >
            <div className='alert-item-top-bar'>
                <svg className={`fault-manager-triangle-icon ${expanded?'expanded':''}`} xmlns="http://www.w3.org/2000/svg" width="42px" height="36" viewBox={(fault_code==-1) ? '0 0 32 32' : "0 0 28 24"}>
                    {fault_code>0&&<rect x="10" y="8" width="5" height="13" fill="var(--background-dark)"></rect>}
                    <path d={dString} className={severityClass()}></path>
                </svg>
                <a className="label">{fault.name}</a>
                <svg className={`fault-manager-expander-icon ${expanded?'expanded':''}`} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 21 21">
                    <path d="M2.35 0.825003L-4.37114e-07 3.175L10 13.175L20 3.175L17.65 0.825002L10 8.45834L2.35 0.825003Z" fill="var(--dark-grey-2)"></path>
                </svg>
            </div>
            <div className="body">
                <a >{fault.desc}</a>
                {(fault_code>0) &&
                <div>
                <span>Seen {faultData.count === 1 ? 'Once' : `${faultData.count} times`}</span>
                
                <div className='seen-times-container'>
                {(expanded) && faultData.times.map((time, idx) => (
                    <a key={idx}>{utc_time_to_string(time)}</a>
                ))}
                </div>
                </div>
                }
            </div>
        </div>
    )
}

const faultCodes = {
    "-1": {
        name: "You have no alerts!",
        desc: "Your boat is in great condition. If you think something is wrong, please contact us.",
        popup: false,
        icon: 0,
        dismiss: 0
    }, 
    "50": {
        name: "DCDC Fault",
        desc: "Maintenance may be Required, please cycle the power",
        popup: false,
        icon: 0,
        dismiss: 0
    },                
    "60": {
        name: "Sensata EVCC Fault",
        desc: "Maintenance may be Required, please cycle the power",
        popup: true,
        icon: 100,
        dismiss: 1
    },                
    "80": {
        name: "EMUS BMS Fault",
        desc: "Maintenance may be Required, please cycle the power",
        popup: false,
        icon: 0,
        dismiss: 0
    },          
    "81": {
        name: "Flux BMS Fault",
        desc: "Maintenance may be Required, please cycle the power",
        popup: false,
        icon: 0,
        dismiss: 0
    },                    
    "90": {
        name: "Warning: Motor Fault",
        desc: "Maintenance may be Required, please cycle the power",
        popup: true,
        icon: 100,
        dismiss: 0
    },          
    "91": {
        name: "Warning: Motor Fault",
        desc: "Maintenance may be Required, please cycle the power",
        popup: false,
        icon: 100,
        dismiss: 0
    },          
    "97": {
        name: "Warning: Charger Fault",
        desc: "Maintenance Required",
        popup: false,
        icon: 0,
        dismiss: 0
    },   
    "98": {
        name: "Warning: Battery Fault",
        desc: "Maintenance Required",
        popup: false,
        icon: 0,
        dismiss: 0
    },            
    "99": {
        name: "Warning: Motor Fault",
        desc: "Maintenance may be Required, please cycle the power",
        popup: false,
        icon: 100,
        dismiss: 0
    },          
    "102": {
        name: "Low Battery Warning",
        desc: "Return to Charger",
        popup: false,
        icon: 0,
        dismiss: 0
    },            
    "103": {
        name: "Belt Temp Rising",
        desc: "The belt temperature is higher than expected. If the temperature continues to rise, your power will be reduced. You can slow down to help lower the belt temperature.",
        popup: false,
        icon: 100,
        dismiss: 0
    },            
    "104": {
        name: "Return Throttle to Neutral",
        desc: "The throttle must be in neutral to start your boat. Move the throttle to neutral and then press the motor start button.",
        popup: true,
        icon: 0,
        dismiss: 0
    },
    "105": {
        name: "System is still powered on after the charger was unplugged",
        desc: "Please try cycling the power, and contact us for further instructions if the issue does not resolve itself.",
        popup: true,
        icon: 100,
        dismiss: 0
    },                
    "106": {
        name: "Motor Timed Out",
        desc: "Your motor was left on without use for more than 5 minutes. To help maintain safety, your boat was turned off. Press the motor start button to turn your motor on.",
        popup: true,
        icon: 0,
        dismiss: 0
    },
    "107": {
        name: "ECOS Missing",
        desc: "Your Emergency Cut Off Switch is not attached. Attach the ECOS or override this safety feature by pressing the motor start button again.",
        popup: true,
        icon: 0,
        dismiss: 0
    },
    "108": {
        name: "ECOS Removed",
        desc: "Your Emergency Cut Off Switch has been removed and your boat has been turned off. Attach the ECOS and press the start button or override this safety feature by just pressing the motor start button again.",
        popup: true,
        icon: 300,
        dismiss: 0
    },
    "109": {
        name: "E-STOP Pressed",
        desc: "The emergency stop button was pressed, turning your boat off. If there is an emergency, call for help. Otherwise, release the E-stop and press the motor start button.",
        popup: true,
        icon: 300,
        dismiss: 0
    },            
    "111": {
        name: "Sensor Failure Detected",
        desc: "Maintenance may be required",
        popup: false,
        icon: 100,
        dismiss: 0
    },            
    "112": {
        name: "Sensor Failure Detected Warning",
        desc: "Maintenance Suggested",
        popup: true,
        icon: 100,
        dismiss: 0
    },            
    "113": {
        name: "Sensor Failure Detected Warning",
        desc: "Maintenance Suggested",
        popup: true,
        icon: 100,
        dismiss: 0
    },            
    "114": {
        name: "Sensor Failure Detected Warning",
        desc: "Maintenance Suggested",
        popup: true,
        icon: 100,
        dismiss: 0
    },            
    "115": {
        name: "Sensor Failure Detected Warning",
        desc: "Maintenance Suggested",
        popup: false,
        icon: 100,
        dismiss: 0
    },            
    "116": {
        name: "Sensor Failure Detected Warning",
        desc: "Maintenance Suggested",
        popup: false,
        icon: 100,
        dismiss: 0
    },            
    "117": {
        name: "Sensor Failure Detected Warning",
        desc: "Maintenance Suggested",
        popup: true,
        icon: 100,
        dismiss: 0
    },            
    "118": {
        name: "Sensor Failure Detected Warning",
        desc: "Maintenance Suggested",
        popup: true,
        icon: 100,
        dismiss: 0
    },            
    "119": {
        name: "Sensor Failure Detected Warning",
        desc: "Maintenance Suggested",
        popup: true,
        icon: 100,
        dismiss: 0
    },            
    "120": {
        name: "Sensor Failure Detected Warning",
        desc: "Maintenance Suggested",
        popup: true,
        icon: 100,
        dismiss: 0
    },            
    "121": {
        name: "Sensor Failure Detected Warning",
        desc: "Maintenance Suggested",
        popup: true,
        icon: 100,
        dismiss: 0
    },            
    "122": {
        name: "Sensor Failure Detected Warning",
        desc: "Maintenance Suggested",
        popup: true,
        icon: 100,
        dismiss: 0
    },            
    "123": {
        name: "Motor Controller Error",
        desc: "Data from the motor controller is not being transmitted. To protect your safety, your boat has been turned off. Contact your service provider for maintenance if the problem persists.",
        popup: true,
        icon: 300,
        dismiss: 0
    },            
    "124": {
        name: "Battery Management System Error",
        desc: "Data from the battery management system is not being transmitted. To protect your safety, operating and charging your boat have been disabled. Contact your service provider for maintenance if the problem persists.",
        popup: true,
        icon: 300,
        dismiss: 0
    },            
    "125": {
        name: "Onboard Charger Error",
        desc: "Data from the onboard charger is not being transmitted. To protect your safety, charging has been disabled. Contact your service provider for maintenance if the problem persists.",
        popup: true,
        icon: 300,
        dismiss: 0
    },            
    "126": {
        name: "Range and Speed Disabled",
        desc: "The computer that transmits range and speed data is not functioning properly. Proceed with caution and contact a service provider for maintenance if the problem persists.",
        popup: true,
        icon: 200,
        dismiss: 0
    },            
    "127": {
        name: "EV Charge Control Error",
        desc: "Data from the electric vehicle charge controller is not being transmitted. To protect your safety, charging has been disabled. Contact your service provider for maintenance if the problem persists.",
        popup: true,
        icon: 300,
        dismiss: 0
    },
    "129": {
        name: "Temperature Sensor Error",
        desc: "The oil temperature sensor is not functioning properly. You can continue operating your boat, but you should contact a service provider to replace the sensor as soon as possible.",
        popup: true,
        icon: 100,
        dismiss: 0
    },
    "130": {
        name: "Temperature Sensor Error",
        desc: "The oil temperature sensors are not functioning properly. You can continue operating your boat, but you should contact a service provider to replace the sensor as soon as possible.",
        popup: true,
        icon: 100,
        dismiss: 0
    },
    "132": {
        name: "Liquid Detection Sensor Error",
        desc: "The liquid detection sensor is not functioning properly. You can continue operating your boat, but you should contact a service provider to replace the sensor as soon as possible.",
        popup: true,
        icon: 100,
        dismiss: 0
    },
    "133": {
        name: "Trim Sensor Error",
        desc: "The trim angle sensor is misfunctioning. You will still be able to trim your motor but do so carefully to prevent damaging the outboard. The trim gauge is no longer accurate. Contact your service provider to replace the sensor.",
        popup: true,
        icon: 200,
        dismiss: 0
    },
    "134": {
        name: "Coolant Sensor Error",
        desc: "The coolant level sensor is not functioning properly. This means we are unable to monitor the amount of coolant in your system. You can continue operating your boat, but you should contact a service provider to replace the sensor as soon as possible.",
        popup: true,
        icon: 100,
        dismiss: 0
    },
    "135": {
        name: "Low Coolant",
        desc: "You are running low on coolant in your outboard. You can continue operating your boat, but you should contact a service provider to add more coolant and check for system leaks.",
        popup: true,
        icon: 100,
        dismiss: 0
    },
    "136": {
        name: "Leak Detected",
        desc: "Liquid has been detected inside the powerhead. This is likely due to a leak. You will still be able to operate the boat, but you should contact a service provider as soon as possible.",
        popup: true,
        icon: 100,
        dismiss: 0
    },
    "137": {
        name: "Battery Management System Data Error",
        desc: "The battery management system is sending faulty data. To maintain safety, the boat has been turned off and charging has been disabled. Contact a service provider for maintenance.",
        popup: true,
        icon: 300,
        dismiss: 0
    },
    "138": {
        name: "Helm Data Error",
        desc: "The helm is sending faulty data to the system. To maintain safety, the boat has been turned off and charging has been disabled. Contact a service provider for maintenance.",
        popup: true,
        icon: 300,
        dismiss: 0
    },
    "139": {
        name: "High Voltage System Off",
        desc: "The high voltage system is turned off, preventing the boat from turning on or charging. Contact a service provider for maintenance.",
        popup: true,
        icon: 300,
        dismiss: 0
    },
    "141": {
        name: "Remove key to use outboard trim switch",
        desc: "To protect your safety, you cannot use the trim switch on the outboard when the key is on. Turn off the key to use the trim switch or turn on the motor and use the throttle trim controls.",
        popup: true,
        icon: 0,
        dismiss: 0
    },    
    "143": {
        name: "More Power Available",
        desc: "The power cutback has been removed. Return the throttle to 00% to access your full power range.",
        popup: true,
        icon: 0,
        dismiss: 0
    },            
    "146": {
        name: "House Battery low",
        desc: "The House battery voltage has fallen below an acceptable charge.",
        popup: true,
        icon: 0,
        dismiss: 1
    },            
    "147": {
        name: "Control Pilot Unavailable",
        desc: "Cannot arbitrate charge.",
        popup: true,
        icon: 0,
        dismiss: 0
    },            
    "148": {
        name: "Excessive Electrical Power Loss",
        desc: "Cannot arbitrate charge.",
        popup: false,
        icon: 0,
        dismiss: 0
    },            
    "202": {
        name: "Low Power Mode",
        desc: "Your battery charge is below 10%. Low Power Mode has been turned on to limit your power usage and help you get back to recharge.",
        popup: true,
        icon: 200,
        dismiss: 0
    },            
    "203": {
        name: "Belt Temperature Cutback",
        desc: "The belt in your outboard is beginning to overheat. We have reduced your power output to help cool down the belt.",
        popup: true,
        icon: 200,
        dismiss: 0
    },
    "210": {
        name: "Charging Limit Active",
        desc: "The current has been reduced below the requested amount to help maintain system health. If this fault persists, you may contact a service provider. Otherwise, it will likely resolve on its own.",
        popup: true,
        icon: 100,
        dismiss: 0
    },            
    "227": {
        name: "High Motor Temperature Warning",
        desc: "Power Reduced to Allow System to Cool",
        popup: true,
        icon: 200,
        dismiss: 0
    },            
    "245": {
        name: "Torque Limiting Active",
        desc: "The maximum available torque has been reduced to protect your system.",
        popup: true,
        icon: 200,
        dismiss: 0
    },            
    "301": {
        name: "Throttle Communication Error",
        desc: "Communication has been lost between the throttle and the boat. Your boat has been turned off to protect your safety. Contact a service provider for maintenance.",
        popup: false,
        icon: 300,
        dismiss: 0
    },            
    "302": {
        name: "Out of Battery",
        desc: "Your battery charge is down to 0%. You do not have enough power to operate the boat. You will need to be towed to the dock to recharge.",
        popup: true,
        icon: 300,
        dismiss: 0
    },            
    "303": {
        name: "Belt Temperature Error",
        desc: "The belt in your outboard has exceeded the operable temperature. Your boat has been turned off to allow the belt to cool down. When the belt returns to an acceptable temperature, the error will clear and you will be able to operate your boat again.",
        popup: true,
        icon: 300,
        dismiss: 0
    },
    "306": {
        name: "Motor Timed Out",
        desc: "Your motor was left on without use for more than 5 minutes. To help maintain safety, your boat was turned off. Press the motor start button to turn your motor on.",
        popup: true,
        icon: 0,
        dismiss: 0
    },
    "308": {
        name: "ECOS Removed",
        desc: "Your Emergency Cut Off Switch has been removed and your boat has been turned off. Attach the ECOS and press the start button or override this safety feature by just pressing the motor start button again.",
        popup: true,
        icon: 300,
        dismiss: 0
    },
    "323": {
        name: "Motor Controller Error",
        desc: "Data from the motor controller is not being transmitted. To protect your safety, your boat has been turned off. Contact your service provider for maintenance if the problem persists.",
        popup: true,
        icon: 300,
        dismiss: 0
    },            
    "324": {
        name: "Battery Management System Error",
        desc: "Data from the battery management system is not being transmitted. To protect your safety, operating and charging your boat have been disabled. Contact your service provider for maintenance if the problem persists.",
        popup: true,
        icon: 300,
        dismiss: 0
    },            
    "325": {
        name: "Onboard Charger Error",
        desc: "Data from the onboard charger is not being transmitted. To protect your safety, charging has been disabled. Contact your service provider for maintenance if the problem persists.",
        popup: false,
        icon: 0,
        dismiss: 0
    },            
    "327": {
        name: "EVCC CAN timeout",
        desc: "Communication with the EVCC timed out.",
        popup: true,
        icon: 300,
        dismiss: 0
    },
    "328": {
        name: "High Voltage Interlock Loop Error",
        desc: "The high voltage interlock loop, a safety feature that monitors the high voltage connections, is not functioning properly. Your boat has been turned off to protect your safety. Contact a service provider for maintenance.",
        popup: true,
        icon: 300,
        dismiss: 0
    },
    "330": {
        name: "Contactor Error",
        desc: "The contactors are not functioning properly. Your boat has been disabled for your safety. Contact a service provider for maintenance. ",
        popup: true,
        icon: 300,
        dismiss: 0
    },
    "331": {
        name: "Helm Communication Error",
        desc: "The communication between the helm controls and the boat is not functioning properly. Your boat has been turned off to protect your safety. Contact a service provider for maintenance.",
        popup: true,
        icon: 300,
        dismiss: 0
    },
    "402": {
        name: "Out of Battery",
        desc: "Your battery charge is down to 0%. You do not have enough power to operate the boat. You will need to be towed to the dock to recharge.",
        popup: true,
        icon: 300,
        dismiss: 0
    },            
    "403": {
        name: "Belt Temperature Error",
        desc: "The belt in your outboard has exceeded the operable temperature. Your boat has been turned off to allow the belt to cool down. When the belt returns to an acceptable temperature, the error will clear and you will be able to operate your boat again.",
        popup: true,
        icon: 300,
        dismiss: 0
    },
    "409": {
        name: "E-STOP Pressed",
        desc: "The emergency stop button was pressed, turning your boat off. If there is an emergency, call for help. Otherwise, release the E-stop and press the motor start button.",
        popup: true,
        icon: 300,
        dismiss: 1
    },    
    "424": {
        name: "Battery Management System Error",
        desc: "Data from the battery management system is not being transmitted. To protect your safety, operating and charging your boat have been disabled. Contact your service provider for maintenance if the problem persists.",
        popup: true,
        icon: 300,
        dismiss: 0
    },            
    "428": {
        name: "High Voltage Interlock Loop Error",
        desc: "The high voltage interlock loop, a safety feature that monitors the high voltage connections, is not functioning properly. Your boat has been turned off to protect your safety. Contact a service provider for maintenance.",
        popup: true,
        icon: 300,
        dismiss: 0
    },
    "430": {
        name: "Contactor Error",
        desc: "The contactors are not functioning properly. Your boat has been disabled for your safety. Contact a service provider for maintenance. ",
        popup: true,
        icon: 300,
        dismiss: 0
    },
    "431": {
        name: "Helm Communication Error",
        desc: "The communication between the helm controls and the boat is not functioning properly. Your boat has been turned off to protect your safety. Contact a service provider for maintenance.",
        popup: true,
        icon: 300,
        dismiss: 0
    },
    "437": {
        name: "Battery Management System Data Error",
        desc: "The battery management system is sending faulty data. To maintain safety, the boat has been turned off and charging has been disabled. Contact a service provider for maintenance.",
        popup: true,
        icon: 300,
        dismiss: 0
    },
    "438": {
        name: "Helm Data Error",
        desc: "The helm is sending faulty data to the system. To maintain safety, the boat has been turned off and charging has been disabled. Contact a service provider for maintenance.",
        popup: true,
        icon: 300,
        dismiss: 0
    },
    "439": {
        name: "High Voltage Inactive",
        desc: "The high voltage system is not functioning properly. Your boat has been turned off to protect your safety. Contact a service provider for maintenance.",
        popup: true,
        icon: 300,
        dismiss: 0
    },
    "440": {
        name: "Cable Error",
        desc: "The cables are not transmitting the proper voltage. Your boat has been turned off to protect your safety. Contact a service provider for maintenance. ",
        popup: true,
        icon: 300,
        dismiss: 0
    },
    "481": {
        name: "Flux BMS Contactor Critical Fault",
        desc: "The Battery Management System experienced a fault which caused the contactors to open which has disabled power to your system. Please try cycling the ignition.",
        popup: true,
        icon: 300,
        dismiss: 1
    },

};