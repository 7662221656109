import { 
    Box, 
    Modal, 
    CardHeader,
    TextField,
    Button
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../app/zustore';
import { fetchData } from '../../../app/QueryHandler';
import PublishIcon from '@mui/icons-material/Publish';
import { enqueueSnackbar } from 'notistack';
import { FleetTreeView } from '../../../fleet/FleetTreeView';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/groups/rename')({
    component: RenameGroup
  })

export function RenameGroup ({ initial_node }) {
    const [groupName, setGroupName] = useState('')
    const token = useAuth(state=>state.token)
    const [selectedNode, setSelectedNode] = useState(initial_node);
      
    const handleInputChange = (event) => {  
        setGroupName(event.target.value);
    };
        
    const handleCreateGroup = async () => {
        if (groupName.length>0){
            console.groupCollapsed('NewGroup-handleSubmit');
            // setStatus('loading')
            let requestBody = {
                node_name:groupName, 
                node_id:selectedNode.id
            };
        
            console.table(requestBody)
            console.log(JSON.stringify(requestBody))
            console.groupEnd();
            
            const {status, response} = await fetchData({method:'PUT', resource:'nodes', token:token, body:requestBody})
            if (status==400){
                enqueueSnackbar('Failed to create Group', {variant: 'error'})
            } else if (status==201||status==200){
                enqueueSnackbar('Group Created', {variant: 'success'})
                window.location.reload()
                
            }
            console.table(response)
        }
    }

    const header = () => {
        if (!selectedNode) return <h1>Rename a group</h1>
        else return <h1>Rename <span>{`${selectedNode.name}`}</span> {groupName && 'to'} <span>{groupName && groupName}</span></h1>
    }

    return (
        <div className='manage-page-1-col' >
                {header()}
                <div className='manage-page-2-col' >
                    
                    <FleetTreeView setSelectedNode={setSelectedNode} initial_selection={initial_node} variant={'from'}/>

                    {/* <div> */}
                    <TextField                         
                        label={"New name of group"}
                        className='text-field'
                        placeholder={`Enter a new name`}
                        variant="outlined"
                        defaultValue={''}
                        onChange={(value) => handleInputChange(value)}
                        />

                    <button 
                        className='confirm'
                        onClick={()=>{handleCreateGroup()}}>
                            <span>Submit</span>
                            <PublishIcon />
                    </button>
                    {/* </div> */}
                </div>
            </div>
    )
}