import ErrorBoundary from 'utils/ErrorBoundary';
import {  useVessel, useVesselList } from 'app/zustore';
import { VesselQuickSelector } from 'vessel/VesselQuickSelector';
import { createFileRoute, Link, Outlet, useLocation, useNavigate } from '@tanstack/react-router';
import { useEffect, useMemo } from 'react';

export const Route = createFileRoute('/_authenticated/vessels/manage')({
  component: VesselManager,
})

export default function VesselManager(){
    const vesselList = useVesselList(state=>state.vesselList);
    const { vessel_id } = Route.useSearch() || useVessel.getState().vessel_id
    const vessel_name = vesselList.find(v=>v.vessel_id==vessel_id)?.vessel_name || '';
    const vesselToUpdate = vesselList.find(v=>v.vessel_id==vessel_id)
    const location = useLocation();
    const navigate = useNavigate()
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    const actionTab = useMemo(()=>{
      return location.pathname.split('/')[location.pathname.split('/').length-1];
    },[location])

    useEffect(() => {
      console.log({vessel_id, location})
    }, []);

    return(
      <ErrorBoundary>
        <div className='manage-page'>

        <div style={styles.container}>
          <h3 style={{color:'var(--light-grey-3)', fontSize:'2.5em'}}>{vessel_name}</h3>

        <div className='btn-container'>
        
        <button className='fm-btn' style={{backgroundColor:actionTab==='edit'&&'#00b9b8', borderRadius:'5px', }}>
          <Link
            to="/vessels/manage/edit"
            params={{ vessel_id:vessel_id }} 
            mask={{to:`/vessels/${vessel_name.replace(' ', '-')}/manage/edit`}}
            >Edit Vessel</Link>
          </button>

        <button className='fm-btn' style={{backgroundColor:actionTab==='move'&&'#00b9b8', borderRadius:'5px', }}>
          <Link
              to="/vessels/manage/move"
              params={{vessel_id:vessel_id}} 
              mask={{to:`/vessels/${vessel_name.replace(' ', '-')}/manage/move`}}
              >Move Vessel</Link>
              </button>

        <button className='fm-btn' style={{backgroundColor:actionTab==='delete'&&'#00b9b8', borderRadius:'5px', }} >
          <Link
              to="/vessels/manage/delete"
              params={{vessel_id:vessel_id}} 
              mask={{to:`/vessels/${vessel_name.replace(' ', '-')}/manage/delete`}}
              >Delete Vessel</Link>
              </button>

        <button className='fm-btn' style={{backgroundColor:actionTab==='rename'&&'#00b9b8', borderRadius:'5px', }} >
          <Link
              to="/vessels/manage/rename"
              params={{vessel_id:vessel_id}} 
              mask={{to:`/vessels/${vessel_name.replace(' ', '-')}/manage/rename`}}
              >Rename Vessel</Link>
              </button>

        </div>

          <Outlet />

          {uuidRegex.test(vessel_id) &&
            <VesselQuickSelector vessel_id={vessel_id}/>
          }

        </div>
        </div>
      </ErrorBoundary>
    )
}

const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      placeContent: 'center',
      alignSelf:'center'
    },
    sidebarStyle: {
      width: '25%',
      backgroundColor: 'blue',
      display: 'flex',
      flexDirection: 'column',
    },
    topSidebarSectionStyle: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '18px',
      color: 'white',
      borderBottom: '1px solid #fff',
    },
    bottomSidebarSectionStyle: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '18px',
      color: 'white',
    },
    mainContentStyle: {
      flex: 1,
    }
}