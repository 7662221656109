import * as React from "react";

export const MiniUI = ({index, soc, hp, mph, dir, system_on})=>{

    let soc_height = Math.round(98 * (1-(soc/100)))
    let hp_height = Math.max(0, Math.min(98, Math.round(98 * (1-(hp/100)))))
    let accent_color = system_on ? '#00FFFE' : '#4A5468'


    return(    
    <svg className='vessel-overview-mini-ui' width="95%" height="100%" viewBox="0 0 340 164" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Mini UI">
            <g id="Battery">
                <mask id='mini-ui-battery-mask'>
                    <rect x="12.5%" y="25" width="12" height="98" rx="2" fill="white"/>
                </mask>

                <rect id="Rectangle 5" x="12.5%" y="25" width="12" height="98" mask='url(#mini-ui-battery-mask)' fill={system_on ? "#12F17A" : 'var(--light-grey-1)'} />
                <rect id="Rectangle 3" x="12.5%" y="25" width="12" height={soc_height} mask='url(#mini-ui-battery-mask)' fill={system_on ? "#A0ABC0" : '#2D3648'}/>
                <text id="70%" fill="white" fontFamily="Inter" fontSize="24" letterSpacing="0.10em">
                    <tspan textAnchor='end' x="17.5%" y="150">{format_numerical_text(soc)}</tspan>
                </text>
                <text id="70%" fill="white" fontFamily="Inter" fontSize="18" letterSpacing="0.10em">
                    <tspan textAnchor='start' x="18.5%" y="150">%</tspan>
                </text>
            </g>
            <g id="Power">
                <mask id='mini-ui-power-mask'>
                    <rect x="85%" y="25" width="12" height="98" rx="2" fill="white"/>
                </mask>

                <rect id="Rectangle 6" x="85%" y="25" width="12" height="98" mask='url(#mini-ui-power-mask)' fill={system_on ? "#00B7BE" : '#2D3648'}/>
                <rect id="Rectangle 4" x="85%" y="25" width="12" height={hp_height} mask='url(#mini-ui-power-mask)' fill={system_on ? "#A0ABC0" : '#2D3648'}/>
                <text id="65hp" fill="white" fontFamily="Inter" fontSize="24" letterSpacing="0.10em">
                    <tspan textAnchor='end' x="88%" y="150">{format_numerical_text(hp)}</tspan>
                </text>
                <text id="65hp" fill="white" fontFamily="Inter" fontSize="18" letterSpacing="0.10em">
                    <tspan textAnchor='start' x="89.25%" y="150">hp</tspan>
                </text>
            </g>
            <g id="Center" data-dir={dir}>
                <path id="Ellipse 1" d="M251 82C251 127.287 214.287 164 169 164C123.713 164 87 127.287 87 82C87 36.7127 123.713 0 169 0C214.287 0 251 36.7127 251 82ZM91.1738 82C91.1738 124.982 126.018 159.826 169 159.826C211.982 159.826 246.826 124.982 246.826 82C246.826 39.0178 211.982 4.1738 169 4.1738C126.018 4.1738 91.1738 39.0178 91.1738 82Z" fill={`url(#mini-ui-accent-grad-${index})`}/>
                <g id="Speedo">
                    <text id="18" fill="white" fontFamily="Inter" fontSize="48" letterSpacing="0.05em">
                        <tspan textAnchor='middle' x="50%" y="90.9091">{format_numerical_text(mph)}</tspan>
                    </text>
                    <text id="mph" fill="white" fontFamily="Inter" fontSize="13" letterSpacing="0.10em">
                        <tspan textAnchor='middle' x="50%" y="108.727">mph</tspan>
                    </text>
                </g>
                <g id="FNR">
                    <text id="F" fill={system_on ? (dir=='F'?"#00B7BE":"white") : '#4A5468'} fontFamily="Inter" fontSize={system_on ? (dir=="F"?"24":'10') : 10} fontWeight="600" letterSpacing="0.10em">
                        <tspan 
                            textAnchor='middle' 
                            x={dir=="F"?"226":"224"} 
                            y={system_on ? (dir=="F" ?"76.5":"68.4545") : '76.5'}>F</tspan>
                    </text>
                    <text id="N" fill={system_on ? (dir=='N'?"#00B7BE":"white") : '#4A5468'} fontFamily="Inter" fontSize={system_on ? (dir=="N"?"24":"10") : 10} letterSpacing="0.10em">
                        <tspan 
                            textAnchor='middle' 
                            x="224" 
                            y="91.2727">N</tspan>
                    </text>
                    <text id="R" fill={system_on ? (dir=='R'?"#00B7BE":"white") : '#4A5468'} fontFamily="Inter" fontSize={system_on ? (dir=="R"?"24":"10") : 10} letterSpacing="0.10em">
                        <tspan 
                            textAnchor='middle' 
                            x="224" 
                            y="106.273">R</tspan>
                    </text>
                </g>
            </g>
        </g>
        <defs>
            <linearGradient id={`mini-ui-accent-grad-${index}`}x1="169" y1="0" x2="169" y2="164" gradientUnits="userSpaceOnUse">
                <stop stopColor={accent_color} stopOpacity="0" offset={dir=='F'?"0":"0.15"}/>
                <stop stopColor={accent_color} offset={dir=='F'?"0":"0.5"}/>
                <stop offset="0.850129" stopColor={accent_color} stopOpacity="0"/>
            </linearGradient>
        </defs>
    </svg>
    )
}

export function format_numerical_text(num, digits=2){
    let minNum = Math.pow(10, digits-1);
    let formatted_num = num;
    if (typeof(formatted_num)=="string"){
        formatted_num = Math.floor(parseFloat(formatted_num)*10)/10
    } 
    formatted_num = Math.round(formatted_num*minNum)/minNum
    if (formatted_num>=minNum){
        // is at least 2 digits, make it an integer
        return Math.round(formatted_num)
    } else if (formatted_num==0){
        // zero, ensure consistent format
        return String(Math.pow(10, digits)).slice(1)
    } else if (formatted_num - Math.floor(formatted_num) > 0){
        // there is a decimal
        return String(Math.round(formatted_num*minNum)/minNum)
    } else {
        // left pad single digit integers
        if (formatted_num<0){
            console.log("Input", num, "Intermediate", formatted_num, "Output", String(Math.round(formatted_num*minNum)/minNum))
            return `-${Math.abs(formatted_num)}`
        }
        let lPad = String(Math.pow(10, digits-String(Math.round(formatted_num)).length)).slice(1)
        return `${lPad}${Math.round(formatted_num)}`
    }
}

