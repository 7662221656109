import { TextField } from '@mui/material';
import { usePlots } from '../../app/zustore';

export const PresetNamer = ({handleInputChange, initialName}) => {

    // Determine the default value based on the field's path and field name
    let defaultValue = initialName;
  
      const styles = {
        editorOuterDiv: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', // Align items vertically
            justifyContent: 'space-between', // Space between h3 and TextField
            width: '100%',
            marginTop: '10px',
            // backgroundColor: 'white'
        },
        editorInnerDiv: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', // Align items vertically
            justifyContent: 'space-between', // Space between h3 and TextField        
            width: '100%',
        },
        input: {
            padding: 10,
            // backgroundColor: 'white',
            paddingRight: '30px',
            borderRadius: '5px',
            width: '70%',
            maxWidth: '500px'
        }
      }
      
      return (
            <TextField 
                    label='Save as a new preset'
                    placeholder={`Enter a name`}
                    variant="filled"
                    style={styles.input}
                    defaultValue={defaultValue}
                    onChange={(value) => handleInputChange(value)}
                    inputProps={{}}
              />
    )
  }