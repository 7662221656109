import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import Select from 'react-select';
import dayjs from 'dayjs';

const timeOptions = {
  minutes: [5, 10, 15, 30, 45],
  hours: [1, 2, 3, 6, 8, 12],
  days: [1, 2, 3, 4, 5, 6],
  weeks: [1, 2, 3, 4],
};

const units_to_seconds = {
    minutes: 60*1000,
    hours: 60*60*1000,
    days: 60*60*24*1000,
    weeks: 60*60*24*7*1000,
}

const RelativeTimePicker = ({ isMobile, setStartTime, setEndTime }) => {
  const [selTime, setSelectedTime] = useState({unit:'hours', value:12});
  const [selUnit, setUnit] = useState('days')

  const handleTimeSelect = (unit, value) => {
    setSelectedTime({ unit:unit, value:value });
    setEndTime(dayjs(Date.now()));
    const newTime = dayjs(Date.now() - (units_to_seconds[unit]*value));
    setStartTime(newTime);
  };

  const handleSelectorChange = (event) => {
    setUnit(event.value);
  };  

  const handleInputChange = (event)=>{
    const newTime = dayjs(Date.now() - (units_to_seconds[selUnit]*event.target.value));
    setEndTime(dayjs(Date.now()));
    setStartTime(newTime);
  }

  return (
    <div className={`time-picker ${isMobile&&'mobile'}`}>
        <div className='time-picker-quick-boxes'>
            {Object.entries(timeOptions).map(([unit, values]) => (
                <div key={unit} className="time-unit">
                    <div className="unit-label">{unit.charAt(0).toUpperCase() + unit.slice(1)}</div>
                    <div className="unit-buttons">
                        {values.map((value) => (
                        <button
                            key={value}
                            className={`time-button ${(selTime.unit === unit)&&(selTime.value==value) ? 'selected' : ''}`}
                            onClick={() => handleTimeSelect(unit, value)}
                        >
                            {value}
                        </button>
                        ))}
                    </div>
                </div>
            ))}
        </div>

        <Box className={`dataset-loader-duration ${isMobile&&'mobile'}`}>
            <Box className={`input`}>
                <span style={{color:'white'}}>Duration</span>
                <TextField type='number' onChange={(e)=>handleInputChange(e)}/>
            </Box>

            <div style={{ height: "100%", justifyContent: 'center', alignItems: 'flex-start', display:'flex', flexDirection:'column', marginBottom:'6px' }}>
                <a style={{fontWeight:'400',  marginInlineEnd:'20px', marginTop:'8px', color:'white'}}>Select Unit:</a>
                <Select
                    options={Object.keys(timeOptions).map(t=>({label:t.toLocaleUpperCase(), value:t}))}
                    styles={{width:'300px'}}
                    isMulti={false}
                    isClearable={false}
                    onChange={(event)=>handleSelectorChange(event)}
                    value={{label:selUnit, value:selUnit}}
                />
            </div>
        </Box>
    </div>
  );
};

export default RelativeTimePicker;
