import * as React from 'react' ;

const Plotly = window.Plotly;

export const FaultTreeViewer = ({faults, isMobile})=>{
    const treeplotContainerRef = React.useRef(null);
    const [plotRendered, setPlotRendered] = React.useState(false)

    const treeplotData = React.useMemo(() => {
        if (faults.length > 0) {
            console.groupCollapsed('Fault viewer plotData useMemo');
    
            let dataMap = {
                'all': {
                    label: 'all',
                    fault_count: 0, // Initialize with 0 and accumulate
                    id: 'all',
                    parent: ''
                }
            };
    
            faults.forEach(row => {
                const severity = parseInt(row.fault / 100) * 100;
                const severityId = `all/severity-${severity}`;
                const faultId = `${severityId}/${row.fault}`;
                let vesselName = row.vessel_name || row.vessel_id;
                const vesselId = `${faultId}/${vesselName}`;
    
                // Aggregate total faults
                dataMap['all'].fault_count += row.fault_count;
    
                // Aggregate severity
                if (!dataMap[severityId]) {
                    dataMap[severityId] = {
                        label: `severity-${severity}`,
                        fault_count: 0,
                        id: severityId,
                        parent: 'all'
                    };
                }
                dataMap[severityId].fault_count += row.fault_count;
    
                // Aggregate faults
                if (!dataMap[faultId]) {
                    dataMap[faultId] = {
                        label: `${row.fault} - ${row.desc}`,
                        fault_count: 0,
                        id: faultId,
                        parent: severityId
                    };
                }
                dataMap[faultId].fault_count += row.fault_count;
    
                // Aggregate vessels
                if (!dataMap[vesselId]) {
                    dataMap[vesselId] = {
                        label: `${vesselName}`,
                        fault_count: row.fault_count,
                        id: vesselId,
                        parent: faultId
                    };
                } else {
                    dataMap[vesselId].fault_count += row.fault_count;
                }
            });
    
            const data = Object.values(dataMap);
            console.table(data);
            console.groupEnd();
    
            const traces = [{
                type: "treemap",
                branchvalues: "total",
                ids: data.map(d => d.id),
                labels: data.map(d => d.label),
                parents: data.map(d => d.parent),
                values: data.map(d => d.fault_count),
                textinfo: "label+value+percent parent+percent entry",
            }];
    
            return traces;
        } else {
            return [];
        }
    }, [faults]);

    React.useEffect(() => {
        if (treeplotData.length>0){
            // Render the plot into the div with a ref attached
            const layout={          
            hoverlabel: {bgcolor: 'rgba(200, 200, 200, 0.1)'},
            color:'rgba(255, 255, 255, 0.2)',
            paper_bgcolor: '#CBD2E0',
            plot_bgcolor: 'rgba(255, 255, 255, 0.2)',
            // autosize: true,
            fillpattern: {bgcolor:'rgba(250, 250, 250, 0.2)', fillmode:'overlay'},
            margin: isMobile?{l:30, r:35, t:30, b:30}:{l:15, r:15, t:35, b:40},
            height: isMobile?300:350
            }
        
            const config = {
            responsive: true, 
            modeBarButtonsToRemove: [
                'hoverClosestGl2d', 
                'hoverCompareCartesian '
            ]
            }
            // console.log(plotContainerRef.current)
            if (!plotRendered){
            console.log('rendering new preset plot')
            Plotly.newPlot(treeplotContainerRef.current, treeplotData, layout, config);
            // plot.on(event=>console.log('plotly event detected', event));
            setPlotRendered(true);
            } else {
            Plotly.react(treeplotContainerRef.current, treeplotData, layout, config);
            }
        }
    
    }, [treeplotData]); // Redraw the plot if data or layout props change

    return (
        <div className='fault-viewer-plot-area' ref={treeplotContainerRef} style={{minWidth:'250px', width:'100%', minHeight:'250px', height:'100%'}} />
    )
}