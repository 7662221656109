import * as React from 'react' ;

const Plotly = window.Plotly;

export const FaultLinePlot = ({faults, isMobile})=>{
    const lineplotContainerRef = React.useRef(null);
    const [lineplotRendered, setLinePlotRendered] = React.useState(false);

    const lineplotData = React.useMemo(() => {
        if (faults.length > 0) {
            console.groupCollapsed('Fault viewer plotData useMemo');
    
            let dataMap = {
                'all': {
                    label: 'all',
                    fault_count: 0, // Initialize with 0 and accumulate
                    id: 'all',
                    parent: ''
                }
            };
    
            const data = Object.values(dataMap);
            console.table(data);
            console.groupEnd();
            
            // add a trace for each severity
            const traces =  [0, 100, 200, 300].map(s=>{
                const severity = faults.filter(f=>f.severity==s);
                let traceData = {}

                // aggregate by date
                severity.forEach(row=>{
                    if (!traceData[row.date]){
                        traceData[row.date] = {date:row.date, count:row.fault_count}
                    } else {
                        traceData[row.date].count += row.fault_count
                    }
                })

                // sort the data by date
                traceData = Object.values(traceData).map(day=>({...day, date: new Date(day.date.replace("_","-"))})).sort((a, b) => {return a.date - b.date;})

                return {
                    type: "scatter",
                    mode: "lines",
                    name: `severity-${s}`,
                    line: {shape: 'spline', width:3},
                    x:    traceData.map(d => d.date),
                    y:    traceData.map(d => d.count)
                }
            })
    
            return traces;
        } else {
            return [];
        }
    }, [faults]);

    React.useEffect(() => {
        // console.log('plot data changed, updating plot', plotData)
        // Render the plot into the div with a ref attached
        if (lineplotData.length>0){
            const layout={
                title: {text:isMobile?'Faults':`Faults - last 30 days`, x:0, pad: {l:20}},
                xaxis: { 
                    title: 'date',
                    tickmode: "auto",
                    type: 'date',
                    nticks: 4,
                    tickformat: "%Y-%m-%d",
                    tickangle: 0, 
                },
                yaxis: { title: "Count" },
                margin: isMobile?{l:45, r:35, t:30, b:30}:{l:55, r:25, t:35, b:50},
                editable: false,
                hovermode: "x-unified",
                hoverinfo: 'x+y',
                hoverlabel: {bgcolor: 'rgba(255, 255, 255, 1)'},
                hovertext: {bgcolor: 'rgba(255, 255, 255, 1)'},
                color:'rgba(255, 255, 255, 1)',
                paper_bgcolor: '#CBD2E0',
                plot_bgcolor: '#4A5468',
                autosize: true,
                automargin:true,
                //   fillpattern: {bgcolor:'rgba(0, 0, 0, 0.0)', fillmode:'overlay'},
                legend: {
                    x: 0,
                    y: 1,
                    xanchor: "left",
                    yanchor: "top",
                    bgcolor: "#A0ABC0",
                    bordercolor: "rgba(0, 0, 0, 0.2)",
                    orientation: 'h',
                    borderwidth: 1,
                },
                height: isMobile?300:350,
                responsive: true,
                font: {
                    color: '#110D22',
                    family: 'Inter'
                }            
            }
        
            const config = {responsive: true,}
            // console.log(plotContainerRef.current)
            if (!lineplotRendered){
                console.log('rendering new preset plot')
                Plotly.newPlot(lineplotContainerRef.current, lineplotData, layout, config);
                setLinePlotRendered(true);
            } else if (lineplotRendered) {
                Plotly.react(lineplotContainerRef.current, lineplotData, layout, config);
            }
        }
    
      }, [lineplotData]); // Redraw the plot if data or layout props change

    return (
        <div 
            className='fault-viewer-plot-area' 
            ref={lineplotContainerRef} 
            style={{minWidth:'250px', width:'100%', minHeight:'250px', height:'100%'}} 
        /> 
    )
}