import { useEffect, useState } from 'react';
import { fetchData } from 'app/QueryHandler';
import PublishIcon from '@mui/icons-material/Publish';
import { enqueueSnackbar } from 'notistack';
import { FleetTreeView } from 'fleet/FleetTreeView';
import { createFileRoute } from '@tanstack/react-router';
import { useAuth, useGroups, useVesselList } from 'app/zustore';
import { VesselUpdater } from '../../../../vessel/manage/vesselUpdater';
import { VesselTreeUpdater } from '../../../../vessel/manage/vesselTreeUpdater';
import { InputField } from '../../../../Panels/AddVessel/vesselScreenUtils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const Route = createFileRoute('/_authenticated/vessels/manage/edit')({
    component: EditGroup
  })

export function EditGroup ({  }) {
    const {groups, updateGroups} = useGroups((state) => ({ groups: state.groups, updateGroups:state.updateGroups}))
    const token = useAuth(state=>state.token)
    const vesselList = useVesselList(state=>state.vesselList);
    const { vessel_id } = Route.useParams()
    const vessel_name = vesselList.find(v=>v.vessel_id==vessel_id)?.vessel_name || '';
    const vesselToUpdate = vesselList.find(v=>v.vessel_id==vessel_id)
    const [selectedFields, setSelectedFields] = useState([])
    const queryClient = useQueryClient();
      const [fieldData, setFieldData] = useState({})

    const [fieldValidity, setFieldValidity] = useState({
        "vessel_name": false,
        "device_id": false,
    }
    );
        
    const handleUpdateVessel = async () => {
        console.groupCollapsed('NewGroup-handleSubmit');
        // setStatus('loading')
        let newVessel = {'vessel_id': vesselToUpdate.vessel_id};
        if (fieldData['.']) {
            newVessel = { ...newVessel, ...fieldData['.'] };
        }
        if (fieldData['outboards']) {
            newVessel.outboards = [fieldData['outboards']];
        }
        if (fieldData['outboards[0].prop']) {
            if (!newVessel.outboards) newVessel.outboards = [{}];
            newVessel.outboards[0].prop = fieldData['outboards[0].prop'];
        }
    
        console.groupEnd();
        if (window.confirm(`Are you sure you want to update ${vessel_name}?`)){
            const {status, response} = await fetchData({method:'PUT', resource:'vessel-info', token:token, body:newVessel})
            if (status==400){
                enqueueSnackbar(`Failed to update ${vessel_name}`, {variant: 'error'})
            } else if (status==201||status==200){
                enqueueSnackbar('Vessel Updated!', {variant: 'success'})
                updateGroups(response)
                
            }
            console.table(response)
        }
    }

    const edit_vessel = useMutation({ // ReactQuery Mutation, handles the request
            mutationFn: async (vessel) => {
              // vessel is the vessel object to be sent to the API
              const token = useAuth.getState().token
              let {status, response} = await fetchData({ method: 'PUT', resource: 'vessel-info', token: token, body: vessel })
              console.log("response from POST vessel-info", response, status)
              if (status == 400) {
                throw new Error('Vessel name already in use!')
              }
              return response
            },
            onSuccess: () => {
              queryClient.invalidateQueries({ queryKey: ['groups'] })
              queryClient.invalidateQueries({ queryKey: ['vessel-info'] })
              enqueueSnackbar("New Vessel Added! 🚤🚤", { variant: 'QueryInfo' })
            },
            onError: (error) => {
              console.info(error)
              enqueueSnackbar("Failed to add new Vessel!", { variant: 'error' })
            }
    })

    useEffect(() => {
        console.log(selectedFields)
    }, [selectedFields]);

    const validateInput = (value, field) => {
            // console.log("validateInput", value, field, value.length)
            return value.length > 0;
          }
        
          const handleInputChange = (event, field) => {
            console.groupCollapsed("handleInputChange");
            console.log(event.target.value)
            console.log(field)
            const isValid = validateInput(event.target.value, field); // Replace `validateInput` with your own validation function
            console.log('isValid =', isValid)
        
            setFieldValidity((prevValidity) => ({
              ...prevValidity,
              [field.field]: isValid,
            }));
        
            setFieldData({ ...fieldData, [field.path]: { ...fieldData[field.path], [field.field]: event.target.value } });
        
            console.table({ ...fieldData, [field.path]: { ...fieldData[field.path], [field.field]: event.target.value } })
        
            console.groupEnd();
          };

    const button_disabled = ()=>{
        if (
            !vessel_id
            // || selectedNode?.children
            // || !selectedNode?.can_write
        ) return true;
        else {
            return false;
        }
      }

    return (
        <div className='manage-page-1-col' >
                {/* {selectedNode?.children && <h3 style={{color:'var(--red-alt)'}}>Group must have no subgroups</h3>} */}
                <div className='outer' >
                    
                    {/* <VesselUpdater vesselToUpdate={vesselToUpdate}/> */}
                    <VesselTreeUpdater vesselToUpdate={vesselToUpdate} selectedFields={selectedFields} setSelectedFields={setSelectedFields}/>
                    
                    <div className='input-fields'>
                        {selectedFields.filter(f=>f.children==null).sort((fieldA, fieldB)=>fieldA.rank-fieldB.rank).map((field, index) => (
                        <InputField
                            key={field.rank}
                            label={field.label}
                            index={index}
                            optional={false}
                            field={field}
                            vesselToUpdate={vesselToUpdate}
                            // style={styles.required}
                            onChange={(value) => handleInputChange(value, field)}
                        />))}
                    </div>
                    
                </div>
                <button 
                    className='confirm'
                    disabled={button_disabled()}
                    onClick={()=>{handleUpdateVessel()}}     
                    >
                        Update
                      <PublishIcon />
                </button>
            </div>
    )
}
