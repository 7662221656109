import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useAuth, useGroups, useSelectedNodeStore, useUser } from '../../../app/zustore';
import { useState } from 'react';
import { FleetTreeView } from '../../../fleet/FleetTreeView';
import { fetchData } from '../../../app/QueryHandler';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/groups/move')({
    component: MoveNode
})

export default function MoveNode({ initial_node }) {
  const user = useUser(state => state.user);
  const {groups, updateGroups} = useGroups((state) => ({ groups: state.groups, updateGroups:state.updateGroups}))
  const {selectedNode, setSelectedNode} = useSelectedNodeStore();
  const [destinationNode, setDestinationNode] = useState(null);

  const handleConfirmMove = async () => {
    if (!selectedNode || !destinationNode || selectedNode.id===destinationNode.id) return;
    if (window.confirm(`Are you sure you want to move ${selectedNode.name} to ${destinationNode.name}?`)){
        console.log('Moving', selectedNode.name, 'to', destinationNode.name)
        let requestBody = {
            node_id: selectedNode.id,
            parent_node_id: destinationNode.id
        }
        const JWT = useAuth.getState().token
        let {status, body} = await fetchData({method:'PUT', resource:'nodes/move', body:requestBody, token:JWT})
        console.log({status, body})
        if (status===200){
            console.log('Delete successful')
            // window.location.reload()
            // let updated_node = {...groups.find(group=>group.node_id===selectedNode.id), parent_id:destinationNode.id}
            // let new_parent = groups.find(group=>group.node_id===destinationNode.id)
            //     new_parent = {...new_parent, children:[...new_parent.children, updated_node]}
            // let old_parent = groups.find(group=>group.node_id===selectedNode.parentId)
            //     old_parent = {...new_parent, children:[...new_parent.children, updated_node]}
            // let updated_groups = [...groups, updated_node, new_parent, old_parent]
            // console.log(groups, updated_groups)
            updateGroups(body)
        } else {
            console.log('Move failed')
        }

    } else {
        console.log('Move canceled')
    }
  }

  const button_disabled = ()=>{
    if (
        !selectedNode 
        || !destinationNode 
        || selectedNode.id===destinationNode.id
        || selectedNode.parentId===destinationNode.id
        || !selectedNode.can_write
        || !destinationNode.can_write
    ) return true;
    else {
        return false;
    }
  }

    return (
    <DndProvider backend={HTML5Backend}>
    <div className='manage-page-2-col'>
        <h2 className='move-text'>Move <span>{selectedNode?.name}</span> {selectedNode?.parentName && `from`} <span>{selectedNode?.parentName && selectedNode?.parentName}</span> to <span>{destinationNode?.name}</span></h2>
        
        <div className='move-node-trees'>
        {user?.nodes &&
            <FleetTreeView isSidebar={false} initial_selection={selectedNode} variant={'from'}/>
        }   
        
        {user?.nodes &&
            <FleetTreeView isSidebar={false} alt_setter={setDestinationNode} initial_selection={initial_node} variant={'to'}/>
        }   
        </div>

        <button className='confirm' onClick={handleConfirmMove} disabled={button_disabled()}>Move</button>
    </div>
    </DndProvider>
)}