import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
    Badge,
    Box,
    Typography,
    Tooltip
} from '@mui/material';
import BoatIcon from '@mui/icons-material/DirectionsBoat'; // Assuming you're using this icon for boats
import PersonIcon from '@mui/icons-material/Person';

// import { TreeView } from '@mui/x-tree-view/TreeView';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { TreeItem2 } from '@mui/x-tree-view/TreeItem2';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { useUser, useGroups, useSelectedNodeStore } from '../app/zustore';
import { useEffect, useMemo, useState } from 'react';
import { useTreeViewApiRef } from '@mui/x-tree-view/hooks';

export const FleetTreeView = ({ isSideBar, initial_selection, alt_setter, variant, isStatic }) => {
    const user = useUser(state => state.user);
    const { groups, setSelectedGroup } = useGroups((state) => ({ groups: state.groups, setSelectedGroup: state.setSelectedGroup }));
    const [expandedItems, setExpandedItems] = useState((!isSideBar && groups) ? groups.map(g=>g.node_id) : Object.keys(user.nodes))
    const {selectedNode, setSelectedNode} = useSelectedNodeStore();
    const [selectedTreeNode, setSelectedTreeNode] = useState(selectedNode?.id||null)
    const apiRef = useTreeViewApiRef();

    useEffect(() => {
        if (initial_selection) {
            setSelectedTreeNode(initial_selection);
        }
    }, [initial_selection]);
 
    const mapChildren = (nodeId) => {
        console.log('recursing with mapChildren', nodeId)
        const node = groups.find(group => group.node_id === nodeId);
        if (!node) {
            console.log("Could not find node:", nodeId)
            return null;
        };

        // Recursively build the children nodes
        const children = node.child_node_ids ? node.child_node_ids.map(childNode => mapChildren(childNode)) : null;

        // Aggregate vessel IDs from the current node and its children
        const vesselIds = new Set(node.vessel_ids || []);
        if (children) {children.forEach(child => {if (child && child.vessels) {child.vessels.forEach(vesselId => vesselIds.add(vesselId));}});}
        
        return {
            id: node.node_id,
            name: node.node_name,
            label: node.node_name.replace("'s_Default_Group", ""),
            vessels: Array.from(vesselIds),
            members: node.members ? node.members : [],
            can_write: node.can_write,
            children: children,
            parentId: node?.parent_node?.id,
            parentName: node?.parent_node?.name,
            can_write: node.can_write
        };
    };        

    const data = useMemo(() => {
        console.groupCollapsed('FleetManagement useMemo - data');
        if (!groups) {
            console.log("No groups found");
            return null;
        }
        const roots = [groups.find(g=>g.node_id==Object.keys(user?.nodes)[0])] || [null];
        console.log("found root", user?.nodes, roots)
        if (!roots) {
            console.log("Could not find root node");
            return null;
        }
        const mapped_data = roots.map(root=>mapChildren(root.node_id));
        const nodeData = groups.find(group => group.node_id === mapped_data.id);
        setSelectedGroup(nodeData);
        setSelectedNode(mapped_data[0]);
        console.log(mapped_data)
        console.groupEnd();
        return mapped_data;
    }, [groups, user]);

    const handleExpandedItemsChange = (event, newExpandedItem, isExpanded) => {
        if (isStatic){
            return
        }
        console.log('Expanded items changed:', newExpandedItem);
        if (isExpanded) {
            setExpandedItems([...expandedItems, newExpandedItem]);
        } else {
            if (!Object.keys(user.nodes).find(g=>g==newExpandedItem)){
            setExpandedItems(expandedItems.filter(item => item !== newExpandedItem));}
        }
        // setExpandedItems([...expandedItems, newExpandedItem] || Object.keys(user.nodes));
    };

    if (!data) {
        return <div>User {user.user_name} does not belong to any groups!</div>
    }
    return (
        <div className={`Fleet-Treeview-div ${isSideBar ? 'side-bar' : 'manage'}`} style={{ 
            
            }}>
            <RichTreeView
                aria-label="file system navigator"
                className={`Fleet-Treeview ${variant}`}
                expandedItems={expandedItems}
                apiRef={apiRef}
                // onExpandedItemsChange={handleExpandedItemsChange}
                disableSelection={isStatic}
                selectedItems={selectedTreeNode}
                defaultSelectedItems={initial_selection || data[0].id}
                onItemExpansionToggle={handleExpandedItemsChange}                
                sx={{
                    paddingTop: '2px',
                    paddingRight: '2px',
                    paddingBottom: '2px',
                    maxHeight: '80vh',                    
                }}
                onSelectedItemsChange={(event, nodeId) => {
                    if (isStatic){
                        return
                    }
                    const newGroup = groups.find(group => group.node_id === nodeId);
                    console.groupCollapsed('selecting a new node in the Tree Viewer', nodeId);
                    const newNode = apiRef.current.getItem(nodeId)
                    console.log('new Node selected in Fleet Tree View', newGroup, newNode, groups)
                    console.groupEnd();
                    if (alt_setter){
                        alt_setter(newNode)
                    } else {
                        setSelectedNode(newNode);
                    }
                    setSelectedGroup(newGroup);
                    setSelectedTreeNode(nodeId)
                }}
                items={data} 
                />
            {/* >
                <RenderTree data={data} hideIcons={hideIcons} setSelectedNode={setSelectedNode} isSideBar={isSideBar}/>
            </RichTreeView> */}
        </div>
    );
}
