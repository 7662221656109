import React, { useEffect, useMemo } from 'react';
import { Plot2d } from '../utils/Plots/EChartPlots'
import { Grid, Card, CardContent } from '@mui/material';
import { useVessel, useFleetData, useLiveData } from "../app/zustore";
import { useParams } from '@tanstack/react-router';
import { Expander } from '../fleet/Expander';

function GaugeGrid({ data, vessel_id, isMobile }) {
  // console.log("Gauge grid, ", {current: current, data: data});
  const fleetData = useFleetData((state) => state.fleetData);

  const gauges = useMemo(() => {
    console.groupCollapsed("GaugeGrid useMemo")
    console.log(vessel_id)
    console.log(data[data.length-1])
    //_n_.table(fleetData)']
    console.table(data?.slice(data.length-10, data.length-1))
    console.groupEnd()
    return { 
    telemetry: {
      title: 'Telemetry',
      show: true,
      gauges: [
        {
          value:data[data.length-1]?.['telemetry_data.inst_speed'] ?? '--',
          title:"GPS Speed", 
          units:"mph",
          x: "sample_time_str", 
          y:"telemetry_data.inst_speed"
        },
        {
          value:data[data.length-1]['total_distance_mi'] ?? data[data.length-2]['total_distance_mi'] ?? '--',
          title:"Total Distance", 
          units:"miles",
          x: "sample_time_str", 
          y:"total_distance_mi"
        },
        {
          value:data[data.length-1]['telemetry_data.distance'] ?? '--',
          title:"Distance", 
          units:"meters",
          x: "sample_time_str", 
          y:"telemetry_data.distance"
        },
    ]},
    motor: {
      title: 'Motor',
      show: true,
      gauges: [
      {
        value:data[data.length-1]['battery_data.electrical_power'] ?? "--",
        title:"Electrical Power", 
        units:"kW",
        x: "sample_time_str", 
        y:"battery_data.electrical_power"
      },
      {
        value:  data[data.length-1]?.['motor_data.0.throttle_percentage'] ?? "--",
          title:"Throttle", 
          units:"%",
          x: "sample_time_str", 
          y:"motor_data.0.throttle_percentage"
      },
      {
        value:  data[data.length-1]?.['motor_data.0.velocity'] ?? "--",
          title:"Motor Rpm", 
          units:"rpm",
          x: "sample_time_str", 
          y:"motor_data.0.velocity"
      },
      {
        value:  data[data.length-1]?.['motor_data.0.horse_power'] ?? "--",
          title:"Motor Horsepower", 
          units:"hp",
          x: "sample_time_str", 
          y:"motor_data.0.horse_power"
      },
      {
        value:  data[data.length-1]?.['motor_data.0.prop_horse_power'] ?? "--",
          title:"Prop Motor Horsepower", 
          units:"hp",
          x: "sample_time_str", 
          y:"motor_data.0.prop_horse_power"
      },
      {
        value:  data[data.length-1]?.['motor_data.0.torque'] ?? "--",
          title:"Motor Torque", 
          units:"Nm",
          x: "sample_time_str", 
          y:"motor_data.0.torque"
      },
      {
        value:data[data.length-1]?.['ecu_data.0.sensors_trim_tilt_angle'] ?? '--',
          title:"Trim Tilt Angle", 
          units:"°",
          x: "sample_time_str", 
          y:"ecu_data.0.sensors.trim_tilt_angle"
      },
      {
        value:  data[data.length-1]?.['motor_data.0.rpm_limit'] ?? "--",
          title:"RPM Limit", 
          units:"rpm",
          x: "sample_time_str", 
          y:"motor_data.0.rpm_limit"
      },
      {
        value:  data[data.length-1]?.['motor_data.0.torque_limit'] ?? "--",
          title:"Motor Torque limit", 
          units:"Nm",
          x: "sample_time_str", 
          y:"motor_data.0.torque_limit"
      },
    ]
    },
    battery: {
      title: 'Battery',
      show: false,
      gauges: [
      {
        value:data[data.length-1]['battery_data.battery_soc'] ?? '--',
        title:"State Of Charge", 
        units:"%",
        x: "sample_time_str", 
        y:"battery_data.battery_soc"
      },
      {
        value:data[data.length-1]['battery_data.battery_voltage'] ?? '--',
        title:"HV Battery Voltage", 
        units:"V",
        x: "sample_time_str", 
        y:"battery_data.battery_voltage"
      },
      {
        value:data[data.length-1]['battery_data.battery_current'],
        title:"HV Battery Current", 
        units:"A",
        x: "sample_time_str", 
        y:"battery_data.battery_current"
      },
      {
        value:data[data.length-1]?.['battery_data.low_voltage'] ?? '--',
        title:"LV Battery Voltage", 
        units:"V",
        x: "sample_time_str", 
        y:"battery_data.low_voltage"
      },
      {
        value:data[data.length-1]?.['battery_data.lv_current'] ?? '--',
          title:"LV Battery Current", 
          units:"A",
          x: "sample_time_str", 
          y:"battery_data.lv_current"
      },
      {
        value: data[data.length-1]['battery_data.high_cell_voltage'] ?? '--',
        title:"Highest Cell Voltage", 
        units:"",
        x: "sample_time_str", 
        y:"battery_data.high_cell_voltage",
        sigfigs: 3
      },
      {
        value: data[data.length-1]['battery_data.cell_avg_voltage'] ?? '--',
        title:"Average Cell Voltage", 
        units:"",
        x: "sample_time_str", 
        y:"battery_data.cell_avg_voltage",
        sigfigs: 3
      },
      {
        value: data[data.length-1]['battery_data.low_cell_voltage'] ?? '--',
        title:"Lowest Cell Voltage", 
        units:"",
        x: "sample_time_str", 
        y:"battery_data.low_cell_voltage",
        sigfigs: 3
        },
    ]},
    temperatures: {
      title: 'Temperatures',
      show: false,
      gauges: [
      {
        value:data[data.length-1]?.['motor_data.0.motor_temp'] ?? '--',
        title:"Motor Temp", 
        units:"°C",
        x: "sample_time_str", 
        y:"motor_data.0.motor_temp"
      },
      {
        value:data[data.length-1]?.['motor_data.0.controller_coolant_temp'] ?? '--',
        title:"Motor Coolant Temp", 
        units:"°C",
        x: "sample_time_str", 
        y:"motor_data.0.controller_coolant_temp"
      },
      {
        value:data[data.length-1]?.['motor_data.0.controller_temp'] ?? '--',
        title:"Motor Controller Temp", 
        units:"°C",
        x: "sample_time_str", 
        y:"motor_data.0.controller_temp"
      },
      {
        value:data[data.length-1]?.['motor_data.0.controller_coolant_temp'] ?? '--',
        title:"Motor Controller Coolant Temp", 
        units:"°C",
        x: "sample_time_str", 
        y:"motor_data.0.controller_coolant_temp"
      },
      {
        value:data[data.length-1]?.['motor_data.0.controller_hot_spot'] ?? '--',
        title:"Hot Spot Temp", 
        units:"°C",
        x: "sample_time_str", 
        y:"motor_data.0.controller_hot_spot"
      },
      {
        value:data[data.length-1]['ecu_data.0.sensors_belt_oil_temperature'] ?? '--',
        title:"Belt Oil Temp", 
        units:"°C",
        x: "sample_time_str", 
        y:"ecu_data.0.sensors.belt_oil_temperature"
        },
      {
        value:data[data.length-1]?.['obc_data.0.temperature'] ?? '--',
        title:"OBC Temp", 
        units:"°C",
        x: "sample_time_str", 
        y:"obc_data.0.temperature"
        },
      {
        value: data[data.length-1]['battery_data.high_cell_temp'] ?? "--",
        title:"Highest Cell Temp", 
        units:"°C",
        x: "sample_time_str", 
        y:"battery_data.high_cell_temp"
      },
      {
        value: data[data.length-1]['battery_data.avg_cell_temp'] ?? '--',
        title:"Average Cell Temp", 
        units:"°C",
        x: "sample_time_str", 
        y:"battery_data.avg_cell_temp"
      },
      {
        value: data[data.length-1]['battery_data.low_cell_temp'] ?? "--",
        title:"Lowest Cell Temp", 
        units:"°C",
        x: "sample_time_str", 
        y:"battery_data.low_cell_temp"
      },
    ]},
    system_performance: {
      title: 'System Performance',
      show: false,
      gauges: [
      {
        value: data[data.length-1]['motor_data.0.system_state'] ?? '--',
        title:"System State", 
        units:"",
        x: "sample_time_str", 
        y:"motor_data.0.system_state"
      },
      {
        value:data[data.length-1]['ecu_data.0.system_state'] ?? '--',
          title:"ECU System State", 
          units:"",
          x: "sample_time_str", 
          y:"ecu_data.0.system_state"
      },
      {
        value:data[data.length-1]['mi_per_kwh'] ?? '--',
        title:"Efficiency", 
        units:"mi/kWh",
        x: "sample_time_str", 
        y:"mi_per_kwh"
      },
      {
        value: data[data.length-1]['system_performance_data?_0.range_realtime'] ?? '--',
        title:"Range", 
        units:"miles",
        x: "sample_time_str", 
        y:"system_performance_data.0.range_realtime"
      },
      {
        value: data[data.length-1]['system_performance_data?_0.range_minutes'] ?? '--',
        title:"Endurance", 
        units:"minutes",
        x: "sample_time_str", 
        y:"system_performance_data.0.range_minutes"
      },
      {
        value:data[data.length-1]['MPGe'],
        title:"Efficiency", 
        units:"MPGe",
        x: "sample_time_str", 
        y:"MPGe"
      },
      {
        value: data[data.length-1]['system_performance_data?_0.operational_efficiency'] ?? '--',
        title:"Operational Efficiency", 
        units:"mi/kWh",
        x: "sample_time_str", 
        y:"system_performance_data.0.operational_efficiency"
      }
    ]},
    onboard_charger: {
      title: 'Onboard Charger',
      show: false,
      gauges: [
        {
          value:data[data.length-1]['battery_data.charge_stage'],
          title:"Charge Stage", 
          units:"",
          x: "sample_time_str", 
          y:"battery_data.charge_stage"
        },  
        { 
          value: data[data.length-1]['obc_data.0.charge_type'] ?? '--',
          title:"Charge Type", 
          units:"",
          x: "sample_time_str", 
          y:"obc_data.0.charge_type"
        },
        { 
          value: data[data.length-1]['obc_data.0.charge_state'] ?? '--',
          title:"Charge state", 
          units:"",
          x: "sample_time_str", 
          y:"obc_data.0.charge_state"
        },
        { 
          value: data[data.length-1]['obc_data.0.current_req'] ?? '--',
          title:"Current Req", 
          units:"A",
          x: "sample_time_str", 
          y:"obc_data.0.current_req"
        },
        { 
          value: data[data.length-1]['obc_data.0.battery_current'] ?? '--',
          title:"Charge Current", 
          units:"A",
          x: "sample_time_str", 
          y:"charge_current"
        },
        { 
          value: data[data.length-1]['obc_data.0.voltage_req'] ?? '--',
          title:"Voltage Req", 
          units:"V",
          x: "sample_time_str", 
          y:"obc_data.0.voltage_req"
        },
        { 
          value: data[data.length-1]['obc_data.0.battery_voltage'] ?? '--',
          title:"Charge Voltage", 
          units:"V",
          x: "sample_time_str", 
          y:"charge_voltage"
        },
        { 
          value: data[data.length-1]['obc_data.0.max_current'] ?? '--',
          title:"Max Current", 
          units:"A",
          x: "sample_time_str", 
          y:"obc_data.0.max_current"
        },
        { 
          value: data[data.length-1]['obc_data.0.charge_time_remaining'] ?? '--',
          title:"Charge Time Remaining", 
          units:"A",
          x: "sample_time_str", 
          y:"obc_data.0.charge_time_remaining"
        },
      ]
    },
    dcdc: {
      title: 'DCDC',
      show: false,
      gauges: [
        {
          value: data[data.length-1]['dc_dc_data.0.mode_state'] ?? '--',
          title:"DCDC State",
          units:"",
          x: "sample_time_str",
          y:"dc_dc_data.0.mode_state"
        },
        {
          value: data[data.length-1]['dc_dc_data.0.hv_current'] ?? '--',
          title:"DCDC HV Current",
          units:"A",
          x: "sample_time_str",
          y:"dc_dc_data.0.hv_current"
        },
        {
          value: data[data.length-1]['dc_dc_data.0.hv_voltage'] ?? '--',
          title:"DCDC HV Voltage",
          units:"V",
          x: "sample_time_str",
          y:"dc_dc_data.0.hv_voltage"
        },
        {
          value: data[data.length-1]['dc_dc_data.0.lv_current'] ?? '--',
          title:"DCDC LV Current",
          units:"A",
          x: "sample_time_str",
          y:"dc_dc_data.0.lv_current"
        },
        {
          value: data[data.length-1]['dc_dc_data.0.lv_voltage'] ?? '--',
          title:"DCDC LV Voltage",
          units:"V",
          x: "sample_time_str",
          y:"dc_dc_data.0.lv_voltage"
        },
        {
          value: data[data.length-1]['dc_dc_data.0.temperature'] ?? '--',
          title:"DCDC temperature",
          units:"°C",
          x: "sample_time_str",
          y:"dc_dc_data.0.temperature"
        },
      ]
    }
  }      
    
  }, [data]);
  
  const stats = useMemo(() => {
    console.log(gauges)
    let statsMap = {}
    Object.values(gauges).forEach(g=>{
      // console.log(g);
      Object.values(g.gauges).forEach(gauge=>{
        const series = data.map(item => item[gauge.y]).filter(value => typeof value === 'number' && value!==null && !isNaN(value));
        const sum = series?.reduce((a, b) => a + b, 0);
        // if (series.length==0){
        //   console.log(g, gauge, series, sum)
        // }
        const min = sum===0 ? 0 : Math.round((Math.min(...series)*10))/10;
        const max = sum===0 ? 0 : Math.round((Math.max(...series)*10))/10;
        statsMap[gauge.title] = {average: sum===0?0:Math.round((sum / series?.length)*10)/10, min, max, sum, isNull:series.length==0}
        
      })
    })

  return statsMap
  }, [data]);

  function displayValue(val, sigfigs=1){
    return Math.round(val*(10**sigfigs))/(10**sigfigs)      
  }

  const customExpanderStyle = {
    minWidth:'250px', 
    width:'100%',  
    display:'flex', 
    flexDirection:'column', 
    borderRadius:'4px', 
    backgroundColor:'var(--dark-grey-2)', 
    alignItems:'center', 
    marginBottom:'3px'
  }  

  return (
    <div style={{width:'100%'}} className='GaugeGrid'> {/* Adjust the spacing here */}
      {Object.values(gauges).map((group, groupIndex) => {
        return <Expander isMobile={isMobile} title={group.title} startCollapsed={!group.show} key={groupIndex} expanderStyle={customExpanderStyle}>
          <div className='LiveDataGaugeGrid'>
          {
          group.gauges.map((item, index) => {
            // console.log(item)
          if (item.value === undefined || item.value === null || stats[item.title].isNull) {
            return null;
          }
          return (
            <div key={index} className='LiveDataGaugeCard'>
              <div className='inner'>
                <h2 style={{fontSize:'2.5vh', color:'var(--light-grey-2)', marginBlockEnd:"0.1em", marginBlockStart:"0.1em"}}>{item.title}</h2>
                <h1 style={{fontSize:'3vh', color:'var(--light-grey-3)', marginBlockEnd:"0.1em", marginBlockStart:"0.1em"}}>{item.value=='--'?'--':displayValue(item.value, item?.sigfigs)} {item.units}</h1>
                <h2 style={{fontSize:'2vh', color:'var(--light-grey-3)', marginBlockEnd:"0.1em", marginBlockStart:"0.1em"}}>Min: {stats[item.title]?.min || ''} | Max: {stats[item.title]?.max || ''}</h2>
                <h2 style={{fontSize:'2vh', color:'var(--light-grey-3)', marginBlockEnd:"0.1em", marginBlockStart:"0.1em"}}>Average: {stats[item.title]?.average.toFixed(2) || ''} {item.units}</h2>
                <Plot2d items={data} xAxisKey={item.x} yAxisKey={item.y} chartType='line'/> 
              </div>
            </div>
          );
        })}
        </div>
        </Expander>
      })}
    </div>
  );
}

export default GaugeGrid;