import { useState, useEffect } from "react";
import { fetchData } from "../../app/QueryHandler";
import { useAuth, useVessel, useVesselList } from "../../app/zustore";
import { fields, motorFields, propellerFields, sections } from "../../Types/vessel";
import { Select, 
        MenuItem, 
        TextField, 
        Button, 
        Modal, 
        Box,
        Accordion, AccordionDetails, AccordionSummary, Typography, Checkbox,
        Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'
import PublishIcon from '@mui/icons-material/Publish';
import ExpandMoreIcon from '@mui/icons-material/Expand';

export const VesselUpdater = ({vesselToUpdate, showModal, setShowModal}) => {
    const token = useAuth((state)=>(state.token))
    const [selectedFields, setSelectedFields] = useState({})
    const [fieldData, setFieldData] = useState({})   
    const [status, setStatus] = useState('entering') 
    const [response, setResponse] = useState({})
    // const vessel_id = useVessel(s=>s.vessel_id)
    const vesselList = useVesselList(s=>s.vesselList)
    const vessel = vesselList.find(v=>v.vessel_id===vesselToUpdate.vessel_id)

    const handleInputChange = (event, field) => {
        console.groupCollapsed("handleInputChange");
        console.log(event.target.value, field)
        console.table({...fieldData, [field.path]: {...fieldData[field.path], [field.field]: event.target.value}})
        console.groupEnd();
  
        setFieldData({...fieldData, [field.path]: {...fieldData[field.path], [field.field]: event.target.value}});
    };

    const addField = (newFields, path) => {
        console.groupCollapsed('VesselUpdater: addField');
            console.log(path)
            console.log(newFields, 'added to fields')
            console.log('fields now contains:')
            console.table({...selectedFields, [path]: newFields})
        console.groupEnd();

        setSelectedFields({...selectedFields, [path]: newFields}) 
    }

    const handleSubmit = async () => {
        console.groupCollapsed('handleSubmit');
        setStatus('loading')
        let newVessel = {...vessel};
        if (fieldData['.']) {
            newVessel = { ...newVessel, ...fieldData['.'] };
        }
        if (fieldData['outboards']) {
            newVessel.outboards = [{...vessel.outboards[0], ...fieldData['outboards']}];
        }
        if (fieldData['outboards[0].prop']) {
            if (!newVessel.outboards) newVessel.outboards = [{...vessel.outboards[0]}]
            newVessel.outboards[0].prop = {...vessel.outboards[0].prop, ...fieldData['outboards[0].prop']};
        }
        console.table(newVessel)
        console.log(JSON.stringify(newVessel))
        console.groupEnd();
        if (window.confirm('Are you sure you want to update this vessel?')){
            const {status, body, type} = await fetchData({method:'PUT', resource:'vessel-info', token:token, body:newVessel})
            if (status===200){
                console.log(body)
                setResponse(body)
                setStatus("entering")
            }
        }
        // if (response?.statusCode!==201){
        //     setStatus('failed')
        // }
        
    }

    if (status==='entering'){
        return (
                <Box sx={{ ...styles.box, maxWidth:'1000px'  }}>
                    
                    <Typography style={styles.modalTitle}>Update {vesselToUpdate.vessel_name}</Typography>
                    
                    <Box sx={{ ...styles.InnerBox  }}>
                        <div style={styles.editorContainer}>
                            {/* {sections.map((section, index) => (
                                <FieldSelector 
                                    addField={addField}
                                    editableFields={section.fields}
                                    handleInputChange={handleInputChange}
                                    key={index}
                                    path={section.path}
                                    title={section.title}
                                    vesselToUpdate={vesselToUpdate}
                                />
                                ))} */}
                        </div>
                    </Box>
                    <div style={styles.buttonContainer}>
                        {/* <Button variant="outlined" sx={styles.button} onClick={()=>{setShowModal(0)}} endIcon={<CloseIcon/>}>Close</Button> */}
                        <Button variant="contained" sx={{...styles.button, backgroundColor:'#3d444b'}} onClick={()=>{handleSubmit()}} endIcon={<PublishIcon />}>Submit</Button>
                    </div>
                </Box>
        )
        
      } else if (status==='loading') {
        return(
            <Box sx={{ ...styles.box, maxWidth:'1000px'  }}>
                <Box sx={{...styles.modalTitle, 'display':'flex', flexDirection:'column'}}>
                    <Typography >Sending request up to AWS now</Typography>
                    <Typography >Please wait...</Typography>
                </Box>
                
                <Box sx={{ ...styles.InnerBox  }}>
                    <h2>Message:  {response.message}</h2>
                </Box>
                <div style={styles.buttonContainer}>
                    {/* <Button variant="outlined" sx={styles.button} onClick={()=>{setShowModal(0)}} endIcon={<CloseIcon/>}>Close</Button> */}
                    <Button variant="contained" sx={{...styles.button, backgroundColor:'#3d444b'}} onClick={()=>{handleSubmit()}} >🤷‍♀️</Button>
                </div>
            </Box>
        )
      } else if (status==='failed') {
        return(
            <Box sx={{ ...styles.box, maxWidth:'1000px'  }}>
                <Box sx={{...styles.modalTitle, 'display':'flex', flexDirection:'column'}}>
                    <Typography >Error adding Vessel</Typography>
                    <Typography >Status code: {response.statusCode}</Typography>
                </Box>
                
                <Box sx={{ ...styles.InnerBox  }}>
                    <h2>Message:  {response.message}</h2>
                </Box>
                <div style={styles.buttonContainer}>
                    {/* <Button variant="outlined" sx={styles.button} onClick={()=>{setShowModal(0)}} endIcon={<CloseIcon/>}>Close</Button> */}
                    <Button variant="contained" sx={{...styles.button, backgroundColor:'#3d444b'}} onClick={()=>{handleSubmit()}} >🤷‍♀️</Button>
                </div>
            </Box>
        )
      }
}

export const FieldSelector = ({ addField, editableFields, path, title, handleInputChange, vesselToUpdate }) => {
    const [selected, setSelected] = useState([])

    // console.table(editableFields)
    
    const handleSelect = ( event ) => {
        console.groupCollapsed("handleSelect");
        const selectedValues = event.target.value;
        console.log(selectedValues);
        console.log(path);
        const selectedFields = selectedValues.map((index) => editableFields[index]);
        console.log(selectedFields);
        setSelected(selectedValues);
        console.groupEnd();
        addField(selectedFields, path);
    }

    return (
        <Accordion sx={{width:'100%'}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{color:'#00b9b8'}}/>}>
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selected}
                    displayEmpty
                    sx={{width:'100%'}}
                    onChange={handleSelect}
                    multiple={true}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                        return <em>Select which properties to update</em>;
                        }
            
                        return selected.map((index)=>editableFields[index].label).join(', ');
                    }}
                    style={styles.selector}>
                    {editableFields.map((field, index) => (
                        <MenuItem value={index} key={index}>
                            <Checkbox checked={selected.indexOf(index) > -1} />
                            {field.label}
                        </MenuItem>
                    ))
                    }  

                </Select>
                {
                    selected.map((index)=>(
                        <FieldEditor key={index} field={editableFields[index]} handleInputChange={handleInputChange} vesselToUpdate={vesselToUpdate}/>
                    ))
                }
            </AccordionDetails>
        </Accordion>
    )
}

const FieldEditor = ({ field, handleInputChange, vesselToUpdate }) => {

    // Determine the default value based on the field's path and field name
    let defaultValue = "";
    if (vesselToUpdate) {
        if (field.path === '.') {
            defaultValue = vesselToUpdate[field.field] || "";
        } else if (field.path === 'outboards' && vesselToUpdate.outboards && vesselToUpdate.outboards[0]) {
            defaultValue = vesselToUpdate.outboards[0][field.field] || "";
        } else if (field.path === 'prop' && vesselToUpdate.outboards && vesselToUpdate.outboards[0] && vesselToUpdate.outboards[0].prop) {
            defaultValue = vesselToUpdate.outboards[0].prop[field.field] || "";
        }
    }
    
    return (
        <div style={styles.editorOuterDiv}>
            <div style={styles.editorInnerDiv}>
                <TextField 
                    key={field.label}
                    label={field.label}
                    placeholder={`Enter ${field.label}`}
                    variant="filled"
                    style={styles.input}
                    defaultValue={defaultValue}
                    onChange={(value) => handleInputChange(value, field)}
                    inputProps={field?.numeric?{
                        pattern: "[0-9]*", // Only allow numeric characters
                        inputMode: "numeric", // Show numeric keyboard on mobile devices
                    }:{}}
                />
            </div>
            {/* <div style={styles.divider}/> */}
        </div>

    )
}

const styles = {
    modal: { 
        position:'fixed', top:'15%', left:'7%',  width: '86%', 
        height: '70%', borderRadius:"2px", justifyContent:"center", 
        backgroundColor: "#3d5ea5", marginLeft:"auto", 
        marginRight:"auto", padding: "0px", boxShadow:'rgb(0 0 0) 15px 15px 1px 5px',
    },
    box: {
        // position: 'absolute',
        top: '50%',
        maxHeight:'70%',
        left: '50%',
        alignSelf:'center',
        // transform: 'translate(-50%, -50%)',
        width: '100%',
        bgcolor: '#e1e1e1',
        border: '3px solid #6c7982',
        borderRadius: '15px',
        // boxShadow: 24,
        overflowY: 'hide',
        display: 'grid',
        gridTemplateRows: 'auto 1fr auto',
        gridTemplateColumns: '1fr',
        gridTemplateAreas: `
  "header"
  "content" 
  "footer"
`,
        pt: 1,
    },
    InnerBox: {
        position: 'relative',
        gridArea: 'content',
        height:'100%',
        width: "100%",
        bgcolor: '#e1e1e1',
        overflowY: 'scroll',
        borderTopStyle: 'double',
        borderBottomStyle: 'double',
        WebkitOverflowScrolling: 'touch',
        // pt: 2,
        // px: 4,
        mb: 3,
    },
    buttonContainer: {
        display:'flex', 
        flexDirection:'row', 
        justifyContent:'space-around',
        alignItems: 'flex-end' ,
        gridArea: 'footer',
        paddingTop: '10px'
    },
    modalTitle: {
        marginLeft:'5px', display: 'block', /* Makes the anchor block-level */
        marginBottom: '15px',
        gridArea: 'header',
        width: '100%', /* Takes up the full width of its container */
        height: '100%', /* Takes up the full height of its container */
        textAlign: 'left', /* Centers the text horizontally */
        fontSize: 'calc(1.5vw + 2vh)', /* Adjusts font size based on viewport width and height */
        overflow: 'hidden', /* Ensures content doesn't spill outside the container */
        whiteSpace: 'nowrap', /* Prevents text from wrapping to the next line */
        textOverflow: 'ellipsis', /* Adds an ellipsis when the text overflows */
        position: 'relative'
    },
    selectorDiv: {
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        margin: '0 auto', // Center horizontally
    },
    editorContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center', // Center horizontally
        width: '100%',
        margin: '0 auto', // Center horizontally
        marginTop: '20px',
        boderRadius: '4px',
        // height: '100%',
        marginBottom:'20px',
        WebkitOverflowScrolling: 'touch',
    },
    editorOuterDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Align items vertically
        justifyContent: 'space-between', // Space between h3 and TextField
        width: '100%',
        marginTop: '10px',
        backgroundColor: 'white'
    },
    nestedContainer: {display:'flex', flexDirection:'column', alignItems:'center', width:'90%',
        marginTop: '15px', marginBottom: '15px'
    },
    editorInnerDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center', // Align items vertically
        justifyContent: 'space-between', // Space between h3 and TextField        
        width: '100%',
    },
    input: {
        padding: 10,
        backgroundColor: 'white',
        paddingRight: '30px',
        width: '100%'
    },
    selector: { 
        minWidth: "200px", 
        marginBottom:'10px',
        width: "90%", 
        maxWidth: "800px", 
        backgroundColor: 'aliceblue' },
    divider: {
        backgroundColor: 'black',
        height: '4px',
        width: '100%',
    },
    button: {
        margin: '10px auto 10px auto', 
        height:'80%', 
        maxWidth:'45%',
        color: '#00b9b8',
        
    }
}