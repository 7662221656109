import { useRef, useState } from 'react';
import { resetPassword, confirmResetPassword, updatePassword  } from '@aws-amplify/auth';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_login/update')({
  // loader: async ({ params: { vessel_id } }) => fetchPost(postId),
  // errorComponent: PostErrorComponent as any,
  notFoundComponent: () => {
    return (
      <p>Error loading password reset Page</p>
    )
  },
  component: UpdatePassword,
})

function UpdatePassword({ setFormType }) {
  const [formState, setFormState] = useState({ username: '', verificationCode: '', old_password: '', new_password: '' });
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [codeSent, setCodeSent] = useState(false);
  const [resetting, setResetSuccessful] = useState(false);

  const signInButtonRef = useRef();

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      if (!codeSent) {
        await updatePassword({username: formState.username});
        console.log('Verification code sent');
        setCodeSent(true);
      } else {
        if (formState.password !== formState.confirmPassword) {
          setPasswordsMatch(false);
          throw new Error('Passwords do not match!');
        }
        setPasswordsMatch(true);
        setResetSuccessful(true);
        await confirmResetPassword({username:formState.username, confirmationCode:formState.verificationCode, newPassword:formState.password});
        console.log('Password reset initiated');
        setTimeout(() => signInButtonRef.current.click(), 3000);
        setResetSuccessful(false)
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };

  return (
    <>
      <form onSubmit={handleForgotPassword}>
        <Box spacing={2} className='Auth-content-box-inner' style={{columnGap:'40px'}}>
          
          <Typography variant="h5" color='white'>Change Password</Typography>
          
          
          <TextField
            label="Username"
            name="username"
            value={formState.username}
            onChange={handleChange}
            type="text"
            fullWidth
            required
          />
          
            <TextField
              label="Old Password"
              name="password"
              type="password"
              value={formState.old_password}
              onChange={handleChange}
              fullWidth
              required
            />
          
          
            <TextField
              label="New Password"
              name="confirmPassword"
              type="password"
              value={formState.new_password}
              onChange={handleChange}
              fullWidth
              required
              error={!passwordsMatch}
              helperText={!passwordsMatch ? 'Passwords must match' : ''}
            />
              
          
            <Button variant="contained" color="primary" type="submit" fullWidth>Update Password</Button>
          
          
            <Button variant="outlined" onClick={setFormType} fullWidth ref={signInButtonRef} style={{ marginTop: "60px"}}>
              Back to Sign In
            </Button>
          
        </Box>
      </form>
      
      <Backdrop open={resetting} style={{ color: '#fff', zIndex: 1500 }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h4">
            Password reset successfully
          </Typography>
          <CircularProgress color="inherit" />
        </Box>
      </Backdrop>
    </>
  );
};

export default updatePassword;