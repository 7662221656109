import { useEffect, useState } from 'react';
import { useDataSource, usePlots } from '../../app/zustore';
import { Button, Modal, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'
import PublishIcon from '@mui/icons-material/Publish';
import superjson from 'superjson';
import { PlotCustomizer } from './PlotCustomizer';
import { PresetNamer } from './PlotPresetNamer';
import Select from 'react-select';
import { DeleteForever, Download, UploadFile } from '@mui/icons-material';

export const ManagePresetsModal = ({ env, showModal, setShowModal, options, isMobile }) => {
  const [newPresetName, setNewPresetName] = useState(''); 
  const datasource = useDataSource((s)=>s.datasource);
  const plots = usePlots( s=>s.plots)  
  const preset = usePlots(s=>s.preset)
  const plot = plots[datasource]?.[preset] 
  const updateFullDatasource = usePlots( s=>s.updateFullDatasource)  
  const [newPlot, setNewPlot] = useState({...plot, isLocal:true})
  const [updateAccepted, setUpdateAccepted] = useState(0)
  const [selectedPlots, setSelectedPlots] = useState({})
  const [numUploaded, setNumUploaded] = useState(0);
  const [uploadedData, setUploadedData] = useState({});
  const  updatePlotIndex = usePlots(s=>s.updatePlotIndex)
  const updatePreset = usePlots(s=>s.updatePreset)  

  const localPlotPresets = Object.entries(plots[datasource]).filter(p=>p[1].isLocal);

  let globalPlotPresets = {};
  Object.entries(plots[datasource]).filter( p => !p[1].isLocal ).forEach( p => (globalPlotPresets[p[0]] = p[1]) );

  const handleSelectorChange = (event) => {
    console.log(event)
    if (event.length==0){
        setSelectedPlots({})
    } else {
        let newSelection = {...selectedPlots};
        event.forEach( p => (newSelection[p.label]=p.value) )
        setSelectedPlots(newSelection);
    }
  };  

  const exportToJson = e => {
    e.preventDefault()
    if (selectedPlots){
        downloadFile({
            data: superjson.stringify({[datasource]: selectedPlots}),
            fileName: 'fleet_dashboard_plot_presets.json',
            fileType: 'text/json',
        })
    }
  }

  const handleDelete = e => {
    e.preventDefault()
    if (window.confirm('Are you sure?')){
        let newSubset = {...plots[datasource]}
        Object.keys(selectedPlots).forEach(preset=>{
            delete newSubset[preset]
        })
        const newPlots = {...plots, [datasource]:newSubset};
        updateFullDatasource(datasource, newSubset);
        localStorage.setItem(`plotly-presets-${env}`, superjson.stringify(newPlots));
        setSelectedPlots({})
        updatePlotIndex(0)
        updatePreset('speed')

        console.log(newPlots)
    }
        
    console.log(selectedPlots)
  }

  const handleFileChange = e => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = e => {
      const data = superjson.parse(e.target.result);
      setUploadedData(data)
      const [uploadedDataSource, updloadedPresets] = Object.entries(data)[0] 
      setNumUploaded(Object.keys(updloadedPresets).length)
      console.log(uploadedDataSource, updloadedPresets)
      if (uploadedDataSource==='csv'||uploadedDataSource==='pi'||uploadedDataSource==='bud'){
        const newSubset = {...plots[uploadedDataSource], ...updloadedPresets};
        const newPlots = {...plots, [uploadedDataSource]:newSubset};
        console.log(newPlots)
        updateFullDatasource(uploadedDataSource, newSubset);
        localStorage.setItem(`plotly-presets-${env}`, superjson.stringify(newPlots));
      }
    };
  };


  useEffect(()=>{
    setNewPlot({...plot, isLocal:true})  
  },[plot])

  if (showModal){
    return (
        <Modal 
            open={showModal} 
            onClose={()=>{setShowModal(0)}}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
          <Box className='analyze-page-modal-box'>
            

            <a className='analyze-page-modal-header'>
                <span >
                    Upload new presets, 
                </span>
                <br></br>
                <span >
                    Or select existing presets to manage
                </span>
            </a>

            <div style={{ width:'300px', justifyContent: 'center', alignItems: 'center', display:'flex', flexDirection:'column', marginBottom:'6px', rowGap:'25px' }}>
                {/* <label style={{fontWeight:'400', fontSize:'larger', marginInlineEnd:'20px', marginTop:'8px'}}>
                    {toTitleCase(axis)}{axis==='mode'?'':' Axis'}:
                </label> */}
                <div style={{
                    width:'60%', maxWidth:'500px', height:'25%', maxHeight:'300px', borderRadius:'12px',
                    display:'flex', placeContent:'center', placeItems:'center', backgroundColor:'#dcf2f58c', 
                    flexDirection: 'column'}}
                >                    
                    <label onChange={handleFileChange} htmlFor="formId" style={{padding:'50px'}}>
                        <input name="Upload file" type="file" id="formId" hidden />
                        <UploadFile sx={{height:'40px', width:'40px'}} />
                    </label>
                </div>
                <span>{numUploaded>0&&`${numUploaded} new presets detected`}</span>

                <div style={{ height: "100%", width:'300px', justifyContent: 'center', alignItems: 'center', display:'flex', flexDirection:'column', marginBottom:'6px' }}>
                    <label style={{fontWeight:'400', fontSize:'larger', marginInlineEnd:'20px', marginTop:'8px'}}>Select Presets:</label>
                    <Select
                        options={localPlotPresets.map(p => ({value:p[1], label:p[0]}))}
                        styles={{width:'300px'}}
                        isMulti={true}
                        isClearable={true}
                        onChange={(event)=>handleSelectorChange(event)}
                        value={Object.entries(selectedPlots).map(p=>({ label:p[0], value:p[1]}))}
                    />
                </div>
                
                        
            </div>
            
            <div className='analyze-page-modal-button-div'>
                <Button variant="contained" className='analyze-page-modal-button' onClick={()=>{setShowModal(0); setUpdateAccepted(0)}} 
                    endIcon={<CloseIcon/>}>{!isMobile&&"Close"}
                </Button>
                <Button variant="contained" className='analyze-page-modal-button' sx={{backgroundColor:updateAccepted<2?'#3d444b':'red'}} onClick={exportToJson} 
                    endIcon={<Download />}>{!isMobile&&"Download"}
                </Button>
                <Button variant="contained" className='analyze-page-modal-button' sx={{backgroundColor:updateAccepted<2?'#3d444b':'red'}} onClick={handleDelete} 
                    endIcon={<DeleteForever />}>{!isMobile&&"Delete"}
                </Button>
            </div>
                
          </Box>
        </Modal> 
    )
  }
}


const downloadFile = ({ data, fileName, fileType }) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType })
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }
  
  