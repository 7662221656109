import { createFileRoute, Link, Outlet, redirect, useLocation, useNavigate } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import { LocalizationProvider } from '@mui/x-date-pickers';
import Tooltip from '@mui/material/Tooltip';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Badge, Box, IconButton, Menu, MenuItem, SwipeableDrawer, Typography, useMediaQuery } from '@mui/material';
import { Home, Logout, Menu as MenuIcon, Groups, ArrowBack, ArrowForward, ChevronRight, RouteOutlined, AccountCircleOutlined, DirectionsBoatOutlined, HomeOutlined, NotificationsOutlined, GroupOutlined} from '@mui/icons-material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ErrorBoundary from "../utils/ErrorBoundary";
import logo from "../images/01_Flux Only.svg";
import { useAuth, useFleetData, useGroups, useNotifications, useSelectedNodeStore, useUser, useVessel, useVesselList, zuPage } from '../app/zustore';
import { useResizeDetector } from 'react-resize-detector';
import { fetchAuthSession, getCurrentUser } from '@aws-amplify/auth';
import { fetchData } from '../app/QueryHandler';
import { format_date_string } from '../utils/utilityMethods';
import { checkIsAuthenticated, determineStatus, isAuthenticated } from '.';
import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import superjson from 'superjson';
import { useEffect, useMemo, useState } from 'react';
import { LoadingBar } from '../loadingBar/loadingBar';
import dayjs from 'dayjs';
import { SignOutButton } from '../utils/signOutButton';
import { subscribeUser } from '../app/push-notifications';
import { Notification } from '../app/notification';

const env = process.env.REACT_APP_ENV;
const TOP_BAR_HEIGHT = 40;

const getLocalData = (key, defaultValue)=>{
    const data = localStorage.getItem(key)
    return data===null?JSON.stringify(defaultValue):data
};
  
function makeVesselListUnique(combinedData){
    const uniqueVessels = combinedData.reduce((acc, item) => {
      const vesselId = item.vessel_id;
      const latestMessageDate = new Date(item.latest_message);
      
      if (!acc[vesselId] || new Date(acc[vesselId].latest_message) < latestMessageDate) {
        acc[vesselId] = item;
      }
      
      return acc;
    }, {});
    
    return Object.values(uniqueVessels);
}

export const Route = createFileRoute('/_authenticated')({
    loader: AuthLoader,
    component: AuthLayout,
})

/**
 * This Component runs prior to the AuthLayout Component rendering.
 * It requests the data from Nodes, Users, and Vessel-Info in parallel,
 * and awaits the results from all 3 before returning and allowing the main component to render.
 */
async function AuthLoader({ context, location}){
    const setAuth = useAuth.getState().setAuth;
    const isAuthenticated = useAuth.getState().isAuthenticated;
    const setIsAuthenticated= useAuth.getState().setIsAuthenticated;
    const setUser = useUser.getState().setUser;
    
    const setVesselList = useVesselList.getState().setVesselList;
    const fleetData = useFleetData.getState().fleetData;
    const updateGroups = useGroups.getState().updateGroups
    
    async function getUser(JWT){
      const {status, body, type} = await fetchData({method:'GET', resource:'users', token:JWT, })
      // console.log(status, body)
      setUser(body);
      let user = {...body};
      if (user?.invites !== null && user.invites !== null && Object.keys(user.invites)?.length>0) {
        enqueueSnackbar("User info updated: New Invite found!", {variant:'Invite', autoHideDuration:10000, showExpand:true, invites:user.invites})
      }
      }
    
    async function getVesselInfo(JWT, user){
        const {status, body, type} = await fetchData({ method: 'GET', resource: 'vessel-info', token:JWT });  
        // console.log(status, body, user)
        const localVesselData = superjson.parse(getLocalData(`vessel-info-${env}-${user.userId}`, {}));
        if (localVesselData) {
        // console.log(body, localVesselData)
        const combinedData = [...body, ...localVesselData]      
        const uniqueVesselsArray = makeVesselListUnique(combinedData);
        const updatedVesselList = uniqueVesselsArray.map((vessel) => ({
            ...vessel,
            latest_message: (fleetData[vessel.vessel_id] ? format_date_string(fleetData[vessel.vessel_id].time) : null) || vessel.latest_message,
            status: determineStatus(fleetData?.[vessel.vessel_id]),
            })
        );
        // console.log('root loader - vessel-info', updatedVesselList)
        setVesselList(updatedVesselList);
        return updatedVesselList
        } else {
        setVesselList(body)
        return body
        }
    }
    
    async function getNodes(JWT){
        const {status, body, type} = await fetchData({method:'GET', resource:'nodes', token:JWT})
        // console.log('Fetched Nodes', status, body)
        if (status==200){
          updateGroups(body)
        }
      }
    
    try {
        console.log("Authenticated Route Loader Component running")
        const user = await getCurrentUser();
        let { idToken } = (await fetchAuthSession()).tokens ?? {};
        const JWT = idToken.toString();
        setAuth(JWT, user.userId);
        await Promise.all([getUser(JWT), getNodes(JWT), getVesselInfo(JWT, user)])
        } catch (error) {
        setIsAuthenticated(false);
        console.log('Root Loader is not authenticated')
        
        return false
      }  
      return true;  
};

function AuthLayout(){
    const isMobile = useMediaQuery('(max-width:600px)');
    const { height, ref } = useResizeDetector();
    const { fleetData, updateFleetData } = useFleetData();
    const {vesselList, setVesselList } = useVesselList();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const token = useAuth(s=>s.token)
    const { user_info, setUser } = useUser(state=>({user_info:state.user, setUser:state.setUser}))
    const [activeBoats, setActiveBoats] = useState(false)
    const [hasCheckedCache, setHasCheckedCache] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const vessel_id = useVessel(s=>s.vessel_id)
    const vessel_name = useVessel(s=>s.vessel_name)
    const groups = useGroups(s=>s.groups)
    const selectedNode = useSelectedNodeStore(s=>s.selectedNode)
    const { page, setPage, leftSidebarOpen, toggleLeftSidebar, rightSidebarOpen, toggleRightSidebar } = zuPage(s=>({
        page:s.page, 
        setPage:s.setPage, 
        leftSidebarOpen:s.leftSidebarOpen,
        toggleLeftSidebar:s.toggleLeftSidebar,
        rightSidebarOpen:s.rightSidebarOpen,
        toggleRightSidebar:s.toggleRightSidebar
    }));
    const [sidebarView, setSidebarView] = useState(null)
    const setSubscription = useNotifications(s=>s.setSubscription)
    const notifications = useNotifications(s=>s.notifications)
    const addNotification = useNotifications(s=>s.addNotification)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [menu_type, setMenuType] = useState(null)

    async function checkAuth() {
      const authStatus = await checkIsAuthenticated();
      console.log('isAuthenticated?', authStatus)
      if (!authStatus){
        console.log('navigating to login')
        navigate({ 
          to: '/signIn',
          search: {
              redirect: location.href,
            }
          })  
        } 
    }
    
    useEffect(() => {
      checkAuth();
        
      console.log('user info', user_info)
      try{
        subscribeUser().then(subscription => {
          console.log('Subscription object:', subscription);
          setSubscription(subscription.endpoint, subscription.keys.p256dh, subscription.keys.auth)
          // Subscribe API call go here
          const JWT = useAuth.getState().token
          
          if (!user_info?.notification_subscriptions?.find(sub=>sub.endpoint==subscription.endpoint)){
            fetchData({
              method:'POST', 
              resource:'notifications', 
              token:JWT, 
              body: {
                endpoint: subscription.endpoint,
                keys: {
                  p256dh: subscription.keys.p256dh, 
                  auth: subscription.keys.auth
                }
              }
            })
          } else {
            console.log('Subscription already exists in user object')
          }
        }).catch(error => {
          console.log('Service Worker Subscription Failed', error);
        });
      } catch (error) {
        console.log(error)
      }
      
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('message', (event) => {
          if (event.data && event.data.type === 'NEW_NOTIFICATION') {
            if (!notifications.find(n=>n.notification_id==event.data.notification_id)){
              // Update your application state to display the notification
              console.log("Adding new notification to queue", event.data)
              addNotification(event.data); // This could be a state setter or a dispatch
            }
          }
        });
      }
    }, []);    

    const activeIcon = useMemo(()=>{
      console.log("authenticated route", location, location.pathname.split('/')[1])
      return location.pathname.split('/')[1]
    }, [location])

    const handleHomeButtonClick = () => {
      console.log(location.pathname)  
      if (location.pathname=='/home'){
        if (vessel_id==null){
          navigate({ to: '/vessels/select' })
        } else {
          navigate({ to: '/vessels/', params:{ vessel_id:vessel_id} })
        }
      } else {
        navigate({ to: '/home' })
      }
      
    };

    useEffect(()=>{
    
        console.groupCollapsed('Local Data merger');
        if (!user_info){
          return
        }
        const localFleetData = superjson.parse(getLocalData(`fleet-${env}-${user_info?.user_id}`, []));
        let mergedFleetData = {...localFleetData}
        if (localFleetData) {
          console.log(fleetData, localFleetData)
          // check if the data in local storage is newer than the data in the zustore
          if (Object.keys(fleetData).length>=0 || Object.keys(localFleetData).length==0){
            mergedFleetData = {...fleetData, ...mergedFleetData}
          }
          updateFleetData(mergedFleetData)    
        } 
        
        const localVesselInfo = superjson.parse(getLocalData(`vessel-info-${env}-${user_info?.user_id}`, {}));
        console.log('localVesselInfo', localVesselInfo)
        setHasCheckedCache(true)
        if (localVesselInfo) {
          console.log('found local vessel data', vesselList, localVesselInfo, mergedFleetData?.['fd550513-d026-4d34-9783-0876d52a7123'])
          const combinedData = [...vesselList, ...localVesselInfo]      
          const uniqueVesselsArray = makeVesselListUnique(combinedData);
          console.log(uniqueVesselsArray.length)
          
          let updatedVesselList = {}; // add in the info from latest data
          Array.from(uniqueVesselsArray).forEach((vessel, index) => (
            updatedVesselList[vessel.vessel_id] = { 
              ...vessel,
              latest_message: mergedFleetData[vessel.vessel_id] ? format_date_string(mergedFleetData[vessel.vessel_id].time) : null,
              status: determineStatus(mergedFleetData[vessel.vessel_id])
            }
          ));
          updatedVesselList = Array.from(new Set(Object.values(updatedVesselList)))
          console.log(updatedVesselList)
          setVesselList(updatedVesselList)
        } else {
          console.log('exiting Local Data merger')
        }
        console.groupEnd();
        
    }, [])

    function getLatest(data){
        let startTime = dayjs(Date.now()).subtract(30, 'day').valueOf().toString();
        if ( Object.keys(fleetData).length>0 ){
          // console.log(fleetData, Object.values(fleetData).map(v=>Date.parse(v.time)), Object.values(fleetData).map(v=>Date.parse(v.time)).filter(t=>t!=NaN).sort((a,b) => a-b))
          startTime = Object.values(fleetData).map(v=>Date.parse(v.time)).filter(t=>t!=NaN).sort((a,b) => a-b)
          startTime = startTime[startTime.length-1]
          
          const d = new Date;
          startTime = startTime - parseInt(d.getTimezoneOffset()*60*1000) + 1
        }
        
        return startTime
    }

    const fleetDataQuery = useQuery({
        queryKey: ['fleet-data'],
        queryFn: async () => {
          const JWT = useAuth.getState().token
          // console.log('fleet query', vesselList, fleetData)
          const vessels = vesselList ? vesselList.flatMap((vessel) => (vessel.vessel_id)) : [];
          let startTime = getLatest(fleetData)
  
          let {status, body, type} = await fetchData({
            method:'GET', 
            resource:'vessel-data/latest', 
            token:JWT, 
            params: {
              start_time:startTime,
              vessel_id:vessels
            }
          })
          if (status===204) throw new Error('vessel-data/latest: No data returned')
          ;
          if (Object.keys(body).length===0) throw new Error('No data returned')
          if (body==='User is not authorized to access any vessels') throw new Error('User is not authorized to access any vessels')
          return body;
        },
        enabled: vesselList.length>0 && token!=='' && token!=null && (token??null)!==null,
        staleTime: 1000*10, // 10 seconds
        refetchInterval: (page==='FleetOverview'&&activeBoats)?1000*10:1000*60*10, // refetch every 30 seconds
        onSuccess: (data) => {
          // Handle side effects after a successful fetch
          if (data){
            // console.log('GET vessel-data/latest returned data')
            if (Object.keys(fleetData).filter(k=>k!='message').length>0){
              data = {...fleetData, ...data}
            } else {
              console.log('No previous fleet data found')
            }
            let oldStartTime = getLatest(fleetData)
            let newStartTime = getLatest(data)
            
            updateFleetData(data)
            setActiveBoats(true)
            // console.log('Fleet Data', Object.keys(data).length)
    
            localStorage.setItem(`fleet-${env}-${user_info.user_id}`, superjson.stringify(data))
            
            let updatedVesselList = {};
            Array.from(vesselList).forEach((vessel, index) => (
            updatedVesselList[vessel.vessel_id] = { 
                ...vessel,
                latest_message: data[vessel.vessel_id] ? format_date_string(data[vessel.vessel_id].time) : null,
                status: determineStatus(data[vessel.vessel_id])
            }));
    
            updatedVesselList = Array.from(new Set(Object.values(updatedVesselList)))
            // console.log(updatedVesselList, vesselList, updatedVesselList.length==vesselList.length)
            setVesselList(updatedVesselList)
            
            // console.log({cache_key:`vessel-info-${env}-${user_info.user_id}`, user_info})
            localStorage.setItem(`vessel-info-${env}-${user_info.user_id}`, superjson.stringify(updatedVesselList))
            if (oldStartTime !== newStartTime){
              enqueueSnackbar(`Vessel data updated: ${Object.keys(data).length} vessels found`, {variant:'QueryInfo'})
            }
          } else {
            console.groupCollapsed('fetchFleetData, data not defined');
            console.log(data)
            console.groupEnd();
          }
        },
        onError: (error) => {
          console.warn("Error fetching fleet data:", error);
          // setActiveBoats(false)
        }
    })

    const toggleDrawer = (anchor, open) => (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }

      
      // Keep the mobile drawer open when swiped to the right
      if (open) {
        if (anchor === 'right'){
          toggleRightSidebar(true)
          if (sidebarView==null){
            setSidebarView('profile')
          }
        } else if (anchor==='left'){
          toggleLeftSidebar(true)
        }
      } else {
        if (anchor === 'right'){
          toggleRightSidebar(false)
        } else if (anchor==='left'){
          toggleLeftSidebar(false)
        }
      }
    }; 

    const handleOpenMenu = (event, type) => {
      setMenuType(type)
      setAnchorEl(event.currentTarget);
    };

    const handleClose = (item) => {
      console.log('menu clicked for ', item)
      setAnchorEl(null);
      switch (item) {
        case "Live": 
          if (vessel_id == null) {
            navigate({
              to: '/vessels/select',
              params: { vessel_id: vessel_id }
            });
            break;
          }
          navigate({ 
            to: `/vessels/${vessel_id}/`, 
            params: { vessel_id: vessel_id }, 
            mask: { to: `/vessels/${vessel_name}/` }
          });
          break;
    
        case "Analyze": 
          if (vessel_id == null) {
            navigate({
              to: '/vessels/select',
              params: { vessel_id: vessel_id }
            });
            break;
          }
          navigate({ 
            to: `/vessels/${vessel_id}/analyze`, 
            params: { vessel_id: vessel_id }, 
            mask: { to: `/vessels/${vessel_name}/analyze` }
          });
          break;
    
        case "Manage": 
          if (vessel_id == null) {
            navigate({
              to: '/vessels/select',
              params: { vessel_id: vessel_id }
            });
            break;
          }
          navigate({ 
            to: `/vessels/${vessel_id}/manage`, 
            params: { vessel_id: vessel_id }, 
            mask: { to: `/vessels/${vessel_name}/manage` }
          });
          break;
    
        case "Select": 
          if (menu_type=='vessels'){
            navigate({ 
              to: '/vessels/select', 
              params: { vessel_id: vessel_id }
            });
          } else if (menu_type=='trips'){
            navigate({ 
              to: '/trips/select', 
              params: { vessel_id: vessel_id }
            });
          }
          break;
    
        case "Members": 
          navigate({ to: `/groups/members`, });
          break;
        
        case "Delete": 
          navigate({ to: `/groups/delete`, });
          break;
    
        case "Move": 
          navigate({ to: `/groups/move`, });
          break;
    
        case "Add Vessel": 
          navigate({ to: `/groups/d}/new-vessel`, });
          break;
    
        case "Create": 
          navigate({ to: `/groups/new`, });
          break;
    
        case "Rename": 
          navigate({ to: `/groups/rename`, });
          break;
    
        case "Feed": 
          navigate({ to: '/trips/' });
          break;
    
        case "View Trip": 
          navigate({ to: '' });
          break;
    
        case "Home": 
          navigate({ to: '/home/' });
          break;
    
        default:
          console.log('Unknown menu item:', item);
      }
    };

    const menuItems = useMemo(() => {
      switch (menu_type) {
        case 'vessels':
          return [ "Live",  "Analyze",  "Manage", "Select"  ]
        case "groups": 
          return [  "Members",  "Move", "Add Vessel", "Create", "Rename", "Delete"  ]
        case "trips": 
          return [  "Feed", "Select"]
        case "home": 
          return [  "Home" ]
        default:
          return null
      }
    }, [menu_type])

    const SideBar = ()=>{
      const SideBarContent = ()=>{
        if (sidebarView==null) return null
        else if (sidebarView=='profile') {
          return (
            <div className='SideBarContent'>
              <div className='profile'>
                <Typography variant="h4" sx={{ color: 'var(--light-grey-2)', fontSize: '1.2rem', textAlign:'start', marginLeft:'8px' }}>
                  Signed in as:
                </Typography>
                <Typography variant="h3" sx={{ color: 'var(--light-grey-1)', fontSize: '1.5rem', textAlign:'start', marginLeft:'8px' }}>
                  {user_info?.user_name}
                </Typography>
              </div>

              <div className='groups'>
                {
                (user_info.nodes) ? 
                <Box className="groups-inner-content">
                  <Typography variant="h4" sx={{ color: 'var(--light-grey-3)', fontSize: '1.2rem', textAlign:'start', marginLeft:'8px' }}>
                    You belong to the following groups:
                  </Typography>
                  <ul>

                  {
                    Object.keys(user_info.nodes).map((node_id, index)=>{
                      let group = groups.find(g=>g.node_id==node_id)
                      return <li key={index} className='group'>{group.node_name}</li>
                    })
                  }
                  </ul>
                </Box>
                :
                <Box className="groups-inner-content">
                  <h2 style={{color:'white', marginTop:4, textTransform:'bold'}} >You do not belong to any groups.</h2>
                  <Typography sx={{color:'white', }} >Access to boats is provided through groups and you must belong to one in order to see any boats</Typography>
                  <Typography sx={{color:'white', }}>If you have already purchased a boat and are the primary owner please contact your sales representative.</Typography>
                  <Typography sx={{color:'white', marginBottom:4}}>Otherwise please ask the primary owner of your boat to invite you to their Fleet.</Typography>
                  
                </Box>
              }
              </div>
                
  
                <SignOutButton expanded={true}/>
  
            </div>
        )} else {
          return (
            <div className='SideBarContent'>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  marginBottom: '10px',
                  width:'100%'
                }}
              >
                <Typography
                  variant="h4"
                  sx={{ color: 'var(--light-grey-3)', fontSize: '1.2rem', textAlign:'start', marginLeft:'8px' }}
                >
                  Notifications:
                </Typography>

                <div className='notifications'>
                  {notifications.map((n, i)=>{
                    return (
                      <Notification key={i} notification={n}/>
                    )
                  }
                  )}
                </div>
                
  
              </div>
            </div>
        )}
      }
  
      if (isMobile){
        return (
          <SwipeableDrawer
            className='swipe'
            swipeAreaWidth={32}
            anchor="right"
            open={rightSidebarOpen}
            onOpen={toggleDrawer('right', true)}
            onClose={toggleDrawer('right', false)}
          >
            <Box className='SideBar'>
              {SideBarContent()}
            </Box>
          </SwipeableDrawer>
        )
      } else {
        return (
        <Box 
          className={`SideBar right ${sidebarView==null?'':'open'}`}
          onClick={()=>{sidebarView==null&&setSidebarView('profile')}}
          >
          <button onClick={()=>{sidebarView==null?setSidebarView('profile'):setSidebarView(null)}} style={{backgroundColor:'var(--dark-grey-3)', borderRadius:0, width:'100%', display:'flex', justifyContent:'space-around', border:'none', alignItems:'center', height:'3.5vh', margin:0, padding:0}}>
            {
              sidebarView==null ?
              <MenuIcon sx={{color:'var(--light-grey-3)', transform:'rotate(90deg)'}} />
              :
              <ChevronRight sx={{color:'var(--light-grey-3)'}} />}
            {sidebarView!=null && <span style={{color:'var(--light-grey-3)', fontSize:'1em'}}>Close Sidebar</span>}
          </button>
          {SideBarContent()}
        </Box>
        )
      } 
  
    }  

    const header = ()=>{
      if (isMobile){
        return (
          <div className='app-header'>          
            
          <div className='header-left-group'>
            <Tooltip title="Notifications" arrow>
              <IconButton className={`header-icon ${(sidebarView=='notifications' && rightSidebarOpen) ? 'active': ''}`} 
              onClick={()=>{
                setSidebarView(rightSidebarOpen ? null : 'notifications');
                if (isMobile){
                  toggleRightSidebar(!rightSidebarOpen)
                }
              }}>
                <NotificationsOutlined color='black'/>
              </IconButton>
              </Tooltip>
          </div>
            
            <div 
              onClick={handleHomeButtonClick}
            >
              <Link to='/'><img src={logo} className="App-logo fm" alt="logo" /></Link>
            </div>
      
            <div className='header-right-group'>
              
              <Tooltip title="Profile" arrow>
                <IconButton className={`header-icon ${(sidebarView=='profile' && rightSidebarOpen) ? 'active': ''}`} 
                onClick={()=>{
                  setSidebarView(rightSidebarOpen ? null : 'profile');
                  if (isMobile){
                    toggleRightSidebar(!rightSidebarOpen)
                  }
                }}>
                  <AccountCircleOutlined color='black'/>
                </IconButton>
              </Tooltip>
            </div>
      
        </div>     
        )
      } else {
        return (
          <div className='app-header'>          
            
          <div className='header-left-group'>
              
          <IconButton
            className={`header-icon ${activeIcon=='home' ? 'active' : ''}`} 
            onClick={(event)=>handleClose('Home')} 
          >
            <HomeOutlined />
          </IconButton>

          <IconButton
            className={`header-icon ${activeIcon=='vessels' ? 'active' : ''}`} 
            onClick={(event)=>handleOpenMenu(event, 'vessels')} 
          >
            <DirectionsBoatOutlined />
          </IconButton>

          <IconButton
            className={`header-icon ${activeIcon=='trips' ? 'active' : ''}`}
            onClick={(event)=>handleOpenMenu(event, 'trips')} 
          >
            <RouteOutlined />
          </IconButton>

          <IconButton
            className={`header-icon ${activeIcon=='groups' ? 'active' : ''}`}
            onClick={(event)=>handleOpenMenu(event, 'groups')} 
          >
            <GroupOutlined />  
          </IconButton>

          <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              onMouseLeave={handleClose}
              className='header-menu'
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
          >{
            menuItems && 
            menuItems.map((item, index)=>(
              <MenuItem onClick={()=>handleClose(item)} key={index}>{item}</MenuItem>
            ))
          }
        </Menu>

            
          </div>
            
            <div 
              onClick={handleHomeButtonClick}
            >
              <Link to='/'><img src={logo} className="App-logo fm" alt="logo" /></Link>
            </div>
      
            <div className='header-right-group'>
            <Tooltip title="Profile" arrow>
              <IconButton className={`header-icon ${(sidebarView=='profile' && rightSidebarOpen) ? 'active': ''}`}  onClick={()=>{setSidebarView(sidebarView=='profile' ? null : 'profile')}}>
                <AccountCircleOutlined color='black' sx={{color:'#a3a9b1'}}/>
              </IconButton>
            </Tooltip>
              
            <Tooltip title="Notifications" arrow>
              <IconButton className={`header-icon ${(sidebarView=='notifications' && rightSidebarOpen) ? 'active': ''}`}  onClick={()=>{setSidebarView(sidebarView=='notifications' ? null : 'notifications')}}>
                <Badge 
                  badgeContent={notifications.length} 
                  color='primary' 
                  anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}>
                  <NotificationsOutlined color='black' sx={{color:'#a3a9b1'}}/>
                </Badge>
              </IconButton>
              </Tooltip>
            </div>
      
        </div>     
        )
      }
    }

    const footer = ()=>{
      return (
        <div className='app-footer'>          
            
          <div className='footer-icon-group'>
            <Tooltip title="Home" arrow>
              <Link className={`header-icon ${activeIcon=='home' ? 'active' : ''}`}
                to={ location.pathname=='/home' ? (vessel_id==null ? '/vessels/select' : '/vessels/') : '/home' } params={{ vessel_id:vessel_id }}>
                <HomeOutlined />
              </Link>
            </Tooltip>
            
            <Tooltip title="Vessel" arrow>
              <Link className={`header-icon ${activeIcon=='vessels' ? 'active' : ''}`}
                to={vessel_id==null ? '/vessels/select' : '/vessels/'} params={{vessel_id: vessel_id}}> 
                <DirectionsBoatOutlined />
              </Link>
            </Tooltip>      

            <Tooltip title="Trips" arrow>
              <Link className={`header-icon ${activeIcon=='trips' ? 'active' : ''}`}
                to={'/trips'}
                params={{node_id: selectedNode?.id}} 
                mask={{to:`/trips/`}}
                >
                <RouteOutlined />
              </Link>
            </Tooltip>

            <Tooltip title="Groups" arrow>
              <Link className={`header-icon ${activeIcon=='groups' ? 'active' : ''}`}
                to={'/groups/$node_id/members'}
                params={{node_id: selectedNode?.id}} 
                mask={{to:`/groups/${selectedNode?.name.replace(' ', '-')}/manage`}}
                >
                <GroupOutlined />
              </Link>
            </Tooltip>
            
            
          </div>
        </div>     
      )
    }

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>      
        
        

        {header()}
          
        <div className={`App ${sidebarView==null?'':'open'} auth ${isMobile?'mobile':''}`}         
          style={{
            position: 'absolute',
            top:`${TOP_BAR_HEIGHT}px`,
            left: 0,
            width: '100vw',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
    
          <div style={{width:"100%", height:'100%'}} ref={ref} className="height-ref-div"></div>
          <ErrorBoundary>
              
            <Outlet /> 
              
          </ErrorBoundary>
          
        {SideBar()}
        </div>

        {
        isMobile && 
        footer()
        }

        
      </LocalizationProvider>
        
    )
}